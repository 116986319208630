// Get Venus Finance Const
export const getVenusFinancePrices = "GET_VENUS_FINANCE_PRICES";
// Get Supply List Const
export const getVenusSupplyList = "GET_VENUS_SUPPLY_LIST";
// Get CToken Const
export const getVTokenList = "GET_VENUS_CTOKEN_LIST";
// Get CToken Price
export const getVTokenPriceList = "GET_VENUS_TOKEN_PRICE_LIST";
//  Get all contracts with snapshot
export const getContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT_VENUS";
//  Clear all contracts with snapshot
export const clearContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT_VENUS";
// Actions

// Get Venus Finance Action
export function getVenusFinancePricesAction(data) {
  return {
    type: getVenusFinancePrices,
    payload: data,
  };
}

// Get Supply List Action
export function getVenusSupplyListAction(data) {
  return {
    type: getVenusSupplyList,
    payload: data,
  };
}

// Get Supply List Action
export function getVTokenListAction(data) {
  return {
    type: getVTokenList,
    payload: data,
  };
}
// Get price token list Action
export function getVTokenPriceListAction(data) {
  return {
    type: getVTokenPriceList,
    payload: data,
  };
}

// Get all contracts with snapshot Action
export function getContractsWithSnapshotAction(data) {
  return {
    type: getContractsWithSnapshot,
    payload: data,
  };
}

export function clearContractsWithSnapshotAction() {
  return {
    type: clearContractsWithSnapshot,
  };
}
