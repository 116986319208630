import { BigNumber } from "bignumber.js";
import {
  ctokenAddress,
  vtokenAddress,
  etherscanUrl,
  bscscanUrl,
} from "../constants/constant";
import millify from "millify";
export function getHeaders(isFormData) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (isFormData) {
    delete headers["Accept"];
    delete headers["Content-Type"];
  }
  return headers;
}

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};
export function millifyNumber(value, options) {
  if (Number.MAX_SAFE_INTEGER < value) {
    return value.toFixedNoRounding(2);
  } else {
    return millify(value, options);
  }
}

function toFixed(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}
export function getTokenAddressQueryString(protocol, network) {
  let tokens = [];
  if (protocol === "compound") {
    tokens = ctokenAddress[network];
  } else if (protocol === "venus") {
    tokens = vtokenAddress[network];
  }
  let query = "";
  for (let index = 0; index < tokens.length; index++) {
    query += `&addresses[]=${tokens[index].address}`;
  }
  return query;
}
export function getExchangeUrl() {
  let url = etherscanUrl;
  if (window.location.pathname === "/venus/manage") {
    url = bscscanUrl;
  }
  return url;
}
export function truncateNumBeforeTransaction(value) {
  // value = value.toString();
  // if (value.indexOf(".") !== -1) {
  //   let decimalPart = value.split(".")[1];
  //   if (decimalPart.length > 6) {
  //     value = `${value.split(".")[0]}.${decimalPart.substring(0, 6)}`;
  //   }
  // }
  value = Number(value);
  return value;
}
String.prototype.capitalizeString = function () {
  let str = this;
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};
Number.prototype.toFixedNoRounding = function (n) {
  let num = new BigNumber(this);
  if (Number.MAX_SAFE_INTEGER < num) {
    num = Number(num).toExponential();
    let splitNum = num.split("e");
    num = splitNum[0].toFixedWithRegex(n);
    num = num + "e" + (splitNum[1] ? splitNum[1] : "");
  } else {
    num = num.toFixed(n);
  }
  return num;
};
String.prototype.toFixedNoRounding = function (n) {
  let num = new BigNumber(this);
  if (Number.MAX_SAFE_INTEGER < num) {
    num = Number(num).toExponential();
    let splitNum = num.split("e");
    num = splitNum[0].toFixedWithRegex(n);
    num = num + "e" + splitNum[1] ? splitNum[1] : "";
  } else {
    num = num.toFixed(n);
  }
  return num;
};

Number.prototype.toFixedWithRegex = function (n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  let str = this.toString();
  if (str !== "Infinity") {
    const a = str.match(reg)[0];
    const dot = a.indexOf(".");
    if (dot === -1) {
      // integer, insert decimal dot and pad up zeros
      return a + "." + "0".repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + "0".repeat(b) : a;
  } else {
    return str;
  }
};
String.prototype.toFixedWithRegex = function (n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  let str = this.toString();
  if (str !== "Infinity") {
    const a = str.match(reg)[0];
    const dot = a.indexOf(".");
    if (dot === -1) {
      // integer, insert decimal dot and pad up zeros
      return a + "." + "0".repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + "0".repeat(b) : a;
  } else {
    return str;
  }
};
