import { Fragment, useState, useEffect } from 'react';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { RightMenu } from "./rightMenu";

let walletSvgs = {
   metamask: "metamask.svg",
   trezor: "trezor.svg",
   ledger: "ledger.svg",
   fortmatic: "fortmatic.svg",
   walletconnect: "wallet.svg",
   coinbase: "coinbase.svg",
   trackAddress: "track-address.svg",
}
function Header(props) {
   const [state, setState] = useState({
      linkName: ''
   })
   useEffect(() => {
      if (props.isConnected) {
         ReactTooltip.rebuild();
      }
   }, [props.isConnected])

   // Toggle Header Dropdown
   const toggleDropdown = (name) => {
      let linkName = state.linkName === name ? '' : name
      setState({
         ...state,
         linkName
      })
   }
   const trimAddress = (address) => {
      let length = address.length;
      if (length > 20) {
         address = address.substring(0, 6) + "..." + address.substring(length - 5, length);
      }
      return address;
   }
   const toFixedBalance = (balance) => {
      if (balance) {
         let currency = balance.replace(/[^a-zA-Z ]/g, "")
         balance = parseFloat(balance).toFixedNoRounding(2) + currency;
      }
      return balance;
   }
   return (
      <Fragment>
         <div className="header-wrapper">
            <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
               <div className="width-container">
                  <Link to="/"><Navbar.Brand >Elixyr</Navbar.Brand></Link>
                  <Navbar.Collapse id="responsive-navbar-nav">
                     <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/" className={props.name === 'Portfolio' ? "active" : ''} >Portfolio</Nav.Link>
                        <Nav.Link as={Link} to="/compound/manage" className={props.name === 'Compound' ? "active" : ''} >Compound</Nav.Link>
                        <Nav.Link as={Link} to="/aave/manage" className={props.name === 'Aave' ? "active" : ''} >Aave</Nav.Link>
                        {/* <Nav.Link as={Link} to="/venus/manage" className={props.name === 'Venus' ? "active" : ''} >Venus</Nav.Link> */}
                        <Nav.Link as={Link} to="/smart-savings" className={props.name === 'Smart' ? "active" : ''} >Smart Savings</Nav.Link>
                     </Nav>
                  </Navbar.Collapse>

                  <div className="nav-icons-wrappers">
                     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                     {props.isConnected ?
                        <div className="wallet-address text-right mr-2">
                           <p className="mb-0" data-tip={props.accountId} >{trimAddress(props.accountId)}</p>
                           <a target="_blank" data-tip={props.balance} href={`https://etherscan.io/address/${props.accountId}`}>{toFixedBalance(props.balance)}</a>
                        </div> : ""}
                     <Nav>
                        <Dropdown>
                           <Dropdown.Toggle variant="Secondary" id="dropdown-basic" className={`${props.isConnected ? "active" : ""}`}>{props.isConnected ? <img src={require(`../../assets/svgs/${walletSvgs[props.connectedWallet]}`).default} alt="" width="20" height="20" /> : "Connect your wallet"}</Dropdown.Toggle>
                           <Dropdown.Menu>
                              <Dropdown.Item className={`meta ${props.connectedWallet === "metamask" ? "active" : ""}`} onClick={() => props.connectMetaMask()} >
                                 <img src={require("../../assets/svgs/metamask.svg").default} alt="" width="20" height="20" />
                                  MetaMask</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "trezor" ? "active" : ""}`} ><img src={require("../../assets/svgs/trezor.svg").default} alt="" width="20" height="20" /> Trezor</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "ledger" ? "active" : ""}`} ><img src={require("../../assets/svgs/ledger.svg").default} alt="" width="20" height="20" /> Ledger</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "fortmatic" ? "active" : ""}`} ><img src={require("../../assets/svgs/fortmatic.svg").default} alt="" width="20" height="20" /> Fortmatic</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "walletconnect" ? "active" : ""}`} ><img src={require("../../assets/svgs/wallet.svg").default} alt="" width="20" height="20" /> WalletConnect</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "coinbase" ? "active" : ""}`} ><img src={require("../../assets/svgs/coinbase.svg").default} alt="" width="20" height="20" /> Coinbase</Dropdown.Item>
                              <Dropdown.Item className={`${props.connectedWallet === "track" ? "active" : ""}`} onClick={() => props.handleModalChange(true, "trackAddress")}  ><span className="icon fa fa-search"></span> Track Address</Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </Nav>
                     {props.isConnected ?
                        <RightMenu apiCallStatus={props.apiCallStatus} days={props.days} gasPriceHistory={props.gasPriceHistory} currentGasPrice={props.currentGasPrice} linkName={state.linkName} toggleDropdown={toggleDropdown} />
                        : ""}
                  </div>
               </div>
            </Navbar >
         </div >
         <ReactTooltip />
      </Fragment >
   );
}

export default Header;