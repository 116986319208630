import { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/header";
import ConnectWallet from "../../components/ConnectWallet/connectWallet";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletLink from 'walletlink';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from 'fortmatic';
import TrezorConnect from 'trezor-connect';
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { Client } from 'coinbase';
import { getCookie, setCookie } from "../../utils/cookies";
import { Modal, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import { infuraId, compoundChainId, network, etheriumProvider } from "../../constants/constant";
import { getTokenPrices } from '../../views/Compound/ApiCalls/compound'
import { getEthPrice, getCurrentGasPrice, getGasPriceHistory } from "../../components/Common/ApiCalls/apiCalls";
import { updateAccountDetailAction } from '../../components/Common/Actions/action';
import { ClearApiByNameAction } from "../../components/ApiCallStatus/Actions/action";
import { toast } from "react-toastify";

const history = createBrowserHistory();
var web3Modal;
var provider;
var trezorConnect;
var interval;
function DashboardLayout(props) {
  const [state, setState] = useState({
    isConnectingWallet: false,
    isConnected: getCookie('isConnected') === 'true',
    connectedWallet: getCookie('connectedWallet'),
    accountId: getCookie('accountId'),
    balance: getCookie('balance'),
    days: 7,
    network: network,
  })
  const [dialog, updatedDialog] = useState({
    showModal: false,
    modalType: "",
  })
  useEffect(() => {
    // initialize providers
    initializeProvider();
    // fetch etherium price();
    getTokenPrices();
    // fetch current gas price 
    props.getCurrentGasPrice();
    // fetch gas price history
    props.getGasPriceHistory(state.days);
    // trackAddress()
  }, [])
  useEffect(() => {
    if (state.isConnected && (state.connectedWallet === "metamask" || state.connectedWallet === "trackAddress") && interval === undefined) {
      interval = setInterval(async () => {
        if (etheriumProvider) {
          const web3 = new Web3(etheriumProvider);
          if (state.connectedWallet === "metamask") {
            const chainId = await web3.eth.getChainId();
            if (compoundChainId == chainId) {
              const accounts = await web3.eth.getAccounts();
              if (accounts.length === 0) {
                setCookie('isConnected', false);
                setCookie('connectedWallet', '');
                setCookie('accountId', '')
                setState({
                  ...state,
                  isConnected: false,
                  accountId: "",
                  connectedWallet: '',
                  isConnectingWallet: false
                })
                clearInterval(interval);
                interval = undefined;
                history.push("/")
              } else {
                web3.eth.getBalance(accounts[0], async (err, balance) => {
                  if (balance) {
                    let networkType = await web3.eth.net.getNetworkType();
                    let currency = "ETH";
                    if (networkType === "private") {
                      currency = "BNB";
                    }
                    balance = web3.utils.fromWei(balance.toString(), "ether") + ` ${currency}`;
                    setCookie('balance', balance);
                    setCookie('currency', currency);
                    setCookie('accountId', accounts[0]);
                    props.updateAccountDetailAction({
                      accountId: accounts[0],
                      balance: balance,
                      connectedWallet: 'metamask',
                      isConnected: true,
                      currency: currency,
                    })
                    setState({
                      ...state,
                      balance,
                      accountId: accounts[0]
                    })
                  }
                });
              }
            } else {
              showNetworkAlert();
              clearInterval(interval);
              interval = undefined;
            }
          } else if (state.connectedWallet === "trackAddress") {
            let accountId = getCookie("accountId");
            web3.eth.getBalance(accountId).then(async balance => {
              let networkType = await web3.eth.net.getNetworkType();
              let currency = "ETH";
              if (networkType === "private") {
                currency = "BNB";
              }
              balance = web3.utils.fromWei(balance, "ether") + ` ${currency}`;
              setCookie('isConnected', true);
              setCookie('connectedWallet', 'trackAddress');
              setCookie('accountId', accountId);
              setCookie('balance', balance);
              setCookie('currency', currency);
              props.updateAccountDetailAction({
                accountId: accountId,
                balance: balance,
                connectedWallet: 'trackAddress',
                isConnected: true,
                currency: currency,
              })
              setState({
                ...state,
                accountId,
                balance,
                isConnectingWallet: false,
                isConnected: true,
                connectedWallet: 'trackAddress',
              })
            });
          }
        }
      }, 1000);
    }
  }, [state.isConnected]);
  useEffect(() => {
    if (props.apiCallStatus.apiCallFor === "connectWallet") {
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      handleModalChange(true, "connectWallet")
    }
  }, [props.apiCallStatus.apiCallFor])
  const initializeProvider = async () => {
    try {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: infuraId,
          }
        },
        fortmatic: {
          package: Fortmatic,
          options: {
            // Mikko's TESTNET api key
            key: "pk_test_78E884EF853F3DBB"
          }
        },
        coinbase: {
          package: Client,
          options: {
            // Mikko's TESTNET api key
            key: "pk_test_391E26A3B43A3350"
          }
        },
      };
      web3Modal = new Web3Modal({
        // network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions // required
      });
      if (trezorConnect === undefined) {
        trezorConnect = TrezorConnect.init({
          connectSrc: 'https://connect.trezor.io/8/',
          lazyLoad: true, // this param will prevent iframe injection until TrezorConnect.method will be called
          manifest: {
            email: 'nkx.wilson@gmail.com',
            appUrl: 'localhost:3000',
          }
        })
      }
    } catch (e) {
      console.log("Could not get a wallet connection", e);
    }
  }
  const connectToWallet = async (providerName) => {
    try {
      setState({
        ...state,
        isConnectingWallet: true
      })
      if (web3Modal) {
        if (providerName) {
          provider = await web3Modal.connectTo(providerName);

        } else {
          provider = await web3Modal.connect();
        }
        // Subscribe to provider connection
        provider.on("connect", (info) => {
          console.log(info);
          setCookie('isConnected', true);
          setCookie('connectedWallet', providerName);
          setState({
            ...state,
            accountId: info,
            isConnectingWallet: false,
            isConnected: true,
            connectedWallet: providerName
          })
        });
        provider.on("disconnect", (info) => {
          console.log(info);
          setState({
            ...state,
            isConnected: false,
            accountId: "",
            connectedWallet: '',
            isConnectingWallet: false
          })
        });
      }
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      setState({
        ...state,
        isConnected: false,
        accountId: "",
        connectedWallet: '',
        isConnectingWallet: false
      })
    }
  }
  const connectMetaMask = async () => {
    if (state.connectedWallet !== "metamask") {
      const web3 = new Web3(etheriumProvider);
      const chainId = await web3.eth.getChainId();
      if (compoundChainId == chainId) {
        setState({
          ...state,
          isConnectingWallet: true
        })
        if (etheriumProvider) {
          try {
            let balance = 0;
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();
            let accountId = accounts[0] ? accounts[0] : "";
            web3.eth.getBalance(accountId, async (err, balance) => {
              let networkType = await web3.eth.net.getNetworkType();
              let currency = "ETH";
              if (networkType === "private") {
                currency = "BNB";
              }
              balance = web3.utils.fromWei(balance, "ether") + ` ${currency}`;
              setCookie('isConnected', true);
              setCookie('connectedWallet', 'metamask');
              setCookie('accountId', accountId);
              setCookie('balance', balance);
              setCookie('currency', currency);
              props.updateAccountDetailAction({
                accountId: accountId,
                balance: balance,
                connectedWallet: 'metamask',
                isConnected: true,
                currency: currency,
              })
              setState({
                ...state,
                accountId,
                balance,
                isConnectingWallet: false,
                isConnected: true,
                connectedWallet: 'metamask'
              })
            });
            web3.eth.defaultAccount = accountId;
          } catch (error) {
            console.log(error)
            setState({
              ...state,
              isConnected: false,
              accountId: "",
              connectedWallet: '',
              isConnectingWallet: false
            })
          }
        }
      } else {
        toast.error(<div className="m-2">{`Wrong network - please set MetaMask to ${network}`}</div>, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          pauseOnHover: true,
          progress: false,
          closeButton: false
        });
        setCookie('isConnected', false);
        setCookie('connectedWallet', '');
        setCookie('accountId', '')
        setState({
          ...state,
          isConnected: false,
          accountId: "",
          connectedWallet: '',
          isConnectingWallet: false
        })
      }

    }
  }
  const connectCoinBase = async () => {
    setState({
      ...state,
      isConnectingWallet: true
    })
    try {
      const APP_NAME = 'Elixyr'
      const APP_LOGO_URL = 'https://app.defisaver.com/assets/images/logo.svg'
      const ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/8043bb2cf99347b1bfadfb233c5325c0'
      const CHAIN_ID = 1;
      const walletLink = new WalletLink({
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
        darkMode: true
      })
      const ethereum = walletLink.makeWeb3Provider(ETH_JSONRPC_URL, CHAIN_ID);
      web3 = new Web3(ethereum);
      ethereum.enable().then((accounts) => {
        setCookie('isConnected', true);
        setCookie('connectedWallet', 'coinbase');
        setState({
          ...state,
          accountId: accounts[0] ? accounts[0] : "",
          isConnectingWallet: false,
          isConnected: true,
          connectedWallet: 'coinbase'
        })
        web3.eth.defaultAccount = accounts[0]
      }).catch((error) => {
        setState({
          ...state,
          isConnected: false,
          accountId: "",
          connectedWallet: '',
          isConnectingWallet: false
        })
      })
    } catch (error) {
      console.log(error)
      setState({
        ...state,
        isConnected: false,
        accountId: "",
        connectedWallet: '',
        isConnectingWallet: false
      })
    }

  }
  const connectTrezorWallet = async (path) => {
    setState({
      ...state,
      isConnectingWallet: true
    })
    try {
      const result = await TrezorConnect.ethereumGetAddress({ path });
      let accountId = "";
      let isConnectingWallet = false;
      let isConnected = false;
      let connectedWallet = '';
      if (result.success) {
        setCookie('isConnected', true);
        setCookie('connectedWallet', 'trezor');
        accountId = result.payload.address;
        isConnected = true;
        connectedWallet = "trezor";
      }
      setState({
        ...state,
        accountId,
        isConnectingWallet,
        isConnected,
        connectedWallet,
      })
      handleModalChange(false, "")
    } catch (error) {
      console.log(error)
      setState({
        ...state,
        isConnected: false,
        accountId: "",
        connectedWallet: '',
        isConnectingWallet: false,
      })
      handleModalChange(false, "")
    }

  }
  const handleModalChange = (showModal, modalType) => {
    updatedDialog({
      showModal,
      modalType
    })
  }
  // Get Etherium Price after every 1 minute.
  const getTokenPrices = () => {
    setInterval(() => {
      if (state.isConnected) {
        props.getTokenPrices(true)
      }
    }, 60000)
  }
  // tract address using alchemyapi
  const trackAddress = (accountId) => {
    // Using HTTPS
    const web3 = new Web3(etheriumProvider);
    web3.eth.getBalance(accountId).then(async balance => {
      let networkType = await web3.eth.net.getNetworkType();
      let currency = "ETH";
      if (networkType === "private") {
        currency = "BNB";
      }
      balance = web3.utils.fromWei(balance, "ether") + ` ${currency}`;
      setCookie('isConnected', true);
      setCookie('connectedWallet', 'trackAddress');
      setCookie('accountId', accountId);
      setCookie('balance', balance);
      setCookie('currency', currency);
      props.updateAccountDetailAction({
        accountId: accountId,
        balance: balance,
        connectedWallet: 'trackAddress',
        isConnected: true,
        currency: currency,
      })
      setState({
        ...state,
        accountId,
        balance,
        isConnectingWallet: false,
        isConnected: true,
        connectedWallet: 'trackAddress',
      })
      handleModalChange(false, "")
    }).catch((error) => {

    });
  }
  const showNetworkAlert = () => {
    toast.error(<div className="m-2">{`Wrong network - please set MetaMask to ${network}`}</div>, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      pauseOnHover: true,
      progress: false,
      closeButton: false
    });
    setCookie('isConnected', false);
    setCookie('connectedWallet', '');
    setCookie('accountId', '')
    setState({
      ...state,
      isConnected: false,
      accountId: "",
      connectedWallet: '',
      isConnectingWallet: false
    })
  }
  return (
    <Fragment>
      <Header history={history} apiCallStatus={props.apiCallStatus} days={state.days} gasPriceHistory={props.gasPriceHistory} currentGasPrice={props.currentGasPrice} balance={state.balance} accountId={state.accountId} name={props.name} handleModalChange={handleModalChange} connectedWallet={state.connectedWallet} isConnected={state.isConnected} isConnectingWallet={state.isConnectingWallet} connectMetaMask={connectMetaMask} connectToWallet={connectToWallet} />
      {state.isConnected ? <BreadCrumbs links={props.links} tittleName={props.name} /> : ""}
      {state.isConnectingWallet ? <Loader /> : ""}
      <div className={`dashboard-page-wrapper ${!state.isConnected ? '' : props.wrapperClassName ? props.wrapperClassName : ""}`}>
        <div className="content-wrapper">
          {state.isConnected ?
            props.children
            : <ConnectWallet handleModalChange={handleModalChange} connectCoinBase={connectCoinBase} connectMetaMask={connectMetaMask} connectToWallet={connectToWallet} trackAddress={trackAddress} />}
        </div>
      </div>
      <ConnectModal showModal={dialog.showModal} modalType={dialog.modalType} handleModalChange={handleModalChange} connectTrezorWallet={connectTrezorWallet} connectCoinBase={connectCoinBase} trackAddress={trackAddress} connectMetaMask={connectMetaMask} connectToWallet={connectToWallet} />
    </Fragment>
  );
}

function Loader(props) {
  return <div className="loader-page-wrapper private">
    <div className="loader-wrapper">
      <div className="loader">
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">sub<path d="M100.19 53.2317C93.7818 58.1962 93.474 67.6211 99.2061 73.3532L184.129 158.277C188.974 163.121 196.571 163.656 202.54 160.293C235.692 141.616 276.303 141.616 309.454 160.293C315.423 163.656 323.021 163.121 327.865 158.277L412.789 73.3532C418.521 67.6211 418.213 58.1962 411.804 53.2317C320.186 -17.7439 191.807 -17.7439 100.19 53.2317Z" fill="#37B06F"></path><path d="M458.77 100.198C453.805 93.7895 444.381 93.4817 438.648 99.2138L353.725 184.137C348.881 188.982 348.346 196.579 351.709 202.548C370.385 235.7 370.385 276.31 351.709 309.462C348.346 315.431 348.881 323.029 353.725 327.873L438.648 412.796C444.381 418.528 453.805 418.221 458.77 411.812C529.746 320.194 529.746 191.815 458.77 100.198Z" fill="#37B06F"></path><path d="M327.865 353.727C323.021 348.883 315.423 348.348 309.454 351.711C276.303 370.387 235.692 370.387 202.54 351.711C196.571 348.348 188.974 348.883 184.129 353.727L99.2061 438.651C93.474 444.383 93.7818 453.808 100.19 458.772C191.807 529.748 320.186 529.748 411.804 458.772C418.213 453.808 418.521 444.383 412.789 438.65L327.865 353.727Z" fill="#37B06F"></path><path d="M160.293 202.548C163.656 196.579 163.121 188.982 158.277 184.137L73.3532 99.2138C67.6211 93.4817 58.1962 93.7895 53.2317 100.198C-17.7439 191.815 -17.7439 320.194 53.2317 411.812C58.1962 418.221 67.6211 418.528 73.3532 412.796L158.277 327.873C163.121 323.029 163.656 315.431 160.293 309.462C141.616 276.31 141.616 235.7 160.293 202.548Z" fill="#37B06F"></path></svg>
      </div>
      <div className="message-wrapper">Connecting your account, please wait...</div>
    </div>
  </div>
}
function ConnectModal(props) {
  const web3 = new Web3();
  const [path, setPath] = useState("m/44'/60'/0'/0/0");
  const [address, setAddress] = useState("");
  const isValidAddress = () => {
    return web3.utils.isAddress(address);
  }
  const trackAddress = (e) => {
    e.preventDefault();
    props.trackAddress(address);
  }
  return (
    <Modal dialogClassName={`custom-width ${props.modalType === "connectWallet" ? "connect-modal" : ""}`} show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
      <Modal.Header closeButton> <span className=" icon fa fa-search"></span>Track Address
      </Modal.Header>
      <Modal.Body>
        {props.modalType === "trackAddress" ?
          <div className="content-wrapper">
            <p>Simply enter your address to track your assets and decentralized finance portfolio without connecting your wallet.</p>
            <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
              <div className="blocker-content not-connected-wrapper">
                <div className="address-monitor form-wrapper">
                  <div className="address-monitor-inputs">
                    <div className="form-item-wrapper">
                      <div className="input-values">
                        <form id="get-address-for-monitoring">
                          <label className="address-label" htmlFor="address-for-monitoring">Ethereum address or ENS</label>
                          <input className="form-item" type="text" placeholder="0x" name="address-for-monitoring" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="" ><span><button type="submit" className="button" form="get-address-for-monitoring" disabled={!isValidAddress()} onClick={(e) => trackAddress(e)}>Connect</button></span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          :
          props.modalType === "trezor" ? <div className="connect-login-wrapper trezor-wrapper">
            <h2>Connect Trezor</h2><p>This is a secure way to access your wallet</p>
            <form className="form-wrapper">
              <div className="form-item-wrapper  ">
                <div className="input-values">
                  <label className="" htmlFor="connect-trezor-path" style={{ color: '#846d6d' }}>Path:</label>
                  <input name="path" placeholder="" id="connect-trezor-path" className="form-item " type="text" value={path} onChange={(e) => setPath(e.target.value)} style={{ color: '#846d6d' }} /><span className="label-replacement"></span>
                </div></div>
            </form>
          </div> : props.modalType === "connectWallet" ?
            <div className="cdp-blocker--message">
              <h3>Connect your wallet to manage your positions:</h3>
              <div className="connect-wallet-buttons-wrapper">
                <div className="button-option " onClick={() => props.connectMetaMask()}>
                  <img src={require("../../assets/svgs/dashboard/metamask-org.svg").default} width="53" height="53" alt="" />
                  <p className="connect-wallet-button-name">MetaMask</p>
                </div>
                <div className="button-option Trezor " onClick={() => props.handleModalChange(true, "trezor")}>
                  <img src={require("../../assets/svgs/dashboard/trezor.svg").default} alt="" />
                  <p className="connect-wallet-button-name">Trezor</p>
                </div>
                <div className="button-option Ledger ">
                  <img src={require("../../assets/svgs/dashboard/ledger.svg").default} alt="" />
                  <p className="connect-wallet-button-name">Ledger</p>
                </div>
                <div className="button-option Fortmatic " onClick={() => props.connectToWallet("fortmatic")}>
                  <img src={require("../../assets/svgs/dashboard/fortmatic.svg").default} alt="" />
                  <p className="connect-wallet-button-name">Fortmatic</p>
                </div>
                <div className="button-option WalletConnect " onClick={() => props.connectToWallet("walletconnect")}>
                  <img src={require("../../assets/svgs/dashboard/walletconnect.svg").default} alt="" />
                  <p className="connect-wallet-button-name">WalletConnect</p>
                </div>
                <div className="button-option Coinbase " onClick={() => props.connectCoinBase()}>
                  <img src={require("../../assets/svgs/dashboard/coinbase.svg").default} alt="" />
                  <p className="connect-wallet-button-name">Coinbase</p>
                </div>
                <div className="button-option track-address " onClick={() => props.handleModalChange(true, "trackAddress")}>
                  <img src={require("../../assets/svgs/dashboard/track-address.svg").default} alt="" />
                  <p className="connect-wallet-button-name">Track Address</p>
                </div>
              </div>
            </div> : ""}
      </Modal.Body>
      <Modal.Footer>
        {props.modalType === "trezor" ?
          <Button variant="primary" onClick={() => props.connectTrezorWallet(path)}>
            Connect
          </Button> : ""}
      </Modal.Footer>
    </Modal >
  );
}
function BreadCrumbs(props) {
  const validateAndGetUrl = () => {
    let isValid = false;
    let url = '';
    let isTransparent = false;
    if (window.location.pathname.indexOf("compound") !== -1) {
      url = "/compound/saver";
      isValid = true;
      isTransparent = url !== window.location.pathname;
    } else if (window.location.pathname.indexOf("venus") !== -1) {
      url = "/venus/saver";
      isValid = true;
      isTransparent = url !== window.location.pathname;
    }
    return { isValid, url, isTransparent }
  }
  let { isValid, url, isTransparent } = validateAndGetUrl();
  return <div className="sub-header-wrapper first">
    <div className="width-container">
      <div className="row">
        <div className="col-sm-6">
          <div className="page-title">{props.tittleName}</div>
        </div>
        {/* {isValid ?
          <div className="col-sm-6 text-right">
            <Link type="button" className={`button ${isTransparent ? "btn-transparent" : ""}`} to={url}>Create</Link>
          </div>
          : ""} */}
      </div>
      <div className="navigation-links-wrapper links-wrapper left">
        <div className="links">
          {props.links ? props.links.map((item, index) => {
            return <Link key={index} className={`undefined ${item.url === window.location.pathname ? "active" : ""}`} to={item.url}><span className="label" dangerouslySetInnerHTML={{ __html: item.name }} ></span></Link>
          }) : ""}

          {/* <a className=" undefined" href="/"><span className="label">History</span><span className="badge">(BETA)</span></a> */}
        </div>
        {props.links ?
          <div className="select-container">
            <div className="label">Pages:</div>
            <div className="select box css-2b097c-container">

              <Dropdown>
                <Dropdown.Toggle variant="Secondary" id="dropdown-basic"><span className="label" dangerouslySetInnerHTML={{ __html: props.links[0].name }} ></span></Dropdown.Toggle>
                <Dropdown.Menu>
                  {props.links.map((item, index) => {
                    return <Dropdown.Item as={Link} to={item.url} key={index} className={`${item.url === window.location.pathname ? "active" : ""}`}><span className="label" dangerouslySetInnerHTML={{ __html: item.name }} ></span></Dropdown.Item>
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          : ""}
      </div>
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => ({
  // Get api handler state
  apiCallStatus: state.apicallStatusReducer,
  //Get Etherium Price
  ethPrice: state.commonReducer.ethPrice,
  //Get Current Gas Price
  currentGasPrice: state.commonReducer.currentGasPrice,
  //Get Gas Price History
  gasPriceHistory: state.commonReducer.gasPriceHistory
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  // Get Token Prices
  getTokenPrices: (isUpdatePrice) => dispatch(getTokenPrices(isUpdatePrice)),
  // Get Etherium Price Apicall
  getEthPrice: (address) => dispatch(getEthPrice(address)),
  // Get Current Gas Price Apicall
  getCurrentGasPrice: () => dispatch(getCurrentGasPrice()),
  // Get Gas Price History Apicall
  getGasPriceHistory: (days) => dispatch(getGasPriceHistory(days)),
  // Update Account Detail
  updateAccountDetailAction: (data) => dispatch(updateAccountDetailAction(data)),
  //clear api name 
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLayout)