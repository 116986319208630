import Web3 from "web3";
import {
  network as defaultNetwork,
  infuraUrl,
  infuraId,
  etheriumProvider,
} from "../constants/constant";

export function readContractByName(type, name, network, address) {
  try {
    let contractABI = [];
    let contractAddress = "";
    let decimal = 0;
    let data = getContractABIByName(type, name, network);
    contractABI = data.contractABI;
    if (address) {
      contractAddress = address;
    } else {
      contractAddress = data.contractAddress;
    }
    if (data.decimal) {
      decimal = data.decimal;
    }
    // Get network provider and web3 instance.
    const web3 = new Web3(etheriumProvider);
    const instance = new web3.eth.Contract(contractABI, contractAddress);
    return {
      web3,
      instance,
      contractABI,
      contractAddress,
      decimal,
    };
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export async function readUnderLyingContractByName(
  type,
  name,
  network,
  address
) {
  try {
    let contractABI = [];
    let contractAddress = "";
    let decimal = 0;
    let data = getContractABIByName(type, name, network);
    contractABI = data.contractABI;
    if (address) {
      contractAddress = address;
    } else {
      contractAddress = data.contractAddress;
    }
    if (data.decimal) {
      decimal = data.decimal;
    }
    // Get network provider and web3 instance.
    const web3 = new Web3(etheriumProvider);
    const contractInstance = new web3.eth.Contract(
      contractABI,
      contractAddress
    );
    let instanceExecute;
    let underlyingAddress = "0x0000000000000000000000000000000000000000";
    Object.keys(contractInstance.methods).map((key) => {
      if (
        key === "underlying()" ||
        key === "underlyingAssetAddress()" ||
        key === "UNDERLYING_ASSET_ADDRESS()"
      ) {
        instanceExecute = contractInstance.methods[key];
      }
    });
    if (instanceExecute) {
      underlyingAddress = await instanceExecute().call();
    } else {
      if (name === "ETH") {
        underlyingAddress = getContractKeyByName(
          type,
          name,
          `underLyingAddress`
        );
      } else {
        underlyingAddress = data.contractAddress;
      }
    }
    const underlyingInstance = new web3.eth.Contract(
      contractABI,
      underlyingAddress
    );
    decimal = await underlyingInstance.methods.decimals().call();
    return {
      web3,
      instance: underlyingInstance,
      contractABI,
      contractAddress: underlyingAddress,
      decimal,
    };
  } catch (error) {
    console.error(error);
    return { error };
  }
}
export function getContractABIByName(type, name, network) {
  if (type === undefined) {
    type = "compound";
  }
  let contract = require(`./../contracts/${type}/${name}.json`);
  if (contract) {
    let response = {
      contractAddress: contract[network]
        ? contract[network]
        : contract[defaultNetwork],
      contractABI: contract.abi,
    };
    if (contract.decimal) {
      response["decimal"] = contract.decimal;
    }
    return response;
  }
  return { address: "", abi: [] };
}

export const getAllEstimationGasPrice = (
  instance,
  options,
  currentGasPrice,
  accountId
) => {
  let safeLowGasPrice =
    currentGasPrice && currentGasPrice.safeLow ? currentGasPrice.safeLow : 0;
  let averageGasPrice =
    currentGasPrice && currentGasPrice.average ? currentGasPrice.average : 0;
  let fastestGasPrice =
    currentGasPrice && currentGasPrice.fastest ? currentGasPrice.fastest : 0;
  safeLowGasPrice = 1;
  return Promise.all([
    calculateEstimateGasPrice(instance, {
      from: accountId,
      gasPrice: safeLowGasPrice * 10e8,
      ...options,
    }),
    calculateEstimateGasPrice(instance, {
      from: accountId,
      gasPrice: averageGasPrice * 10e8,
      ...options,
    }),
    calculateEstimateGasPrice(instance, {
      from: accountId,
      gasPrice: fastestGasPrice * 10e8,
      ...options,
    }),
  ])
    .then((response) => {
      return {
        isError: false,
        gasPrice: {
          safeLow: response[0],
          average: response[1],
          fastest: response[2],
        },
      };
    })
    .catch((error) => {
      let errorMesssage =
        typeof error.message === "string"
          ? error.message.split("{")[0]
          : error.message;
      return {
        isError: true,
        error: errorMesssage,
      };
    });
};

export function getContractAddressByName(type, name, network, isEncoded) {
  try {
    if (type === undefined) {
      type = "compound";
    }
    let contract = require(`./../contracts/${type}/${name}.json`);
    if (contract) {
      return contract[network];
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function getEncodedContractAddressByName(type, name, network, web3) {
  try {
    if (type === undefined) {
      type = "compound";
    }
    let contract = require(`./../contracts/${type}/${name}.json`);
    if (contract) {
      let address = contract[network];
      let enodeValue = web3.eth.abi.encodeParameter("address", address);
      return enodeValue;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function getContractDecimalByName(type, name) {
  if (type === undefined) {
    type = "compound";
  }
  let contract = require(`./../contracts/${type}/${name}.json`);
  if (contract.decimal) {
    return Number("1e" + contract.decimal);
  }
  return 18;
}
export async function getContractUnderlyingAddressByName(
  type,
  name,
  network,
  address
) {
  try {
    let contractABI = [];
    let contractAddress = "";
    let data = getContractABIByName(type, name, network);
    contractABI = data.contractABI;
    if (address) {
      contractAddress = address;
    } else {
      contractAddress = data.contractAddress;
    }
    // Get network provider and web3 instance.
    const web3 = new Web3(etheriumProvider);
    const contractInstance = new web3.eth.Contract(
      contractABI,
      contractAddress
    );
    let instanceExecute;
    Object.keys(contractInstance.methods).map((key) => {
      if (
        key === "underlying()" ||
        key === "underlyingAssetAddress()" ||
        key === "UNDERLYING_ASSET_ADDRESS()"
      ) {
        instanceExecute = contractInstance.methods[key];
      }
    });
    let underlyingAddress = "0x0000000000000000000000000000000000000000";
    if (instanceExecute) {
      underlyingAddress = await instanceExecute().call();
    } else {
      if (name === "ETH") {
        underlyingAddress = getContractKeyByName(
          type,
          name,
          `underLyingAddress`
        );
      } else {
        underlyingAddress = data.contractAddress;
      }
    }
    return underlyingAddress;
  } catch (error) {
    console.error(error);
    return { error };
  }
}

export function getContractKeyByName(type, name, key) {
  if (type === undefined) {
    type = "compound";
  }
  let contract = require(`./../contracts/${type}/${name}.json`);
  if (contract[key]) {
    return contract[key];
  }
  return "0x0000000000000000000000000000000000000000";
}

const calculateEstimateGasPrice = (instance, options) => {
  return new Promise(async (resolve, reject) => {
    try {
      let gasPrice = await instance.estimateGas(options);
      resolve(gasPrice);
    } catch (error) {
      reject(error);
    }
  });
};
