import {
  backendApiUrl,
  compoundFinanceApiUrl,
  aaveApiBaseUrl,
  aavePoolId,
} from "../../../constants/constant";
import { FETCH } from "../../../utils/fetch";
import {
  ApiRequestedAction,
  ApiFulfilledAction,
  ApiRejectedAction,
} from "../../../components/ApiCallStatus/Actions/action";
import {
  getTransactionHistoryAction,
  getPortFolioStatsAction,
} from "../Actions/action";
import { readContractByName } from "../../../utils/contracts";
import { getTokenAddressQueryString } from "../../../utils/common";
import { BigNumber } from "bignumber.js";

// Apicall to get transaction list
export function getTransactionHistory() {
  return (dispatch) => {
    let apiCallFor = "getTransactionHistory";
    dispatch(ApiRequestedAction({ apiCallFor }));
    let url = `${backendApiUrl}transactions`;
    FETCH("GET", url)
      .then(function (myJson) {
        if (myJson && myJson.statusCode === 200 && myJson.response.status) {
          let data = myJson.response;
          dispatch(
            getTransactionHistoryAction({
              list: data.data.result,
              total: data.data.total,
            })
          );
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: myJson.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor,
              message: myJson.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// get connected account supply list
export function getPortFolioStats(network, accountId) {
  return async (dispatch) => {
    let apiCallFor = "getPortFolioStats";
    try {
      dispatch(ApiRequestedAction({ apiCallFor }));
      const { instance } = readContractByName(
        "compound",
        "ProxyRegistry",
        network
      );
      const proxies = await instance.methods.proxies(accountId).call();
      let smartWalletAddress = "0x";
      if (proxies !== "0x0000000000000000000000000000000000000000") {
        smartWalletAddress = proxies;
      }
      const promises = [
        getCompoundStats(network, accountId, smartWalletAddress),
        getAaveStats(network, accountId, smartWalletAddress),
      ];
      Promise.all(promises)
        .then((response) => {
          let data = {};
          data["compound"] = response[0];
          data["aavev1"] = response[1]["v1"];
          data["aavev2"] = response[1]["v2"];
          dispatch(getPortFolioStatsAction(data));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: "data.message",
            })
          );
        })
        .catch((error) => {
          dispatch(
            ApiRejectedAction({
              statusCode: 400,
              apiCallFor,
              message: error,
            })
          );
        });
    } catch (error) {
      dispatch(
        ApiRejectedAction({
          statusCode: 400,
          apiCallFor,
          message: error,
        })
      );
    }
  };
}

function getCompoundStats(network, accountId, smartWalletAddress) {
  return new Promise(async (resolve, reject) => {
    try {
      let query = `network=${network}${getTokenAddressQueryString(
        "compound",
        network
      )}`;
      let url = `${compoundFinanceApiUrl}ctoken?${query}`;
      let headers = {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      };
      let { response } = await FETCH("GET", url, "", false, headers);
      let list = response.cToken;
      const promises = [getTokenPrices("compound")];
      list &&
        list.forEach((element) => {
          promises.push(
            getSnapshotOfCToken(
              element.underlying_symbol,
              network,
              element.token_address,
              accountId,
              smartWalletAddress
            )
          );
        });
      Promise.all(promises).then((response) => {
        let data = {
          account: { supplyBalance: 0, borrowBalance: 0 },
          smartWallet: { supplyBalance: 0, borrowBalance: 0 },
        };
        let ethPrice = 0;
        let ethIndex = response
          ? response[0].findIndex((x) => x.token === "ETH")
          : -1;
        if (ethIndex !== -1 && response[0]) {
          ethPrice = response[0][ethIndex]["price"];
        }
        response.forEach((element, index) => {
          if (index !== 0) {
            data["account"]["supplyBalance"] +=
              element["account"]["snapshot"]["supplyBalance"] * ethPrice;
            data["account"]["borrowBalance"] +=
              element["account"]["snapshot"]["borrowBalance"] * ethPrice;
            data["smartWallet"]["supplyBalance"] +=
              element["smartWallet"]["snapshot"]["supplyBalance"] * ethPrice;
            data["smartWallet"]["borrowBalance"] +=
              element["smartWallet"]["snapshot"]["borrowBalance"] * ethPrice;
          }
        });
        resolve(data);
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

function getSnapshotOfCToken(
  name,
  network,
  address,
  accountId,
  smartWalletAddress
) {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = readContractByName("compound", name, network, address);
      let accountAddress = [
        { type: "account", address: accountId },
        { type: "smartWallet", address: smartWalletAddress },
      ];
      let obj = { name };
      for (let index = 0; index < accountAddress.length; index++) {
        const element = accountAddress[index];
        let snapshot = await contract.instance.methods
          .getAccountSnapshot(element.address)
          .call();
        let supplyBalance = new BigNumber(snapshot[1])
          .times(snapshot[3])
          .div(new BigNumber(10).pow(contract.decimal + 18));
        supplyBalance = supplyBalance.toNumber();
        let borrowBalance = new BigNumber(snapshot[2]).div(
          new BigNumber(10).pow(contract.decimal)
        );
        borrowBalance = borrowBalance.toNumber();
        let snap = {
          supplyBalance: "0",
          borrowBalance: "0",
          exchangeRate: "0",
        };
        if (snapshot) {
          snap = {
            supplyBalance: supplyBalance, // snapshot[1]
            borrowBalance: borrowBalance, //snapshot[2],
            exchangeRate: snapshot[3],
          };
        }
        obj[element.type] = {
          type: element.type,

          snapshot: snap,
          address: element.address,
        };
      }
      resolve(obj);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

function getAaveStats(network, accountId, smartWalletAddress) {
  return new Promise(async (resolve, reject) => {
    try {
      Promise.all([
        getTokenPrices("aave"),
        getAtokenList(network, "v1"),
        getAtokenList(network, "v2"),
      ])
        .then(function (data) {
          let ethPrice = 0;
          let ethIndex = data[0]
            ? data[0].findIndex((x) => x.token === "ETH")
            : -1;
          if (ethIndex !== -1 && data[0]) {
            ethPrice = data[0][ethIndex]["price"];
          }
          Promise.all([
            getAaveVersionSnapShot(
              "aave/v1",
              network,
              data[1],
              accountId,
              smartWalletAddress,
              ethPrice
            ),
            getAaveVersionSnapShot(
              "aave/v2",
              network,
              data[2],
              accountId,
              smartWalletAddress,
              ethPrice
            ),
          ])
            .then(function (snapshot) {
              resolve({
                v1: snapshot[0],
                v2: snapshot[1],
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

function getAaveVersionSnapShot(
  version,
  network,
  list,
  accountId,
  smartWalletAddress,
  ethPrice
) {
  return new Promise(async (resolve, reject) => {
    try {
      let dataProviderContract = readContractByName(
        version,
        "DataProvider",
        network
      );
      const promises = [];
      list &&
        list.forEach((element) => {
          promises.push(
            getSnapshotOfAToken(
              element.symbol,
              network,
              element.underlyingAsset,
              accountId,
              dataProviderContract,
              version,
              smartWalletAddress
            )
          );
        });
      Promise.all(promises).then((response) => {
        let data = {
          account: { supplyBalance: 0, borrowBalance: 0 },
          smartWallet: { supplyBalance: 0, borrowBalance: 0 },
        };

        response.forEach((element) => {
          if (version === "aave/v2") {
            console.log(
              "supplyBalance" + element.name,
              element["account"]["snapshot"]["supplyBalance"]
            );
          }
          data["account"]["supplyBalance"] +=
            element["account"]["snapshot"]["supplyBalance"] * ethPrice;
          data["account"]["borrowBalance"] +=
            element["account"]["snapshot"]["borrowBalance"] * ethPrice;
          data["smartWallet"]["supplyBalance"] +=
            element["smartWallet"]["snapshot"]["supplyBalance"] * ethPrice;
          data["smartWallet"]["borrowBalance"] +=
            element["smartWallet"]["snapshot"]["borrowBalance"] * ethPrice;
        });
        resolve(data);
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

function getTokenPrices(protocol) {
  return new Promise(async (resolve, reject) => {
    let headers = {
      accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    };
    let url = `${backendApiUrl}prices/${protocol}`;
    FETCH("GET", url, "", false, headers)
      .then(function (data) {
        if (
          data &&
          data.statusCode === 200 &&
          data.response &&
          data.response.data.length > 0
        ) {
          resolve(data.response.data);
        } else {
          reject(data.message);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}

function getAtokenList(network, version) {
  return new Promise(async (resolve, reject) => {
    try {
      let poolId = aavePoolId[version][network];
      let url = `${aaveApiBaseUrl}data/liquidity/${version}/?poolId=${poolId}`;
      let headers = {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      };
      FETCH("GET", url, "", false, headers)
        .then(function (data) {
          let list = [];
          data.response.forEach((element) => {
            if (element.symbol === "WETH" && version === "v2") {
              element.symbol = "ETH";
            }
            list.push(element);
          });
          resolve(list);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.log(name, error);
      reject(error);
    }
  });
}

function getSnapshotOfAToken(
  name,
  network,
  address,
  accountId,
  dataProviderContract,
  version,
  smartWalletAddress
) {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = readContractByName(version, name, network);

      let accountAddress = [
        { type: "account", address: accountId },
        { type: "smartWallet", address: smartWalletAddress },
      ];
      let obj = { name };
      for (let index = 0; index < accountAddress.length; index++) {
        const element = accountAddress[index];
        let snap = {
          supplyBalance: "0",
          borrowBalance: "0",
          exchangeRate: "0",
        };
        if (contract && network === "mainnet") {
          let snapshot = await dataProviderContract.instance.methods
            .getUserReserveData(address, element.address)
            .call();
          let supplyBalance = new BigNumber(snapshot.currentATokenBalance).div(
            new BigNumber(10).pow(contract.decimal)
          );
          supplyBalance = supplyBalance.toNumber();
          let stableDebt = 0;
          let variableDebt = 0;
          let borrowBalance = 0;
          if (version === "aave/v1") {
            borrowBalance = new BigNumber(snapshot.currentBorrowBalance).div(
              new BigNumber(10).pow(contract.decimal)
            );
            borrowBalance = borrowBalance.toNumber();
          } else if (version === "aave/v2") {
            stableDebt = new BigNumber(snapshot.currentStableDebt).div(
              new BigNumber(10).pow(contract.decimal)
            );
            stableDebt = stableDebt.toNumber();
            variableDebt = new BigNumber(snapshot.currentVariableDebt).div(
              new BigNumber(10).pow(contract.decimal)
            );
            variableDebt = variableDebt.toNumber();

            borrowBalance = new BigNumber(stableDebt).plus(variableDebt);
            borrowBalance = borrowBalance.toNumber();
          }
          if (snapshot) {
            snap = {
              supplyBalance: supplyBalance, // snapshot[1]
              borrowBalance: borrowBalance, //snapshot[2],
            };
          }
        }
        obj[element.type] = {
          type: element.type,
          snapshot: snap,
          address: element.address,
        };
      }
      resolve(obj);
    } catch (error) {
      console.log(name, error);
      reject(error);
    }
  });
}
