// Constants

// Get Eth Price Const
export const getEthPrice = "GET_ETH_PRICE_COMMON";
// Get Current Gas Price Const
export const getCurrentGasPrice = "GET_CURRENT_GAS_PRICE_COMMON";
// Get Gas Price History Const
export const getGasPriceHistory = "GET_GAS_PRICE_HISTORY_COMMON";
// Update Account Detail Const
export const updateAccountDetail = "uPDATE_ACCOUNT_DETAIL_COMMON";

// Actions

// Get Eth Price Action
export function getEthPriceAction(pirce) {
  return {
    type: getEthPrice,
    payload: pirce,
  };
}
// Get Current Gas Price Action
export function getCurrentGasPriceAction(data) {
  return {
    type: getCurrentGasPrice,
    payload: data,
  };
}
// Get Gas Price History Action
export function getGasPriceHistoryAction(data) {
  return {
    type: getGasPriceHistory,
    payload: data,
  };
}

// Update Account Detail Action
export function updateAccountDetailAction(data) {
  return {
    type: updateAccountDetail,
    payload: data,
  };
}
