import {
  aaveApiBaseUrl,
  aavePoolId,
  backendApiUrl,
} from "../../../constants/constant";
import { FETCH } from "../../../utils/fetch";
import {
  ApiRequestedAction,
  ApiFulfilledAction,
  ApiRejectedAction,
} from "../../../components/ApiCallStatus/Actions/action";
import {
  getAaveFinancePricesAction,
  getContractsWithSnapshotAction,
} from "../Actions/action";
import _ from "underscore";
import { readContractByName } from "../../../utils/contracts";
import { BigNumber } from "bignumber.js";

// Apicall to get market info data
export function getAaveMarketPrice(network, version) {
  return (dispatch) => {
    let apiCallFor = "getAaveMarketPrice";
    dispatch(ApiRequestedAction({ apiCallFor }));
    dispatch(getAaveFinancePricesAction({ list: [] }));
    version = version.replace("aave/", "");
    Promise.all([getAtokenList(network, version), getAtokenPrice()])
      .then(function (data) {
        let aTokenList = data[0];
        let aTokenPrices = data[1] ? data[1]["data"] : [];
        if (aTokenList.length) {
          let list = [];
          aTokenList.forEach((element) => {
            if (element.symbol === "WETH" && version === "v2") {
              element.symbol = "ETH";
            }
            let index = aTokenPrices.findIndex(
              (x) => x.token === element.symbol
            );
            if (index !== -1) {
              element.referenceItem.priceInUsd = aTokenPrices[index].price;
            }
            element.tokenPrice = element.referenceItem.priceInUsd;
            element.marketSize = new BigNumber(element.totalLiquidity)
              .multipliedBy(element.referenceItem.priceInUsd)
              .toNumber();
            list.push(element);
          });
          let sortedData = _.sortBy(list, "marketSize");
          sortedData.reverse();
          dispatch(getAaveFinancePricesAction({ list: sortedData }));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: data.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: data.statusCode,
              apiCallFor,
              message: data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// get connected account supply list
export function getAccountSnapshotOfAllATokens(
  list,
  network,
  accountId,
  version
) {
  return (dispatch) => {
    try {
      let apiCallFor = "getAccountSnapshotOfAllATokens";
      // version = version.replace("aave/", "");
      dispatch(ApiRequestedAction({ apiCallFor }));
      dispatch(getContractsWithSnapshotAction([]));
      console.log("getAccountSnapshotOfAllATokens accountId", accountId);
      let dataProviderContract = readContractByName(
        version,
        "DataProvider",
        network
      );
      const promises = [];
      list &&
        list.forEach((element) => {
          promises.push(
            getSnapshotOfAToken(
              element.symbol,
              network,
              element.underlyingAsset,
              accountId,
              dataProviderContract,
              version
            )
          );
        });
      Promise.all(promises)
        .then((response) => {
          let data = {};
          response.forEach((element) => {
            data[element.name] = element;
          });
          dispatch(getContractsWithSnapshotAction(data));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: "data.message",
            })
          );
        })
        .catch((error) => {
          dispatch(
            ApiRejectedAction({
              statusCode: 400,
              apiCallFor,
              message: error,
            })
          );
        });
    } catch (error) {
      dispatch(
        ApiRejectedAction({
          statusCode: 400,
          apiCallFor,
          message: error,
        })
      );
    }
  };
}

function getSnapshotOfAToken(
  name,
  network,
  address,
  accountId,
  dataProviderContract,
  version
) {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = readContractByName(version, name, network);
      let snap = { supplyBalance: "0", borrowBalance: "0", exchangeRate: "0" };
      if (network === "mainnet") {
        let snapshot = await dataProviderContract.instance.methods
          .getUserReserveData(address, accountId)
          .call();
        let supplyBalance = new BigNumber(snapshot.currentATokenBalance).div(
          new BigNumber(10).pow(contract.decimal)
        );
        supplyBalance = supplyBalance.toNumber();
        let stableDebt = 0;
        let variableDebt = 0;
        let borrowBalance = 0;
        if (version === "aave/v1") {
          borrowBalance = new BigNumber(snapshot.currentBorrowBalance).div(
            new BigNumber(10).pow(contract.decimal)
          );
          borrowBalance = borrowBalance.toNumber();
        } else if (version === "aave/v2") {
          stableDebt = new BigNumber(snapshot.currentStableDebt).div(
            new BigNumber(10).pow(contract.decimal)
          );
          stableDebt = stableDebt.toNumber();
          variableDebt = new BigNumber(snapshot.currentVariableDebt).div(
            new BigNumber(10).pow(contract.decimal)
          );
          variableDebt = variableDebt.toNumber();

          borrowBalance = new BigNumber(stableDebt).plus(variableDebt);
          borrowBalance = borrowBalance.toNumber();
        }
        if (snapshot) {
          snap = {
            supplyBalance: supplyBalance, // snapshot[1]
            borrowBalance: borrowBalance, //snapshot[2],
            stableDebt: 0,
            variableDebt: 0,
            exchangeRate: 0,
            usageAsCollateralEnabled: snapshot.usageAsCollateralEnabled,
          };
        }
        // }
      }
      resolve({
        contract,
        name,
        snapshot: snap,
        accountId,
      });
    } catch (error) {
      console.log(name, error);
      reject(error);
    }
  });
}

function getAtokenList(network, version) {
  return new Promise(async (resolve, reject) => {
    try {
      let poolId = aavePoolId[version][network];
      let url = `${aaveApiBaseUrl}data/liquidity/${version}/?poolId=${poolId}`;
      let headers = {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      };
      FETCH("GET", url, "", false, headers)
        .then(function (data) {
          resolve(data.response);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.log(name, error);
      reject(error);
    }
  });
}
function getAtokenPrice() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${backendApiUrl}prices/aave`;
      let headers = {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      };
      FETCH("GET", url, "", false, headers)
        .then(function (data) {
          resolve(data.response);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.log(name, error);
      reject(error);
    }
  });
}
