import { useState } from "react";
import DashboardLayout from "../../../layout/DashboardLayout/dashboardLayout";
import { SmartWalletModal, CompTokenModal } from "../../../components/Models/models";
function SmartSaving(props) {
    const [state, setState] = useState({
        showModal: false,
        modalType: ""
    })
    const handleModalChange = (showModal, modalType) => {
        setState({
            ...state,
            showModal,
            modalType
        })
    }
    return (
        <DashboardLayout name="Smart" wrapperClassName="savings-manage-page-wrapper">
            <div className="width-container">
                <div className="main-section-wrapper">
                    <div className="dashboard-header-wrapper savings-manage-sub-header-wrapper width-container">
                        <div className="wallet-type-select-wrapper">
                            <div className="select-container">

                                <div className="display-inherit"><button type="button" onClick={() => handleModalChange(true, 'smartwallet')} className="button btn-Secondary mini dark with-icon wallet"><i></i> Smart Wallet</button>
                                </div>
                                <div className="display-inherit"><button type="button" onClick={() => handleModalChange(true, 'compTokens')} className="button btn-Secondary mini dark with-icon comp"><i></i> COMP</button>
                                </div>
                            </div>
                        </div>
                        <div className="Flex moneymarket-sub-header-right-wrapper right ">
                            <div className="data-item-wrapper arrow  ">
                                <div className="label-value-wrapper">
                                    <div className="label-wrapper">
                                        <div className="" ><span><i className="icon icon-Info-circle"></i></span>
                                        </div><span className="label">Current interest rate:</span>
                                    </div>
                                    <div className="value-wrapper">
                                        <div className="" ><span>0<span className="symbol false">%</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-subsections-wrapper">
                        <br />
                        <div className="savings-manage-stats-wrapper">
                            <div className="savings-manage-total-wrapper false">
                                <div className="title">
                                    <span>In total:</span>
                                </div>
                                <div className="items-wrapper">
                                    <div className="item-wrapper">
                                        <div className="value"> <span className="value-label">DAI</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="interest-estimate-wrapper">
                                <div className="title">
                                    <span>Interest estimate:</span>
                                </div>
                                <div className="pairs-wrapper">
                                    <div className="value-wrapper">
                                        <div className="label">In a week:</div>
                                        <div className="value">
                                            <div className=""><span>0 <span className="value-label">DAI</span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="value-wrapper">
                                        <div className="label">In a month:</div>
                                        <div className="value">
                                            <div className=""><span>0 <span className="value-label">DAI</span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="value-wrapper">
                                        <div className="label">In a year:</div>
                                        <div className="value">
                                            <div className=""><span>0 <span className="value-label">DAI</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="savings-manage-projects-wrapper show">
                            <div className="savings-manage-projects-warnings-wrapper no-warnings"></div>
                            <div className="savings-manage-projects-info-wrapper">
                                <div className="project-info-items-wrapper">
                                    <div className="project-info-item-wrapper">
                                        <div className="info-item-title-wrapper">
                                            <img className="info-item-icon" src={require("../../../assets/svgs/compoundMini.svg").default} alt="" />
                                            <div className="title item-title green">Compound</div>
                                        </div>
                                        <div className="apr-wrapper">
                                            <div className="apr-value green">
                                                <div className=""><span>7.25% APY</span>
                                                </div>
                                            </div>
                                            <div className="project-infodata"></div>
                                        </div>
                                        <div className="dai-wrapper">
                                            <div className="dai-value">
                                                <div className="dai-value-label">Deposited amount:</div> <span className="value-label">DAI</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-info-item-wrapper">
                                        <div className="info-item-title-wrapper">
                                            <img className="info-item-icon" src={require("../../../assets/svgs/dYdXmini.svg").default} alt="" />
                                            <div className="title item-title blue">dYdX</div>
                                        </div>
                                        <div className="apr-wrapper">
                                            <div className="apr-value blue">
                                                <div className=""><span>16.30% APY</span>
                                                </div>
                                            </div>
                                            <div className="project-infodata"></div>
                                        </div>
                                        <div className="dai-wrapper">
                                            <div className="dai-value">
                                                <div className="dai-value-label">Deposited amount:</div><span className="value-label">DAI</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-info-item-wrapper">
                                        <div className="info-item-title-wrapper">
                                            <img className="info-item-icon" src={require("../../../assets/svgs/dsrMini.svg").default} alt="" />
                                            <div className="title item-title dark-green">DSR</div>
                                        </div>
                                        <div className="apr-wrapper">
                                            <div className="apr-value dark-green">
                                                <div className="" ><span>0.00% APY</span>
                                                </div>
                                            </div>
                                            <div className="project-infodata"></div>
                                        </div>
                                        <div className="dai-wrapper">
                                            <div className="dai-value">
                                                <div className="dai-value-label">Deposited amount:</div> <span className="value-label">DAI</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-info-item-wrapper">
                                        <div className="info-item-title-wrapper">
                                            <img className="info-item-icon" src={require("../../../assets/svgs/aaveMini.svg").default} alt="" />
                                            <div className="title item-title purple">Aave</div>
                                        </div>
                                        <div className="apr-wrapper">
                                            <div className="apr-value purple">
                                                <div className=""><span>6.49% APY</span>
                                                </div>
                                            </div>
                                            <div className="project-infodata"></div>
                                        </div>
                                        <div className="dai-wrapper">
                                            <div className="dai-value">
                                                <div className="dai-value-label">Deposited amount:</div> <span className="value-label">DAI</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="actions-section-wrapper">
                    <div className="user-wallet-tab-wrapper manage-user-wallet-blocker">
                        <div className="title">Create Smart Wallet</div>
                        <div className="description">In order to use our advanced Compound features you first need to create a Smart wallet - your personal smart contract wallet that will enable usage of advanced features. Please note that by using the Smart wallet you lose compatibility with other apps (e.g. Compound's default app).</div>
                        <button type="button" className="button ">Create</button>
                    </div>
                </div>
            </div>
            <CompTokenModal showModal={state.showModal && state.modalType === "compTokens"} handleModalChange={handleModalChange} />
            <SmartWalletModal showModal={state.showModal && state.modalType === "smartwallet"} handleModalChange={handleModalChange} />
        </DashboardLayout>
    );
}

export default SmartSaving;