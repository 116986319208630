import {
  getCompoundFinancePrices,
  getCompoundSupplyList,
  getCTokenList,
  getTokenPriceList,
  getContractsWithSnapshot,
  clearContractsWithSnapshot,
} from "./../Actions/action";
import InitialState from "../../../constants/initialReducersState";

export default function CompoundReducer(state = InitialState.compound, action) {
  switch (action.type) {
    case getCompoundFinancePrices:
      let s = { ...state };
      s.marketInfo = action.payload;
      return s;

    case getCompoundSupplyList:
      let s1 = { ...state };
      s1.supplyList = action.payload;
      return s1;

    case getCTokenList:
      let s2 = { ...state };
      s2.cTokensList = action.payload;
      return s2;

    case getTokenPriceList:
      let s3 = { ...state };
      s3.tokenPriceList = action.payload;
      return s3;

    case getContractsWithSnapshot:
      let s4 = { ...state };
      s4.contracts = action.payload;
      return s4;

    case clearContractsWithSnapshot:
      let s5 = { ...state };
      s5.contracts = {};
      return s5;

    default:
      return state;
  }
}
