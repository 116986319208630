import { Tabs, Tab } from "react-bootstrap";
import { Collapse, Button } from 'react-bootstrap';
import { getExchangeUrl } from '../../../utils/common';
import { Fragment, useState } from 'react';
import Loader from "../Components/loader";
import Web3 from "web3";
import { etheriumProvider } from "../../../constants/constant";
export default function TransactionToastHandler(props) {
    return <div>
        {props.status === "creating" ?
            <TransactionGasPricing {...props} />
            : props.status === "inprogress" ?
                <InProgressTransaction {...props} /> :
                props.status === "confirm" ?
                    <TransactionConfirmation {...props} /> :
                    props.status === "error" ? <TransactionError {...props} /> :
                        props.status === "progressbar" ? <ProgressStep {...props} /> : ""}
    </div>
}

function InProgressTransaction(props) {
    const trimAddress = (address) => {
        let length = address.length;
        if (length > 10) {
            address = address.substring(0, 6) + "...";
        }
        return address;
    }
    return <div>
        <div className="toast-header">
            {props.label ? props.label : `Transaction pending`}
        </div>
        <div className="toast-body">
            <div className="transaction-process">
                <h3><a href={props.hash ? `${getExchangeUrl()}/tx/${props.hash}` : "#"} target="_blank">Transaction {trimAddress(props.hash ? props.hash : '')} pending</a></h3>
                <Loader />
            </div>
        </div>
    </div>
}

const TransactionConfirmation = (props) => (
    <div>
        <div className="toast-header">
            {props.label ? props.label : `Transaction complete`}
        </div>
        <div className="toast-body success">
            Your transaction has been confirmed
        </div>
    </div>
)

const TransactionError = (props) => (
    <div>
        <div className="toast-header">
            {props.label ? props.label : `Transaction error`}
        </div>
        <div className="toast-body error">
            {props.message}
        </div>
    </div>
)

function TransactionGasPricing(props) {
    const [activeTab, handleTab] = useState(1);
    const web3 = new Web3(etheriumProvider);
    const handleSubmit = (e) => {
        e.preventDefault();
        let gasPrice = 0;
        let gasLimit = 0;
        if (activeTab == 1) {
            gasPrice = props.gasPrice && props.gasPrice.safeLow ? props.gasPrice.safeLow : 0;
            gasLimit = props.gasLimit && props.gasLimit.safeLow ? props.gasLimit.safeLow : 0;
        } else if (activeTab == 2) {
            gasPrice = props.gasPrice && props.gasPrice.average ? props.gasPrice.average : 0;
            gasLimit = props.gasLimit && props.gasLimit.average ? props.gasLimit.average : 0;
        } else if (activeTab == 3) {
            gasPrice = props.gasPrice && props.gasPrice.fastest ? props.gasPrice.fastest : 0;
            gasLimit = props.gasLimit && props.gasLimit.fastest ? props.gasLimit.fastest : 0;
        }
        gasPrice = gasPrice * 10e8;
        props.handleSubmit(gasPrice, gasLimit)
    }
    const getCheapEstimatedFee = () => {
        let gasPrice = props.gasPrice && props.gasPrice.safeLow ? props.gasPrice.safeLow : 0;
        let gasLimit = props.gasLimit && props.gasLimit.safeLow ? props.gasLimit.safeLow : 0;
        let fastestGasPrice = props.gasPrice && props.gasPrice.fastest ? props.gasPrice.fastest : 0;
        let ethGasPrice = web3.utils.fromWei((gasPrice / 10 * 10e9 * gasLimit).toString(), 'ether');
        let usGasPrice = ethGasPrice * props.USD;
        let ethFastestGasPrice = web3.utils.fromWei((fastestGasPrice / 10 * 10e9 * gasLimit).toString(), 'ether');
        let usFastestGasPrice = ethFastestGasPrice * props.USD
        return <><div className="proxy-list-item d-flex align-items-center">
            <p className="flex-fill mb-0">Extimated Fee</p>
            <div className="d-flex flex-column align-items-end">
                <strong className="counter-value">{ethGasPrice} ETH</strong>
                <span className="proxy-price">$ {usGasPrice}</span>
            </div>
        </div >
            <div className="proxy-list-item d-flex align-items-center">
                <p className="flex-fill mb-0">Max. Transaction Fee</p>
                <div className="d-flex flex-column align-items-end">
                    <strong className="counter-value">{ethFastestGasPrice} ETH</strong>
                    <span className="proxy-price">$ {usFastestGasPrice}</span>
                </div>
            </div></>
    }
    const getAverageEstimatedFee = () => {
        let gasPrice = props.gasPrice && props.gasPrice.average ? props.gasPrice.average : 0;
        let gasLimit = props.gasLimit && props.gasLimit.average ? props.gasLimit.average : 0;
        let fastestGasPrice = props.gasPrice && props.gasPrice.fastest ? props.gasPrice.fastest : 0;
        let ethGasPrice = web3.utils.fromWei((gasPrice / 10 * 10e9 * gasLimit).toString(), 'ether');
        let usGasPrice = ethGasPrice * props.USD
        let ethFastestGasPrice = web3.utils.fromWei((fastestGasPrice / 10 * 10e9 * gasLimit).toString(), 'ether');
        let usFastestGasPrice = ethFastestGasPrice * props.USD
        return <><div className="proxy-list-item d-flex align-items-center">
            <p className="flex-fill mb-0">Extimated Fee</p>
            <div className="d-flex flex-column align-items-end">
                <strong className="counter-value">{ethGasPrice} ETH</strong>
                <span className="proxy-price">$ {usGasPrice}</span>
            </div>
        </div >
            <div className="proxy-list-item d-flex align-items-center">
                <p className="flex-fill mb-0">Max. Transaction Fee</p>
                <div className="d-flex flex-column align-items-end">
                    <strong className="counter-value">{ethFastestGasPrice} ETH</strong>
                    <span className="proxy-price">$ {usFastestGasPrice}</span>
                </div>
            </div></>
    }
    const getFastEstimatedFee = () => {
        let gasPrice = props.gasPrice && props.gasPrice.fastest ? props.gasPrice.fastest : 0;
        let gasLimit = props.gasLimit && props.gasLimit.fastest ? props.gasLimit.fastest : 0;
        let ethGasPrice = web3.utils.fromWei((gasPrice / 10 * 10e9 * gasLimit).toString(), 'ether');
        let usGasPrice = ethGasPrice * props.USD
        return <><div className="proxy-list-item d-flex align-items-center">
            <p className="flex-fill mb-0">Extimated Fee</p>
            <div className="d-flex flex-column align-items-end">
                <strong className="counter-value">{ethGasPrice} ETH</strong>
                <span className="proxy-price">$ {usGasPrice}</span>
            </div>
        </div >
            <div className="proxy-list-item d-flex align-items-center">
                <p className="flex-fill mb-0">Max. Transaction Fee</p>
                <div className="d-flex flex-column align-items-end">
                    <strong className="counter-value">{ethGasPrice} ETH</strong>
                    <span className="proxy-price">$ {usGasPrice}</span>
                </div>
            </div></>
    }
    const closeToast = () => {
        props.closeToast();
        if (props.closeToastById) {
            props.closeToastById()
        }
    }
    return <div>
        <div className="toast-header">
            {props.label ? props.label : "Select Gas Price"}
        </div>
        <div className="toast-body">
            <Tabs id="uncontrolled-tab-example" className="ds-proxy-modals" defaultActiveKey={activeTab} onSelect={(key) => handleTab(key)}>
                <Tab eventKey="1" title={<div>Cheap <small>&lt; 10 min.</small></div>}>
                    <CustomizeGasSetting gasLimit={props.gasLimit && props.gasLimit.safeLow ? props.gasLimit.safeLow : 0} gasPrice={props.gasPrice && props.gasPrice.safeLow ? props.gasPrice.safeLow : 0} />
                    <Fragment>{getCheapEstimatedFee()}</Fragment>
                </Tab>
                <Tab eventKey="2" title={<div>Regular <small>&lt; 3 min.</small></div>}>
                    <CustomizeGasSetting gasLimit={props.gasLimit && props.gasLimit.average ? props.gasLimit.average : 0} gasPrice={props.gasPrice && props.gasPrice.average ? props.gasPrice.average : 0} />
                    <Fragment>{getAverageEstimatedFee()}</Fragment>
                </Tab>
                <Tab eventKey="3" title={<div>Fast <small>&lt; 1 min.</small></div>}>
                    <CustomizeGasSetting gasLimit={props.gasLimit && props.gasLimit.fastest ? props.gasLimit.fastest : 0} gasPrice={props.gasPrice && props.gasPrice.fastest ? props.gasPrice.fastest : 0} />
                    <Fragment>{getFastEstimatedFee()}</Fragment>
                </Tab>
            </Tabs>
            {props.hasError ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div> : ""}
        </div>
        <div className=" d-flex justify-content-end toast-footer">
            <button onClick={handleSubmit} className="btn btn-secondary">Accept</button>
            <button onClick={closeToast} className="btn btn-primary">Cancel</button>
        </div>
    </div>
}

function CustomizeGasSetting(props) {
    const [open, setOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return <div className="advanced-wrapper">
        <div className="title-wrapper">
            <Button color="primary" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>Customize gas settings <span className="fa fa-chevron-down"></span></Button>
        </div>

        <Collapse in={open}>
            <div id="example-collapse-text">
                <div className="advanced-content">
                    <form id="gas-price-modal-form" className="form-wrapper">
                        <div className="form-item-wrapper  ">
                            <div className="input-values">
                                <label className="" htmlFor="gas-price">
                                    <span className="icon fa fa-info-circle"></span>Gas price
                    </label>
                                <input name="gasPrice" placeholder="0" className="form-item" defaultValue={props.gasPrice} type="number" /><label className="second-label" htmlFor="gas-price">Gwei</label>
                            </div>
                        </div>
                        <div className="form-item-wrapper  ">
                            <div className="input-values">
                                <label className="" htmlFor="gas-limit">
                                    <span className="icon fa fa-info-circle"></span> Gas limit
                    </label>
                                <input name="gasLimit" placeholder="0" className="form-item" defaultValue={props.gasLimit} type="number" /><span className="label-replacement"></span>
                            </div>
                        </div>
                    </form>
                    <div className="section-wrapper">
                        <div className="label">
                            <span className="icon fa fa-info-circle"></span> Estimated gas:
            </div>
                        <div className="value">
                            <div className="" ><span>{props.gasPrice}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    </div>
}

function ProgressStep(props) {
    const progressBar = () => {
        let totalSteps = props.totalSteps ? props.totalSteps : 2;
        let steps = [];
        for (let index = 0; index < totalSteps; index++) {
            steps.push(index + 1);
        }
        return <div className="progress-steps">
            {steps.map((item, index) => {
                return <span key={index} className={`step ${props.activeStep >= item || props.isLast ? "success" : ""}`}>{item}</span>
            })}
        </div>
    }
    return <div>
        <div className="toast-body">
            {progressBar()}
        </div>
    </div>
}
