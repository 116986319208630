import { useState } from "react";
import Web3 from "web3";

function ConnectWallet(props) {
    const web3 = new Web3();
    const [address, setAddress] = useState("");
    const isValidAddress = () => {
        return web3.utils.isAddress(address);
    }
    const trackAddress = (e) => {
        e.preventDefault();
        setAddress("")
        props.trackAddress(address);
    }
    return (
        <div className="width-container">
            <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
                <div className="blocker-content not-connected-wrapper">
                    <div className="left-content-wrapper">
                        <div className="cdp-blocker--title">Welcome to Elixyr</div>
                        <div className="cdp-blocker--message">
                            <span className="label">Connect your wallet to access this page:</span><br /><br />
                            <div className="connect-wallet-buttons-wrapper">
                                <div className="button-option " onClick={() => props.connectMetaMask()}>
                                    <img src={require("../../assets/svgs/dashboard/metamask-org.svg").default} width="53" height="53" alt="" />
                                    <p className="connect-wallet-button-name">MetaMask</p>
                                </div>
                                <div className="button-option Trezor " onClick={() => props.handleModalChange(true, "trezor")}>
                                    <img src={require("../../assets/svgs/dashboard/trezor.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">Trezor</p>
                                </div>
                                <div className="button-option Ledger ">
                                    <img src={require("../../assets/svgs/dashboard/ledger.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">Ledger</p>
                                </div>
                                <div className="button-option Fortmatic " onClick={() => props.connectToWallet("fortmatic")}>
                                    <img src={require("../../assets/svgs/dashboard/fortmatic.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">Fortmatic</p>
                                </div>
                                <div className="button-option WalletConnect " onClick={() => props.connectToWallet("walletconnect")}>
                                    <img src={require("../../assets/svgs/dashboard/walletconnect.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">WalletConnect</p>
                                </div>
                                <div className="button-option Coinbase " onClick={() => props.connectCoinBase()}>
                                    <img src={require("../../assets/svgs/dashboard/coinbase.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">Coinbase</p>
                                </div>
                                <div className="button-option track-address " onClick={() => props.handleModalChange(true, "trackAddress")}>
                                    <img src={require("../../assets/svgs/dashboard/track-address.svg").default} alt="" />
                                    <p className="connect-wallet-button-name">Track Address</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="address-monitor form-wrapper">
                        <div className="address-monitor-inputs">
                            <div className="enter-address-description">Or enter your address to monitor your portfolio</div>
                            <div className="form-item-wrapper">
                                <div className="input-values">
                                    <form id="get-address-for-monitoring">
                                        <label className="address-label" htmlFor="address-for-monitoring">Ethereum address or ENS</label><input className="form-item" type="text" placeholder="0x" name="address-for-monitoring" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ display: 'inline' }}><span><button type="submit" className="button blue-gradient" disabled={!isValidAddress()} onClick={(e) => trackAddress(e)} form="get-address-for-monitoring">Connect</button></span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectWallet;