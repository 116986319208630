import { getHeaders } from "./common";
export async function FETCH(type, url, body, isFormData, customHeaders) {
  let headers = {
    method: type,
    headers: customHeaders ? customHeaders : getHeaders(isFormData),
  };
  if (isFormData === true) {
    headers.body = body;
  } else {
    if (body) {
      headers.body = JSON.stringify(body);
    }
  }
  return await fetch(url, headers)
    .then(async function (response) {
      return {
        response: await response.json(),
        statusCode: response.status,
      };
    })
    .then(function (myJson) {
      return myJson;
    })
    .catch((error) => {
      console.log(error.statusCode, error);
      return error;
    });
}
