import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Account, AccountTabs } from "./accounts";
import {
  SmartWalletModal,
  CreateDSProxyModal,
  DisableTokenAlertModal,
  StackingRateModal
} from "../../../../components/Models/models";
import { connect } from "react-redux";
import { etheriumProvider, network } from "../../../../constants/constant";
import {
  getAaveMarketPrice,
  getAccountSnapshotOfAllATokens
} from "../../ApiCalls/aave";
import { SmartWallet } from './createSmartWallet';
import { MarketInfoListing } from './marketInfoListing';
import { SupplyListing } from './supplyingListing';
import { BorrowListing } from './borrowListing';
import { ClearApiByNameAction, ApiRequestedAction } from "../../../../components/ApiCallStatus/Actions/action";
import { clearContractsWithSnapshotAction } from "../../Actions/action";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../../../utils/cookies";
import { readContractByName, getContractKeyByName, getContractAddressByName } from "../../../../utils/contracts";
import Loader from "../../../../components/Common/Components/loader";
import { toast } from "react-toastify";
import TransactionToast from "../../../../components/Common/Components/toastAlertMsg";
import ReactTooltip from "react-tooltip";
import Web3 from "web3";
import { getAllEstimationGasPrice } from '../../../../utils/contracts';
import useInterval from "use-interval";
import _ from "underscore";
import { BigNumber } from "bignumber.js";

function Manage(props) {
  const [smartWalletAddress, setSmartWalletAddress] = useState("");
  const [compoundType, setCompoundType] = useState(getCookie("compoundType") === "" ? "account" : getCookie("compoundType"));
  const [checkingSmaerWallet, setCheckingSmaerWallet] = useState(true);
  const [startInterval, updateInterval] = useState(false);
  const [marketInfoList, updateMarketInfoList] = useState([]);
  const [supplyList, updateSupplyList] = useState([]);
  const [borrowList, updateBorrowList] = useState([]);
  const [isFirstLoad, updateFirstLoad] = useState(true);
  const [state, setState] = useState({
    isValidNetwork: props.accountDetail.currency === "ETH",
    currency: props.accountDetail.currency,
    protocol: getCookie("aaveProtocol") ? getCookie("aaveProtocol") : "aave/v1",
    version: "v1",
    showModal: false,
    modalType: "",
    modalPayload: {},
    isEnable: true,
    accountId: getCookie("accountId"),
    network: network,
    supplyBalance: 0,
    borrowBalance: 0,
    borrowLimit: 0,
    safetyRatio: 0,
    leftToBorrow: 0,
    afterType: "",
    enableSupplyTokens: [],
    isAdvance: "",
    advanceRepay: {
      value: "",
      withDrawtype: "",
      withDrawContractAddress: "",
      repaytype: "",
      repayContractAddress: "",
      balance: 0,
    },
    advanceBoost: {
      value: "",
      borrowtype: "",
      borrowContractAddress: "",
      supplytype: "",
      supplyContractAddress: "",
      balance: 0,
    },
    supply: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    withDraw: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    borrow: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    repay: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    isCallAccountSnapshot: false,
    activeAccountTab: "advanced"
  });
  let transactionDetail = {
    status: "",
    toastId: "",
    progressBarToastId: "",
  };
  let createDSProxyWalletToastId;

  useEffect(() => {
    if (props.accountDetail.connectedWallet !== "") {
      setState({
        ...state,
        isValidNetwork: props.accountDetail.currency === "ETH",
        currency: props.accountDetail.currency,
        accountId: getCookie("accountId")
      })
      props.getAaveMarketPrice(state.network, state.protocol);
      checkSmartWallet();
    }
  }, [props.accountDetail.connectedWallet]);
  useEffect(() => {
    if (state.isValidNetwork && state.accountId !== props.accountDetail.accountId) {
      updateFirstLoad(false);
      setSmartWalletAddress("");
      props.clearContractsWithSnapshotAction();
      checkSmartWallet(props.accountDetail.accountId);
      props.getAaveMarketPrice(state.network, state.protocol);
      resetState(false);
      updateMarketInfoList([]);
      updateSupplyList([]);
      updateBorrowList([]);
    }
  }, [props.accountDetail.accountId]);

  useEffect(() => {
    if (state.isValidNetwork &&
      state.accountId === props.accountDetail.accountId &&
      state.supply.type === "ETH"
    ) {
      let stateObj = { ...state };
      let balance = getCookie("balance");
      balance = balance.replace(/ .*/, "");
      stateObj["supply"]["balance"] = balance;
      stateObj["supply"]["value"] = "";
      setState(stateObj);
    }
  }, [getCookie("balance")]);

  useEffect(() => {
    if (state.isValidNetwork && !checkingSmaerWallet) {
      resetState();
      props.clearContractsWithSnapshotAction();
      updateMarketInfoList([]);
      updateSupplyList([]);
      updateBorrowList([]);
      updateInterval(true);
    }
  }, [compoundType, checkingSmaerWallet]);

  useEffect(() => {
    if (state.isValidNetwork && !checkingSmaerWallet) {
      props.clearContractsWithSnapshotAction();
      checkSmartWallet(props.accountDetail.accountId);
      props.getAaveMarketPrice(state.network, state.protocol);
      resetState();
      updateMarketInfoList([]);
      updateSupplyList([]);
      updateBorrowList([]);
    }
  }, [state.protocol]);

  useInterval(
    () => {
      if (state.isValidNetwork && !checkingSmaerWallet && props.marketInfoList.length > 0) {
        updateInterval(false)
        getAccountSnapshotOfAllATokens();
      }
    },
    startInterval ? 100 : null
  );
  useEffect(() => {
    if (state.isValidNetwork && props.accountDetail.currency !== state.currency
    ) {
      let stateObj = { ...state };
      stateObj["currency"] = props.accountDetail.currency;
      stateObj["isValidNetwork"] = props.accountDetail.currency === "ETH";
      setState(stateObj);
      if (stateObj["isValidNetwork"]) {
        props.getAaveMarketPrice(state.networkstate.protocol);
        checkSmartWallet();
      }
    }
  }, [props.accountDetail.currency]);
  useEffect(async () => {
    ReactTooltip.rebuild();
    if (state.isValidNetwork &&
      props.apiCallStatus.apiCallFor === "getAccountSnapshotOfAllATokens" &&
      props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed &&
      (supplyList.length === 0 || borrowList.length === 0) &&
      props.marketInfoList.length > 0 &&
      Object.size(props.contracts) > 0
    ) {
      let newSupplyList = [];
      let newBorrowList = [];
      let supplyBalance = 0;
      let borrowLimit = 0;
      let safetyRatio = 0;
      let borrowBalance = 0;
      let leftToBorrow = 0;
      let withDraw = { ...state.withDraw };
      let advanceRepay = { ...state.advanceRepay };
      let repay = { ...state.repay };
      let enableSupplyTokens = await checkMembership();
      let total_borrow_value_in_eth = 0;
      let ethTokenPrice = 0;
      props.marketInfoList.forEach((childElem, j) => {
        let symbol =
          childElem.symbol;
        let tokenPrice = childElem.referenceItem.priceInUsd;
        let address = childElem.aToken.id;
        let obj = {}
        let supply_balance_underlying = 0;
        let borrow_balance_underlying = 0;
        let usageAsCollateralEnabled = false;
        let lifetime_borrow_interest_accrued = 0;
        if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["supplyBalance"] > 0)) {
          supply_balance_underlying = props.contracts[symbol]["snapshot"]["supplyBalance"];
        }
        if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["borrowBalance"] > 0)) {
          borrow_balance_underlying = props.contracts[symbol]["snapshot"]["borrowBalance"];
        }
        if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["usageAsCollateralEnabled"] > 0)) {
          usageAsCollateralEnabled = props.contracts[symbol]["snapshot"]["usageAsCollateralEnabled"];
        }
        let underlyingAsset = childElem.underlyingAsset;
        if (childElem.symbol === "ETH") {
          underlyingAsset = getContractKeyByName(props.protocol, "ETH", 'underLyingAddress');
        }
        obj = {
          address: address,
          borrow_balance_underlying: {
            value: borrow_balance_underlying
          },
          lifetime_borrow_interest_accrued: {
            value: lifetime_borrow_interest_accrued
          },
          lifetime_supply_interest_accrued: {
            value: "0.0"
          },
          safe_withdraw_amount_underlying: {
            value: "0.0"
          },
          supply_balance_underlying: {
            value: supply_balance_underlying
          },
          usageAsCollateralEnabled,
          symbol: childElem.symbol,
          liquidityRate: childElem.liquidityRate * 100,
          underlyingAsset: childElem.underlyingAsset,
          baseLTVasCollateral: childElem.baseLTVasCollateral * 100
        }
        obj["tokenPrice"] = tokenPrice;
        let sbalance = tokenPrice * supply_balance_underlying;
        let bbalance = tokenPrice *
          (borrow_balance_underlying - lifetime_borrow_interest_accrued);
        supplyBalance += Number(sbalance);
        borrowBalance += Number(bbalance);
        if (supply_balance_underlying > 0 && usageAsCollateralEnabled) {
          borrowLimit += sbalance * childElem.baseLTVasCollateral;
        }
        if (childElem.referenceItem.averageStableBorrowRate > 0) {
          obj["type"] = "Stable";
          if (symbol === "ETH") {
            ethTokenPrice = tokenPrice;
            total_borrow_value_in_eth = childElem.referenceItem.stableBorrowRate;
          }
        } else {
          obj["type"] = "Variable";
          if (symbol === "ETH") {
            ethTokenPrice = tokenPrice;
            total_borrow_value_in_eth = childElem.referenceItem.variableBorrowRate;
          }
        }
        if (withDraw["type"] === "" && supply_balance_underlying > 0) {
          withDraw["balance"] = supply_balance_underlying;
          withDraw["type"] = symbol;
          withDraw["contractAddress"] = address;
          advanceRepay["withDrawtype"] = symbol;
          advanceRepay["withDrawContractAddress"] = address;
        }
        if (repay["type"] === "" && borrow_balance_underlying > 0) {
          repay["balance"] =
            borrow_balance_underlying - lifetime_borrow_interest_accrued;
          repay["type"] = symbol;
          repay["contractAddress"] = address;
          advanceRepay["repaytype"] = symbol;
          advanceRepay["repayContractAddress"] = address;
        }
        if (supply_balance_underlying > 0) {
          newSupplyList.push(obj)
        }
        if (borrow_balance_underlying > 0) {
          if (props.protocol === "aave/v2") {
            if (props.contracts[symbol]["snapshot"]["stableDebt"] > 0) {
              obj["type"] = "Stable";
              obj["borrowApy"] = childElem.stableBorrowRate * 100
              newBorrowList.push(obj);
            }
            if (props.contracts[symbol]["snapshot"]["variableDebt"] > 0) {
              obj["type"] = "Variable";
              obj["borrowApy"] = childElem.variableBorrowRate * 100
              newBorrowList.push(obj);
            }
          } else {
            obj["borrowApy"] = childElem.variableBorrowRate * 100
            newBorrowList.push(obj)
          }
        }
      });
      total_borrow_value_in_eth = Number(total_borrow_value_in_eth);
      if (total_borrow_value_in_eth !== 0) {
        safetyRatio =
          (borrowLimit / (total_borrow_value_in_eth * ethTokenPrice)) * 100;
      }
      leftToBorrow = borrowLimit - borrowBalance;
      updateSupplyList(newSupplyList);
      updateBorrowList(newBorrowList);
      let stateObj = {
        ...state,
        supplyBalance,
        borrowBalance,
        borrowLimit,
        safetyRatio,
        leftToBorrow,
        enableSupplyTokens,
        withDraw,
        advanceRepay,
        repay,
        isCallAccountSnapshot: true,
      };
      setState(stateObj);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
    } else if (state.isValidNetwork &&
      marketInfoList.length === 0 &&
      props.marketInfoList.length > 0
    ) {
      let sortedData = props.marketInfoList;
      let type = sortedData[0] ? sortedData[0].symbol : "";
      let contractAddress = sortedData[0] ? sortedData[0].aToken.id : "";
      let underlyingAsset = sortedData[0] ? sortedData[0].underlyingAsset : "0x";
      let stateObj = { ...state };
      let balance = getCookie("balance");
      balance = balance.replace(/ .*/, "");
      let items = ["supply", "borrow", "advanceBoost"];
      items.forEach(async (element) => {
        if (element === "advanceBoost") {
          stateObj["advanceBoost"]["borrowtype"] = type
          stateObj["advanceBoost"]["supplytype"] = type
          stateObj["advanceBoost"]["borrowContractAddress"] = contractAddress
          stateObj["advanceBoost"]["supplyContractAddress"] = contractAddress
        } else {
          stateObj[element]["type"] = type;
          stateObj[element]["contractAddress"] = contractAddress;
        }
        if (element !== "borrow") {
          if (element === "supply" && type !== "ETH") {
            const { instance, decimal } = readContractByName(state.protocol,
              type,
              state.network,
              underlyingAsset
            );
            balance = await instance.methods.balanceOf(state.accountId).call();
            stateObj[element]["balance"] = new BigNumber(balance).div(
              new BigNumber(10).pow(decimal)).toNumber();
          } else {
            stateObj[element]["balance"] = type === "ETH" ? balance : 0;
          }
        }
      });

      setState(stateObj);
      updateMarketInfoList(sortedData);
      // props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
    }
  }, [props, checkingSmaerWallet, compoundType, state.protocol]);
  useEffect(() => {
    if (state.isValidNetwork &&
      state.leftToBorrow !== 0 &&
      state.borrow.balance === 0 &&
      state.borrow.type !== ""
    ) {
      let borrow = { ...state.borrow };
      let i = props.marketInfoList.findIndex(
        (x) => x.symbol === state.borrow.type
      );
      if (i !== -1) {
        let tokenPrice = props.marketInfoList[i].referenceItem.priceInUsd;
        borrow["balance"] = state.leftToBorrow / tokenPrice;
        setState({
          ...state,
          borrow,
        });
      }
    }
  }, [state]);

  const getAccountSnapshotOfAllATokens = () => {
    let accountId = "";
    if (compoundType === "smartWallet" && smartWalletAddress !== "") {
      accountId = smartWalletAddress
    } else {
      accountId = state.accountId;
    }
    console.log("Account Id", accountId)
    props.getAccountSnapshotOfAllATokens(props.marketInfoList, state.network, accountId, state.protocol);
  }
  const resetState = (isCallAccountSnapshot) => {
    setState({
      ...state,
      showModal: false,
      modalType: "",
      supplyBalance: 0,
      borrowBalance: 0,
      borrowLimit: 0,
      leftToBorrow: 0,
      safetyRatio: 0,
      afterType: "",
      advanceRepay: {
        value: "",
        withDrawtype: "",
        withDrawContractAddress: "",
        repaytype: "",
        repayContractAddress: "",
        balance: 0,
      },
      advanceBoost: {
        value: "",
        borrowtype: "",
        borrowContractAddress: "",
        supplytype: "",
        supplyContractAddress: "",
        balance: 0,
      },
      supply: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      withDraw: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      borrow: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      repay: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      isCallAccountSnapshot: !isCallAccountSnapshot ? isCallAccountSnapshot : state.isCallAccountSnapshot,
      accountId:
        state.accountId !== props.accountDetail.accountId
          ? props.accountDetail.accountId
          : state.accountId,
    });
  };
  const compundTypeHandler = (compoundType) => {
    setCookie("compoundType", compoundType);
    setCompoundType(compoundType);
  };
  const handleModalChange = (showModal, modalType, isAdvance, payload) => {
    let obj = {
      ...state,
      showModal,
      modalType,
      isAdvance: isAdvance ? isAdvance : "",
      modalPayload: payload ? payload : {}
    }
    setState(obj);
  };
  const aaveTypeHandler = (protocol) => {
    setCookie("aaveProtocol", protocol)
    setState({ ...state, protocol })
  }
  const checkMembership = async (data) => {
    try {
      const { instance } = readContractByName(state.protocol,
        "Comptroller",
        state.network
      );
      var result = await instance.methods
        .getAssetsIn(state.accountId)
        .call();
      return result;
    } catch (error) {
      console.error(error);
    }
  };
  const transHandler = (gasPrice, gasLimit, method, isEnable, address) => {
    let toastId = transactionDetail.toastId;
    let transactionStatus = transactionDetail.status;
    try {
      let isTransactionSuccessfull = false;
      method.send({
        from: state.accountId,
        gasPrice: gasPrice,
        gas: gasLimit
      }).on("transactionHash", (hash) => {
        console.log("hash", hash);
        transactionStatus = "inprogress";
        toast.update(toastId, {
          render: () => (
            <TransactionToast
              label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
              hash={hash}
              status={transactionStatus}
            />
          ),
        });

      })
        .on("confirmation", async (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
                  status={transactionStatus}
                />
              ),
            });
            let enableSupplyTokens = await checkMembership();
            setState({
              ...state,
              enableSupplyTokens,
            });
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
        });
    } catch (e) {
      toast.update(toastId, {
        render: () => (
          <TransactionToast message={e.message} status={"error"} />
        ),
      });
    }

  };
  const enablerHandler = async (address, symbol, isEnable) => {
    let USD = 0;
    let tokenIndex = marketInfoList.findIndex(
      (x) => x.symbol === symbol
    );
    let underLyingAddress = "";
    if (tokenIndex !== -1) {
      let cToken = marketInfoList[tokenIndex];
      USD = cToken.tokenPrice;
      underLyingAddress = cToken.underlyingAsset
    }
    const { instance } = readContractByName(state.protocol,
      "LendingPool",
      state.network
    );
    let gasLimit = {};
    let transactionStatus = "creating";
    let method = instance.methods
      .setUserUseReserveAsCollateral(underLyingAddress, !isEnable)
    let data = await getAllEstimationGasPrice(method, {}, props.currentGasPrice, state.accountId);
    let errorMessage = "";
    if (!data.isError) {
      gasLimit = data.gasPrice;
    } else {
      errorMessage = data.error
    }
    let toastId = toast(<TransactionToast
      status={transactionStatus}
      USD={USD}
      gasLimit={gasLimit}
      gasPrice={props.currentGasPrice}
      handleSubmit={(gasPrice, gasLimit) => transHandler(gasPrice, gasLimit, method, isEnable, address)}
      label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
      hasError={data.isError} errorMessage={errorMessage}
    />);
    transactionDetail = {
      status: transactionStatus,
      toastId,
    };
  };
  const estimateSwapBorrowRate = async (address, symbol, type) => {
    let USD = 0;
    let label = `Swap Borrow to ${type === "Stable" ? "Variable" : "Stable"}`
    let tokenIndex = marketInfoList.findIndex(
      (x) => x.symbol === symbol
    );
    let underLyingAddress = "";
    if (tokenIndex !== -1) {
      let cToken = marketInfoList[tokenIndex];
      USD = cToken.tokenPrice;
      underLyingAddress = cToken.underlyingAsset
    }
    let contractName = "LendingPool";
    if (compoundType === "smartWallet") {
      contractName = "DSProxy";
    }
    let method;

    if (compoundType === "smartWallet") {
      const { instance } = readContractByName(state.protocol,
        contractName,
        state.network,
        smartWalletAddress
      );
      const web3 = new Web3(etheriumProvider);
      let borrowRate = 2;
      if (type === "Stable") {
        borrowRate = 1
      }
      let instanceExecute;
      Object.keys(instance.methods).map((key) => {
        if (key === "execute(address,bytes)") {
          instanceExecute = instance.methods[key]
        }
      })
      let lendingPoolAddress = getContractAddressByName(state.protocol, "LendingPoolAddressesProvider", state.network);
      let methodId = "0x9270c759";
      let data = web3.eth.abi.encodeParameters(['address', 'address', 'uint256'], [lendingPoolAddress, underLyingAddress, borrowRate]);
      data = data.replace("0x", methodId);
      const executeAddress = getContractAddressByName(state.protocol, "AaveBasicProxy", state.network);
      method = instanceExecute(executeAddress, data);
    } else {
      const { instance } = readContractByName(state.protocol,
        contractName,
        state.network
      );
      let borrowRate = 1;
      if (type === "Stable") {
        borrowRate = 2
      }
      method = instance.methods
        .swapBorrowRateMode(underLyingAddress, borrowRate)
    }
    let gasLimit = {};
    let transactionStatus = "creating";
    let data = await getAllEstimationGasPrice(method, {}, props.currentGasPrice, state.accountId);
    let errorMessage = "";
    if (!data.isError) {
      gasLimit = data.gasPrice;
    } else {
      let limit = 55600;
      // errorMessage = data.error
      data = {
        isError: true,
        gasPrice: {
          safeLow: limit,
          average: limit,
          fastest: limit,
        }
      }
      errorMessage = "An issue was detected that would cause this transaction to fail. Please contact us for assistance if the error persists"
    }
    let toastId = toast(<TransactionToast
      status={transactionStatus}
      USD={USD}
      gasLimit={gasLimit}
      gasPrice={props.currentGasPrice}
      handleSubmit={(gasPrice, gasLimit) => swapBorrowRateTransaction(gasPrice, gasLimit, label, method, type, address)}
      label={label}
      hasError={data.isError} errorMessage={errorMessage}
    />);
    transactionDetail = {
      status: transactionStatus,
      toastId,
    };
  };
  const swapBorrowRateTransaction = async (gasPrice, gasLimit, label, method) => {
    let toastId = transactionDetail.toastId;
    let transactionStatus = transactionDetail.status;
    try {
      let isTransactionSuccessfull = false;
      method.send({
        from: state.accountId,
        gasPrice: gasPrice,
        gas: gasLimit
      }).on("transactionHash", (hash) => {
        console.log("hash", hash);
        transactionStatus = "inprogress";
        toast.update(toastId, {
          render: () => (
            <TransactionToast
              label={label}
              hash={hash}
              status={transactionStatus}
            />
          ),
        });
      })
        .on("confirmation", async (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            refresh();
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
        });
    } catch (e) {
      toast.update(toastId, {
        render: () => (
          <TransactionToast label={label} message={e.message} status={"error"} />
        ),
      });
    }
  };
  const handleTypeChange = async (type, address, id, name) => {
    let balance = 0;
    let obj = { ...state[id] };
    obj["value"] = "";
    obj["balance"] = balance;
    if (name) {
      obj[name + "type"] = type;
      obj[name + "ContractAddress"] = address;
    } else {
      obj["type"] = type;
      obj["contractAddress"] = address;
    }
    setState({
      ...state,
      [id]: obj,
    });
    UpdateSupplyingTable("", id);
    if (id === "borrow") {
      let index = marketInfoList.findIndex((x) => x.symbol === type);
      if (index !== -1) {
        let tokenPrice = marketInfoList[index].referenceItem.priceInUsd;
        balance = state.leftToBorrow / tokenPrice;
      }
    } else if (id === "withDraw") {
      let data = supplyList;
      let index = data.findIndex((x) =>
        x.symbol[0] === "c" ? x.symbol.substring(1) === type : x.symbol === type
      );
      if (index !== -1) {
        balance =
          data[index]["supply_balance_underlying"] &&
            data[index]["supply_balance_underlying"]["value"]
            ? data[index]["supply_balance_underlying"]["value"]
            : 0;
      }
    } else if (id === "repay") {
      let data = borrowList;
      let index = data.findIndex((x) =>
        x.symbol[0] === "c" ? x.symbol.substring(1) === type : x.symbol === type
      );
      if (index !== -1) {
        let borrow_balance_underlying =
          data[index]["borrow_balance_underlying"] &&
            data[index]["borrow_balance_underlying"]["value"]
            ? data[index]["borrow_balance_underlying"]["value"]
            : 0;
        let lifetime_borrow_interest_accrued =
          data[index]["lifetime_borrow_interest_accrued"] &&
            data[index]["lifetime_borrow_interest_accrued"]["value"]
            ? data[index]["lifetime_borrow_interest_accrued"]["value"]
            : 0;
        balance = borrow_balance_underlying - lifetime_borrow_interest_accrued;
      }
    } else {
      if (type !== "ETH") {
        let tokenIndex = marketInfoList.findIndex(x => x.symbol === type);
        let underlyingAsset = marketInfoList[tokenIndex] ? marketInfoList[tokenIndex].underlyingAsset : "0x";
        const { instance, decimal } = readContractByName(state.protocol,
          type,
          state.network,
          underlyingAsset
        );
        balance = await instance.methods.balanceOf(state.accountId).call();
        balance = new BigNumber(balance).div(
          new BigNumber(10).pow(decimal)).toNumber();
      } else {
        balance = getCookie("balance");
        balance = balance.replace(/ .*/, "");
      }
    }
    obj["balance"] = balance;
    setState({
      ...state,
      [id]: obj,
    });
  };
  const handleChange = (e, id) => {
    let stateObj = { ...state }
    let obj = state[id];
    let name = "";
    let value = "";
    if (typeof e !== "string") {
      name = e.target.name;
      value = e.target.value;
      stateObj[id][name] = value;
    } else {
      stateObj[id] = e
    }
    stateObj["afterType"] = id;
    UpdateSupplyingTable(value, id);
    if (id === "supply") {
      stateObj["withDraw"]["value"] = "";
    } else if (id === "withDraw") {
      stateObj["supply"]["value"] = "";
    } else if (id === "advanceRepay") {
      stateObj["advanceBoost"]["value"] = "";
    } else if (id === "advanceBoost") {
      stateObj["advanceRepay"]["value"] = "";
    } else if (id === "repay") {
      stateObj["borrow"]["value"] = "";
    } else if (id === "borrow") {
      stateObj["repay"]["value"] = "";
    } else if (id === "activeAccountTab") {
      stateObj["advanceRepay"]["value"] = "";
      stateObj["advanceBoost"]["value"] = "";
      stateObj["supply"]["value"] = "";
      stateObj["withDraw"]["value"] = "";
      stateObj["borrow"]["value"] = "";
      stateObj["repay"]["value"] = "";
    }
    setState(stateObj);
  };
  const UpdateSupplyingTable = (value, id) => {
    if (document.getElementById("Supplying")) {
      var tbodyRef = document
        .getElementById("Supplying")
        .getElementsByTagName("tbody")[0];
      if (value && id === "supply") {
        tbodyRef.classList.add("supply-body");
        tbodyRef.classList.remove("withDraw-body");
      } else if (!value && id === "supply") {
        tbodyRef.classList.remove("supply-body");
      } else if (value && id === "withDraw") {
        tbodyRef.classList.add("withDraw-body");
        tbodyRef.classList.remove("supply-body");
      } else if (!value && id === "withDraw") {
        tbodyRef.classList.remove("withDraw-body");
      }
    }
  }
  const refresh = () => {
    updateFirstLoad(false);
    props.clearContractsWithSnapshotAction();
    props.getAaveMarketPrice(state.network, state.protocol);
    updateMarketInfoList([]);
    updateSupplyList([]);
    updateBorrowList([]);
    resetState(false);
    checkSmartWallet();
  };
  const isShowSupplyBorrow = (data, key) => {
    let isShow = false;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element[key] && element[key]["value"] > 0) {
          isShow = true;
        }
      }
    }
    return isShow;
  };
  const getIsShowAfter = () => {
    let isShowSupplyAfter = false;
    let isShowBorrowAfter = false;
    let selectedToken = state[state.afterType];
    let type = "";
    if (state.supply.type !== "" &&
      state.supply.value !== "" &&
      parseFloat(state.supply.value) !== 0) {
      isShowSupplyAfter = true;
      type = state.supply.type;
    } else if (state.withDraw.type !== "" &&
      state.withDraw.value !== "" &&
      parseFloat(state.withDraw.value) !== 0) {
      type = state.withDraw.type;
      isShowSupplyAfter = true;
    } else if (state.advanceBoost.value !== "" && parseFloat(state.advanceBoost.value) !== 0 && state.advanceBoost.supplytype !== "" && state.advanceBoost.borrowtype !== "") {
      type = state.advanceBoost.supplytype;
      isShowSupplyAfter = true;
      isShowBorrowAfter = true;
    }
    if (state.borrow.type !== "" &&
      state.borrow.value !== "" &&
      parseFloat(state.borrow.value) !== 0) {
      type = state.borrow.type;
      isShowBorrowAfter = true;
    } else if (state.repay.type !== "" &&
      state.repay.value !== "" &&
      parseFloat(state.repay.value) !== 0) {
      type = state.repay.type;
      isShowBorrowAfter = true;
    } else if (state.advanceRepay.value !== "" && parseFloat(state.advanceRepay.value) !== 0 && state.advanceRepay.repaytype !== "" && state.advanceRepay.repaytype !== "") {
      type = state.advanceRepay.repaytype;
      isShowSupplyAfter = true;
      isShowBorrowAfter = true;
    }
    let activeMarketInfoToken = {};
    if ((isShowSupplyAfter || isShowBorrowAfter) && state.afterType) {
      if (type) {
        let index = props.marketInfoList.findIndex(
          (x) => x.underlying_symbol === type
        );
        if (index !== -1) {
          activeMarketInfoToken = props.marketInfoList[index];
        }
      }

    }
    return { isShowSupplyAfter, isShowBorrowAfter, activeMarketInfoToken, selectedToken }
  }
  const checkSmartWallet = async (accountId) => {
    try {
      setCheckingSmaerWallet(true);
      const { instance } = readContractByName(state.protocol,
        "ProxyRegistry",
        state.network
      );
      const proxies = await instance.methods.proxies(accountId ? accountId : state.accountId).call();
      if (proxies !== "0x0000000000000000000000000000000000000000") {
        setSmartWalletAddress(proxies);
      }
      setCheckingSmaerWallet(false);
    } catch {
      setCheckingSmaerWallet(false);
    }
  };
  async function createDSProxyWallet() {
    const { instance } = readContractByName(state.protocol,
      "ProxyRegistry",
      state.network
    );
    let transactionStatus = "creating";
    let USD = 0;
    let tokenIndex = marketInfoList.findIndex(
      (x) => x.underlying_symbol === state.supply.type
    );
    if (tokenIndex !== -1) {
      let cToken = marketInfoList[tokenIndex];
      USD = cToken.pricing.price ? cToken.pricing.price : 0;
    }

    let gasLimit = {};
    let data = await getAllEstimationGasPrice(instance.methods
      .build(state.accountId), {}, props.currentGasPrice, state.accountId);
    if (!data.isError) {
      gasLimit = data.gasPrice;
      createDSProxyWalletToastId = toast(
        <TransactionToast
          status={transactionStatus}
          USD={USD}
          gasLimit={gasLimit}
          gasPrice={props.currentGasPrice}
          handleSubmit={(gasPrice, gasLimit) => acceptGasPrice(gasPrice, gasLimit, instance)}
          label={`Create smart wallet`}
        />
      );
    } else {
      createDSProxyWalletToastId = toast(
        <TransactionToast message={data.error} status={"error"} label={`Creating smart wallet`} />
      );
    }

  }
  const acceptGasPrice = async (gasPrice, gasLimit, instance) => {
    if (instance === undefined) {
      let contract = readContractByName(state.protocol,
        "ProxyRegistry",
        state.network
      );
      instance = contract.instance;
    }
    toast.update(createDSProxyWalletToastId, {
      render: () => <TransactionToast status={"inprogress"} label={`Creating smart wallet`} />,
    });
    try {
      const tx = await instance.methods
        .build(state.accountId)
        .send({ from: state.accountId, gasPrice: gasPrice, gas: gasLimit });
      if (tx) {
        refresh();
        toast.update(createDSProxyWalletToastId, {
          render: () => <TransactionToast status={"confirm"} label={`Smart wallet created`} />,
        });
      }
    } catch (e) {
      toast.update(createDSProxyWalletToastId, {
        render: () => <TransactionToast message={e.message} status={"error"} label={`Creating smart wallet`} />,
      });
    }
  };
  let { isShowSupplyAfter, isShowBorrowAfter, activeMarketInfoToken, selectedToken } = getIsShowAfter();
  let allowTransaction = props.accountDetail.connectedWallet !== "trackAddress"
  return (
    <React.Fragment>
      {!state.isValidNetwork ?
        <div className="width-container">
          <div className="alert alert-danger" role="alert">Connected network not supported with compound</div>
        </div>
        : ""}
      <div className={`width-container ${!state.isValidNetwork ? "disabled" : ""}`}>
        <div className="main-section-wrapper">
          <div className="dashboard-header-wrapper compound-manage-sub-header-wrapper width-container">
            <div className="wallet-type-select-wrapper">
              <div className="label">Account type:</div>
              <div className="select-container">
                <Dropdown>
                  <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                    {compoundType === "account"
                      ? "Account"
                      : "Smart Wallet"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {["account", "smartWallet"].map((item, index) => {
                      return <Dropdown.Item key={index}
                        onClick={() => compundTypeHandler(item)}
                        className={`${compoundType === item ? "active" : ""
                          }`}
                      >
                        {item === "smartWallet"
                          ? "Smart Wallet" : item.capitalizeString()}
                      </Dropdown.Item>
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {compoundType === "smartWallet"
                  ? <div className="">
                    <button
                      type="button"
                      onClick={() => handleModalChange(true, "smartwallet")}
                      className="button mid dark with-icon wallet"
                    >
                      <i></i>
                    </button>
                  </div>
                  : ""}

                <div className="">

                  <button
                    type="button"
                    onClick={() => handleModalChange(true, "stackingRate", compoundType)}
                    className="button btn-Secondary mini dark with-icon comp"
                  >
                    <i></i> Staking
                  </button>
                </div>
                {state.protocol === "aave/v1" && compoundType === "smartWallet" ?
                  <div className="" style={{ marginLeft: "5px" }}>
                    <button
                      type="button"
                      onClick={() => handleModalChange(true, "migrateAavev2", compoundType)}
                      className="button btn-Secondary mini dark with-icon comp"
                    >
                      <i></i> Migrate to V2
                    </button>
                  </div> : ""}
              </div>
              <div className="Flex moneymarket-sub-header-right-wrapper right ">
                <div className="data-item-wrapper arrow  ">
                  <div className="label-value-wrapper">
                    <div className="label-wrapper">
                      <div className="">
                        <span data-background-color="#5383FF" data-html="true" data-tip="Yearly interset percentage on supplied funds.Changes based on market supply and demand." data-class="custom-tooltip">
                          <i className="icon icon-Info-circle"></i>
                        </span>
                      </div>
                      <span className="label">Net APY:</span>
                    </div>
                    <div className="value-wrapper">
                      <div className="">
                        <span>
                          0<span className="symbol false">%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="select-container aave">
                <Dropdown>
                  <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                    {state.protocol === "aave/v1" ? "Aave V1" : "Aave V2"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    <Dropdown.Item
                      className="" onClick={() => aaveTypeHandler('aave/v1')}                 >
                      AAVE V1
                    </Dropdown.Item>
                    <Dropdown.Item
                      className=""
                      onClick={() => aaveTypeHandler('aave/v2')}
                    >
                      AAVE V2
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {checkingSmaerWallet ? null : (
            <>
              {compoundType === "smartWallet" &&
                smartWalletAddress === "" ? (
                <SmartWallet
                  handleModalChange={handleModalChange}
                  createDSProxyWallet={createDSProxyWallet}
                  allowTransaction={allowTransaction}
                />
              ) : (
                <Account
                  supplyBalance={state.supplyBalance}
                  supplyList={supplyList}
                  borrowList={borrowList}
                  leftToBorrow={state.leftToBorrow}
                  borrowBalance={state.borrowBalance}
                  activeMarketInfoToken={activeMarketInfoToken}
                  supply={state.supply}
                  ethPrice={state.ethPrice}
                  marketInfoList={marketInfoList}
                  borrow={state.borrow}
                  withDraw={state.withDraw}
                  borrowLimit={state.borrowLimit}
                  advanceBoost={state.advanceBoost}
                  advanceRepay={state.advanceRepay}
                  isShowAfter={isShowSupplyAfter || isShowBorrowAfter}
                  afterType={state.afterType}
                  repay={state.repay}
                  selectedToken={selectedToken}
                  safetyRatio={state.safetyRatio}
                />
              )}
            </>
          )}
        </div>
        {/* Account data start */}
        {compoundType === "account" ||
          (compoundType === "smartWallet" && smartWalletAddress !== "") ? (
          <AccountTabs
            network={state.network}
            borrow={state.borrow}
            supplyList={supplyList}
            cTokenContracts={props.contracts}
            borrowList={borrowList}
            cTokensList={marketInfoList}
            currentGasPrice={props.currentGasPrice}
            withDraw={state.withDraw}
            supply={state.supply}
            showModal={state.showModal}
            modalType={state.modalType}
            isAdvance={state.isAdvance}
            handleChange={handleChange}
            enableSupplyTokens={state.enableSupplyTokens}
            handleTypeChange={handleTypeChange}
            borrowLimit={state.borrowLimit}
            refresh={refresh}
            repay={state.repay}
            advanceRepay={state.advanceRepay}
            advanceBoost={state.advanceBoost}
            handleModalChange={handleModalChange}
            compundTypeHandler={compundTypeHandler}
            setSmartWalletAddress={setSmartWalletAddress}
            ApiRequestedAction={props.ApiRequestedAction}
            smartWalletAddress={smartWalletAddress}
            compoundType={compoundType}
            accountId={state.accountId}
            activeAccountTab={state.activeAccountTab}
            protocol={state.protocol}
            allowTransaction={allowTransaction}
            modalPayload={state.modalPayload}
            aaveTypeHandler={aaveTypeHandler}
          />
        ) : (
          ""
        )}
        {/* Acccount data end */}
        {(supplyList.length > 0 &&
          isShowSupplyBorrow(supplyList, "supply_balance_underlying")) ||
          isShowSupplyAfter ? (
          <SupplyListing
            supplyList={supplyList}
            supply={state.supply}
            withDraw={state.withDraw}
            selectedToken={selectedToken}
            enablerHandler={enablerHandler}
            isShowAfter={isShowSupplyAfter}
            enableSupplyTokens={state.enableSupplyTokens}
            marketInfoList={marketInfoList}
            apiCallStatus={props.apiCallStatus}
            afterType={state.afterType}
            allowTransaction={allowTransaction}
            handleModalChange={handleModalChange}
          />
        ) : (
          ""
        )}
        {(supplyList.length > 0 &&
          isShowSupplyBorrow(borrowList, "borrow_balance_underlying")) ||
          isShowBorrowAfter ? (
          <BorrowListing
            borrowList={borrowList}
            borrow={state.borrow}
            selectedToken={selectedToken}
            isShowAfter={isShowBorrowAfter}
            apiCallStatus={props.apiCallStatus}
            borrowLimit={state.borrowLimit}
            marketInfoList={marketInfoList}
            repay={state.repay}
            afterType={state.afterType}
            estimateSwapBorrowRate={estimateSwapBorrowRate}
            protocol={state.protocol}
          />
        ) : (
          ""
        )}
        <MarketInfoListing
          marketInfoList={marketInfoList}
          apiCallStatus={props.apiCallStatus}
        />
        <SmartWalletModal
          showModal={state.showModal && state.modalType === "smartwallet"}
          handleModalChange={handleModalChange}
          smartWalletAddress={smartWalletAddress}
        />
        <CreateDSProxyModal
          showModal={state.showModal && state.modalType === "createDSProxy"}
          handleModalChange={handleModalChange}
          createSmartWallet={createDSProxyWallet}
          currentGasPrice={props.currentGasPrice}
          tokenPriceList={props.tokenPriceList}
          accountId={state.accountId}
          protocol={state.protocol}
        />
        <DisableTokenAlertModal showModal={state.showModal && state.modalType === "disableTokenAlert"}
          handleModalChange={handleModalChange} modalPayload={state.modalPayload} enablerHandler={enablerHandler} />
        <StackingRateModal showModal={state.showModal && state.modalType === "stackingRate"}
          handleModalChange={handleModalChange} />

      </div>
      <ReactTooltip />
      {
        props.apiCallStatus.isStarted.length !== 0 && props.apiCallStatus.apiCallFor !== "connectWallet" && props.apiCallStatus.apiCallFor !== "updateTokenPrice" && isFirstLoad ?
          <Loader isFullPage={true} message="Connecting your account, please wait..." /> : ""
      }
    </React.Fragment >
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  marketInfoList: state.aaveReducer.marketInfo.list,
  supplyList: state.aaveReducer.supplyList,
  cTokensList: state.aaveReducer.cTokensList,
  tokenPriceList: state.aaveReducer.tokenPriceList,
  currentGasPrice: state.commonReducer.currentGasPrice,
  accountDetail: state.commonReducer.accountDetail,
  contracts: state.aaveReducer.contracts
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAaveMarketPrice: (network, version) =>
    dispatch(getAaveMarketPrice(network, version)),
  getCTokenList: (network) => dispatch(getCTokenList(network)),
  getAccountSnapshotOfAllATokens: (list, network, accountId, version) => dispatch(getAccountSnapshotOfAllATokens(list, network, accountId, version)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  ApiRequestedAction: (data) => dispatch(ApiRequestedAction(data)),
  clearContractsWithSnapshotAction: () => dispatch(clearContractsWithSnapshotAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(Manage);
