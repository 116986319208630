import { useState, useEffect } from "react";
import { aaveSmartWalletAddress, aaveReferralCode, etheriumProvider } from "../../../../constants/constant";
import { readContractByName, getContractAddressByName, getContractDecimalByName, getAllEstimationGasPrice, readUnderLyingContractByName, getEncodedContractAddressByName, getContractUnderlyingAddressByName } from "../../../../utils/contracts";
import {
  BoostRepayAccountModal,
  BoostRepaySmartWalletModal,
  InterestRateModal,
  MigrateAaveV2Modal
} from "../../../../components/Models/models";
import Dropdown from "react-bootstrap/Dropdown";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import TransactionToast from "../../../../components/Common/Components/toastAlertMsg";
import ReactTooltip from "react-tooltip";
import { millifyNumber, truncateNumBeforeTransaction } from '../../../../utils/common'
import { BigNumber } from "bignumber.js";
import Web3 from "web3";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
const toHex = require('to-hex');

export function Account(props) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);
  const getAfterSupplyBalance = () => {
    let result = 0;
    let balance = 0;
    let percentageDetail = {};
    let keys = [];
    let data = props.supplyList;
    if (props.isShowAfter) {
      let amount = 0;
      let type = "";
      if (
        (props.afterType === "supply" || props.afterType === "advanceBoost") &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.afterType === "advanceBoost" ? props.advanceBoost.supplytype : props.selectedToken.type;
        amount = props.activeMarketInfoToken.pricing.price * props.selectedToken.value;
        result = props.supplyBalance + amount;
      } else if (
        (props.afterType === "withDraw" || props.afterType === "advanceRepay") &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.afterType === "advanceRepay" ? props.advanceRepay.repaytype : props.selectedToken.type;
        amount =
          props.activeMarketInfoToken.pricing.price * props.selectedToken.value;
        result = props.supplyBalance - amount;
      } else {
        result = props.supplyBalance;
      }
      if (data.length > 0 && result > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let symbol =
            element.symbol[0] === "c"
              ? element.symbol.substring(1)
              : element.symbol;
          let supply_balance_underlying =
            element.supply_balance_underlying &&
              element.supply_balance_underlying.value
              ? parseFloat(element.supply_balance_underlying.value)
              : 0;
          let suppliedBalanceInDollar =
            supply_balance_underlying * element.tokenPrice;
          if (symbol === type) {
            if (props.afterType === "supply" || props.afterType === "advanceBoost") {
              suppliedBalanceInDollar += amount;
            } else if (props.afterType === "withDraw" || props.afterType === "advanceRepay") {
              suppliedBalanceInDollar -= amount;
            }
          }
          let supplyBalance = result;
          let percentage = (suppliedBalanceInDollar / supplyBalance) * 100;
          if (percentage !== 0) {
            percentageDetail[symbol] = {
              name: symbol,
              percentage: percentage,
            };
          }
        }
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (amount / result) * 100,
          };
        }
      } else if (result > 0) {
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (result / result) * 100,
          };
        }
      }
      keys = Object.keys(percentageDetail);
      if (Number.MAX_SAFE_INTEGER > Number(result)) {
        balance = millifyNumber(result, {
          precision: 2,
          lowercase: false,
        });
      } else {
        balance = result.toFixedNoRounding(2)
      }
    }

    return { balance: balance, result: result, percentageDetail, keys: keys };
  };
  const getAfterBorrowBalance = (resultType) => {
    let result = 0;
    if (
      props.afterType === "borrow" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.borrowBalance +
        (props.activeMarketInfoToken.pricing.price * props.selectedToken.value);
    } else if (
      props.afterType === "repay" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.borrowBalance -
        (props.activeMarketInfoToken.pricing.price * props.selectedToken.value);
    } if (
      props.afterType === "advanceBoost"
    ) {
      let type = props.selectedToken.borrowtype;
      let value = props.selectedToken.value;
      let tokenPrice = getTokenPrice(type);
      result =
        props.borrowBalance +
        (tokenPrice * value);
    } else {
      result = props.borrowBalance;
    }
    if (resultType) {
      if (Number.MAX_SAFE_INTEGER > Number(result)) {
        result = millifyNumber(result, {
          precision: 2,
          lowercase: false,
        });
      } else {
        result = result.toFixedNoRounding(2)
      }
    }
    return result;
  };
  const getAfterBorrowLimit = (type) => {
    let result = 0;
    if (type) {
      result = props.borrowLimit.toFixedNoRounding(2);
    } else {
      result = props.borrowLimit;
    }
    return result;
  };
  const getAfterLeftToBorrow = (type) => {
    let result = 0;
    if (
      props.afterType === "borrow" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.leftToBorrow -
        props.activeMarketInfoToken.pricing.price * props.borrow.value;
    } else if (
      props.afterType === "repay" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.leftToBorrow +
        props.activeMarketInfoToken.pricing.price * props.repay.value;
    } if (
      props.afterType === "advanceBoost"
    ) {
      let type = props.selectedToken.borrowtype;
      let value = props.advanceBoost.value;
      let tokenPrice = getTokenPrice(type);
      result =
        props.leftToBorrow -
        tokenPrice * value;
    } else {
      result = props.leftToBorrow;
    }
    if (type) {
      result = result.toFixedNoRounding(2);
    }

    return result;
  };
  const getBorrowPowerUsedPercentage = () => {
    let percentage = 0;
    let percentageDetail = {};
    let keys = [];
    let data = props.borrowList;
    if (props.borrowBalance !== 0 && props.borrowLimit !== 0 && data.length > 0) {
      // let value = props.borrowLimit - props.leftToBorrow;
      let value = props.borrowBalance;
      percentage = (value / props.borrowLimit) * 100;
      if (percentage > 100) {
        percentage = 100;
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let symbol =
          element.symbol[0] === "c"
            ? element.symbol.substring(1)
            : element.symbol;
        let borrow_balance_underlying =
          element.borrow_balance_underlying &&
            element.borrow_balance_underlying.value
            ? (element.borrow_balance_underlying.value)
            : 0;
        let lifetime_borrow_interest_accrued =
          element.lifetime_borrow_interest_accrued &&
            element.lifetime_borrow_interest_accrued.value
            ? (element.lifetime_borrow_interest_accrued.value)
            : 0;
        let borrowBalanceInDollar =
          (borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice;
        let tokenPercentage = (borrowBalanceInDollar / props.borrowLimit) * 100;
        if (tokenPercentage !== 0) {
          percentageDetail[symbol] = {
            name: symbol,
            percentage: tokenPercentage,
          };
        }
      }
      keys = Object.keys(percentageDetail)
    }

    return { percentage: isNaN(percentage) ? 0 : Number(percentage.toFixedNoRounding(2)), percentageDetail, keys };
  };
  const getAfterBorrowPowerUsedPercentage = () => {
    let percentage = 0;

    let percentageDetail = {};
    let keys = [];
    let data = props.borrowList;
    if (props.isShowAfter) {
      let borrowLimit = getAfterBorrowLimit("toFix");
      let borrowBalance = getAfterBorrowBalance();
      percentage = (borrowBalance / borrowLimit) * 100;
      if (percentage > 100) {
        percentage = 100;
      }
      let amount = 0;
      let type = "";
      if (
        props.afterType === "borrow" &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.selectedToken.type;
        amount = props.activeMarketInfoToken.pricing.price * props.borrow.value;
      } else if (
        props.afterType === "repay" &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.selectedToken.type;
        amount =
          props.activeMarketInfoToken.pricing.price * props.repay.value;
      } if (
        props.afterType === "advanceBoost"
      ) {
        type = props.selectedToken.borrowtype;
        let tokenPrice = getTokenPrice(type);
        amount = tokenPrice * props.advanceBoost.value;
      }
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let symbol =
            element.symbol[0] === "c"
              ? element.symbol.substring(1)
              : element.symbol;
          let borrow_balance_underlying =
            element.borrow_balance_underlying &&
              element.borrow_balance_underlying.value
              ? (element.borrow_balance_underlying.value)
              : 0;
          let lifetime_borrow_interest_accrued =
            element.lifetime_borrow_interest_accrued &&
              element.lifetime_borrow_interest_accrued.value
              ? (element.lifetime_borrow_interest_accrued.value)
              : 0;
          let borrowBalanceInDollar = 0;
          if (type === symbol) {
            if (props.afterType === "borrow" || props.afterType === "advanceBoost") {
              borrowBalanceInDollar =
                ((borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice) + amount;
            } else if (props.afterType === "repay") {
              borrowBalanceInDollar =
                ((borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice) - amount;
            }
          } else {
            borrowBalanceInDollar =
              (borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice;
          }
          let tokenPercentage = (borrowBalanceInDollar / borrowLimit) * 100;
          if (tokenPercentage !== 0) {
            percentageDetail[symbol] = {
              name: symbol,
              percentage: tokenPercentage,
            };
          }
        }
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (amount / borrowLimit) * 100,
          };
        }
        keys = Object.keys(percentageDetail);
      }
    }
    return { percentage: isNaN(percentage) ? 0 : Number(percentage.toFixedNoRounding(2)), percentageDetail, keys };
  };
  const getSupplyComposition = () => {
    let percentage = 0;
    let percentageDetail = {};
    let data = props.supplyList;
    if (data.length > 0 && props.supplyBalance > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let symbol =
          element.symbol[0] === "c"
            ? element.symbol.substring(1)
            : element.symbol;
        let supply_balance_underlying =
          element.supply_balance_underlying &&
            element.supply_balance_underlying.value
            ? (element.supply_balance_underlying.value)
            : 0;
        let suppliedBalanceInDollar =
          supply_balance_underlying * element.tokenPrice;
        let supplyBalance = props.supplyBalance;
        percentage = (suppliedBalanceInDollar / supplyBalance) * 100;
        if (percentage !== 0) {
          percentageDetail[symbol] = {
            name: symbol,
            percentage: percentage,
          };
        }
      }
    }
    return percentageDetail;
  };
  const getTokenPrice = (type) => {
    let cTokenIndex = props.marketInfoList.findIndex(
      (x) => (x.symbol) === type
    );
    let tokenPrice = 0;
    if (cTokenIndex !== -1) {
      tokenPrice = props.marketInfoList[cTokenIndex].referenceItem.priceInUsd;
    }
    return tokenPrice;
  }
  let supplyCompositionGraphData = getSupplyComposition();
  let supplyCompositionKeys = supplyCompositionGraphData
    ? Object.keys(supplyCompositionGraphData)
    : [];
  let getAfterSupply = getAfterSupplyBalance();
  let borrowPowerUsedPercentage = getBorrowPowerUsedPercentage();
  let afterBorrowPowerUsedPercentage = getAfterBorrowPowerUsedPercentage();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <div>
      <div className="main-subsections-wrapper">
        <div className="main-subsection moneymarket-supply-wrapper">
          <div className="top-wrapper">
            <div className="data-item-wrapper standard  ">
              <div className="label-value-wrapper">
                <div className="label-wrapper">
                  <div className="">
                    <span data-background-color="#5383FF" data-html="true" data-tip="The sum of your supplied Collateral." data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip">
                      <i className="icon icon-Info-circle"></i>
                    </span>
                  </div>
                  <span className="label">Supply Balance:</span>
                </div>
                <div className="value-wrapper">
                  <div className="">
                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                      data-tip={props.supplyBalance ? props.supplyBalance : 0}
                    >
                      <span className="symbol false">$</span>
                      {props.supplyBalance
                        ? millifyNumber(props.supplyBalance, {
                          precision: 2,
                          lowercase: false,
                        })
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              {props.isShowAfter ? (
                <div className="label-value-wrapper after">
                  <div className="label-wrapper">
                    <span className="label">After:</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={getAfterSupply.result}>
                        <span className="symbol false">$</span>
                        {getAfterSupply.balance}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="spacer"></div>
          </div>
          <div className="bottom-wrapper">
            <div className="moneymarket-supply-graph-wrapper">
              <h2>Supply composition:</h2>
              <div className="amount-graph-wrapper">
                <div className="percentage-graph-wrapper ">
                  <div className="Flex SpaceBetween top-labels">
                    <span></span>
                    <span>
                      <div className="">
                        <span></span>
                      </div>
                    </span>
                  </div>
                  <div className="graph-wrapper">
                    {supplyCompositionKeys &&
                      supplyCompositionKeys.map(function (char, idx) {
                        let element = supplyCompositionGraphData[char];
                        let prevElem =
                          supplyCompositionGraphData[
                          supplyCompositionKeys[idx - 1]
                          ];
                        let toFixedPercentage = element.percentage.toFixedNoRounding(
                          2
                        );
                        if (idx === 0) {
                          return (
                            <span
                              key={idx}
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                }`}
                              style={{ width: element.percentage + "%" }}
                            ></span>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${prevElem
                                ? prevElem.percentage + element.percentage >=
                                  100
                                  ? "br-full"
                                  : element.percentage >= 100
                                    ? "br-full"
                                    : ""
                                : ""
                                }`}
                              style={{
                                width: `${prevElem
                                  ? prevElem.percentage + element.percentage
                                  : element.percentage
                                  }%`,
                              }}
                            ></div>
                          );
                        }
                      })}
                  </div>
                </div>
                {props.isShowAfter ? (
                  <div className="percentage-graph-wrapper ">
                    <div className="Flex SpaceBetween top-labels">
                      <span>After</span>
                      <span>
                        <div className="">
                          <span></span>
                        </div>
                      </span>
                    </div>
                    <div className="graph-wrapper small">
                      {getAfterSupply &&
                        getAfterSupply.keys &&
                        getAfterSupply.keys.map(function (char, idx) {
                          let element = getAfterSupply.percentageDetail[char];
                          let prevElem =
                            getAfterSupply.percentageDetail[
                            getAfterSupply.keys[idx - 1]
                            ];
                          let toFixedPercentage = element.percentage.toFixedNoRounding(
                            2
                          );
                          if (idx === 0) {
                            return (
                              <span
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                  }`}
                                style={{ width: (element.percentage > 100 ? 100 : element.percentage) + "%" }}
                              ></span>
                            );
                          } else {
                            return (
                              <div
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${prevElem
                                  ? prevElem.percentage +
                                    element.percentage >=
                                    100
                                    ? "br-full"
                                    : element.percentage >= 100
                                      ? "br-full"
                                      : ""
                                  : ""
                                  }`}
                                style={{
                                  width: `${prevElem
                                    ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                    : element.percentage
                                    }%`,
                                }}
                              ></div>
                            );
                          }
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-subsection compound-manage-borrow-wrapper">
          <div className="top-wrapper">
            <div className="Flex FlexStart FlexGrow">
              <div className="data-item-wrapper standard  ">
                <div className="label-value-wrapper">
                  <div className="label-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip="The sum of your boorrowed funds.">
                        <i className="icon icon-Info-circle"></i>
                      </span>
                    </div>
                    <span className="label">Borrow Balance:</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.borrowBalance}>
                        <span className="symbol false">$</span>
                        {props.borrowBalance.toFixedNoRounding(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="data-item-wrapper standard  ">
                <div className="label-value-wrapper">
                  <div className="label-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-html={true} data-tip="<p>The ratio of your borrow limit versus borrowed funds.<br/> Liquidation happens below 100%.</p>">
                        <i className="icon icon-Info-circle"></i>
                      </span>
                    </div>
                    <div
                      className="ratio-circle"
                      style={{ backgroundColor: "rgb(55, 176, 110)" }}
                    ></div>
                    <span className="label">Safety ratio (min 100%):</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.safetyRatio ? props.safetyRatio : 0}>
                        {props.safetyRatio
                          ? props.safetyRatio.toFixedNoRounding(2)
                          : 0}
                        <span className="symbol false">%</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
            </div>
            <div className="top-right-wrapper">
              <Slider className="slick-slider" {...settings}>
                <div>
                  <div className="slide-inner-wrapper">
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <div className="">
                            <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip="The maximum value of funds you can borrow.">
                              <i className="icon icon-Info-circle"></i>
                            </span>
                          </div>
                          <span className="label">Borrow Limit:</span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                              data-tip={
                                props.borrowLimit ? props.borrowLimit : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.borrowLimit
                                ? props.borrowLimit.toFixedNoRounding(2)
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <span className="label">
                            {" "}
                            {props.isShowAfter
                              ? "After"
                              : "Left to Borrow:"}
                          </span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                              data-tip={
                                props.isShowAfter
                                  ? getAfterBorrowLimit()
                                  : props.leftToBorrow
                                    ? props.leftToBorrow
                                    : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.isShowAfter
                                ? getAfterBorrowLimit("toFix")
                                : props.leftToBorrow
                                  ? props.leftToBorrow.toFixedNoRounding(2)
                                  : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          {props.isShowAfter ? (
            <div className="top-wrapper after">
              <div className="Flex FlexStart FlexGrow">
                <div className="data-item-wrapper standard  ">
                  <div className="label-value-wrapper">
                    <div className="label-wrapper">
                      <span className="label">After:</span>
                    </div>
                    <div className="value-wrapper">
                      <div className="">
                        <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={getAfterBorrowBalance()}>
                          <span className="symbol false">$</span>
                          {getAfterBorrowBalance("millify")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
              <div className="top-right-wrapper">
                <Slider className="slick-slider" {...settings}>
                  <div>
                    <div className="slide-inner-wrapper">
                      <div className="data-item-wrapper small  ">
                        <div className="label-value-wrapper">
                          <div className="label-wrapper">
                            <span className="label">Left to Borrow:</span>
                          </div>
                          <div className="value-wrapper">
                            <div className="">
                              <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                data-tip={
                                  props.leftToBorrow
                                    ? props.leftToBorrow
                                    : 0
                                }
                              >
                                <span className="symbol false">$</span>
                                {props.leftToBorrow
                                  ? props.leftToBorrow.toFixedNoRounding(
                                    2
                                  )
                                  : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-item-wrapper small  ">
                        <div className="label-value-wrapper">
                          <div className="label-wrapper">
                            <span className="label"> After</span>
                          </div>
                          <div className="value-wrapper">
                            <div className="">
                              <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={getAfterLeftToBorrow()}>
                                <span className="symbol false">$</span>
                                {getAfterLeftToBorrow("toFix")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="bottom-wrapper">
            <div className="compound-manage-borrow-graph-wrapper">
              <h2>Borrow power used:</h2>
              <div className="amount-graph-wrapper">
                <div className="percentage-graph-wrapper ">
                  <div className="Flex SpaceBetween top-labels">
                    <span>0%</span>
                    <span>
                      <div className="">
                        <span>100%</span>
                      </div>
                    </span>
                  </div>
                  <div className="graph-wrapper">
                    {borrowPowerUsedPercentage.keys &&
                      borrowPowerUsedPercentage.keys.map(function (char, idx) {
                        let element = borrowPowerUsedPercentage.percentageDetail[char];
                        let prevElem =
                          borrowPowerUsedPercentage.percentageDetail[
                          borrowPowerUsedPercentage.keys[idx - 1]
                          ];
                        let toFixedPercentage = element.percentage.toFixedNoRounding(
                          2
                        );
                        if (idx === 0) {
                          return (
                            <span
                              key={idx} data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                              data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                }`}
                              style={{ width: (element.percentage < 100 ? element.percentage : 100) + "%" }}
                            ></span>
                          );
                        } else {
                          return (
                            <div
                              key={idx} data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                              data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${prevElem
                                ? prevElem.percentage + element.percentage >=
                                  100
                                  ? "br-full"
                                  : element.percentage >= 100
                                    ? "br-full"
                                    : ""
                                : ""
                                }`}
                              style={{
                                width: `${prevElem
                                  ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                  : element.percentage
                                  }%`,
                              }}
                            ></div>
                          );
                        }
                      })}
                    <div data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                      data-tip={`Total ${borrowPowerUsedPercentage.percentage}%`}
                      className={`overlay-total color-overlay`}
                      style={{
                        width: `${((borrowPowerUsedPercentage.percentage > 100 ? 100 : borrowPowerUsedPercentage.percentage) + 1)}%`,
                      }}
                    ></div>
                  </div>
                </div>
                {props.isShowAfter ? (
                  <div className="percentage-graph-wrapper ">
                    <div className="Flex SpaceBetween top-labels">
                      <span>After</span>
                      <span>
                        <div className="">
                          <span></span>
                        </div>
                      </span>
                    </div>
                    {/* <div className="graph-wrapper small">
                      <span
                        className="first-color-overlay"
                        style={{
                          width: getAfterBorrowPowerUsedPercentage() + "%",
                        }}
                      ></span>
                      <div
                        className="special-data-point"
                        style={{
                          width: getAfterBorrowPowerUsedPercentage() + "%",
                        }}
                      >
                        <span className="bottom-percent">
                          {getAfterBorrowPowerUsedPercentage()}%
                        </span>
                      </div>
                    </div> */}
                    <div className="graph-wrapper small">
                      {afterBorrowPowerUsedPercentage.keys &&
                        afterBorrowPowerUsedPercentage.keys.map(function (char, idx) {
                          let element = afterBorrowPowerUsedPercentage.percentageDetail[char];
                          let prevElem =
                            afterBorrowPowerUsedPercentage.percentageDetail[
                            afterBorrowPowerUsedPercentage.keys[idx - 1]
                            ];
                          let toFixedPercentage = element.percentage.toFixedNoRounding(
                            2
                          );
                          if (idx === 0) {
                            return (
                              <span
                                key={idx} data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                  }`}
                                style={{ width: (element.percentage < 100 ? element.percentage : 100) + "%" }}
                              ></span>
                            );
                          } else {
                            return (
                              <div
                                key={idx} data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${prevElem
                                  ? prevElem.percentage + element.percentage >=
                                    100
                                    ? "br-full"
                                    : element.percentage >= 100
                                      ? "br-full"
                                      : ""
                                  : ""
                                  }`}
                                style={{
                                  width: `${prevElem
                                    ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                    : element.percentage
                                    }%`,
                                }}
                              ></div>
                            );
                          }
                        })}
                      <div data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                        data-tip={`Total ${afterBorrowPowerUsedPercentage.percentage}%`}
                        className={`overlay-total color-overlay`}
                        style={{
                          width: `${((afterBorrowPowerUsedPercentage.percentage > 100 ? 100 : afterBorrowPowerUsedPercentage.percentage) + 1)}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AccountTabs(props) {
  const { smartWalletAddress, compoundType } = props;
  const [state, setState] = useState({
    contractResponse: null,
    smatyWalletSupplyAddress: getContractAddressByName(props.protocol, "AaveBasicProxy", props.network),
    aaveSmartWalletAddress: aaveSmartWalletAddress[props.network],
    collateralAsset: "",
    debtAsset: ""
  });
  let transactionDetail = {
    status: "",
    toastId: "",
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val
    })
  }
  const selectHandler = (e) => {
    var tableRef = document.getElementById("Supplying");
    if (tableRef) {
      let tbodyRef = tableRef.getElementsByTagName("tbody")[0];
      if (e !== "collateral") {
        tbodyRef.classList.remove("supply-body");
        tbodyRef.classList.remove("withDraw-body");
      }
    }
    props.handleChange(e, "activeAccountTab")
  };
  const approveToken = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} isLast={true} />
              ),
            });
            let type = compoundType === "smartWallet" ? "boost" : "transferTokenBoost";
            if (id === "supply") {
              type = ""
            }
            selectGasPrice(id, type, true);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const createSmartWallet = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.build().send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", async (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            const proxies = await instanceMethod.proxies(props.accountId).call();
            if (proxies !== "0x0000000000000000000000000000000000000000") {
              props.setSmartWalletAddress(proxies);
            }
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} />
              ),
            });
            let contractAddress = props[id][id === "advanceRepay" ? "repayContractAddress" : "supplyContractAddress"];
            let isApprove = props.enableSupplyTokens && props.enableSupplyTokens.findIndex(address => Number(address) === Number(contractAddress)) !== -1;
            let type = "approve";
            if (isApprove) {
              type = "transferTokenBoost";
            }
            selectGasPrice(id, type, true);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
    // test();
  };
  const transferTokenBoost = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      let isTransactionSuccessfull = false;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} isLast={true} />
              ),
            });
            props.compundTypeHandler("smartWallet")
            props.handleModalChange(false, '')
            closeToastById();
            props.refresh();
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
    // test();
  };
  const executeTransaction = (gasPrice, gasLimit, label, instanceMethod, options, id) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            console.log("Refreshing");
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            closeToastById();
            if (id === "migratev1tov2") {
              props.aaveTypeHandler("aave/v2")
            } else {
              props.refresh();
            }
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const makeExecuteData = async (instance, web3, selectedToken, methodId, type, borrowMode) => {
    let instanceExecute;
    let _data = "";
    let amount = "";
    let hexValue = "";
    Object.keys(instance.methods).map((key) => {
      if (key === "execute(address,bytes)") {
        instanceExecute = instance.methods[key]
      }
    })
    let lendingPoolAddress = "0x";
    // if (props.protocol === "aave/v2") {
    lendingPoolAddress = getContractAddressByName(props.protocol, "LendingPoolAddressesProvider", props.network);
    lendingPoolAddress = encodeParameter(web3, "address", lendingPoolAddress, "00")
    lendingPoolAddress = lendingPoolAddress.substr(2);
    // }
    if (type === "transferTokenBoost") {
      let decimal = getContractDecimalByName(props.protocol, selectedToken.supplytype);
      let value = truncateNumBeforeTransaction(selectedToken.value);
      let valueInWie = new BigNumber(value);
      valueInWie = valueInWie.multipliedBy(BigNumber(decimal));
      hexValue = toHex(valueInWie, { addPrefix: true });
      amount = web3.eth.abi.encodeParameter("uint256", hexValue);
      amount = amount.substr(2)
      let borrowAddress = await getContractUnderlyingAddressByName(props.protocol, selectedToken.borrowtype, props.network);
      borrowAddress = encodeParameter(web3, "address", borrowAddress, "");
      let supplyAddress = await getContractUnderlyingAddressByName(props.protocol, selectedToken.supplytype, props.network);
      supplyAddress = encodeParameter(web3, "address", supplyAddress, "");
      _data = `${methodId}${lendingPoolAddress}${borrowAddress}${supplyAddress}${amount}`;
    } else if (type === "migratev1tov2") {
      const web3 = new Web3(etheriumProvider);
      lendingPoolAddress = getContractAddressByName("aave/v2", "LendingPoolAddressesProvider", props.network);
      let aaveMigrationReceiver = getContractAddressByName(props.protocol, "AaveMigrationReceiver", props.network);
      let { supply, supplyCollateral, borrow, borrowAssetsType } = getSupplyBorrowUnderlyingAddress();
      _data = web3.eth.abi.encodeParameters(['address', 'address[]', 'bool[]', 'address[]', 'uint256[]', 'address'], [lendingPoolAddress, supply, supplyCollateral, borrow, borrowAssetsType, aaveMigrationReceiver]);
      _data = _data.replace("0x", methodId)
    } else {
      let eth_address = await getContractUnderlyingAddressByName(props.protocol, selectedToken.type, props.network);
      eth_address = encodeParameter(web3, "address", eth_address, "00");
      let decimal = getContractDecimalByName(props.protocol, selectedToken.type);
      let value = truncateNumBeforeTransaction(selectedToken.value);
      let valueInWie = new BigNumber(value);
      valueInWie = valueInWie.multipliedBy(BigNumber(decimal));
      hexValue = toHex(valueInWie, { addPrefix: true });
      amount = web3.eth.abi.encodeParameter("uint256", hexValue);
      amount = amount.substr(2)
      if (type === "supply" && props.protocol === "aave/v1") {
        _data = `${methodId}${eth_address.substr(2)}${amount}`;
      } else if (props.protocol === "aave/v2" && (type === "supply" || type === "withDraw")) {
        _data = `${methodId}${lendingPoolAddress}${eth_address.substr(2)}${amount}`;
      } else if (type === "borrow") {
        borrowMode = encodeParameter(web3, "uint256", borrowMode, "");
        if (props.protocol === "aave/v1") {
          _data = `${methodId}${eth_address.substr(2)}${amount}${borrowMode}`;
        } else if (props.protocol === "aave/v2") {
          _data = `${methodId}${lendingPoolAddress}${eth_address.substr(2)}${amount}${borrowMode}`;
        }
      } else if (type === "repay" && props.protocol === "aave/v2") {
        borrowMode = encodeParameter(web3, "uint256", borrowMode, "");
        _data = `${methodId}${lendingPoolAddress}${eth_address.substr(2)}${amount}${borrowMode}`;
      } else {
        const c_selectedTokenAddress = encodeParameter(
          web3, "address",
          selectedToken.contractAddress, "00"
        );
        const inMarket = web3.eth.abi.encodeParameter("bool", false);
        _data =
          methodId +
          eth_address.substr(2) +
          c_selectedTokenAddress.substr(2) +
          amount +
          inMarket.substr(2);
      }
    }

    console.log("Transaction Data", _data)
    return { _data, instanceExecute, hexValue }
  }
  const executeBoostAdvanceData = async (instance, web3, selectedToken, methodCode, firstToken, secondToken, borrowRate) => {
    let instanceExecute;
    Object.keys(instance.methods).map((key) => {
      if (key === "execute(address,bytes)") {
        instanceExecute = instance.methods[key]
      }
    })

    // let reserve = `${props.network}Reserve`
    let borrowReserveAddr = await getContractUnderlyingAddressByName(props.protocol, selectedToken[`${firstToken}type`], props.network);
    let supplyReserveAddr = await getContractUnderlyingAddressByName(props.protocol, selectedToken[`${secondToken}type`], props.network);
    const encodeBorrowAddress = encodeParameter(web3,
      "address",
      borrowReserveAddr
    );
    const encodeSupplyAddress = encodeParameter(web3,
      "address",
      supplyReserveAddr
    );
    let uniSwapAddress = getContractAddressByName(props.protocol, "UniswapWrapperV3", props.network);
    let decimal = getContractDecimalByName(props.protocol, selectedToken[`${firstToken}type`]);
    let value = truncateNumBeforeTransaction(selectedToken.value)
    const valueInWie = BigNumber(value).multipliedBy(BigNumber(decimal)).toString();
    let exchangeEncodeBorrowAddress = encodeBorrowAddress;
    if (selectedToken[`${firstToken}type`] === "ETH" && firstToken === "borrow") {
      let wEthAddress = getContractAddressByName(props.protocol, "WETH9", props.network);
      exchangeEncodeBorrowAddress = encodeParameter(web3,
        "address",
        wEthAddress
      );
    }
    /*UNISWAP EXCHANGE DATA CASES*/
    // let uniswapExchangeData = `0000000000000000000000000000000000000000000000000000000000000002${encodeSupplyAddress}${exchangeEncodeBorrowAddress}`;
    let uniswapExchangeData = await getUniswapExchangeData(selectedToken[`${firstToken}type`], selectedToken[`${secondToken}type`], encodeSupplyAddress, exchangeEncodeBorrowAddress, props.protocol, props.network, web3)
    let reserveAddresses = [
      borrowReserveAddr,
      supplyReserveAddr
    ]
    if (selectedToken[`${firstToken}type`] === "ETH" || selectedToken[`${secondToken}type`] === "ETH") {
      let wEthAddress = getContractAddressByName(props.protocol, "WETH9", props.network);
      if (selectedToken[`${firstToken}type`] === "ETH") {
        reserveAddresses[0] = wEthAddress;
      }
      if (selectedToken[`${secondToken}type`] === "ETH") {
        reserveAddresses[1] = wEthAddress;
      }
    }
    reserveAddresses = reserveAddresses.sort();
    let uniswapMinPrice = await getUniSwapMinPrice(valueInWie, reserveAddresses);
    uniswapMinPrice = encodeParameter(web3, "uint256", uniswapMinPrice);
    let _data = ""
    if (props.protocol === "aave/v1") {
      _data = `${methodCode}00000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000000${encodeBorrowAddress}${encodeSupplyAddress}${encodeParameter(web3, "uint256", valueInWie)}0000000000000000000000000000000000000000000000000000000000000000${encodeParameter(web3, "uint256", uniswapMinPrice)}00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000${encodeParameter(web3, 'address', uniSwapAddress)}0000000000000000000000000000000000000000000000000000000000000140000000000000000000000000000000000000000000000000000000000000022000000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000020${uniswapExchangeData}0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000c000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000`;
    } else if (props.protocol === "aave/v2") {
      let lendingPoolAddress = getContractAddressByName(props.protocol, "LendingPoolAddressesProvider", props.network);
      lendingPoolAddress = encodeParameter(web3,
        "address",
        lendingPoolAddress, ""
      );
      borrowRate = encodeParameter(web3,
        "uint256",
        borrowRate
      );
      let flAmount = "0000000000000000000000000000000000000000000000000000000000000000";
      // flAmount = encodeParameter(web3,
      //   "uint256",
      //   436843886280
      // );
      _data = `${methodCode}${lendingPoolAddress}00000000000000000000000000000000000000000000000000000000000000a0${borrowRate}0000000000000000000000000000000000000000000000000000000000000000${flAmount}${encodeBorrowAddress}${encodeSupplyAddress}${encodeParameter(web3, "uint256", valueInWie)}0000000000000000000000000000000000000000000000000000000000000000${uniswapMinPrice}00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000${encodeParameter(web3, 'address', uniSwapAddress)}0000000000000000000000000000000000000000000000000000000000000140000000000000000000000000000000000000000000000000000000000000022000000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000020${uniswapExchangeData}0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000c000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000`
      console.log(`Transaction Data ${selectedToken.value} ${selectedToken[`${firstToken}type`]} - ${selectedToken[`${secondToken}type`]}`, _data)
    }
    return { _data, instanceExecute }
  }
  const getUniswapExchangeData = async (firstTokenType, secondTokenType, encodeSupplyAddress, exchangeEncodeBorrowAddress, protocol, network, web3) => {
    let uniswapExchangeData = `000000000000000000000000000000000000000000000000000000000000000`;
    let pairs = []
    switch (true) {
      case ["REN", "TUSD"].indexOf(firstTokenType) !== -1 && ["TUSD", "LEND"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "DAI", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["SUSD"].indexOf(firstTokenType) !== -1 && secondTokenType === "USDC":
        pairs = [firstTokenType, "WETH9", "MKR", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["USDC"].indexOf(firstTokenType) !== -1 && ["USDT"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "COMP", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs);
        return uniswapExchangeData;

      case ["TUSD"].indexOf(firstTokenType) !== -1 && ["YFI", "MANA", "REN", "SNX", "ENJ"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "WETH9", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["TUSD"].indexOf(firstTokenType) !== -1 && ["BAT", "UNI", "LINK", "DAI", "MKR", "KNC", "ETH"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "USDC", "COMP", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["TUSD"].indexOf(firstTokenType) !== -1 && ["AAVE"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "USDC", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["TUSD", "USDT"].indexOf(firstTokenType) !== -1 && ["USDC"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "DAI", "MKR", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["TUSD"].indexOf(firstTokenType) !== -1 && ["ZRX"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "WETH9", "USDT", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["YFI"].indexOf(firstTokenType) !== -1 && ["TUSD"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "USDT", "WETH9", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case ["YFI"].indexOf(firstTokenType) !== -1 && ["BAT"].indexOf(secondTokenType) !== -1:
        pairs = [firstTokenType, "USDT", "USDC", secondTokenType];
        uniswapExchangeData += await getUnderlyingTokenData(protocol, network, web3, pairs)
        return uniswapExchangeData;

      case firstTokenType === "TUSD" && secondTokenType === "WBTC":
        uniswapExchangeData += "0";
        return uniswapExchangeData;

      default:
        uniswapExchangeData += `2${encodeSupplyAddress}${exchangeEncodeBorrowAddress}`
        return uniswapExchangeData;
    }
  }
  const getUnderlyingTokenData = async (protocol, network, web3, tokens) => {
    let arr = [];
    for (let index = 0; index < tokens.length; index++) {
      let name = tokens[index];
      if (name === "ETH") {
        name = "WETH9"
      }
      let addr = await getContractUnderlyingAddressByName(protocol, name, network);
      addr = encodeParameter(web3, "address", addr);
      arr.push(addr)
    }
    let addrStr = arr.join("");
    addrStr = `${tokens.length}${addrStr}`;
    addrStr = addrStr.replaceAll(/0x/ig, "")
    return addrStr;
  }
  const encodeParameter = (web3, type, value, replaceValue) => {
    if (replaceValue === undefined) {
      replaceValue = "";
    }
    let enodeValue = web3.eth.abi.encodeParameter(
      type,
      value
    );
    enodeValue = enodeValue.replace("0x", `${replaceValue}`)
    return enodeValue;
  }
  const getUniSwapMinPrice = async (valueInWie, cAddresses) => {
    try {
      const { instance } = readContractByName(props.protocol, "UniswapV2Router02", props.network);
      let amounts = await instance.methods.getAmountsOut(valueInWie, cAddresses).call();
      return amounts[1] ? amounts[1] : 0;
      // return "0000000000000000000000000000000000000000000000000000000000000000";
    } catch (error) {
      console.error(error)
      return 0;
    }

  }
  const closeToastById = () => {
    toast.dismiss("progressbar");
  };
  const selectGasPrice = async (id, type, progressBar, payload) => {
    try {
      if (!props.allowTransaction) {
        props.ApiRequestedAction({ apiCallFor: "connectWallet" })
      } else {
        let inNounce = state.debtAsset === "None";
        let contractResponse = await getContractResponse(props, id, type, inNounce, payload);
        let { instance, web3, decimal } = contractResponse;
        let USD = 0;
        let transactionStatus = "creating";
        let toastId = "";
        let gasLimit = {};
        let value = "";
        let tokenType = "";
        if (props[id] && props[id].value) {
          if (id === "advanceBoost") {
            tokenType = props[id]["supplytype"];
          } else if (id === "advanceRepay") {
            tokenType = props[id]["repaytype"];
          } else {
            tokenType = props[id].type;
          }
          let tokenIndex = props.cTokensList.findIndex(
            (x) => x.symbol === tokenType
          );
          if (tokenIndex !== -1) {
            let cToken = props.cTokensList[tokenIndex];
            USD = cToken.referenceItem.priceInUsd ? parseFloat(cToken.referenceItem.priceInUsd) : 0;
          }
          value = props[id].value;
          value = truncateNumBeforeTransaction(value);
          if (id === "supply" || id === "borrow" || id === "repay" || (id === "withDraw" && props.protocol === "aave/v2")) {
            decimal = getContractDecimalByName(props.protocol, tokenType);
          } else {
            if (decimal <= 18) {
              decimal = Number("1e" + decimal)
            }
          }
          if (decimal !== 0) {
            value = new BigNumber(value);
            value = value.multipliedBy(decimal)
            value = toHex(value, { addPrefix: true })
          }
        }
        let contractMethod;
        let contractMethodOptions = {};
        let submitMethod;
        let toastLabel = "";
        let totalSteps = 2;
        let closeToast = () => { };
        let isProgressBar = false;
        let isAllow = true;
        if (type === "approve" && (id === "supply" || id === "repay" || id === "advanceBoost" || (id === "withDraw" || props.protocol === "aave/v2"))) {
          value = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
          let contractAddress = "";
          toastLabel = `Approve Aave for ${tokenType}`;
          let walletAddress = "";
          if (compoundType === "smartWallet") {
            contractAddress = props.smartWalletAddress;
            walletAddress = props.smartWalletAddress;
          } else {
            if (id === "advanceBoost") {
              contractAddress = props.advanceBoost.supplyContractAddress;
            } else {
              contractAddress = props[id].contractAddress;
            }
            let walletContractName = "LendingPoolCore";
            if (props.protocol === "aave/v2") {
              if (id === "advanceBoost") {
                walletContractName = "";
                walletAddress = props.smartWalletAddress;
              } else if (id === "withDraw" || id === "borrow") {
                walletContractName = "WETHGateway"
              } else {
                walletContractName = "LendingPool"
              }
            }
            if (walletContractName !== "") {
              walletAddress = getContractAddressByName(props.protocol, walletContractName, props.network);
            }
          }
          let isApproved = false;
          if (tokenType === "ETH" && id !== "withDraw" && id !== "borrow") {
            isApproved = props.cTokenContracts[tokenType]["snapshot"]["usageAsCollateralEnabled"];
          } else {
            let allowance = await instance.methods.allowance(props.accountId, walletAddress).call();
            isApproved = allowance > props[id].value;
          }
          if (!isApproved) {
            contractMethod = instance.methods.approve(walletAddress, value);
            submitMethod = (gasPrice, gasLimit) => approveToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
            closeToast = closeToastById;
            isProgressBar = true;
          } else {
            isAllow = false;
            let type = "";
            if (id === "repay") {
              type = "repayBorrowToken";
            } else if (id === "advanceBoost") {
              if (compoundType === "smartWallet") {
                type = "boost";
              } else {
                type = "transferTokenBoost";
              }
            }
            selectGasPrice(id, type, progressBar, payload);
          }
        } else if (id === "withDraw") {
          toastLabel = `With Draw: ${props.withDraw.value} ${props.withDraw.type}`;
          if (compoundType === "smartWallet") {
            let methodId = "0x934785b7";
            if (props.protocol === "aave/v2") {
              methodId = "0xd9caed12"
            }
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodId, id)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            contractMethodOptions = {}
            if (props.protocol === "aave/v2") {
              if (props.withDraw.type === "ETH") {
                let lendingPoolAddress = getContractAddressByName(props.protocol, "LendingPool", props.network);
                contractMethod = instance.methods.withdrawETH(lendingPoolAddress, value, props.accountId)
              } else {
                let underLyingaddress = await getContractUnderlyingAddressByName(
                  props.protocol,
                  props.withDraw.type,
                  props.network
                );
                contractMethod = instance.methods
                  .withdraw(underLyingaddress, value, props.accountId);
              }
            } else {
              contractMethod = instance.methods.redeem(value);
            }
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          }
        } else if (id === "supply") {
          toastLabel = `Supply: ${props.supply.value} ${props.supply.type}`;
          if (compoundType === "smartWallet") {
            let methodId = "0x47e7ef24"
            if (props.protocol === "aave/v2") {
              methodId = "0x8340f549"
            }
            const executeData = await makeExecuteData(instance, web3, props[id], methodId, id);
            const executeAddress = state.smatyWalletSupplyAddress;
            contractMethodOptions = { value: executeData.hexValue }
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            let underLyingaddress = await getContractUnderlyingAddressByName(
              props.protocol,
              props.supply.type,
              props.network
            );
            if (props.protocol === "aave/v2") {
              if (props.supply.type === "ETH") {
                let lendingPoolAddress = getContractAddressByName(props.protocol, "LendingPool", props.network);
                contractMethod = instance.methods.depositETH(lendingPoolAddress, props.accountId, aaveReferralCode)
              } else {
                contractMethod = instance.methods
                  .deposit(underLyingaddress, value, props.accountId, aaveReferralCode);
              }
            } else {
              contractMethod = instance.methods
                .deposit(underLyingaddress, value, aaveReferralCode);
            }
            if (props.supply.type === "ETH") {
              contractMethodOptions = { value }
            } else {
              contractMethodOptions = {}
            }
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          }

        } else if (id === "borrow") {
          toastLabel = `Borrow: ${props[id].value} ${props[id].type}`;
          let borrowRate = 2;
          if (payload.tokenType === "Stable") {
            borrowRate = 1
          }
          if (compoundType === "smartWallet") {
            let methodId = "0xc1bce0b7";
            if (props.protocol === "aave/v2") {
              methodId = "0xfbf37739"
            }
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodId, id, borrowRate)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            let underLyingaddress = await getContractUnderlyingAddressByName(
              props.protocol,
              props[id].type,
              props.network
            );
            if (props.protocol === "aave/v2") {
              contractMethod = instance.methods
                .borrow(underLyingaddress, value, borrowRate, aaveReferralCode, props.accountId);
            } else {
              contractMethod = instance.methods
                .borrow(underLyingaddress, value, borrowRate, aaveReferralCode);
            }

            contractMethodOptions = {}
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);

            // contractMethod = instance.methods.borrow(value);
            // submitMethod = borrowToken;
          }
        } else if (
          id === "repay" &&
          (type === "repayBorrowToken" ||
            (type === "repayApprove" && props[id].type === "ETH"))
        ) {
          let borrowRate = 2;
          if (payload.tokenType === "Stable") {
            borrowRate = 1
          }
          toastLabel = `Repay Borrow : ${props.repay.value} ${props.repay.type}`;
          if (compoundType === "smartWallet") {
            let methodId = "0x8905d178";
            if (props.protocol === "aave/v2") {
              methodId = "0x50356ab7"
            }
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodId, id, borrowRate)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            let underLyingaddress = await getContractUnderlyingAddressByName(
              props.protocol,
              props.repay.type,
              props.network
            );
            if (props.protocol === "aave/v2") {
              contractMethod = instance.methods
                .repay(underLyingaddress, value, 1, props.accountId);
            } else {
              contractMethod = instance.methods
                .repay(underLyingaddress, value, props.accountId);
            }

            contractMethodOptions = {}
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);

            // let data = await getAllEstimationGasPrice(instance.methods
            //   .repayBorrow(value), {}, props.currentGasPrice, props.accountId);
            // if (!data.isError) {
            //   gasLimit = data.gasPrice;
            // }
            // contractMethod = instance.methods.repayBorrow(value);
            // submitMethod = repayBorrowToken;
          }
        } else if (id === "advanceBoost") {
          if (compoundType === "smartWallet" && type === "boost") {
            toastLabel = `Boost: ${props.advanceBoost.value} ${props.advanceBoost.borrowtype} - ${props.advanceBoost.supplytype}`;
            let methodCode = "0xb98b934d";
            let borrowRate = 2;
            if (payload && payload.tokenType === "Stable") {
              borrowRate = 1
            }
            contractMethodOptions = {}
            const executeData = await executeBoostAdvanceData(instance, web3, props[id], methodCode, "borrow", "supply", borrowRate)
            const executeAddress = getContractAddressByName(props.protocol, "AaveSaverProxy", props.network);
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "approve") {
            value = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
            let contractAddress = "";
            let methodType = "";
            if (compoundType === "smartWallet") {
              methodType = "boost";
              contractAddress = props.smartWalletAddress;
              toastLabel = `Approve DSProxy for ${props.advanceBoost.supplytype}`;
            } else {
              methodType = "transferTokenBoost";
              contractAddress = props.advanceBoost.supplyContractAddress;
              toastLabel = `Approve Aave for ${props.advanceBoost.supplytype}`;
            }
            let allowance = await instance.methods.allowance(props.accountId, contractAddress).call();
            if (allowance < props[id].value) {
              toastLabel = `Approve: ${props.advanceBoost.supplytype}`;
              contractMethod = instance.methods.approve(contractAddress, value);
              submitMethod = (gasPrice, gasLimit) => approveToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
            } else {
              isAllow = false;
              selectGasPrice(id, methodType);
            }
          } else if (type === "transferTokenBoost") {
            toastLabel = `Migrating to proxy`;
            if (!inNounce) {
              let methodCode = "0xb2b3f231"
              if (props.protocol === "aave/v2") {
                methodCode = "0xb2b3f231"
              }
              contractMethodOptions = {}
              const executeData = await makeExecuteData(instance, web3, props[id], methodCode, type)
              const executeAddress = getContractAddressByName(props.protocol, "AaveImportTaker", props.network);
              contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            } else {
              contractMethod = instance.methods.transfer(props.smartWalletAddress, value);
            }
            submitMethod = (gasPrice, gasLimit) => transferTokenBoost(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "createSmartWallet") {
            toastLabel = `Create DS Proxy`;
            contractMethod = instance.methods.build();
            submitMethod = (gasPrice, gasLimit) => createSmartWallet(gasPrice, gasLimit, id, toastLabel, instance.methods, contractMethodOptions);
            totalSteps = 3;
          }
          if (progressBar || type === "approve" || type === "createSmartWallet") {
            closeToast = closeToastById;
            isProgressBar = true;
          }
        } else if (id === "advanceRepay") {
          if (compoundType === "smartWallet" && type === "boost") {
            toastLabel = `Repay: ${props.advanceRepay.value} ${props.advanceRepay.withDrawtype} - ${props.advanceRepay.repaytype}`;
            let methodCode = "0x0f57eff4";
            let borrowRate = 2;
            if (payload && payload.tokenType === "Stable") {
              borrowRate = 1
            }
            contractMethodOptions = {}
            const executeData = await executeBoostAdvanceData(instance, web3, props[id], methodCode, "withDraw", "repay", borrowRate)
            const executeAddress = getContractAddressByName(props.protocol, "AaveSaverProxy", props.network);
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "approve") {
            toastLabel = `Approve: ${props.advanceRepay.repaytype}`;
            contractMethod = instance.methods.approve(props.smartWalletAddress, value);
            submitMethod = (gasPrice, gasLimit) => approveToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "transferTokenBoost") {
            toastLabel = `Migrating to proxy`;
            contractMethod = instance.methods.transfer(props.smartWalletAddress, value);
            submitMethod = (gasPrice, gasLimit) => transferTokenBoost(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "createSmartWallet") {
            toastLabel = `Create DS Proxy`;
            contractMethod = instance.methods.build();
            submitMethod = (gasPrice, gasLimit) => createSmartWallet(gasPrice, gasLimit, id, toastLabel, instance.methods, contractMethodOptions);
            totalSteps = 3;
          }
          if (progressBar || type === "approve" || type === "createSmartWallet") {
            closeToast = closeToastById;
            isProgressBar = true;
          }
        } else if (id === "claimComp") {
          toastLabel = `Claim Comp`;
          contractMethod = instance.methods.claimComp(props.accountId);
          submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
        } else if (id === "migratev1tov2") {
          toastLabel = `Migrate v1 to v2`;
          let methodId = "0x7e51714d"
          const executeData = await makeExecuteData(instance, web3, props[id], methodId, id);
          const executeAddress = getContractAddressByName(props.protocol, "AaveMigrationTaker", props.network);;
          contractMethodOptions = {}
          contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
          submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions, id);
        }
        if (isAllow) {
          let gasLimit = 55600;
          let data = {};
          if (id === "claimComp" || (id === "repay" &&
            type === "repayApprove" &&
            props[id].type !== "ETH")) {
            data = {
              isError: false,
              gasPrice: {
                safeLow: gasLimit,
                average: gasLimit,
                fastest: gasLimit,
              }
            }
          } else {
            data = await getAllEstimationGasPrice(contractMethod, contractMethodOptions, props.currentGasPrice, props.accountId);
          }
          let errorMessage = "";
          if (data.isError) {
            data = {
              isError: true,
              gasPrice: {
                safeLow: gasLimit,
                average: gasLimit,
                fastest: gasLimit,
              }
            }
            errorMessage = "An issue was detected that would cause this transaction to fail. Please contact us for assistance if the error persists"
          }
          gasLimit = data.gasPrice;
          if (isProgressBar) {
            toast(<TransactionToast status={"progressbar"} activeStep={1} totalSteps={totalSteps} />, {
              toastId: "progressbar",
              closeButton: false,
            });
          }
          toastId = toast(
            <TransactionToast
              status={transactionStatus}
              USD={USD}
              gasLimit={gasLimit}
              gasPrice={props.currentGasPrice}
              handleSubmit={submitMethod}
              closeToastById={closeToastById}
              label={toastLabel}
              hasError={data.isError} errorMessage={errorMessage}
            />
          );
          transactionDetail = {
            status: transactionStatus,
            toastId,
          };
          setState({
            ...state,
            contractResponse,
          });
        }
      }
    } catch (error) {
      toast(
        <TransactionToast
          label={`Error`}
          message={error.message}
          status={"error"}
        />
      );
      closeToastById();
    }
  };
  const getContractResponse = async (props, id, type, inNounce, payload) => {
    let contractResponse = null;
    if (id === "claimComp") {
      contractResponse = readContractByName(props.protocol, "Comptroller", state.network);
    } else if (compoundType === "smartWallet" || id === "migratev1tov2") {
      if (((id === "advanceRepay" || id === "advanceBoost") && type === "approve") || (id === "repay" && type === "approve" && props[id].type !== "ETH")) {
        let type = props[id].type;
        if (id === "advanceBoost") {
          type = props.advanceBoost.supplytype;
        } else if (id === "advanceRepay") {
          type = props.advanceRepay.repaytype;
        }
        contractResponse = await readUnderLyingContractByName(props.protocol,
          type,
          props.network
        );
      } else {
        contractResponse = readContractByName(props.protocol,
          "DSProxy",
          props.network,
          smartWalletAddress
        );
      }
    } else if (id === "repay" && (type === "repayBorrowToken" || type === "approve") && props[id].type === "ETH") {
      contractResponse = readContractByName(props.protocol,
        "cETHRepay",
        props.network
      );
    } else if (id === "advanceBoost" || id === "advanceRepay") {
      if (type === "createSmartWallet") {
        contractResponse = readContractByName(props.protocol,
          "ProxyRegistry",
          props.network
        );
      } else if (id === "advanceBoost") {
        if (type === "transferTokenBoost" && !inNounce) {
          contractResponse = readContractByName(props.protocol,
            "DSProxy",
            props.network,
            smartWalletAddress
          );
        } else if (type === "approve" && props.protocol === "aave/v1") {
          contractResponse = await readUnderLyingContractByName(props.protocol,
            props.advanceBoost.supplytype,
            props.network,
            props.advanceBoost.supplyContractAddress
          );
        } else {
          contractResponse = readContractByName(props.protocol,
            props.advanceBoost.supplytype,
            props.network,
            props.advanceBoost.supplyContractAddress
          );
        }
        if (type === "transferTokenBoost") {
          let decimal = getContractDecimalByName(props.protocol,
            props.advanceBoost.supplytype)
          // let decimal = await contractResponse.instance.methods.decimals().call();
          contractResponse.decimal = parseInt(decimal)
        }
      } else if (id === "advanceRepay") {
        contractResponse = readContractByName(props.protocol,
          props.advanceRepay.repaytype,
          props.network,
          props.advanceRepay.repayContractAddress
        );
      }
    } else if ((id === "supply" || id === "borrow" || id === "repay" || (id === "withDraw" && props.protocol === "aave/v2")) && type !== "approve") {
      let contractName = "LendingPool";
      if (props.protocol === "aave/v2" && (id === "supply" || id === "withDraw") && props[id].type === "ETH") {
        contractName = "WETHGateway"
      }
      contractResponse = readContractByName(props.protocol,
        contractName,
        props.network
      );
    } else if ((id === "supply" || id === "borrow" || id === "repay") && type === "approve") {
      let contractAddress = props[id].contractAddress;
      // if (props.protocol === "aave/v2" && id === "borrow" && props[id].type === "ETH") {
      //   if (payload.contractAddress) {
      //     contractAddress = payload.contractAddress
      //   }
      // }
      contractResponse = await readUnderLyingContractByName(props.protocol,
        props[id].type,
        props.network,
        contractAddress
      );
    } else {
      if (props.cTokenContracts[props[id].type]) {
        contractResponse = props.cTokenContracts[props[id].type].contract
      } else {
        contractResponse = readContractByName(props.protocol,
          props[id].type,
          props.network,
          props[id].contractAddress
        );
      }
    }
    return contractResponse;
  }
  const mergeArray = (x, y) => {
    let arr = []
    x.concat(y).forEach(item => {
      if (arr.findIndex(x => x.symbol === item.symbol) === -1) {
        arr.push(item)
      }
    });
    return arr;
  }
  const getSupplyBorrowUnderlyingAddress = () => {
    let supply = [];
    let supplyCollateral = [];
    let borrow = [];
    let borrowAssetsType = []
    props.supplyList.forEach(element => {
      supply.push(element.underlyingAsset);
      supplyCollateral.push(element.usageAsCollateralEnabled);
    });
    props.borrowList.forEach(element => {
      borrow.push(element.underlyingAsset);
      let borrowMode = 2;
      if (element.type === "Stable") {
        borrowMode = 1;
      }
      borrowAssetsType.push(borrowMode)
    });
    return { supply, supplyCollateral, borrow, borrowAssetsType }
  }
  let repayCtokenList = mergeArray(props.supplyList, props.borrowList);
  return (
    <div className="actions-section-wrapper ">
      <div className="tabs-wrapper">
        <Tabs
          defaultActiveKey={props.activeAccountTab}
          transition={false}
          id="account-tabs"
          onSelect={(e) => selectHandler(e)}
        >
          <Tab eventKey="advanced" title="Advanced">
            <AdvancedTab
              cTokensList={props.cTokensList}
              advanceRepay={props.advanceRepay}
              advanceBoost={props.advanceBoost}
              borrowList={props.borrowList}
              supplyList={props.supplyList}
              handleModalChange={props.handleModalChange}
              handleChange={props.handleChange}
              selectGasPrice={selectGasPrice}
              handleTypeChange={props.handleTypeChange}
              compoundType={compoundType}
              repayCtokenList={repayCtokenList}
              allowTransaction={props.allowTransaction}
            />
          </Tab>
          <Tab eventKey="collateral" title="Collateral">
            <CollateralTab
              protocol={props.protocol}
              network={props.network}
              cTokensList={props.cTokensList}
              supplyList={props.supplyList}
              withDraw={props.withDraw}
              supply={props.supply}
              compoundType={compoundType}
              currentGasPrice={props.currentGasPrice}
              handleChange={props.handleChange}
              handleTypeChange={props.handleTypeChange}
              selectGasPrice={selectGasPrice}
              allowTransaction={props.allowTransaction}
            />
          </Tab>
          <Tab eventKey="debt" title="Debt">
            {" "}
            <DebtTab
              borrowLimit={props.borrowLimit}
              repay={props.repay}
              borrowList={props.borrowList}
              currentGasPrice={props.currentGasPrice}
              network={props.network}
              cTokensList={props.cTokensList}
              borrow={props.borrow}
              handleTypeChange={props.handleTypeChange}
              handleChange={props.handleChange}
              selectGasPrice={selectGasPrice}
              allowTransaction={props.allowTransaction}
              handleModalChange={props.handleModalChange}
            />{" "}
          </Tab>
        </Tabs>
      </div>
      {props.compoundType === "smartWallet" ?
        <BoostRepaySmartWalletModal
          protocol={props.protocol}
          showModal={props.showModal && props.modalType === "boostrepaysmartwallet"}
          handleModalChange={props.handleModalChange}
          activeSection={props.isAdvance}
          advanceRepay={props.advanceRepay}
          advanceBoost={props.advanceBoost}
          handleSubmit={selectGasPrice}
          modalPayload={props.modalPayload}
          enableSupplyTokens={props.enableSupplyTokens}
        /> :
        <BoostRepayAccountModal
          showModal={props.showModal && props.modalType === "boostrepayaccount"}
          handleModalChange={props.handleModalChange}
          activeSection={props.isAdvance}
          advanceRepay={props.advanceRepay}
          advanceBoost={props.advanceBoost}
          handleSubmit={selectGasPrice}
          enableSupplyTokens={props.enableSupplyTokens}
          smartWalletAddress={smartWalletAddress}
          handleChange={handleChange}
          borrowList={props.borrowList}
          repayCtokenList={repayCtokenList}
          debtAsset={state.debtAsset}
          collateralAsset={state.collateralAsset}
          label="Aave Migration"
          compoundType={compoundType}
          protocol={props.protocol}
        />}
      <InterestRateModal showModal={props.showModal && props.modalType === "interestRate"}
        handleModalChange={props.handleModalChange}
        borrow={props.borrow}
        cTokensList={props.cTokensList}
        protocol={props.protocol}
        compoundType={compoundType}
        selectGasPrice={selectGasPrice} />
      <MigrateAaveV2Modal showModal={props.showModal && props.modalType === "migrateAavev2"}
        handleModalChange={props.handleModalChange} handleSubmit={selectGasPrice} />
    </div>
  );
}

function AdvancedTab(props) {
  const [activeRepayToken, updateRepayToken] = useState({});
  useEffect(() => {
    if (props.borrowList.length > 0) {
      let activeElem = props.borrowList[0];
      if (Object.size(activeRepayToken) === 0 || (activeElem.type !== activeRepayToken.type && activeElem.symbol !== activeRepayToken.symbol)) {
        updateRepayToken(activeElem);
      }
    }
  }, [props.borrowList]);
  const handleRepayTypeChange = (symbol, tokenAddress, item) => {
    updateRepayToken(item);
    props.handleTypeChange(symbol, tokenAddress, "advanceRepay", "repay")
  }
  const handleRepay = () => {
    let tokenType = "";
    let type = props.compoundType === "smartWallet" ? "boostrepaysmartwallet" : "boostrepayaccount";
    if (activeRepayToken.type) {
      tokenType = activeRepayToken.type
    }
    props.handleModalChange(true, type, "advanceRepay", { tokenType })
  }
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="action-desc">Borrow {props.advanceBoost.borrowtype} → Supply {props.advanceBoost.supplytype}</div>
            <div className="max-wrapper ">
              <div className="">
                <span>(max 0)</span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost    ">
              <div className="input-values">
                <label className="">Boost:</label>
                <input
                  placeholder="0"
                  className="form-item"
                  type="number"
                  id="advanceBoostValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "advanceBoost")}
                  value={props.advanceBoost.value}
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Borrow"
              value={props.advanceBoost.borrowtype}
              objKey="symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceBoost", "borrow")
              }
              isValidateBorrow={true}
            />
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              value={props.advanceBoost.supplytype}
              objKey="symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceBoost", "supply")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              onClick={() => props.handleModalChange(true, props.compoundType === "smartWallet" ? "boostrepaysmartwallet" : "boostrepayaccount", "advanceBoost")}
              // onClick={() => props.selectGasPrice("advanceBoost", "boost")}
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.advanceBoost.borrowtype === "" ||
                props.advanceBoost.supplytype === "" ||
                props.advanceBoost.value === "" ||
                props.cTokensList.length === 0
                || !props.allowTransaction
              }
            >
              <span>Boost</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="action-desc">Withdraw {props.advanceRepay.withDrawtype} → Payback {props.advanceRepay.repaytype}</div>
            <div className="max-wrapper ">
              <div className="">
                <span>(max 0)</span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay    ">
              <div className="input-values">
                <label className="">Repay:</label>
                <input
                  placeholder="0"
                  id="advanceRepayValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "advanceRepay")}
                  value={props.advanceRepay.value}
                  className="form-item"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.repayCtokenList}
              label="Withdraw"
              value={props.advanceRepay.withDrawtype}
              objKey="symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceRepay", "withDraw")
              }
            />
            <CurrencyDropdown
              data={props.borrowList}
              label="Payback"
              value={props.advanceRepay.repaytype}
              handleChange={(symbol, tokenAddress, item) =>
                handleRepayTypeChange(symbol, tokenAddress, item)
              }
              objKey="symbol"
              validateKey="borrow_balance_underlying"
              showBottomLable={true}
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.advanceRepay.repaytype === "" ||
                props.advanceRepay.withDrawtype === "" ||
                props.advanceRepay.value === "" ||
                parseFloat(props.advanceRepay.value) === 0 ||
                props.borrowList.length === 0 || props.repayCtokenList.length === 0
                || !props.allowTransaction
              }
              onClick={() => handleRepay()}
            >
              <span>Repay</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function CollateralTab(props) {
  const toFixedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") {
        balance = balance.toString()
      }
      let currency = balance.replace(/[^a-zA-Z ]/g, "");
      balance = parseFloat(balance).toFixedNoRounding(2) + currency;
    }
    return balance;
  };
  const balanceHandler = (balance, objName, fieldName) => {
    props.handleChange(
      {
        target: {
          name: fieldName,
          value: balance,
        },
      },
      objName
    );
  };
  const handleSupply = () => {
    let type = "";
    if (props.compoundType !== "smartWallet") {
      if (props.protocol === "aave/v2") {
        if (props.supply.type !== "ETH") {
          type = "approve";
        }
      } else if (props.protocol === "aave/v1") {
        type = "approve";
      }
    }
    props.selectGasPrice("supply", type)
  }
  const handleWithDraw = () => {
    let type = "";
    if (props.compoundType !== "smartWallet" && props.protocol === "aave/v2" && props.withDraw.type === "ETH") {
      type = "approve";
    }
    props.selectGasPrice("withDraw", type)
  }
  let balance = props.supply && props.supply.type ? props.supply.balance : 0;
  let withDrawBalance = props.withDraw && props.withDraw.type ? props.withDraw.balance : 0;
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() => balanceHandler(balance, "supply", "value")}
                >
                  (max{" "}
                  {props.supply && props.supply.type
                    ? toFixedBalance(balance)
                    : 0}
                  )
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost">
              <div className="input-values">
                <label className="">Supply:</label>
                <input
                  name="boostAmount"
                  placeholder="0"
                  id="supplyValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "supply")}
                  value={props.supply.value}
                  className="form-item"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              objKey="symbol"
              value={props.supply.type}
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "supply")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.supply.type === "" ||
                props.supply.value === "" ||
                parseFloat(props.supply.value) === 0 ||
                balance < props.supply.value || !props.allowTransaction
              }
              onClick={() => handleSupply()}
            >
              <span>Supply</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            {/* <div className="action-desc">Withdraw {withDrawTokenType} → Payback {withDrawTokenType}</div> */}
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(withDrawBalance, "withDraw", "value")
                  }
                >
                  (max{" "}
                  {props.withDraw && props.withDraw.type
                    ? toFixedBalance(withDrawBalance)
                    : 0}
                  )
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay">
              <div className="input-values">
                <label className="">Withdraw:</label>
                <input
                  value={props.withDraw.value}
                  className="form-item "
                  id="withDrawValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "withDraw")}
                  placeholder="0"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.supplyList}
              label="Supply"
              objKey="symbol"
              value={props.withDraw.type}
              validateKey="supply_balance_underlying"
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "withDraw")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              disabled={
                props.supplyList.length === 0 ||
                props.withDraw.type === "" ||
                props.withDraw.value === "" ||
                parseFloat(props.withDraw.value) === 0 ||
                withDrawBalance < props.withDraw.value || !props.allowTransaction
              }
              data-after-text="Migrate"
              onClick={() => handleWithDraw()}
            >
              <span>WithDraw</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function DebtTab(props) {
  const [activeRepayToken, updateRepayToken] = useState({});
  let currentBorrowBalance =
    props.borrow && props.borrow.balance ? parseFloat(props.borrow.balance) : 0;
  let currentRepayBalance =
    props.repay && props.repay.balance ? parseFloat(props.repay.balance) : 0;
  useEffect(() => {
    if (props.borrowList.length > 0) {
      let activeElem = props.borrowList[0];
      if (Object.size(activeRepayToken) === 0 || (activeElem.type !== activeRepayToken.type && activeElem.symbol !== activeRepayToken.symbol)) {
        updateRepayToken(activeElem);
      }
    }
  }, [props.borrowList])
  const balanceHandler = (balance, objName, fieldName) => {
    props.handleChange(
      {
        target: {
          name: fieldName,
          value: balance,
        },
      },
      objName
    );
  };
  const handleTypeChange = (symbol, tokenAddress, id, item) => {
    updateRepayToken(item);
    props.handleTypeChange(symbol, tokenAddress, id)
  }
  const handleRepay = () => {
    let tokenType = "";
    if (activeRepayToken.type) {
      tokenType = activeRepayToken.type
    }
    props.selectGasPrice("repay", "approve", true, { tokenType })
  }
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(currentBorrowBalance, "borrow", "value")
                  }
                >
                  (max {currentBorrowBalance.toFixedNoRounding(2)})
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost    ">
              <div className="input-values">
                <label className="">Borrow:</label>
                <input
                  placeholder="0"
                  className="form-item"
                  type="number"
                  value={props.borrow.value}
                  id="borrowValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "borrow")}
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              value={props.borrow.type}
              objKey="symbol"
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "borrow")
              }
              isValidateBorrow={true}
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.borrow.type === "" ||
                props.borrow.value === "" ||
                parseFloat(props.borrow.value) === 0 ||
                currentBorrowBalance < props.borrow.value
                || !props.allowTransaction
              }
              // onClick={() => props.selectGasPrice("borrow")}
              onClick={() => props.handleModalChange(true, "interestRate")}
            >
              <span>Borrow</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(currentRepayBalance, "repay", "value")
                  }
                >
                  (max {currentRepayBalance.toFixedNoRounding(2)})
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay    ">
              <div className="input-values">
                <label className="">Payback:</label>
                <input
                  value={props.repay.value}
                  id="repayValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "repay")}
                  className="form-item "
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.borrowList}
              label="Supply"
              objKey="symbol"
              validateKey="borrow_balance_underlying"
              value={props.repay.type}
              handleChange={(symbol, tokenAddress, item) =>
                handleTypeChange(symbol, tokenAddress, "repay", item)
              }
              showBottomLable={true}
            />
            {/* <CurrencyDropdown data={props.cTokensList} label="Supply" value={props.cTokensList.length > 0 ? props.cTokensList[0]['symbol'] : ''} handleChange={(symbol, tokenAddress) => { }} /> */}
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              disabled={
                props.repay.type === "" ||
                props.repay.value === "" ||
                parseFloat(props.repay.value) === 0 ||
                currentRepayBalance < props.repay.value
                || !props.allowTransaction
              }
              data-after-text="Migrate"
              onClick={() => handleRepay()}
            >
              <span>Payback</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function CurrencyDropdown(props) {
  let defualtValue =
    props.data.length > 0 ? props.data[0]["underlying_symbol"] : "";
  let value = props.value
    ? props.objKey === "symbol" && props.value[0] === "c"
      ? props.value.substring(1)
      : props.value
    : defualtValue;

  return (
    <div className="select box ">
      <div className="select__control ">
        <span className="select-label">{props.label}</span>
        <div className="select__value-container ">
          <Dropdown>
            <Dropdown.Toggle
              variant="Secondary"
              id="dropdown-basic"
              disabled={props.data.length === 0}
            >
              {value}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.data.map((item, index) => {
                let symbol =
                  props.objKey && item[props.objKey]
                    ? props.objKey === "symbol" && item[props.objKey][0] === "c"
                      ? item[props.objKey].substring(1)
                      : item[props.objKey]
                    : item.underlying_symbol;
                let isAllow = true;
                if (props.isValidateBorrow && item.referenceItem.totalBorrowsStable === "0" && item.referenceItem.totalBorrowsVariable === "0") {
                  isAllow = false
                }
                return ((props.validateKey === undefined) || (props.validateKey && item[props.validateKey] && Number(item[props.validateKey]["value"]) > 0)) && isAllow ? (
                  <Dropdown.Item
                    href="#"
                    key={index}
                    name="type"
                    onSelect={(e) =>
                      props.handleChange(
                        symbol,
                        item.aToken ? item.aToken.id : item.address,
                        item
                      )
                    }
                  >
                    {symbol}<br />
                    {props.showBottomLable ? <span className="bottom-label">{item.type}</span> : ""}

                  </Dropdown.Item>
                ) : "";
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
