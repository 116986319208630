import Dropdown from "react-bootstrap/Dropdown";

export default function Saver(props) {
    return <div className="width-container">
        <div>
            <div className="main-section-wrapper">
                <div className="section-header">
                    <div className="content-heading">Open position</div>
                    <div className="content-text">Open long or short position using the Compound protocol</div>
                </div>
                <form id="create-compound-form" className="form-wrapper" noValidate="">
                    <div className="Flex ">
                        <div className="create-form-side action-items-wrapper">
                            <div className="form-item-wrapper">
                                <div className="compound-create-two-selects">
                                    <div className="select box ">
                                        <div className="select__control ">
                                            <div className="select__value-container ">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic"><span className="icon-wrapper">
                                                    <img width="30" className="icon" src={require("../../../../assets/svgs/table/long.svg").default} alt="" />
                                                    </span>LOng</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">BTC</Dropdown.Item>
                                                        <Dropdown.Item href="#">ETH</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="select box ">
                                        <div className="select__control ">
                                            <div className="select__value-container ">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic"><span className="icon-wrapper">
                                                    <img width="30" className="icon" src={require("../../../../assets/table-icons/dai.png").default} alt="" />
                                                    </span>DAI</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">BTC</Dropdown.Item>
                                                        <Dropdown.Item href="#">ETH</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-item-wrapper">
                                <div className="compound-create-two-selects">
                                    <div className="select box">
                                        <div className="using-label">Using : </div>
                                    </div>
                                    <div className="select box ">
                                        <div className="select__control ">
                                            <div className="select__value-container ">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic"><span className="icon-wrapper"><img width="30" className="icon" src={require("../../../../assets/table-icons/dai.png").default} alt="" /></span> ETH</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">BTC</Dropdown.Item>
                                                        <Dropdown.Item href="#">ETH</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item  show  ">
                                <div className="select-input-max-wrapper">
                                    <div className="action-items-above-wrapper">
                                        <div className="max-wrapper loading">
                                            <div className="" ><span>(max 0 ETH)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-input-wrapper">
                                        <div className="form-item-wrapper  active   has-third-label">
                                            <div className="input-values">
                                                <label className="" htmlFor="create-compound-coll-amount-3073">Deposit:</label>
                                                <input name="collAmount" min="0" max="0" placeholder="0" id="create-compound-coll-amount-3073" className="form-item " type="number" disabled="" />
                                                <label className="second-label" htmlFor="create-compound-coll-amount-3073">ETH</label>
                                                <label className="third-label" htmlFor="create-compound-coll-amount-3073">~$0</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="item  show  ">
                                <div className="select-input-max-wrapper">
                                    <div className="action-items-above-wrapper">
                                        <div className="max-wrapper loading">
                                            <div className="" ><span>(max 0 DAI)</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-input-wrapper">
                                        <div className="form-item-wrapper  active   has-third-label">
                                            <div className="input-values">
                                                <label className="" htmlFor="create-compound-debt-amount-440">Borrow:</label>
                                                <input name="debtAmount" min="0" max="0" placeholder="0" id="create-compound-debt-amount-440" className="form-item " type="number" disabled="" />
                                                <label className="second-label" htmlFor="create-compound-debt-amount-440">DAI</label>
                                                <label className="third-label" htmlFor="create-compound-debt-amount-440">Converted to ~0 ETH</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="create-form-side">
                            <div className="new-stats-wrapper">
                                <div className="stat-wrapper larger">
                                    <div className="label">Supply:</div>
                                    <div className="value">
                                        <div className="after-value-changed-wrapper">
                                            <div className="with-after-value">
                                                <div className=""  ><span>0<span> ETH</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stat-wrapper larger">
                                    <div className="label">Borrow:</div>
                                    <div className="value">
                                        <div className="after-value-changed-wrapper">
                                            <div className="with-after-value">
                                                <div className="" ><span>0<span> DAI</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stat-wrapper larger">
                                    <div className="label">Exposure:</div>
                                    <div className="value">-</div>
                                </div>
                                <div className="stat-wrapper larger">
                                    <div className="label">Safety ratio:</div>
                                    <div className="value">
                                        <div className=""  >
                                            <span>
                                                <div className="after-value-changed-wrapper">
                                                    <div className="with-after-value">
                                                        <div className="current-position ratios">
                                                            <span>0%</span>
                                                           
                                                        </div>
                                                        <div className="value">0%</div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="stat-wrapper larger">
                                    <div className="label">Net APY:</div>
                                    <div className="value"></div>
                                </div>
                                <hr />
                                <div className="stat-wrapper">
                                    <div className="label">
                                        <div className=""  >
                                        </div>Compound ETH price:</div>
                                    <div className="value">~$1,930.35</div>
                                </div>
                                <div className="stat-wrapper">
                                    <div className="label">
                                        <div className=""  >
                                        </div>Buying ETH at:</div>-</div>
                                <div className="stat-wrapper">
                                    <div className="label">
                                        <div className="" >
                                        </div>Trade size price impact:</div>
                                    <div className="value">-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="fee-info">Includes a 0.25% service fee.</p>
                    <div className="action-button" >
                        <button type="submit" disabled="" className="button" form="create-compound-form">Create</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
}