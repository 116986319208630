import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { readContractByName } from "../../utils/contracts";
import { network } from "../../constants/constant";
import { useEffect, useState } from "react";
import { Fragment } from "react";

export function SmartWalletModal(props) {
    return (
        <Modal dialogClassName="custom-width" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                <span className="modal-header-icon mr-2 d-flex justify-content-center align-items-center">
                    <img src={require("./../../assets/svgs/wallet-01.svg").default} />
                </span>
                Smart Wallet
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper">
                    {props.smartWalletAddress !== "" ?
                        <Fragment>
                            <div className="sub-header">
                                <p className="disclaimer">Please do not send funds directly to this address.</p>
                                <p className="description">{props.smartWalletAddress}</p>
                            </div>
                            <br />
                        </Fragment> : ""}
                    <p>Using Compound via your Smart Wallet allows you to execute advanced operations, such as Boost, Repay and Flash Loan-based actions. Smart Wallet is a standard MakerDAO DSProxy wallet used for easier integration with lending protocols.</p>
                    <br />
                    <p>These funds will not be visible on the Compound dashboard.</p>
                    <br />
                    <a href="#" className="learn-more">Learn more about your Smart Wallet</a>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal >
    );
}

export function CompTokenModal(props) {
    return (
        <Modal dialogClassName="custom-width" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                <span className="modal-header-icon mr-2 d-flex justify-content-center align-items-center">
                    <img src={require("./../../assets/svgs/wallet-01.svg").default} />
                </span>
                COMP Tokens
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body className="comp-tokens-body">
                <div className="content-wrapper">
                    <p className="grey-colord">COMP tokens are automatically rewarded to all users of the Compound protocol.</p>
                    <hr />
                    <p>Current Balance:</p>
                    <div className="balanc-counter d-flex flex-column mb-3">
                        <div className="d-flex align-items-baseline">
                            <strong className="mr-1">{props.compReward && props.activeSection && props.compReward[props.activeSection] ? props.compReward[
                                props.activeSection].compBalance.toFixedWithRegex(6) : 0}</strong>
                            <span className="text-uppercase">COMP</span>
                        </div>
                        <span className="text-uppercase rate-ratio">$376.44/COMP</span>
                    </div>
                    <hr />
                    <p>COMP rewards estimate for {`${props.activeSection === "account" ? "account" : "smart wallet"}`}:</p>
                    <div className="d-flex justify-content-between reward-estimates mb-4">
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a week</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a month</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a year</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                    </div>
                    <div className="smart-wallet-position-block">
                        <h3 className="mt-0 mb-3 mb-md-5">Smart Wallet Position:</h3>
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="claim">
                            <Tab eventKey="claim" title="Claim">
                                <p>Claim your COMP tokens.</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="text-holder d-flex flex-column">
                                        <span>Claimable:</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="comp-counter">{props.compReward && props.compReward.smartWallet ? props.compReward.smartWallet.claimComp : 0}</span>
                                            <span className="text-uppercase">COMP</span>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-claim text-uppercase" onClick={() => props.claimComp("claimComp")}>Claim</button>
                                </div>
                            </Tab>
                            <Tab eventKey="supply" title="Supply">
                                <p>Claim and supply COMP to Compound in one transaction.</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="text-holder d-flex flex-column">
                                        <span>Claimable:</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="comp-counter">0</span>
                                            <span className="text-uppercase">COMP</span>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-claim text-uppercase">Supply</button>
                                </div>
                            </Tab>
                            <Tab eventKey="swap" title="Swap">
                                <p>Claim and swap COMP into a different asset in one transaction. Includes a 0.25% service fee.</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="text-holder d-flex flex-column">
                                        <span>Claimable:</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="comp-counter mr-1">0</span>
                                            <span className="text-uppercase">COMP</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex dropdown-block mb-4">
                                    <div className="w-50 dropdown-holder">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">Inch</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="w-50 text-uppercse d-flex justify-content-center align-items-center">
                                        ~ 0 Inch
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="swapSupply" title="Swap + Supply">
                                <p>Claim COMP, swap and supply the new asset to Compound in one transaction. Includes a 0.25% service fee.</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="text-holder d-flex flex-column">
                                        <span>Claimable:</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="comp-counter">0</span>
                                            <span className="text-uppercase">COMP</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex dropdown-block mb-4">
                                    <div className="w-50 dropdown-holder">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">Inch</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                                <Dropdown.Item>Inch</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="w-50 text-uppercse d-flex justify-content-center align-items-center">
                                        ~ 0 Inch
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                        <div className="smart-wallet-account-block">
                            <p className="sub-title">Account Position:</p>
                            <p>Claim your COMP tokens.</p>
                            <div className="d-flex justify-content-between mb-4">
                                <div className="text-holder d-flex flex-column">
                                    <span>Claimable:</span>
                                    <div className="d-flex align-items-baseline">
                                        <span className="comp-counter">{props.compReward && props.compReward.account ? props.compReward.account.claimComp : 0}</span>
                                        <span className="text-uppercase">COMP</span>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-claim text-uppercase" onClick={() => props.claimComp("claimComp")}>Claim</button>
                            </div>
                        </div>
                        <div className="text-right">
                            <Link className="link-learn-more" to="">Learn More...</Link>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export function CreateDSProxyModal(props) {
    const [state, setState] = useState({
        estimateGasPrice: 0,
        gasPriceInDollar: 0,
        transactionFee: 0,
        transactionFeeInDollar: 0,
    })
    useEffect(async () => {
        if (props.tokenPriceList.length > 0) {
            try {
                let index = props.tokenPriceList.findIndex(x => x.symbol === "ETH");
                if (index !== -1) {
                    let price = props.tokenPriceList[index].price;
                    const { instance, contractAddress, web3 } = readContractByName(props.protocol, "ProxyRegistry", network);
                    let buildMethod = instance.methods.build();
                    let gasLimit = await web3.eth.estimateGas({
                        from: props.accountId,
                        to: contractAddress,
                        data: buildMethod.encodeABI()
                    });
                    var avgGas = props.currentGasPrice && props.currentGasPrice.average ? props.currentGasPrice.average : 0;
                    let estimateGasPrice = web3.utils.fromWei(avgGas.toString(), 'gwei')
                    var transactionFee = estimateGasPrice * gasLimit;
                    let gasPriceInDollar = estimateGasPrice / price
                    let transactionFeeInDollar = transactionFee / price
                    setState({
                        ...state,
                        estimateGasPrice,
                        transactionFee,
                        transactionFeeInDollar,
                        gasPriceInDollar
                    })
                }

            } catch (error) {
                // Catch any errors for any of the above operations.
                console.error(error);
            }
        }
    }, [props.currentGasPrice])
    return (
        <Modal dialogClassName="custom-width" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header justify-content-center">Create DSProxy</Modal.Header>
            <Modal.Body className="ds-proxy-modal">
                <div className="content-wrapper">
                    <div>
                        <h3 className="mt-0 mb-3 mb-md-5">Smart Wallet Position:</h3>
                        <Tabs id="uncontrolled-tab-example" className="ds-proxy-modals" defaultActiveKey="cheap">
                            <Tab eventKey="cheap" title={<div>Cheap <small>&lt; 10 min.</small></div>}>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Estimated Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">{state.estimateGasPrice} ETH</strong>
                                        <span className="proxy-price">$ {state.gasPriceInDollar ? state.gasPriceInDollar : 0}</span>
                                    </div>
                                </div>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Max. Transaction Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">{state.transactionFee} ETH</strong>
                                        <span className="proxy-price">${state.transactionFeeInDollar}</span>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="regular" title={<div>Regular <small>&lt; 3 min.</small></div>}>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Estimated Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">0.05 ETH</strong>
                                        <span className="proxy-price">$92.88</span>
                                    </div>
                                </div>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Max. Transaction Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">0.06 ETH</strong>
                                        <span className="proxy-price">$102.53</span>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="fast" title={<div>Fast <small>&lt; 1 min.</small></div>}>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Estimated Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">0.05 ETH</strong>
                                        <span className="proxy-price">$92.88</span>
                                    </div>
                                </div>
                                <div className="proxy-list-item d-flex align-items-center">
                                    <p className="flex-fill mb-0">Max. Transaction Fee</p>
                                    <div className="d-flex flex-column align-items-end">
                                        <strong className="counter-value">0.06 ETH</strong>
                                        <span className="proxy-price">$102.53</span>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="ds-proxy-modal-footer d-flex justify-content-end">
                <button type="button" onClick={() => props.handleModalChange(false, '')} className="btn btn-primary">Cancel</button>
                <button type="button" onClick={() => props.createSmartWallet()} className="btn btn-secondary">Accept</button>
            </Modal.Footer>
        </Modal >
    );
}

export function BoostRepaySmartWalletModal(props) {
    const [state, setState] = useState({
        label: "",
        description: "",
        isSameType: true,
        firstType: "",
        secondType: "",
        value: "",
        description: "",
        showAdvance: false,
        transactionType: "",
        activeTab: "Variable"
    });
    useEffect(() => {
        let label = "";
        let description = "";
        let isSameType = true;
        let firstType = "";
        let secondType = "";
        let value = "";
        let transactionType = "";
        if (props.activeSection !== "" && props[props.activeSection]) {
            if (props.activeSection === "advanceBoost") {
                if (props[props.activeSection]["borrowtype"] !== props[props.activeSection]["supplytype"]) {
                    isSameType = false;
                }
                firstType = props[props.activeSection]["borrowtype"]
                secondType = props[props.activeSection]["supplytype"]
                label = "Boost";
                transactionType = "Leveraging";
                description = `Boost borrows more ${firstType} to obtain more ${secondType}. This increases your leverage at the cost of increasing your borrow power used.`
            } else if (props.activeSection === "advanceRepay") {
                if (props[props.activeSection]["withDrawtype"] !== props[props.activeSection]["repaytype"]) {
                    isSameType = false;
                }
                transactionType = "Repay";
                firstType = props[props.activeSection]["withDrawtype"]
                secondType = props[props.activeSection]["repaytype"]
                label = "Repay"
                description = `Repay uses supplied ${firstType} to pay back ${secondType} debt. This decreases your leverage, but also decreases your borrow power used and makes your position safer.`
            }
            value = props[props.activeSection]["value"];
            setState({
                ...state,
                label,
                description,
                isSameType,
                firstType,
                secondType,
                transactionType,
                value,
                description,
            })
        }
    }, [props])
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val
        })
    }
    const handleSubmit = () => {
        let type = "boost";//"approve";
        let tokenType = state.activeTab;
        if (props.protocol === "aave/v2" && props.activeSection === "advanceRepay" && props.modalPayload) {
            tokenType = props.modalPayload.tokenType;
        }
        // let contractAddress = props[props.activeSection][props.activeSection === "advanceRepay" ? "repayContractAddress" : "supplyContractAddress"];
        // let isApprove = props.enableSupplyTokens && props.enableSupplyTokens.findIndex(address => Number(address) === Number(contractAddress)) !== -1;
        // if (isApprove) {
        //     type = "boost";
        // }
        props.handleSubmit(props.activeSection, type, false, { tokenType })
    }
    return (
        <Modal dialogClassName=" action-modal-wrapper" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                {state.label}
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                {state.isSameType ?
                    <div className="content-wrapper">
                        <div className="Flex">
                            <div className="left-wrapper">
                                <p>{state.description}</p>
                                <p>{props.transactionType}</p>
                                <p>{state.value} {state.firstType}</p>
                            </div>

                        </div>
                    </div> :
                    <div className="new-modal-content-wrapper">
                        <p>{state.description}</p>
                        <div className="data-item">
                            <div>{props.activeSection === "advanceBoost" ? "Buying" : "Paying back"}</div>
                            <div className="amount">
                                <span>3,420.98 {state.secondType}</span>
                            </div>
                            <div>
                                <p>with {state.value} {state.firstType} at price 3,420.98 {state.secondType}/{state.firstType}</p>
                            </div>
                        </div>
                        <div className="tsi-warning">
                            <p> <span className="icon fa fa-info-circle"></span>Trade size price impact: 0.27%</p>
                        </div>
                        {props.protocol === "aave/v2" && props.activeSection === "advanceBoost" ?
                            <div className="Switch-tabs custom">
                                <Tabs id="uncontrolled-tab-example" defaultActiveKey={state.activeTab} onSelect={(e) => handleChange('activeTab', e)}>
                                    <Tab eventKey="Stable" title="Stable" active={"Stable" === state.activeTab}>

                                    </Tab>
                                    <Tab eventKey="Variable" title="Variable" active={"Variable" === state.activeTab}>

                                    </Tab>
                                </Tabs>
                            </div> : ""}
                        <div className="advanced-wrapper">
                            <div className="title-wrapper" onClick={() => handleChange('showAdvance', !state.showAdvance)}>
                                <div className="title-advanced">Advanced</div>
                                <i className={`fa fa-chevron-${state.showAdvance ? "down" : "up"}`}></i>
                            </div>
                            <div className={`rah-static rah-static--height-auto ${state.showAdvance ? "active" : ""}`} >
                                <div className="advanced-content">
                                    <div className="section-wrapper">
                                        <form className="slippage-limit-wrapper form-wrapper">
                                            <div className="data-wrapper">
                                                <div className="form-item-wrapper  has-third-label">
                                                    <div className="input-values">
                                                        <label className="title">Slippage tolerance:</label><input name="slippagePercent" min="0" placeholder="0" className="form-item" type="number" /><label className="second-label" >%</label>
                                                        <label className="third-label" >
                                                            <div className="" ><span>3,078.8893 {state.secondType}/{state.firstType}</span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="" >
                                            <span>
                                                <div className="info-box-wrapper"><i className="fa fa-info-circle"></i></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="bottom-item">Estimated best price sourced from 0x, Kyber, Uniswap and SushiSwap. Includes 0.25% service fee.</p>

                    </div>}
            </Modal.Body>
            <Modal.Footer className="ds-proxy-modal-footer d-flex justify-content-end">
                <button type="button" onClick={() => handleSubmit()} className="btn btn-secondary">{state.label}</button>
            </Modal.Footer>
        </Modal >
    );
}

export function BoostRepayAccountModal(props) {
    const handleSubmit = () => {
        let type = "approve";
        let contractAddress = props[props.activeSection][props.activeSection === "advanceRepay" ? "repayContractAddress" : "supplyContractAddress"];
        let isApprove = props.enableSupplyTokens && props.enableSupplyTokens.findIndex(address => Number(address) === Number(contractAddress)) !== -1;
        if (props.smartWalletAddress === "") {
            type = "createSmartWallet";
        } else if (isApprove) {
            type = "transferTokenBoost";
        }
        props.handleSubmit(props.activeSection, type)
    }
    return (
        <Modal dialogClassName="custom-width boost compound-proxy-migration-modal-wrapper" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                {/* <span className="modal-header-icon mr-2 d-flex justify-content-center align-items-center">
                    <img src={require("./../../assets/svgs/table/long.svg").default} />
                </span> */}
                {props.label ? props.label : "Compound Migration"}
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper">
                    <div className="Flex">
                        <div className="left-wrapper">
                            <p>Some of our advanced features include interactions with multiple smart contracts in one transaction, which is not possible when using a standard Ethereum account.</p>
                            <p>Smart Wallet is your personal smart contract proxy wallet that holds your Compound funds and makes these advanced features possible.</p>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://help.defisaver.com/smart-savings/what-is-the-user-wallet">Learn more about your Smart Wallet</a></p>
                            <p>You can migrate your supplied collateral, or migrate a collateral-debt pair to your Smart Wallet.</p>
                        </div>
                        <div className="separator"></div>
                        <div className="right-wrapper">
                            {props.compoundType === "account" && props.protocol === "aave/v1" ? <Fragment>
                                <div className="warning-box-wrapper">
                                    <img src={require("../../assets/svgs/warning.svg").default} alt="" /><span>Migration unavailable</span>
                                </div>
                                <p>No assets on your account are available for migration.
                                    Most often, this is because funds supplied on your Account Aave are borrowed on your Smart Wallet, or vice-versa.</p>
                            </Fragment> :
                                <Fragment> <div className="d-flex dropdown-block mb-4">
                                    <div className="w-70 text-uppercse d-flex justify-content-center align-items-center">Colletral assets</div>
                                    <div className="w-30 dropdown-holder">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="Secondary" id="collateral-dropdown">{props.collateralAsset}</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {props.repayCtokenList.map((item, index) => {
                                                    return <Dropdown.Item key={index} onClick={() => props.handleChange('collateralAsset', item.symbol)}>{item.symbol}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                    <div className="d-flex dropdown-block mb-4">
                                        <div className="w-70 text-uppercse d-flex justify-content-center align-items-center">Debt assets</div>
                                        <div className="w-30 dropdown-holder">
                                            {/* <div className="dropdown"><button aria-haspopup="true" aria-expanded="false" id="dropdown-basic" type="button" className="dropdown-toggle btn btn-Secondary">UNI</button></div> */}
                                            <Dropdown>
                                                <Dropdown.Toggle variant="Secondary" id="debt-dropdown">{props.debtAsset}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {props.borrowList.map((item, index) => {
                                                        return <Dropdown.Item key={index} onClick={() => props.handleChange('debtAsset', item.symbol)}>{item.symbol}</Dropdown.Item>
                                                    })}
                                                    <Dropdown.Item onClick={() => props.handleChange('debtAsset', 'None')}>None</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="bottom-text">
                                        <div className="item">
                                            <span className="left">Borrow power used(Account): </span>
                                            <span className="right value red">0%</span>
                                        </div>
                                        <div className="item">
                                            <span className="left">Borrow power used(Smart wallet):</span>
                                            <span className="right value green"> 57.6 %</span>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
                        <div className="blocker-content not-connected-wrapper">
                            <div className="address-monitor form-wrapper">
                                <div className=""><span><button type="submit" onClick={() => handleSubmit()} className="button btn-smart-wallet" form="get-address-for-monitoring" disabled={props.compoundType === "account" && props.protocol === "aave/v1"}>Migrate</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal >
    );
}

export function DisableTokenAlertModal(props) {
    const enablerHandler = () => {
        let { address, symbol, isEnable } = props.modalPayload;
        props.enablerHandler(address, symbol, isEnable);
        props.handleModalChange(false, '')
    }
    return (
        <Modal dialogClassName="custom-width" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                Collateral required
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper collateral-required">
                    <p>This asset is required to support your borrowed funds. Disabling this asset as collateral will push your position into liquidation. Please repay borrowed funds or supply a different asset as collateral before disabling this one.</p>
                    <br />
                    <div className="data-wrapper">
                        <div className="item-wrapper">
                            <div className="label">Borrow Limit:</div>
                            <div className="value-wrapper">
                                <div className="val1">$0.20</div>
                                <div className="val2">$0.20</div>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <div className="label">Borrow power used:</div>
                            <div className="value-wrapper">
                                <div className="val1">0.24%</div>
                                <div className="val2">Liquidation</div>
                            </div>
                        </div>
                    </div>
                    <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
                        <div className="blocker-content not-connected-wrapper">
                            <div className="address-monitor form-wrapper">
                                <div className=""><span><button type="submit" onClick={() => enablerHandler()} className="button btn-smart-wallet" form="get-address-for-monitoring" >Confirm</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal >
    );
}


export function InterestRateModal(props) {
    const [state, setState] = useState({
        tokenName: "",
        tokenType: "",
        isEnableStable: false,
        item: {}
    });
    useEffect(() => {
        if (state.tokenName !== props.borrow.type) {
            let tokenType = "";
            let isEnableStable = false;
            let elem = {};
            let index = props.cTokensList.findIndex(x => x.symbol === props.borrow.type);
            if (index !== -1) {
                elem = props.cTokensList[index];
                if (props.protocol === "aave/v1") {
                    if (elem.referenceItem.averageStableBorrowRate > 0) {
                        tokenType = "Stable";
                        isEnableStable = true;
                    } else {
                        tokenType = "Variable";
                    }
                } else if (props.protocol === "aave/v2") {
                    if (elem.stableBorrowRateEnabled) {
                        tokenType = "Stable";
                        isEnableStable = true;
                    }
                }
            }
            setState({
                tokenName: props.borrow.type,
                tokenType: tokenType,
                isEnableStable,
                item: elem
            })
        }
    }, [props.borrow.type])
    const handleSubmit = () => {
        let type = "";
        let contractAddress = "";
        if (props.compoundType !== "smartWallet" && props.borrow.type === "ETH" && props.protocol === "aave/v2") {
            type = "approve";
        }
        if (state.tokenType === "Stable") {
            contractAddress = state.item["stableDebtTokenAddress"]
        } else {
            contractAddress = state.item["variableDebtTokenAddress"]
        }
        props.selectGasPrice("borrow", type, true, { tokenType: state.tokenType, contractAddress });
        props.handleModalChange(false, '')
    }
    const handleChange = (tokenType) => {
        setState({
            ...state,
            tokenType
        })
    }
    return (
        <Modal dialogClassName="custom-width  borrow-modal-wrapper" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                Select your interest rate
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper interest-rate">
                    <div className="description"><div><span className="bold">Stable</span> would be a good choice if you need to plan around a non-volatile rate over a longer period of time.</div><br /><div><span className="bold">Variable</span> is based on the supply and demand for the selected asset on Aave and is re-calculated every second, meaning the rate will be lower when there is less demand for the asset.</div><br /><div>You can also switch between the two rates at any point in the future.</div></div>
                    <br />
                    {!state.isEnableStable ?
                        <div className="warning-box-wrapper">
                            <span>This asset is unsupported for stable rate borrow</span>
                        </div> : ""}
                    <div className="Switch-tabs">
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey={state.tokenType} onSelect={(e) => handleChange(e)}>
                            <Tab eventKey="Stable" title="Stable" disabled={!state.isEnableStable} active={state.tokenType === "Stable"}>
                                <p></p>
                            </Tab>
                            <Tab eventKey="Variable" title="Variable" active={state.tokenType === "Variable"}>
                                <p></p>
                            </Tab>

                        </Tabs>
                    </div>
                    <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
                        <div className="blocker-content not-connected-wrapper">
                            <div className="address-monitor form-wrapper">
                                <div className=""><span><button type="submit" onClick={() => handleSubmit()} className="button btn-smart-wallet" form="get-address-for-monitoring" >Confirm</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal >
    );
}

export function StackingRateModal(props) {
    return (
        <Modal dialogClassName="custom-width aave-stake-modal-wrapper" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                AAVE Staking
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body className="comp-tokens-body">
                <div className="content-wrapper">
                    <p className="grey-colord">Staking AAVE in the Safety Module helps to secure the protocol in exchange for protocol incentives.</p>
                    <hr />
                    <p>Currently staked:</p>
                    <div className="balanc-counter d-flex flex-column mb-3">
                        <div className="d-flex align-items-baseline">
                            <strong className="mr-1">{props.compReward && props.activeSection && props.compReward[props.activeSection] ? props.compReward[
                                props.activeSection].compBalance.toFixedWithRegex(6) : 0}</strong>
                            <span className="text-uppercase">AAVE</span>
                        </div>
                        <span className="text-uppercase rate-ratio">$376.44/COMP</span>
                    </div>
                    <hr />
                    <p>Reward estimates:</p>
                    <div className="d-flex justify-content-between reward-estimates mb-4">
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a week</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a month</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="estimate-text mb-3">In a year</span>
                            <strong className="estimate-value">0</strong>
                        </div>
                    </div>
                    <div className="smart-wallet-position-block">
                        <h3 className="mt-0 mb-3 mb-md-5">Token Actions</h3>
                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="Stake">
                            <Tab eventKey="Stake" title="Stake">
                                <p>Stake your AAVE to secure the Aave protocol and earn rewards.</p>
                                <div className="form-wrapper">
                                    <div className="lower-part">
                                        <div className="max-wrapper">
                                            <div className=""><span>(max 0)</span></div>
                                        </div>
                                        <div className="form-item-wrapper stake-input-section has-third-label">
                                            <div className="input-label">
                                                <svg width="30" height="30" viewBox="0 0 193 193" fill="none">
                                                    <circle cx="96.5" cy="96.5" r="96.5" fill="url(#aave_icon_59eeedbf-3b40-4a1d-8419-8bb090e76c41)"></circle>
                                                    <path d="M137.09 133.327L105.581 56.874C105.04 55.1899 103.985 53.7176 102.564 52.664C101.143 51.6104 99.4278 51.0285 97.659 51H94.8877C93.1242 51.0347 91.4155 51.6195 90.0005 52.6727C88.5856 53.7258 87.5349 55.1947 86.9954 56.874L73.2894 90.1903H62.8969C61.4026 90.206 59.9744 90.8087 58.9206 91.8682C57.8667 92.9277 57.2717 94.3591 57.2638 95.8534V95.9137C57.2717 97.408 57.8667 98.8394 58.9206 99.8989C59.9744 100.958 61.4026 101.561 62.8969 101.577H68.4697L55.3962 133.327C55.1472 134.023 55.0148 134.756 55.0046 135.496C54.9433 137.057 55.4946 138.582 56.5409 139.743C57.0821 140.297 57.7358 140.73 58.4581 141.01C59.1804 141.291 59.9544 141.413 60.728 141.37C61.8985 141.353 63.035 140.974 63.9813 140.285C64.9663 139.547 65.736 138.559 66.2104 137.423L80.6394 101.577H90.6102C92.1097 101.569 93.5456 100.97 94.6059 99.9094C95.6663 98.849 96.2655 97.4132 96.2734 95.9137V95.763C96.2655 94.2635 95.6663 92.8277 94.6059 91.7673C93.5456 90.707 92.1097 90.1078 90.6102 90.0999H85.2784L96.2734 62.6275L126.246 137.423C126.72 138.559 127.49 139.547 128.475 140.285C129.421 140.974 130.558 141.353 131.728 141.37C132.507 141.418 133.287 141.297 134.015 141.017C134.742 140.736 135.401 140.301 135.946 139.743C136.981 138.577 137.521 137.053 137.452 135.496C137.49 134.755 137.366 134.015 137.09 133.327Z" fill="white"></path>
                                                    <defs>
                                                        <linearGradient id="aave_icon_59eeedbf-3b40-4a1d-8419-8bb090e76c41" x1="96.5" y1="0" x2="96.5" y2="193" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#2E8997"></stop>
                                                            <stop offset="1" stop-color="#3B698E"></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>AAVE</div>
                                            <div>
                                                <input type="number" className="form-item" placeholder="0" />
                                                <div className="third-label">~$0</div>
                                            </div>
                                        </div>
                                        <div className="stake-button-section"><button type="button" className="btn btn-claim text-uppercase" disabled="">STAKE</button></div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="unstake" title="Unstake">
                                <p>Unlocking staked funds activates a 10-day cooldown after which you can withdraw your AAVE during a specific unstaked time frame.</p>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="text-holder d-flex flex-column">
                                        <span>Currently staked:</span>
                                        <div className="d-flex align-items-baseline">
                                            <span className="comp-counter">0</span>
                                            <span className="text-uppercase">AAVE</span>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-claim text-uppercase">UNLOCK</button>
                                </div>
                                <div className="form-wrapper">
                                    <div className="lower-part">
                                        <div className="max-wrapper">
                                            <div className=""><span>(max 0)</span></div>
                                        </div>
                                        <div className="form-item-wrapper stake-input-section has-third-label">
                                            <div className="input-label">
                                                <svg width="30" height="30" viewBox="0 0 193 193" fill="none" >
                                                    <circle cx="96.5" cy="96.5" r="96.5" fill="url(#aave_icon_59)"></circle>
                                                    <path d="M137.09 133.327L105.581 56.874C105.04 55.1899 103.985 53.7176 102.564 52.664C101.143 51.6104 99.4278 51.0285 97.659 51H94.8877C93.1242 51.0347 91.4155 51.6195 90.0005 52.6727C88.5856 53.7258 87.5349 55.1947 86.9954 56.874L73.2894 90.1903H62.8969C61.4026 90.206 59.9744 90.8087 58.9206 91.8682C57.8667 92.9277 57.2717 94.3591 57.2638 95.8534V95.9137C57.2717 97.408 57.8667 98.8394 58.9206 99.8989C59.9744 100.958 61.4026 101.561 62.8969 101.577H68.4697L55.3962 133.327C55.1472 134.023 55.0148 134.756 55.0046 135.496C54.9433 137.057 55.4946 138.582 56.5409 139.743C57.0821 140.297 57.7358 140.73 58.4581 141.01C59.1804 141.291 59.9544 141.413 60.728 141.37C61.8985 141.353 63.035 140.974 63.9813 140.285C64.9663 139.547 65.736 138.559 66.2104 137.423L80.6394 101.577H90.6102C92.1097 101.569 93.5456 100.97 94.6059 99.9094C95.6663 98.849 96.2655 97.4132 96.2734 95.9137V95.763C96.2655 94.2635 95.6663 92.8277 94.6059 91.7673C93.5456 90.707 92.1097 90.1078 90.6102 90.0999H85.2784L96.2734 62.6275L126.246 137.423C126.72 138.559 127.49 139.547 128.475 140.285C129.421 140.974 130.558 141.353 131.728 141.37C132.507 141.418 133.287 141.297 134.015 141.017C134.742 140.736 135.401 140.301 135.946 139.743C136.981 138.577 137.521 137.053 137.452 135.496C137.49 134.755 137.366 134.015 137.09 133.327Z" fill="white"></path>
                                                    <defs>
                                                        <linearGradient id="aave_icon_59" x1="96.5" y1="0" x2="96.5" y2="193" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#2E8997"></stop>
                                                            <stop offset="1" stop-color="#3B698E"></stop>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>AAVE</div>
                                            <div>
                                                <input type="number" className="form-item" placeholder="0" />
                                                <div className="third-label">~$0</div>
                                            </div>
                                        </div>
                                        <div className="stake-button-section"><button type="button" className="btn btn-claim text-uppercase" disabled="">Withdraw</button></div>
                                    </div>
                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            </Modal.Body>

        </Modal >
    );
}

export function MigrateAaveV2Modal(props) {
    const handleSubmit = () => {
        props.handleSubmit('migratev1tov2');
        props.handleModalChange(false, '');
    }
    return (
        <Modal dialogClassName="custom-width" show={props.showModal} onHide={() => props.handleModalChange(false, '')} centered>
            <Modal.Header className="smart-wallet-header">
                Migrate to AAVE V2
                <span className="close-icon" onClick={() => props.handleModalChange(false, '')}>
                    <img src={require("./../../assets/svgs/icon-close.svg").default} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper collateral-required">
                    <div className="description"><div>Migrating to Aave v2 will instantly move your whole portfolio (ie. all supplied and borrowed assets) from Aave v1 to Aave v2.</div>
                        <br />
                        <div>Note that the migration is only possible to your Smart Wallet on Aave v2 and there is no reverse migration back to v1 available.</div>
                        <br />
                        <div>If you have Automation enabled on v1, it will be enabled and configured to the same settings on v2.</div></div>


                    <div className="cdp-blocker-wrapper main-section-wrapper no-wallet">
                        <div className="blocker-content not-connected-wrapper">
                            <div className="address-monitor form-wrapper">
                                <div className=""><span><button type="submit" className="button btn-smart-wallet" form="get-address-for-monitoring" onClick={() => handleSubmit()}>Migrate</button></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal >
    );
}