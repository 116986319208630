import Loader from "../../../../components/Common/Components/loader";

export function TableWrapper(props) {
    return (
      <div className="styled-table-wrapper assets-table-wrapper no-after">
        <div className="above-section has-filter">
          <div className="title">{props.label}</div>
        </div>
        <div className="styled-table-wrapper">
          <table id={props.label}>
            <thead>
              <tr>
                {props.headers.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody className="table-body">
              {props.apiCallStatus.isStarted.indexOf(props.apiName) !== -1 ||
                props.apiCallStatus.isStarted.indexOf("getTokenPrices") !== -1 ? (
                <tr>
                  <td colSpan="12">
                    <Loader />
                  </td>
                </tr>
              ) : (props.data && props.data.length > 0) || props.isAfter ? (
                props.children
              ) : (
                <tr>
                  <td colSpan="12">
                    <div className="text-center">No Data Found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }