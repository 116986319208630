import { TableWrapper } from './tableWrapper';
import CoinIcon from "../../../../components/Common/Components/coinIcons";
import { millifyNumber } from '../../../../utils/common';

export function MarketInfoListing(props) {
    let data = props.marketInfoList;
    return (
        <TableWrapper
            apiCallStatus={props.apiCallStatus}
            label="Market info"
            headers={[
                "Asset",
                "Price",
                "Sup. APY",
                "Coll. fac.",
                "Variable B. APY",
                "Stable B. APY",
                "Mar. size",
                "Util.",
            ]}
            data={data}
            apiName="getCompoundMarketPrice"
        >
            {data.map((item, index) => {
                return (
                    <tr className="first-level" key={index}>
                        <td>
                            <CoinIcon name={item.symbol} />
                            {item.symbol}
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.referenceItem && item.referenceItem.priceInUsd ? item.referenceItem.priceInUsd : 0
                                    }
                                >
                                    $
                                    {item.referenceItem && item.referenceItem.priceInUsd
                                        ? item.referenceItem.priceInUsd.toFixedNoRounding(2)
                                        : 0}
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.liquidityRate * 100}>
                                    {(item.liquidityRate * 100).toFixedNoRounding(2)}%
                                </span>
                            </div>
                        </td>
                        <td className="">
                            {(item.baseLTVasCollateral * 100).toFixedNoRounding(2)}%
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.variableBorrowRate * 100}>
                                    {(item.variableBorrowRate * 100).toFixedNoRounding(2)}%
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.stableBorrowRate ? item.stableBorrowRate * 100 : 0
                                    }
                                >
                                    {item.stableBorrowRate
                                        ? (item.stableBorrowRate * 100).toFixedNoRounding(2)
                                        : 0}
                                    %
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.marketSize}>
                                    $
                                    {item.marketSize ? item.marketSize > Number.MAX_SAFE_INTEGER ? item.marketSize : millifyNumber(item.marketSize, {
                                        precision: 1,
                                        lowercase: false,
                                    }) : 0}
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.referenceItem && item.referenceItem.utilizationRate
                                            ? Number(item.referenceItem.utilizationRate * 100)
                                            : 0
                                    }
                                >
                                    {item.referenceItem && item.referenceItem.utilizationRate
                                        ? Number(item.referenceItem.utilizationRate * 100).toFixedNoRounding(2)
                                        : 0}
                                    %
                                </span>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </TableWrapper>
    );
}