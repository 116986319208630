import { Fragment, useRef, useEffect } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { format } from "date-fns";
import Loader from '../Common/Components/loader';

export function RightMenu(props) {
    return <div className="notification-dropdowns-wrapper">

        <DropDownItemWrapper linkName={props.linkName} containerClass="taller" innerHeading="Recent transactions" name="recentTransactions" toggleDropdown={props.toggleDropdown} label="TX">
            <div className="empty-wrapper">
                <div className="title">No recent transactions</div>
                <img src={require("../../assets/empty-inbox.svg").default} alt="No recent transactions" />
            </div>
        </DropDownItemWrapper>
        <DropDownItemWrapper linkName={props.linkName} containerClass="smaller" innerHeading="Recommended gas prices" iconClass="high" name="recommendedGasPrices" toggleDropdown={props.toggleDropdown} label={
            < Fragment>< span className="smaller">{props.currentGasPrice && props.currentGasPrice.average ? props.currentGasPrice.average : 0}</span>
                <img src={require("../../assets/svgs/gas.svg").default} alt="" /></Fragment>}>
            {props.apiCallStatus.isStarted.indexOf("getCurrentGasPrice") !== -1 || props.apiCallStatus.isStarted.indexOf("getGasPriceHistory") !== -1 ?
                <Loader /> : ""}
            <div className="gas-price-status-widget-container">
                {Object.size(props.currentGasPrice) > 0 ?
                    <ul className="gas-price-options">
                        <li>
                            <p><strong>{props.currentGasPrice && props.currentGasPrice.safeLow ? props.currentGasPrice.safeLow : 0}</strong><span>Gwei</span>
                            </p>
                            <p><span>&lt;10min</span>
                            </p>
                        </li>
                        <li>
                            <p><strong>{props.currentGasPrice && props.currentGasPrice.average ? props.currentGasPrice.average : 0}</strong><span>Gwei</span>
                            </p>
                            <p><span>&lt;3min</span>
                            </p>
                        </li>
                        <li>
                            <p><strong>{props.currentGasPrice && props.currentGasPrice.fastest ? props.currentGasPrice.fastest : 0}</strong><span>Gwei</span>
                            </p>
                            <p><span>&lt;1min</span>
                            </p>
                        </li>
                    </ul>
                    : ""}
                <div className="gas-price-history">
                    <h3>Gas price history <span>{`(${props.days} days)`}</span></h3>
                    <div className="gas-price-history-chart">
                        <div data-highcharts-chart="0" style={{ 'overflow': 'hidden' }}>
                            <ResponsiveContainer width="100%" height={200}>
                                <AreaChart
                                    width={500}
                                    height={250}
                                    data={props.gasPriceHistory.history ? props.gasPriceHistory.history : []}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <XAxis
                                        dataKey="date"
                                        tick={false}
                                    />
                                    <YAxis />
                                    <Tooltip content={<ChartCustomTooltip />} />
                                    <Area type="monotone" dataKey="average" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    {props.gasPriceHistory && Object.size(props.gasPriceHistory.min) > 0 && Object.size(props.gasPriceHistory.max) > 0 ?
                        <p className="gas-price-chart-info"><span>Lowest: {props.gasPriceHistory.min.average} ({format(new Date(props.gasPriceHistory.min.date), "MMM-dd-YYY HH:mm")})</span><span>Highest: {props.gasPriceHistory.max.average} ({format(new Date(props.gasPriceHistory.max.date), "MMM-dd-YYY HH:mm")})</span>
                        </p>
                        : ""}
                </div>
            </div>
        </DropDownItemWrapper>
    </div >
}

function DropDownItemWrapper(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.toggleDropdown);
    return <div className="dropdown-list-panel-wrapper"  >
        <div className={`icon-wrapper ${props.iconClass ? props.iconClass : ""}`} onClick={() => props.toggleDropdown(props.name)}>{props.label}</div>
        {props.linkName === props.name ?
            <div className={`dropdown-wrapper opened`} ref={wrapperRef}>
                <h3>{props.innerHeading}</h3>
                <div className={`dropdown-container ${props.containerClass ? props.containerClass : ""}`}>
                    {props.children}
                </div>
            </div> : ""}
    </div>
}
function useOutsideAlerter(ref, toggleDropdown) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains('smaller') && !event.target.classList.contains('icon-wrapper')) {
                event.preventDefault();
                toggleDropdown('')
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


const style = {
    padding: 6,
    backgroundColor: "#fff",
    border: "1px solid #ccc"
};

const ChartCustomTooltip = props => {
    const { active, payload } = props;
    if (active) {
        const currData = payload && payload.length ? payload[0].payload : null;
        return (
            <div className="area-chart-tooltip" style={style}>
                <p>
                    {"Date : "}
                    {currData ? format(new Date(currData.date), "MMM-dd-YYY HH:mm") : " -- "}
                </p>
                <p>
                    {"Average Gas Price : "}
                    <em>{currData ? currData.average : " -- "}</em>
                </p>
            </div>
        );
    }

    return null;
};