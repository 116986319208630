import {
  getEthPrice,
  getCurrentGasPrice,
  getGasPriceHistory,
  updateAccountDetail,
} from "./../Actions/action";
import InitialState from "../../../constants/initialReducersState";

export default function CommonReducer(state = InitialState.common, action) {
  switch (action.type) {
    case getEthPrice:
      let s = Object.assign(state);
      s.ethPrice = action.payload;
      return s;

    case getCurrentGasPrice:
      let s1 = Object.assign(state);
      s1.currentGasPrice = action.payload;
      return s1;

    case getGasPriceHistory:
      let s2 = Object.assign(state);
      s2.gasPriceHistory = action.payload;
      return s2;

    case updateAccountDetail:
      let s3 = { ...state };
      s3.accountDetail = action.payload;
      return s3;

    default:
      return state;
  }
}
