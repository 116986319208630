import { useState, useEffect } from "react";
import { aaveSmartWalletAddress } from "../../../../constants/constant";
import { readContractByName, getContractAddressByName, getContractDecimalByName, getAllEstimationGasPrice, readUnderLyingContractByName, getEncodedContractAddressByName, getContractUnderlyingAddressByName } from "../../../../utils/contracts";
import {
  BoostRepayAccountModal,
  BoostRepaySmartWalletModal,
  CompTokenModal
} from "../../../../components/Models/models";
import Dropdown from "react-bootstrap/Dropdown";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import TransactionToast from "../../../../components/Common/Components/toastAlertMsg";
import ReactTooltip from "react-tooltip";
import { millifyNumber, truncateNumBeforeTransaction } from '../../../../utils/common'
import { BigNumber } from "bignumber.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const toHex = require('to-hex')

export function Account(props) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props]);
  const getAfterSupplyBalance = () => {
    let result = 0;
    let balance = 0;
    let percentageDetail = {};
    let keys = [];
    let data = props.supplyList;
    if (props.isShowAfter) {
      let amount = 0;
      let type = "";
      if (
        (props.afterType === "supply" || props.afterType === "advanceBoost") &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.afterType === "advanceBoost" ? props.advanceBoost.supplytype : props.selectedToken.type;
        amount = props.activeMarketInfoToken.pricing.price * props.selectedToken.value;
        result = props.supplyBalance + amount;
      } else if (
        (props.afterType === "withDraw" || props.afterType === "advanceRepay") &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.afterType === "advanceRepay" ? props.advanceRepay.repaytype : props.selectedToken.type;
        amount =
          props.activeMarketInfoToken.pricing.price * props.selectedToken.value;
        result = props.supplyBalance - amount;
      } else {
        result = props.supplyBalance;
      }
      if (data.length > 0 && result > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let symbol =
            element.symbol[0] === "c"
              ? element.symbol.substring(1)
              : element.symbol;
          let supply_balance_underlying =
            element.supply_balance_underlying &&
              element.supply_balance_underlying.value
              ? parseFloat(element.supply_balance_underlying.value)
              : 0;
          let suppliedBalanceInDollar =
            supply_balance_underlying * element.tokenPrice;
          if (symbol === type) {
            if (props.afterType === "supply" || props.afterType === "advanceBoost") {
              suppliedBalanceInDollar += amount;
            } else if (props.afterType === "withDraw" || props.afterType === "advanceRepay") {
              suppliedBalanceInDollar -= amount;
            }
          }
          let supplyBalance = result;
          let percentage = (suppliedBalanceInDollar / supplyBalance) * 100;
          if (percentage !== 0) {
            percentageDetail[symbol] = {
              name: symbol,
              percentage: percentage,
            };
          }
        }
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (amount / result) * 100,
          };
        }
      } else if (result > 0) {
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (result / result) * 100,
          };
        }
      }
      keys = Object.keys(percentageDetail);
      if (Number.MAX_SAFE_INTEGER > Number(result)) {
        balance = millifyNumber(result, {
          precision: 2,
          lowercase: false,
        });
      } else {
        balance = result.toFixedNoRounding(2)
      }
    }

    return { balance: balance, result: result, percentageDetail, keys: keys };
  };
  const getAfterBorrowBalance = (resultType) => {
    let result = 0;
    if (
      props.afterType === "borrow" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.borrowBalance +
        (props.activeMarketInfoToken.pricing.price * props.selectedToken.value);
    } else if (
      props.afterType === "repay" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.borrowBalance -
        (props.activeMarketInfoToken.pricing.price * props.selectedToken.value);
    } else if (
      props.afterType === "advanceBoost"
    ) {
      let type = props.selectedToken.borrowtype;
      let value = props.selectedToken.value;
      let tokenPrice = getTokenPrice(type);
      result =
        props.borrowBalance +
        (tokenPrice * value);
    } else {
      result = props.borrowBalance;
    }
    if (resultType) {
      if (Number.MAX_SAFE_INTEGER > Number(result)) {
        result = millifyNumber(result, {
          precision: 2,
          lowercase: false,
        });
      } else {
        result = result.toFixedNoRounding(2)
      }
    }
    return result;
  };
  const getAfterBorrowLimit = (type) => {
    let result = 0;
    if (type) {
      result = props.borrowLimit.toFixedNoRounding(2);
    } else {
      result = props.borrowLimit;
    }
    return result;
  };
  const getAfterLeftToBorrow = (type) => {
    let result = 0;
    if (
      props.afterType === "borrow" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.leftToBorrow -
        props.activeMarketInfoToken.pricing.price * props.borrow.value;
    } else if (
      props.afterType === "repay" &&
      props.activeMarketInfoToken &&
      props.activeMarketInfoToken.pricing
    ) {
      result =
        props.leftToBorrow +
        props.activeMarketInfoToken.pricing.price * props.repay.value;
    } else if (
      props.afterType === "advanceBoost"
    ) {
      let type = props.selectedToken.borrowtype;
      let value = props.advanceBoost.value;
      let tokenPrice = getTokenPrice(type);
      result =
        props.leftToBorrow -
        tokenPrice * value;
    } else {
      result = props.leftToBorrow;
    }
    if (type) {
      result = result.toFixedNoRounding(2);
    }

    return result;
  };
  const getBorrowPowerUsedPercentage = () => {
    let percentage = 0;
    let percentageDetail = {};
    let keys = [];
    let data = props.borrowList;
    if (props.borrowBalance !== 0 && props.borrowLimit !== 0 && data.length > 0) {
      // let value = props.borrowLimit - props.leftToBorrow;
      let value = props.borrowBalance;
      percentage = (value / props.borrowLimit) * 100;
      if (percentage > 100) {
        percentage = 100;
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let symbol =
          element.symbol[0] === "c"
            ? element.symbol.substring(1)
            : element.symbol;
        let borrow_balance_underlying =
          element.borrow_balance_underlying &&
            element.borrow_balance_underlying.value
            ? (element.borrow_balance_underlying.value)
            : 0;
        let lifetime_borrow_interest_accrued =
          element.lifetime_borrow_interest_accrued &&
            element.lifetime_borrow_interest_accrued.value
            ? (element.lifetime_borrow_interest_accrued.value)
            : 0;
        let borrowBalanceInDollar =
          (borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice;
        let tokenPercentage = (borrowBalanceInDollar / props.borrowLimit) * 100;
        if (tokenPercentage !== 0) {
          percentageDetail[symbol] = {
            name: symbol,
            percentage: tokenPercentage,
          };
        }
      }
      keys = Object.keys(percentageDetail)
    }

    return { percentage: isNaN(percentage) ? 0 : Number(percentage.toFixedNoRounding(2)), percentageDetail, keys };
  };
  const getAfterBorrowPowerUsedPercentage = () => {
    let percentage = 0;

    let percentageDetail = {};
    let keys = [];
    let data = props.borrowList;
    if (props.isShowAfter) {
      let borrowLimit = getAfterBorrowLimit("toFix");
      let borrowBalance = getAfterBorrowBalance();
      percentage = (borrowBalance / borrowLimit) * 100;
      if (percentage > 100) {
        percentage = 100;
      }
      let amount = 0;
      let type = "";
      if (
        props.afterType === "borrow" &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.selectedToken.type;
        amount = props.activeMarketInfoToken.pricing.price * props.borrow.value;
      } else if (
        props.afterType === "repay" &&
        props.activeMarketInfoToken &&
        props.activeMarketInfoToken.pricing
      ) {
        type = props.selectedToken.type;
        amount =
          props.activeMarketInfoToken.pricing.price * props.repay.value;
      } else if (
        props.afterType === "advanceBoost"
      ) {
        type = props.selectedToken.borrowtype;
        let tokenPrice = getTokenPrice(type);
        amount = tokenPrice * props.advanceBoost.value;
      }
      if (data.length > 0) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let symbol =
            element.symbol[0] === "c"
              ? element.symbol.substring(1)
              : element.symbol;
          let borrow_balance_underlying =
            element.borrow_balance_underlying &&
              element.borrow_balance_underlying.value
              ? (element.borrow_balance_underlying.value)
              : 0;
          let lifetime_borrow_interest_accrued =
            element.lifetime_borrow_interest_accrued &&
              element.lifetime_borrow_interest_accrued.value
              ? (element.lifetime_borrow_interest_accrued.value)
              : 0;
          let borrowBalanceInDollar = 0;
          if (type === symbol) {
            if (props.afterType === "borrow" || props.afterType === "advanceBoost") {
              borrowBalanceInDollar =
                ((borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice) + amount;
            } else if (props.afterType === "repay") {
              borrowBalanceInDollar =
                ((borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice) - amount;
            }
          } else {
            borrowBalanceInDollar =
              (borrow_balance_underlying - lifetime_borrow_interest_accrued) * element.tokenPrice;
          }
          let tokenPercentage = (borrowBalanceInDollar / borrowLimit) * 100;
          if (tokenPercentage !== 0) {
            percentageDetail[symbol] = {
              name: symbol,
              percentage: tokenPercentage,
            };
          }
        }
        if (percentageDetail[type] === undefined) {
          percentageDetail[type] = {
            name: type,
            percentage: (amount / borrowLimit) * 100,
          };
        }
        keys = Object.keys(percentageDetail);
      }
    }
    return { percentage: isNaN(percentage) ? 0 : Number(percentage.toFixedNoRounding(2)), percentageDetail, keys };
  };
  const getSupplyComposition = () => {
    let percentage = 0;
    let percentageDetail = {};
    let data = props.supplyList;
    if (data.length > 0 && props.supplyBalance > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let symbol =
          element.symbol[0] === "c"
            ? element.symbol.substring(1)
            : element.symbol;
        let supply_balance_underlying =
          element.supply_balance_underlying &&
            element.supply_balance_underlying.value
            ? (element.supply_balance_underlying.value)
            : 0;
        let suppliedBalanceInDollar =
          supply_balance_underlying * element.tokenPrice;
        let supplyBalance = props.supplyBalance;
        percentage = (suppliedBalanceInDollar / supplyBalance) * 100;
        if (percentage !== 0) {
          percentageDetail[symbol] = {
            name: symbol,
            percentage: percentage,
          };
        }
      }
    }
    return percentageDetail;
  };
  const getTokenPrice = (type) => {
    let cTokenIndex = props.marketInfoList.findIndex(
      (x) => (x.symbol[0] === "c" ? x.symbol.substring(1) : x.symbol) === type
    );
    let tokenPrice = 0;
    if (cTokenIndex !== -1) {
      tokenPrice = props.marketInfoList[cTokenIndex].pricing.price;
    }
    return tokenPrice;
  }
  let supplyCompositionGraphData = getSupplyComposition();
  let supplyCompositionKeys = supplyCompositionGraphData
    ? Object.keys(supplyCompositionGraphData)
    : [];
  let getAfterSupply = getAfterSupplyBalance();
  let borrowPowerUsedPercentage = getBorrowPowerUsedPercentage();
  let afterBorrowPowerUsedPercentage = getAfterBorrowPowerUsedPercentage();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <div>
      <div className="main-subsections-wrapper">
        <div className="main-subsection moneymarket-supply-wrapper">
          <div className="top-wrapper">
            <div className="data-item-wrapper standard  ">
              <div className="label-value-wrapper">
                <div className="label-wrapper">
                  <div className="">
                    <span data-tip="The sum of your supplied Collateral." data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip">
                      <i className="icon icon-Info-circle"></i>
                    </span>
                  </div>
                  <span className="label">Supply Balance:</span>
                </div>
                <div className="value-wrapper" onClick={(e) => props.copyToClipboard(e)}>
                  <div className="">
                    <span data-arrow-color="#5383FF" data-class="custom-tooltip " data-background-color="#5383FF" data-html="true" data-html="true" data-tip={`<i class='fa'></i> ${props.supplyBalance ? props.supplyBalance : 0}`} >
                      <span className="symbol false">$</span>
                      {props.supplyBalance
                        ? millifyNumber(props.supplyBalance, {
                          precision: 2,
                          lowercase: false,
                        })
                        : 0}
                    </span>
                    {/* {
            this.state.copySuccess ?
            <div style={{"color": "green"}}>
              Success!
            </div> : null
          } */}
                  </div>
                </div>
              </div>
              {props.isShowAfter ? (
                <div className="label-value-wrapper after">
                  <div className="label-wrapper">
                    <span className="label">After:</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-tip={getAfterSupply.result} data-arrow-color="#5383FF" data-class="custom-tooltip">
                        <span className="symbol false">$</span>
                        {getAfterSupply.balance}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="spacer"></div>
          </div>
          <div className="bottom-wrapper">
            <div className="moneymarket-supply-graph-wrapper">
              <h2>Supply composition:</h2>
              <div className="amount-graph-wrapper">
                <div className="percentage-graph-wrapper ">
                  <div className="Flex SpaceBetween top-labels">
                    <span></span>
                    <span>
                      <div className="">
                        <span></span>
                      </div>
                    </span>
                  </div>
                  <div className="graph-wrapper">
                    {supplyCompositionKeys &&
                      supplyCompositionKeys.map(function (char, idx) {
                        let element = supplyCompositionGraphData[char];
                        let prevElem =
                          supplyCompositionGraphData[
                          supplyCompositionKeys[idx - 1]
                          ];
                        let toFixedPercentage = element.percentage.toFixedNoRounding(
                          2
                        );
                        if (idx === 0) {
                          return (
                            <span
                              key={idx}
                              data-arrow-color="#5383FF" data-class="custom-tooltip" data-background-color="#5383FF" data-html="true" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                }`}
                              style={{ width: element.percentage + "%" }}
                            ></span>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              data-arrow-color="#5383FF" data-class="custom-tooltip" data-background-color="#5383FF" data-html="true" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${prevElem
                                ? prevElem.percentage + element.percentage >=
                                  100
                                  ? "br-full"
                                  : element.percentage >= 100
                                    ? "br-full"
                                    : ""
                                : ""
                                }`}
                              style={{
                                width: `${prevElem
                                  ? prevElem.percentage + element.percentage
                                  : element.percentage
                                  }%`,
                              }}
                            ></div>
                          );
                        }
                      })}
                  </div>
                </div>
                {props.isShowAfter ? (
                  <div className="percentage-graph-wrapper ">
                    <div className="Flex SpaceBetween top-labels">
                      <span>After</span>
                      <span>
                        <div className="">
                          <span></span>
                        </div>
                      </span>
                    </div>
                    <div className="graph-wrapper small">
                      {getAfterSupply &&
                        getAfterSupply.keys &&
                        getAfterSupply.keys.map(function (char, idx) {
                          let element = getAfterSupply.percentageDetail[char];
                          let prevElem =
                            getAfterSupply.percentageDetail[
                            getAfterSupply.keys[idx - 1]
                            ];
                          let toFixedPercentage = element.percentage.toFixedNoRounding(
                            2
                          );
                          if (idx === 0) {
                            return (
                              <span
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                  }`}
                                style={{ width: (element.percentage > 100 ? 100 : element.percentage) + "%" }}
                              ></span>
                            );
                          } else {
                            return (
                              <div
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${prevElem
                                  ? prevElem.percentage +
                                    element.percentage >=
                                    100
                                    ? "br-full"
                                    : element.percentage >= 100
                                      ? "br-full"
                                      : ""
                                  : ""
                                  }`}
                                style={{
                                  width: `${prevElem
                                    ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                    : element.percentage
                                    }%`,
                                }}
                              ></div>
                            );
                          }
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main-subsection compound-manage-borrow-wrapper">
          <div className="top-wrapper">
            <div className="Flex FlexStart FlexGrow">
              <div className="data-item-wrapper standard  ">
                <div className="label-value-wrapper">
                  <div className="label-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip="The sum of your boorrowed funds.">
                        <i className="icon icon-Info-circle"></i>
                      </span>
                    </div>
                    <span className="label">Borrow Balance:</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.borrowBalance}>
                        <span className="symbol false">$</span>
                        {props.borrowBalance.toFixedNoRounding(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="data-item-wrapper standard  ">
                <div className="label-value-wrapper">
                  <div className="label-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-html={true} data-tip="<p>The ratio of your borrow limit versus borrowed funds.<br/> Liquidation happens below 100%.</p>">
                        <i className="icon icon-Info-circle"></i>
                      </span>
                    </div>
                    <div
                      className="ratio-circle"
                      style={{ backgroundColor: "rgb(55, 176, 110)" }}
                    ></div>
                    <span className="label">Safety ratio (min 100%):</span>
                  </div>
                  <div className="value-wrapper">
                    <div className="">
                      <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.safetyRatio ? props.safetyRatio : 0}>
                        {props.safetyRatio
                          ? props.safetyRatio.toFixedNoRounding(2)
                          : 0}
                        <span className="symbol false">%</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
            </div>
            <div className="top-right-wrapper">
              {/* <div className="slick-slider slick-initialized"> */}
              <Slider className="slick-slider" {...settings}>

                <div>
                  <div className="slide-inner-wrapper">
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <div className="">
                            <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip="The maximum value of funds you can borrow.">
                              <i className="icon icon-Info-circle"></i>
                            </span>
                          </div>
                          <span className="label">Borrow Limit:</span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={
                                props.borrowLimit ? props.borrowLimit : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.borrowLimit
                                ? props.borrowLimit.toFixedNoRounding(2)
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <span className="label">
                            {" "}
                            {props.isShowAfter
                              ? "After"
                              : "Left to Borrow:"}
                          </span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={
                                props.isShowAfter
                                  ? getAfterBorrowLimit()
                                  : props.leftToBorrow
                                    ? props.leftToBorrow
                                    : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.isShowAfter
                                ? getAfterBorrowLimit("toFix")
                                : props.leftToBorrow
                                  ? props.leftToBorrow.toFixedNoRounding(2)
                                  : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-inner-wrapper">
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <div className="">
                            <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip="The maximum value of funds you can borrow.">
                              <i className="icon icon-Info-circle"></i>
                            </span>
                          </div>
                          <span className="label">Borrow Limit:</span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={
                                props.borrowLimit ? props.borrowLimit : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.borrowLimit
                                ? props.borrowLimit.toFixedNoRounding(2)
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-item-wrapper small  ">
                      <div className="label-value-wrapper">
                        <div className="label-wrapper">
                          <span className="label">
                            {" "}
                            {props.isShowAfter
                              ? "After"
                              : "Left to Borrow:"}
                          </span>
                        </div>
                        <div className="value-wrapper">
                          <div className="">
                            <span
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={
                                props.isShowAfter
                                  ? getAfterBorrowLimit()
                                  : props.leftToBorrow
                                    ? props.leftToBorrow
                                    : 0
                              }
                            >
                              <span className="symbol false">$</span>
                              {props.isShowAfter
                                ? getAfterBorrowLimit("toFix")
                                : props.leftToBorrow
                                  ? props.leftToBorrow.toFixedNoRounding(2)
                                  : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
              {/* </div> */}
            </div>
          </div>
          {props.isShowAfter ? (
            <div className="top-wrapper after">
              <div className="Flex FlexStart FlexGrow">
                <div className="data-item-wrapper standard  ">
                  <div className="label-value-wrapper">
                    <div className="label-wrapper">
                      <span className="label">After:</span>
                    </div>
                    <div className="value-wrapper">
                      <div className="">
                        <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={getAfterBorrowBalance()}>
                          <span className="symbol false">$</span>
                          {getAfterBorrowBalance("millify")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="spacer"></div>
              </div>
              <div className="top-right-wrapper">
                <Slider className="slick-slider" {...settings}>
                  <div>
                    <div className="slide-inner-wrapper">
                      <div className="data-item-wrapper small  ">
                        <div className="label-value-wrapper">
                          <div className="label-wrapper">
                            <span className="label">Left to Borrow:</span>
                          </div>
                          <div className="value-wrapper">
                            <div className="">
                              <span
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={
                                  props.leftToBorrow
                                    ? props.leftToBorrow
                                    : 0
                                }
                              >
                                <span className="symbol false">$</span>
                                {props.leftToBorrow
                                  ? props.leftToBorrow.toFixedNoRounding(
                                    2
                                  )
                                  : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="data-item-wrapper small  ">
                        <div className="label-value-wrapper">
                          <div className="label-wrapper">
                            <span className="label"> After</span>
                          </div>
                          <div className="value-wrapper">
                            <div className="">
                              <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={getAfterLeftToBorrow()}>
                                <span className="symbol false">$</span>
                                {getAfterLeftToBorrow("toFix")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="bottom-wrapper">
            <div className="compound-manage-borrow-graph-wrapper">
              <h2>Borrow power used:</h2>
              <div className="amount-graph-wrapper">
                <div className="percentage-graph-wrapper ">
                  <div className="Flex SpaceBetween top-labels">
                    <span>0%</span>
                    <span>
                      <div className="">
                        <span>100%</span>
                      </div>
                    </span>
                  </div>
                  <div className="graph-wrapper">
                    {borrowPowerUsedPercentage.keys &&
                      borrowPowerUsedPercentage.keys.map(function (char, idx) {
                        let element = borrowPowerUsedPercentage.percentageDetail[char];
                        let prevElem =
                          borrowPowerUsedPercentage.percentageDetail[
                          borrowPowerUsedPercentage.keys[idx - 1]
                          ];
                        let toFixedPercentage = element.percentage.toFixedNoRounding(
                          2
                        );
                        if (idx === 0) {
                          return (
                            <span
                              key={idx}
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                }`}
                              style={{ width: (element.percentage < 100 ? element.percentage : 100) + "%" }}
                            ></span>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                              className={`overlay${idx + 1} color-overlay ${prevElem
                                ? prevElem.percentage + element.percentage >=
                                  100
                                  ? "br-full"
                                  : element.percentage >= 100
                                    ? "br-full"
                                    : ""
                                : ""
                                }`}
                              style={{
                                width: `${prevElem
                                  ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                  : element.percentage
                                  }%`,
                              }}
                            ></div>
                          );
                        }
                      })}
                    <div
                      data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`Total ${borrowPowerUsedPercentage.percentage}%`}
                      className={`overlay-total color-overlay`}
                      style={{
                        width: `${((borrowPowerUsedPercentage.percentage > 100 ? 100 : borrowPowerUsedPercentage.percentage) + 1)}%`,
                      }}
                    ></div>
                  </div>
                </div>
                {props.isShowAfter ? (
                  <div className="percentage-graph-wrapper ">
                    <div className="Flex SpaceBetween top-labels">
                      <span>After</span>
                      <span>
                        <div className="">
                          <span></span>
                        </div>
                      </span>
                    </div>
                    {/* <div className="graph-wrapper small">
                      <span
                        className="first-color-overlay"
                        style={{
                          width: getAfterBorrowPowerUsedPercentage() + "%",
                        }}
                      ></span>
                      <div
                        className="special-data-point"
                        style={{
                          width: getAfterBorrowPowerUsedPercentage() + "%",
                        }}
                      >
                        <span className="bottom-percent">
                          {getAfterBorrowPowerUsedPercentage()}%
                        </span>
                      </div>
                    </div> */}
                    <div className="graph-wrapper small">
                      {afterBorrowPowerUsedPercentage.keys &&
                        afterBorrowPowerUsedPercentage.keys.map(function (char, idx) {
                          let element = afterBorrowPowerUsedPercentage.percentageDetail[char];
                          let prevElem =
                            afterBorrowPowerUsedPercentage.percentageDetail[
                            afterBorrowPowerUsedPercentage.keys[idx - 1]
                            ];
                          let toFixedPercentage = element.percentage.toFixedNoRounding(
                            2
                          );
                          if (idx === 0) {
                            return (
                              <span
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${element.percentage >= 100 ? "br-full" : ""
                                  }`}
                                style={{ width: (element.percentage < 100 ? element.percentage : 100) + "%" }}
                              ></span>
                            );
                          } else {
                            return (
                              <div
                                key={idx}
                                data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`${toFixedPercentage}% ${element.name}`}
                                className={`overlay${idx + 1} color-overlay ${prevElem
                                  ? prevElem.percentage + element.percentage >=
                                    100
                                    ? "br-full"
                                    : element.percentage >= 100
                                      ? "br-full"
                                      : ""
                                  : ""
                                  }`}
                                style={{
                                  width: `${prevElem
                                    ? (prevElem.percentage + element.percentage) > 100 ? 100 : prevElem.percentage + element.percentage
                                    : element.percentage
                                    }%`,
                                }}
                              ></div>
                            );
                          }
                        })}
                      <div
                        data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={`Total ${afterBorrowPowerUsedPercentage.percentage}%`}
                        className={`overlay-total color-overlay`}
                        style={{
                          width: `${((afterBorrowPowerUsedPercentage.percentage > 100 ? 100 : afterBorrowPowerUsedPercentage.percentage) + 1)}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AccountTabs(props) {
  const { smartWalletAddress, compoundType } = props;
  const [state, setState] = useState({
    contractResponse: null,
    smatyWalletSupplyAddress: getContractAddressByName(props.protocol, "SmatyWalletSupply", props.network),
    aaveSmartWalletAddress: aaveSmartWalletAddress[props.network],
    collateralAsset: "",
    debtAsset: ""
  });
  let transactionDetail = {
    status: "",
    toastId: "",
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val
    })
  }
  const selectHandler = (e) => {
    var tableRef = document.getElementById("Supplying");
    if (tableRef) {
      let tbodyRef = tableRef.getElementsByTagName("tbody")[0];
      if (e !== "collateral") {
        tbodyRef.classList.remove("supply-body");
        tbodyRef.classList.remove("withDraw-body");
      }
    }
    props.handleChange(e, "activeAccountTab")
  };
  const supplyToken = (gasPrice, gasLimit) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let contractResponse = null;
      if (state.contractResponse === null) {
        if (props.cTokenContracts[props.supply.type]) {
          contractResponse = props.cTokenContracts[props.supply.type].contract
        } else {
          contractResponse = readContractByName(props.protocol,
            props.supply.type,
            props.network,
            props.supply.contractAddress
          );
        }
      } else {
        contractResponse = state.contractResponse;
      }
      const {
        web3,
        instance,
        contractABI,
        decimal,
      } = contractResponse;
      let value = truncateNumBeforeTransaction(props.supply.value)
      // value = new BigNumber(value);
      // value = value.multipliedBy(Number("1e" + decimal))
      // value = web3.utils.toHex(value);
      value = toHex(value * Number("1e" + decimal), { addPrefix: true })
      let index = contractABI.findIndex(
        (x) => x.name === "mint" && x.type === "function"
      );
      let inputLength = index !== -1 ? contractABI[index].inputs.length : 0;
      let isTransactionSuccessfull = false;
      let mintMethod;
      let options = { from: props.accountId, gasPrice: gasPrice, gas: gasLimit };
      if (inputLength > 0) {
        mintMethod = instance.methods
          .mint(value);
      } else {
        mintMethod = instance.methods
          .mint()
        options["value"] = value;
      }
      mintMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Supply: ${props.supply.value} ${props.supply.type}`}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            console.log("Refreshing");
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Supply: ${props.supply.value} ${props.supply.type}`}
                  status={transactionStatus}
                />
              ),
            });
            props.refresh();
            // toast.success(`Transaction Successfull`);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Supply: ${props.supply.value} ${props.supply.type}`}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={`Supply: ${props.supply.value} ${props.supply.type}`}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
    }
  };
  const withDrawToken = (gasPrice, gasLimit) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let contractResponse = null;
      if (state.contractResponse === null) {
        if (props.cTokenContracts[props.withDraw.type]) {
          contractResponse = props.cTokenContracts[props.withDraw.type].contract
        } else {
          contractResponse = readContractByName(props.protocol,
            props.withDraw.type,
            props.network,
            props.withDraw.contractAddress
          );
        }
      } else {
        contractResponse = state.contractResponse;
      }
      const { instance, web3, decimal } = contractResponse;
      let value = truncateNumBeforeTransaction(props.withDraw.value)
      value = new BigNumber(value);
      value = value.multipliedBy(Number("1e" + decimal))
      value = toHex(value, { addPrefix: true })
      let isTransactionSuccessfull = false;
      instance.methods
        .redeemUnderlying(value)
        .send({ from: props.accountId, gasPrice: gasPrice, gas: gasLimit })
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Withdraw: ${props.withDraw.value} ${props.withDraw.type}`}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Withdraw: ${props.withDraw.value} ${props.withDraw.type}`}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            props.refresh();
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Withdraw: ${props.withDraw.value} ${props.withDraw.type}`}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={`Withdraw: ${props.withDraw.value} ${props.withDraw.type}`}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
    }
  };
  const borrowToken = (gasPrice, gasLimit) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let contractResponse = null;
      if (state.contractResponse === null) {
        if (props.cTokenContracts[props.borrow.type]) {
          contractResponse = props.cTokenContracts[props.borrow.type].contract
        } else {
          contractResponse = readContractByName(props.protocol,
            props.borrow.type,
            props.network,
            props.borrow.contractAddress
          );
        }
      } else {
        contractResponse = state.contractResponse;
      }
      const {
        instance,
        web3,
        decimal,
      } = contractResponse;
      let isTransactionSuccessfull = false;
      let value = truncateNumBeforeTransaction(props.borrow.value)
      value = new BigNumber(value);
      value = value.multipliedBy(Number("1e" + decimal))
      value = toHex(value, { addPrefix: true })
      instance.methods
        .borrow(value)
        .send({ from: props.accountId, gasPrice: gasPrice, gas: gasLimit })
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Borrow: ${props.borrow.value} ${props.borrow.type}`}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Borrow: ${props.borrow.value} ${props.borrow.type}`}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            props.refresh();
            // toast.success(`Transaction Successfull`);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`Borrow: ${props.borrow.value} ${props.borrow.type}`}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={`Borrow: ${props.borrow.value} ${props.borrow.type}`}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
    }
    // test();
  };
  const approveRepayToken = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      // options["gas"] = gasLimit;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} />
              ),
            });
            selectGasPrice(id, "repayBorrowToken");
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={toastLabel}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const approveBoostToken = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} isLast={true} />
              ),
            });
            let type = compoundType === "smartWallet" ? "boost" : "transferTokenBoost";
            selectGasPrice(id, type, true);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const createSmartWallet = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.build().send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", async (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            const proxies = await instanceMethod.proxies(props.accountId).call();
            if (proxies !== "0x0000000000000000000000000000000000000000") {
              props.setSmartWalletAddress(proxies);
            }
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} />
              ),
            });
            let contractAddress = props[id][id === "advanceRepay" ? "repayContractAddress" : "supplyContractAddress"];
            let isApprove = props.enableSupplyTokens && props.enableSupplyTokens.findIndex(address => Number(address) === Number(contractAddress)) !== -1;
            let type = "approve";
            if (isApprove) {
              type = "transferTokenBoost";
            }
            selectGasPrice(id, type, true);
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
    // test();
  };
  const transferTokenBoost = (gasPrice, gasLimit, id, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      let isTransactionSuccessfull = false;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            isTransactionSuccessfull = true;
            toast.update("progressbar", {
              render: () => (
                <TransactionToast status={"progressbar"} activeStep={2} isLast={true} />
              ),
            });
            props.compundTypeHandler("smartWallet")
            props.handleModalChange(false, '')
            closeToastById();
            props.refresh();
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
    // test();
  };
  const repayBorrowToken = (gasPrice, gasLimit) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let contractResponse = null;
      if (state.contractResponse === null) {
        if (props.repay.type === "ETH") {
          contractResponse = readContractByName(props.protocol,
            "cETHRepay",
            props.network
          );
        } else {
          if (props.cTokenContracts[props.repay.type]) {
            contractResponse = props.cTokenContracts[props.repay.type].contract
          } else {
            contractResponse = readContractByName(props.protocol,
              props.repay.type,
              props.network,
              props.repay.contractAddress
            );
          }
        }
      } else {
        contractResponse = state.contractResponse;
      }
      const {
        instance,
        web3,
        decimal,
      } = contractResponse;
      let value = truncateNumBeforeTransaction(props.repay.value)
      value = new BigNumber(value);
      value = value.multipliedBy(Number("1e" + decimal))
      value = toHex(value, { addPrefix: true })
      let isTransactionSuccessfull = false;
      if (props.repay.type === "ETH") {
        instance.methods
          .repayBehalf(props.accountId)
          .send({ from: props.accountId, gasPrice: gasPrice, gas: gasLimit, value })
          .on("transactionHash", (hash) => {
            console.log("hash", hash);
            transactionStatus = "inprogress";
            // let toastId = toast(<TransactionToast status={transactionStatus} />);
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                  hash={hash}
                  status={transactionStatus}
                />
              ),
            });
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            console.log("confirmationNumber", confirmationNumber);
            console.log("receipt", receipt);
            if (confirmationNumber === 0 || !isTransactionSuccessfull) {
              transactionStatus = "confirm";
              toast.update(toastId, {
                render: () => (
                  <TransactionToast
                    label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                    status={transactionStatus}
                  />
                ),
              });
              isTransactionSuccessfull = true;
              toast.update("progressbar", {
                render: () => (
                  <TransactionToast status={"progressbar"} activeStep={2} />
                ),
              });
              closeToastById();
            }
          })
          .on("error", (error) => {
            console.log(error);
            transactionStatus = "error";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                  message={error.message}
                  status={transactionStatus}
                />
              ),
            });
            closeToastById();
            // toast.error(error.message);
          });
      } else {
        instance.methods
          .repayBorrow(value)
          .send({ from: props.accountId, gasPrice: gasPrice, gas: gasLimit })
          .on("transactionHash", (hash) => {
            console.log("hash", hash);
            transactionStatus = "inprogress";
            // let toastId = toast(<TransactionToast status={transactionStatus} />);
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                  hash={hash}
                  status={transactionStatus}
                />
              ),
            });
          })
          .on("confirmation", (confirmationNumber, receipt) => {
            console.log("confirmationNumber", confirmationNumber);
            console.log("receipt", receipt);
            if (confirmationNumber === 0 || !isTransactionSuccessfull) {
              transactionStatus = "confirm";
              toast.update(toastId, {
                render: () => (
                  <TransactionToast
                    label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                    status={transactionStatus}
                  />
                ),
              });
              isTransactionSuccessfull = true;
              toast.update("progressbar", {
                render: () => (
                  <TransactionToast status={"progressbar"} activeStep={2} />
                ),
              });
              closeToastById();
              props.refresh();
            }
          })
          .on("error", (error) => {
            console.log(error);
            transactionStatus = "error";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`Repay Borrow: ${props.repay.value} ${props.repay.type}`}
                  message={error.message}
                  status={transactionStatus}
                />
              ),
            });
            closeToastById();
            // toast.error(error.message);
          });
      }
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={`Approve: ${props.repay.value} ${props.repay.type}`}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const executeTransaction = (gasPrice, gasLimit, label, instanceMethod, options) => {
    let transactionStatus = transactionDetail.status;
    let toastId = transactionDetail.toastId;
    try {
      let isTransactionSuccessfull = false;
      options["from"] = props.accountId;
      options["gasPrice"] = gasPrice;
      options["gas"] = gasLimit;
      instanceMethod.send(options)
        .on("transactionHash", (hash) => {
          console.log("hash", hash);
          transactionStatus = "inprogress";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                hash={hash}
                status={transactionStatus}
              />
            ),
          });
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            console.log("Refreshing");
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={label}
                  status={transactionStatus}
                />
              ),
            });
            closeToastById();
            props.refresh();
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={label}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
          closeToastById();
          // toast.error(error.message);
        });
    } catch (error) {
      // Catch any errors for any of the above operations.
      console.error(error);
      transactionStatus = "error";
      toast.update(toastId, {
        render: () => (
          <TransactionToast
            label={label}
            message={error.message}
            status={transactionStatus}
          />
        ),
      });
      closeToastById();
    }
  };
  const makeExecuteData = async (instance, web3, selectedToken, methodName, type) => {
    let instanceExecute;
    let _data = "";
    let amount = "";
    let hexValue = "";
    Object.keys(instance.methods).map((key) => {
      if (key === "execute(address,bytes)") {
        instanceExecute = instance.methods[key]
      }
    })
    if (type === "transferTokenBoost") {
      let borrowAddress = selectedToken.borrowContractAddress;
      let supplyAddress = selectedToken.supplyContractAddress;
      borrowAddress = encodeParameter(web3, "address", borrowAddress, "")
      supplyAddress = encodeParameter(web3, "address", supplyAddress, "")
      _data = `${methodName}${borrowAddress}${supplyAddress}`;
    } else {
      const _id = web3.utils
        .sha3(methodName)
        .substring(0, 10);
      let eth_address = await getContractUnderlyingAddressByName(props.protocol, selectedToken.type, props.network);
      eth_address = encodeParameter(web3, "address", eth_address, "00")
      const c_selectedTokenAddress = encodeParameter(
        web3, "address",
        selectedToken.contractAddress, "00"
      );
      let decimal = getContractDecimalByName(props.protocol, selectedToken.type);
      let value = truncateNumBeforeTransaction(selectedToken.value)
      let valueInWie = new BigNumber(value);
      valueInWie = valueInWie.multipliedBy(BigNumber(decimal));
      hexValue = toHex(valueInWie, { addPrefix: true });
      amount = web3.eth.abi.encodeParameter("uint256", hexValue);
      const inMarket = web3.eth.abi.encodeParameter("bool", false);
      _data =
        _id +
        eth_address.substr(2) +
        c_selectedTokenAddress.substr(2) +
        amount.substr(2) +
        inMarket.substr(2);
    }

    console.log("Transaction Data", _data)
    return { _data, instanceExecute, hexValue }
  }
  const executeBoostAdvanceData = async (instance, web3, selectedToken, methodCode, firstToken, secondToken) => {
    let instanceExecute;
    Object.keys(instance.methods).map((key) => {
      if (key === "execute(address,bytes)") {
        instanceExecute = instance.methods[key]
      }
    })

    // let reserve = `${props.network}Reserve`
    let borrowReserveAddr = await getContractUnderlyingAddressByName(props.protocol, selectedToken[`${firstToken}type`], props.network);
    let supplyReserveAddr = await getContractUnderlyingAddressByName(props.protocol, selectedToken[`${secondToken}type`], props.network);
    const encodeBorrowAddress = encodeParameter(web3,
      "address",
      borrowReserveAddr
    );
    const encodeSupplyAddress = encodeParameter(web3,
      "address",
      supplyReserveAddr
    );
    let uniSwapAddress = getContractAddressByName(props.protocol, "UniswapWrapperV3", props.network);
    let decimal = getContractDecimalByName(props.protocol, selectedToken[`${secondToken}type`]);
    let value = truncateNumBeforeTransaction(selectedToken.value)
    const valueInWie = BigNumber(value).multipliedBy(BigNumber(decimal)).toString();
    // let valueInWie = toHex(value * decimal, { addPrefix: true })
    let exchangeEncodeBorrowAddress = encodeBorrowAddress;
    if (selectedToken[`${firstToken}type`] === "ETH" && firstToken === "borrow") {
      let wEthAddress = getContractAddressByName(props.protocol, "WETH9", props.network);
      exchangeEncodeBorrowAddress = encodeParameter(web3,
        "address",
        wEthAddress
      );
    }
    /*UNISWAP EXCHANGE DATA CASES*/
    // let uniswapExchangeData = `0000000000000000000000000000000000000000000000000000000000000002${encodeSupplyAddress}${exchangeEncodeBorrowAddress}`;
    let uniswapExchangeData = await getUniswapExchangeData(selectedToken[`${firstToken}type`], selectedToken[`${secondToken}type`], encodeSupplyAddress, exchangeEncodeBorrowAddress, props.protocol, props.network, web3)
    let reserveAddresses = [
      borrowReserveAddr,
      supplyReserveAddr
    ]
    if (selectedToken[`${firstToken}type`] === "ETH" || selectedToken[`${secondToken}type`] === "ETH") {
      let wEthAddress = getContractAddressByName(props.protocol, "WETH9", props.network);
      if (selectedToken[`${firstToken}type`] === "ETH") {
        reserveAddresses[0] = wEthAddress;
      }
      if (selectedToken[`${secondToken}type`] === "ETH") {
        reserveAddresses[1] = wEthAddress;
      }
    }
    reserveAddresses = reserveAddresses.sort();
    let uniswapMinPrice = await getUniSwapMinPrice(valueInWie, reserveAddresses);
    let _data = `${methodCode}0000000000000000000000000000000000000000000000000000000000000080${encodeParameter(web3, 'address', selectedToken[`${firstToken}ContractAddress`])}${encodeParameter(web3, 'address', selectedToken[`${secondToken}ContractAddress`])}0000000000000000000000000000000000000000000000000000000000000000${encodeSupplyAddress}${encodeBorrowAddress}${encodeParameter(web3, "uint256", valueInWie)}0000000000000000000000000000000000000000000000000000000000000000${encodeParameter(web3, "uint256", uniswapMinPrice)}00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000${encodeParameter(web3, 'address', uniSwapAddress)}0000000000000000000000000000000000000000000000000000000000000140000000000000000000000000000000000000000000000000000000000000022000000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000020${uniswapExchangeData}0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000c000000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000`;
    console.log("Transaction Data", _data)
    return { _data, instanceExecute }
  }
  const getUniswapExchangeData = async (firstTokenType, secondTokenType, encodeSupplyAddress, exchangeEncodeBorrowAddress, protocol, network, web3) => {
    let uniswapExchangeData = `000000000000000000000000000000000000000000000000000000000000000`;
    let address = [];
    let addrStr = "";
    switch (true) {
      case firstTokenType === "ETH" && secondTokenType === "USDT":
        address[0] = getEncodedContractAddressByName(protocol, "WETH9", network, web3);
        address[1] = getEncodedContractAddressByName(protocol, "COMP", network, web3);
        address[2] = await getContractUnderlyingAddressByName(protocol, "USDC", network);
        address[3] = await getContractUnderlyingAddressByName(protocol, "USDT", network);
        address[2] = encodeParameter(web3, "address", address[2])
        address[3] = encodeParameter(web3, "address", address[3]);
        address.sort();
        addrStr = address.join("");
        uniswapExchangeData += `4${addrStr}`;
        uniswapExchangeData = uniswapExchangeData.replaceAll(/0x/ig, "")
        return uniswapExchangeData;
      case firstTokenType === "ETH" && secondTokenType === "ETH":
        address[0] = getEncodedContractAddressByName(protocol, "WETH9", network, web3);
        address[1] = await getContractUnderlyingAddressByName(protocol, "USDC", network);
        address[2] = getEncodedContractAddressByName(protocol, "COMP", network, web3);
        address[3] = address[0];;
        address[1] = encodeParameter(web3, "address", address[1])
        address.sort();
        addrStr = address.join("");
        uniswapExchangeData += `4${addrStr}`;
        uniswapExchangeData = uniswapExchangeData.replaceAll(/0x/ig, "")
        return uniswapExchangeData;
      case firstTokenType === "WBTC" && secondTokenType === "WBTC":
        address[0] = await getContractUnderlyingAddressByName(protocol, "WBTC", network);
        address[0] = encodeParameter(web3, "address", address[0])
        address[1] = getEncodedContractAddressByName(protocol, "WETH9", network, web3);
        address[2] = await getContractUnderlyingAddressByName(protocol, "DAI", network);
        address[2] = encodeParameter(web3, "address", address[2])
        address[3] = address[0];
        // address.sort();
        addrStr = address.join("");
        uniswapExchangeData += `4${addrStr}`;
        uniswapExchangeData = uniswapExchangeData.replaceAll(/0x/ig, "")
        return uniswapExchangeData;
      case firstTokenType === "WBTC2" && secondTokenType === "ZRX":
        address[0] = exchangeEncodeBorrowAddress;
        address[1] = await getContractUnderlyingAddressByName(protocol, "USDC", network);
        address[1] = encodeParameter(web3, "address", address[1])
        address[2] = encodeSupplyAddress;
        address.sort();
        addrStr = address.join("");
        uniswapExchangeData += `3${addrStr}`;
        uniswapExchangeData = uniswapExchangeData.replaceAll(/0x/ig, "")
        return uniswapExchangeData;
      case firstTokenType === "ETH" || secondTokenType === "ETH":
        let wEthEncodeAddress = getEncodedContractAddressByName(protocol, "WETH9", network, web3);
        if (firstTokenType === "ETH") {
          exchangeEncodeBorrowAddress = wEthEncodeAddress;
        }
        if (secondTokenType === "ETH") {
          encodeSupplyAddress = wEthEncodeAddress;
        }
        uniswapExchangeData += `2${encodeSupplyAddress}${exchangeEncodeBorrowAddress}`;
        uniswapExchangeData = uniswapExchangeData.replaceAll(/0x/ig, "")
        return uniswapExchangeData;
      default:
        uniswapExchangeData += `2${encodeSupplyAddress}${exchangeEncodeBorrowAddress}`
        return uniswapExchangeData;
    }
  }
  const encodeParameter = (web3, type, value, replaceValue) => {
    if (replaceValue === undefined) {
      replaceValue = "";
    }
    let enodeValue = web3.eth.abi.encodeParameter(
      type,
      value
    );
    enodeValue = enodeValue.replace("0x", `${replaceValue}`)
    return enodeValue;
  }
  const getUniSwapMinPrice = async (valueInWie, cAddresses) => {
    try {
      const { instance } = readContractByName(props.protocol, "UniswapV2Router02", props.network);
      let amounts = await instance.methods.getAmountsOut(valueInWie, cAddresses).call();
      return amounts[1] ? amounts[1] : 0;
    } catch (error) {
      console.error(error)
      return 0;
    }

  }
  const closeToastById = () => {
    toast.dismiss("progressbar");
  };
  const selectGasPrice = async (id, type, progressBar) => {
    try {
      if (!props.allowTransaction) {
        props.ApiRequestedAction({ apiCallFor: "connectWallet" })
      } else {
        let inNounce = state.debtAsset === "None";
        let contractResponse = await getContractResponse(props, id, type, inNounce);
        const { instance, contractABI, web3, decimal } = contractResponse;
        let USD = 0;
        let transactionStatus = "creating";
        let toastId = "";
        let gasLimit = {};
        let value = "";
        if (props[id] && props[id].value) {
          let type = "";
          if (id === "advanceBoost") {
            type = props[id]["supplytype"];
          } else if (id === "advanceRepay") {
            type = props[id]["repaytype"];
          } else {
            type = props[id].type;
          }
          let tokenIndex = props.cTokensList.findIndex(
            (x) => x.underlying_symbol === type
          );
          if (tokenIndex !== -1) {
            let cToken = props.cTokensList[tokenIndex];
            USD = cToken.pricing.price ? parseFloat(cToken.pricing.price) : 0;
          }
          value = props[id].value;
          value = truncateNumBeforeTransaction(value)
          if (decimal !== 0) {
            value = new BigNumber(value);
            value = value.multipliedBy(Number("1e" + decimal))
            value = toHex(value, { addPrefix: true })
          }
        }
        let contractMethod;
        let contractMethodOptions = {};
        let submitMethod;
        let toastLabel = "";
        let totalSteps = 2;
        let closeToast = () => { };
        let isProgressBar = false;
        let isAllow = true;
        if (id === "withDraw") {
          toastLabel = `With Draw: ${props.withDraw.value} ${props.withDraw.type}`;
          if (compoundType === "smartWallet") {
            let methodName = "withdraw(address,address,uint256,bool)";
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodName)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            contractMethod = instance.methods.redeemUnderlying(value);
            submitMethod = withDrawToken;
          }
        } else if (id === "supply") {
          toastLabel = `Supply: ${props.supply.value} ${props.supply.type}`;
          if (compoundType === "smartWallet") {
            let methodName = "deposit(address,address,uint256,bool)"
            const executeData = await makeExecuteData(instance, web3, props[id], methodName)
            const executeAddress = state.smatyWalletSupplyAddress;
            contractMethodOptions = { value: executeData.hexValue }
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            let index = contractABI.findIndex(
              (x) => x.name === "mint" && x.type === "function"
            );
            let inputLength = index !== -1 ? contractABI[index].inputs.length : 0;
            contractMethod = inputLength > 0 ? instance.methods
              .mint(value) : instance.methods.mint();
            contractMethodOptions = inputLength > 0 ? {} : { value }
            submitMethod = supplyToken;
          }
        } else if (id === "borrow") {
          toastLabel = `Borrow: ${props[id].value} ${props[id].type}`;
          if (compoundType === "smartWallet") {
            let methodName = "borrow(address,address,uint256,bool)";
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodName)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            contractMethod = instance.methods.borrow(value);
            submitMethod = borrowToken;
          }
        } else if (
          id === "repay" &&
          type === "repayApprove" &&
          props[id].type !== "ETH"
        ) {
          value = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
          let contractAddress = "";
          if (compoundType === "smartWallet") {
            contractAddress = props.smartWalletAddress;
            toastLabel = `Approve DSProxy for ${props.repay.type}`;
          } else {
            contractAddress = props[id].contractAddress;
            toastLabel = `Approve Compound for ${props.repay.type}`;
          }
          let allowance = await instance.methods.allowance(props.accountId, contractAddress).call();
          if (allowance < props[id].value) {
            contractMethod = instance.methods.approve(contractAddress, value);
            submitMethod = (gasPrice, gasLimit) => approveRepayToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
            closeToast = closeToastById;
            isProgressBar = true;
          } else {
            isAllow = false;
            selectGasPrice("repay", "repayBorrowToken");
          }
        } else if (
          id === "repay" &&
          (type === "repayBorrowToken" ||
            (type === "repayApprove" && props[id].type === "ETH"))
        ) {
          toastLabel = `Repay Borrow : ${props.repay.value} ${props.repay.type}`;
          if (compoundType === "smartWallet") {
            let methodName = "payback(address,address,uint256,bool)";
            contractMethodOptions = { value: 0 }
            const executeData = await makeExecuteData(instance, web3, props[id], methodName)
            const executeAddress = state.smatyWalletSupplyAddress
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else {
            if (props[id].type === "ETH") {
              contractMethod = instance.methods.repayBehalf(props.accountId);
              contractMethodOptions = { value: value }
            } else {
              let data = await getAllEstimationGasPrice(instance.methods
                .repayBorrow(value), {}, props.currentGasPrice, props.accountId);
              if (!data.isError) {
                gasLimit = data.gasPrice;
              }
              contractMethod = instance.methods.repayBorrow(value);
            }
            submitMethod = repayBorrowToken;
          }
        } else if (id === "advanceBoost") {
          if (compoundType === "smartWallet" && type === "boost") {
            toastLabel = `Boost: ${props.advanceBoost.value} ${props.advanceBoost.borrowtype} - ${props.advanceBoost.supplytype}`;
            let methodCode = "0xc8a6b049";
            contractMethodOptions = {}
            const executeData = await executeBoostAdvanceData(instance, web3, props[id], methodCode, "borrow", "supply")
            const executeAddress = getContractAddressByName(props.protocol, "CompoundFlashLoanTaker", props.network);
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "approve") {
            value = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
            let contractAddress = "";
            let methodType = "";
            if (compoundType === "smartWallet") {
              methodType = "boost";
              contractAddress = props.smartWalletAddress;
              toastLabel = `Approve DSProxy for ${props.advanceBoost.supplytype}`;
            } else {
              methodType = "transferTokenBoost";
              contractAddress = props.advanceBoost.supplyContractAddress;
              toastLabel = `Approve Compound for ${props.advanceBoost.supplytype}`;
            }
            let allowance = await instance.methods.allowance(props.accountId, contractAddress).call();
            if (allowance < props[id].value) {
              toastLabel = `Approve: ${props.advanceBoost.supplytype}`;
              contractMethod = instance.methods.approve(contractAddress, value);
              submitMethod = (gasPrice, gasLimit) => approveBoostToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
            } else {
              isAllow = false;
              selectGasPrice(id, methodType);
            }
          } else if (type === "transferTokenBoost") {
            toastLabel = `Migrating to proxy`;
            if (!inNounce) {
              let methodCode = "0x9e9951d8";
              contractMethodOptions = {}
              const executeData = await makeExecuteData(instance, web3, props[id], methodCode, type)
              const executeAddress = getContractAddressByName(props.protocol, "CompoundImportTaker", props.network);
              contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            } else {
              contractMethod = instance.methods.transfer(props.smartWalletAddress, value);
            }
            submitMethod = (gasPrice, gasLimit) => transferTokenBoost(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "createSmartWallet") {
            toastLabel = `Create DS Proxy`;
            contractMethod = instance.methods.build();
            submitMethod = (gasPrice, gasLimit) => createSmartWallet(gasPrice, gasLimit, id, toastLabel, instance.methods, contractMethodOptions);
            totalSteps = 3;
          }
          if (progressBar || type === "approve" || type === "createSmartWallet") {
            closeToast = closeToastById;
            isProgressBar = true;
          }
        } else if (id === "advanceRepay") {
          if (compoundType === "smartWallet" && type === "boost") {
            toastLabel = `Repay: ${props.advanceRepay.value} ${props.advanceRepay.withDrawtype} - ${props.advanceRepay.repaytype}`;
            let methodCode = "0x2ce6f7c3";
            contractMethodOptions = {}
            const executeData = await executeBoostAdvanceData(instance, web3, props[id], methodCode, "withDraw", "repay")
            const executeAddress = getContractAddressByName(props.protocol, "CompoundFlashLoanTaker", props.network);
            contractMethod = executeData.instanceExecute(executeAddress, executeData._data);
            submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "approve") {
            toastLabel = `Approve: ${props.advanceRepay.repaytype}`;
            contractMethod = instance.methods.approve(props.smartWalletAddress, value);
            submitMethod = (gasPrice, gasLimit) => approveBoostToken(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "transferTokenBoost") {
            toastLabel = `Migrating to proxy`;
            contractMethod = instance.methods.transfer(props.smartWalletAddress, value);
            submitMethod = (gasPrice, gasLimit) => transferTokenBoost(gasPrice, gasLimit, id, toastLabel, contractMethod, contractMethodOptions);
          } else if (type === "createSmartWallet") {
            toastLabel = `Create DS Proxy`;
            contractMethod = instance.methods.build();
            submitMethod = (gasPrice, gasLimit) => createSmartWallet(gasPrice, gasLimit, id, toastLabel, instance.methods, contractMethodOptions);
            totalSteps = 3;
          }
          if (progressBar || type === "approve" || type === "createSmartWallet") {
            closeToast = closeToastById;
            isProgressBar = true;
          }
        } else if (id === "claimComp") {
          toastLabel = `Claim Comp`;
          contractMethod = instance.methods.claimComp(props.accountId);
          submitMethod = (gasPrice, gasLimit) => executeTransaction(gasPrice, gasLimit, toastLabel, contractMethod, contractMethodOptions);
        }
        if (isAllow) {
          let gasLimit = 55600;
          let data = {};
          if (id === "claimComp" || (id === "repay" &&
            type === "repayApprove" &&
            props[id].type !== "ETH")) {
            data = {
              isError: false,
              gasPrice: {
                safeLow: gasLimit,
                average: gasLimit,
                fastest: gasLimit,
              }
            }
          } else {
            data = await getAllEstimationGasPrice(contractMethod, contractMethodOptions, props.currentGasPrice, props.accountId);
          }
          let errorMessage = "";
          if (data.isError) {
            data = {
              isError: true,
              gasPrice: {
                safeLow: gasLimit,
                average: gasLimit,
                fastest: gasLimit,
              }
            }
            errorMessage = "An issue was detected that would cause this transaction to fail. Please contact us for assistance if the error persists"
          }
          // else {
          //   toastId = toast(
          //     <TransactionToast
          //       label={toastLabel}
          //       message={data.error}
          //       status={"error"}
          //     />
          //   );
          //   closeToastById();
          // }
          gasLimit = data.gasPrice;
          if (isProgressBar) {
            toast(<TransactionToast status={"progressbar"} activeStep={1} totalSteps={totalSteps} />, {
              toastId: "progressbar",
              closeButton: false,
            });
          }
          toastId = toast(
            <TransactionToast
              status={transactionStatus}
              USD={USD}
              gasLimit={gasLimit}
              gasPrice={props.currentGasPrice}
              handleSubmit={submitMethod}
              closeToastById={closeToastById}
              label={toastLabel}
              hasError={data.isError} errorMessage={errorMessage}
            />
          );
          transactionDetail = {
            status: transactionStatus,
            toastId,
          };
          setState({
            ...state,
            contractResponse,
          });
        }
      }
    } catch (error) {
      toast(
        <TransactionToast
          label={`Error`}
          message={error.message}
          status={"error"}
        />
      );
      closeToastById();
    }
  };
  const getContractResponse = async (props, id, type, inNounce) => {
    let contractResponse = null;
    if (id === "claimComp") {
      contractResponse = readContractByName(props.protocol, "Comptroller", state.network);
    } else if (compoundType === "smartWallet") {
      if (id === "advanceBoost" && type === "approve") {
        contractResponse = readContractByName(props.protocol,
          props.advanceBoost.supplytype,
          props.network,
          props.advanceBoost.supplyContractAddress
        );
      } else if ((id === "advanceRepay" && type === "approve") || (id === "repay" && type === "repayApprove" && props[id].type !== "ETH")) {
        contractResponse = await readUnderLyingContractByName(props.protocol,
          props[id].type,
          props.network
        );
      } else {
        contractResponse = readContractByName(props.protocol,
          "DSProxy",
          props.network,
          smartWalletAddress
        );
      }

    } else if (id === "repay" && (type === "repayBorrowToken" || type === "repayApprove") && props[id].type === "ETH") {
      contractResponse = readContractByName(props.protocol,
        "cETHRepay",
        props.network
      );
    } else if (id === "repay" && type === "repayApprove" && props[id].type !== "ETH") {
      contractResponse = await readUnderLyingContractByName(props.protocol,
        props[id].type,
        props.network
      );
    } else if (id === "advanceBoost" || id === "advanceRepay") {
      if (type === "createSmartWallet") {
        contractResponse = readContractByName(props.protocol,
          "ProxyRegistry",
          props.network
        );
      } else if (id === "advanceBoost") {
        if (type === "transferTokenBoost" && !inNounce) {
          contractResponse = readContractByName(props.protocol,
            "DSProxy",
            props.network,
            smartWalletAddress
          );
        } else {
          contractResponse = readContractByName(props.protocol,
            props.advanceBoost.supplytype,
            props.network,
            props.advanceBoost.supplyContractAddress
          );
          if (type === "transferTokenBoost") {
            let decimal = await contractResponse.instance.methods.decimals().call();
            contractResponse.decimal = parseInt(decimal)
          }
        }
      } else if (id === "advanceRepay") {
        contractResponse = readContractByName(props.protocol,
          props.advanceRepay.repaytype,
          props.network,
          props.advanceRepay.repayContractAddress
        );
      }
    } else {
      if (props.cTokenContracts[props[id].type]) {
        contractResponse = props.cTokenContracts[props[id].type].contract
      } else {
        contractResponse = readContractByName(props.protocol,
          props[id].type,
          props.network,
          props[id].contractAddress
        );
      }
    }
    return contractResponse;
  }
  const mergeArray = (x, y) => {
    let arr = []
    x.concat(y).forEach(item => {
      if (arr.findIndex(x => x.symbol === item.symbol) === -1) {
        arr.push(item)
      }
    });
    return arr;
  }
  let repayCtokenList = mergeArray(props.supplyList, props.borrowList);
  return (
    <div className="actions-section-wrapper ">
      <div className="tabs-wrapper">
        <Tabs
          defaultActiveKey={props.activeAccountTab}
          transition={false}
          id="account-tabs"
          onSelect={(e) => selectHandler(e)}
        >
          <Tab eventKey="advanced" title="Advanced">
            <AdvancedTab
              cTokensList={props.cTokensList}
              advanceRepay={props.advanceRepay}
              advanceBoost={props.advanceBoost}
              borrowList={props.borrowList}
              supplyList={props.supplyList}
              handleModalChange={props.handleModalChange}
              handleChange={props.handleChange}
              handleTypeChange={props.handleTypeChange}
              compoundType={compoundType}
              repayCtokenList={repayCtokenList}
              allowTransaction={props.allowTransaction}
            />
          </Tab>
          <Tab eventKey="collateral" title="Collateral">
            <CollateralTab
              network={props.network}
              cTokensList={props.cTokensList}
              supplyList={props.supplyList}
              withDraw={props.withDraw}
              supply={props.supply}
              currentGasPrice={props.currentGasPrice}
              handleChange={props.handleChange}
              handleTypeChange={props.handleTypeChange}
              selectGasPrice={selectGasPrice}
              allowTransaction={props.allowTransaction}
            />
          </Tab>
          <Tab eventKey="debt" title="Debt">
            {" "}
            <DebtTab
              borrowLimit={props.borrowLimit}
              repay={props.repay}
              borrowList={props.borrowList}
              currentGasPrice={props.currentGasPrice}
              network={props.network}
              cTokensList={props.cTokensList}
              borrow={props.borrow}
              handleTypeChange={props.handleTypeChange}
              handleChange={props.handleChange}
              selectGasPrice={selectGasPrice}
              allowTransaction={props.allowTransaction}
            />{" "}
          </Tab>
        </Tabs>
      </div>
      {props.compoundType === "smartWallet" ?
        <BoostRepaySmartWalletModal
          showModal={props.showModal && props.modalType === "boostrepaysmartwallet"}
          handleModalChange={props.handleModalChange}
          activeSection={props.isAdvance}
          advanceRepay={props.advanceRepay}
          advanceBoost={props.advanceBoost}
          handleSubmit={selectGasPrice}
          enableSupplyTokens={props.enableSupplyTokens}
        /> :
        <BoostRepayAccountModal
          showModal={props.showModal && props.modalType === "boostrepayaccount"}
          handleModalChange={props.handleModalChange}
          activeSection={props.isAdvance}
          advanceRepay={props.advanceRepay}
          advanceBoost={props.advanceBoost}
          handleSubmit={selectGasPrice}
          enableSupplyTokens={props.enableSupplyTokens}
          smartWalletAddress={smartWalletAddress}
          handleChange={handleChange}
          borrowList={props.borrowList}
          repayCtokenList={repayCtokenList}
          debtAsset={state.debtAsset}
          collateralAsset={state.collateralAsset}
        />}
      <CompTokenModal
        showModal={props.showModal && props.modalType === "compTokens"}
        handleModalChange={props.handleModalChange}
        compReward={props.compReward}
        claimComp={selectGasPrice}
        activeSection={props.isAdvance}
      />
    </div>
  );
}

function AdvancedTab(props) {
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="action-desc">Borrow {props.advanceBoost.borrowtype} → Supply {props.advanceBoost.supplytype}</div>
            <div className="max-wrapper ">
              <div className="">
                <span>(max 0)</span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost    ">
              <div className="input-values">
                <label className="">Boost:</label>
                <input
                  placeholder="0"
                  className="form-item"
                  type="number"
                  id="advanceBoostValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "advanceBoost")}
                  value={props.advanceBoost.value}
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Borrow"
              value={props.advanceBoost.borrowtype}
              objKey="underlying_symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceBoost", "borrow")
              }
            />
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              value={props.advanceBoost.supplytype}
              objKey="underlying_symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceBoost", "supply")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              onClick={() => props.handleModalChange(true, props.compoundType === "smartWallet" ? "boostrepaysmartwallet" : "boostrepayaccount", "advanceBoost")}
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.advanceBoost.borrowtype === "" ||
                props.advanceBoost.supplytype === "" ||
                props.advanceBoost.value === "" ||
                props.cTokensList.length === 0
                || !props.allowTransaction
              }
            >
              <span>Boost</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            <div className="action-desc">Withdraw {props.advanceRepay.withDrawtype} → Payback {props.advanceRepay.repaytype}</div>
            <div className="max-wrapper ">
              <div className="">
                <span>(max 0)</span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay    ">
              <div className="input-values">
                <label className="">Repay:</label>
                <input
                  placeholder="0"
                  id="advanceRepayValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "advanceRepay")}
                  value={props.advanceRepay.value}
                  className="form-item"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.repayCtokenList}
              label="Withdraw"
              value={props.advanceRepay.withDrawtype}
              objKey="symbol"
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceRepay", "withDraw")
              }
            />
            <CurrencyDropdown
              data={props.borrowList}
              label="Payback"
              value={props.advanceRepay.repaytype}
              handleChange={(symbol, tokenAddress) =>
                props.handleTypeChange(symbol, tokenAddress, "advanceRepay", "repay")
              }
              objKey="symbol"
              validateKey="borrow_balance_underlying"
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.advanceRepay.repaytype === "" ||
                props.advanceRepay.withDrawtype === "" ||
                props.advanceRepay.value === "" ||
                parseFloat(props.advanceRepay.value) === 0 ||
                props.borrowList.length === 0 || props.repayCtokenList.length === 0
                || !props.allowTransaction
              }
              onClick={() => props.handleModalChange(true, props.compoundType === "smartWallet" ? "boostrepaysmartwallet" : "boostrepayaccount", "advanceRepay")}
            >
              <span>Repay</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function CollateralTab(props) {
  const toFixedBalance = (balance) => {
    if (balance) {
      if (typeof balance === "number") {
        balance = balance.toString()
      }
      let currency = balance.replace(/[^a-zA-Z ]/g, "");
      balance = parseFloat(balance).toFixedNoRounding(2) + currency;
    }
    return balance;
  };
  const balanceHandler = (balance, objName, fieldName) => {
    props.handleChange(
      {
        target: {
          name: fieldName,
          value: balance,
        },
      },
      objName
    );
  };
  let supplyTokenType =
    props.supply && props.supply.type ? props.supply.type : "";
  let withDrawTokenType =
    props.withDraw && props.withDraw.type ? props.withDraw.type : "";
  let balance = props.supply && props.supply.type ? props.supply.balance : 0;
  let withDrawBalance = props.withDraw && props.withDraw.type ? props.withDraw.balance : 0;
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            {/* <div className="action-desc">
              Borrow {supplyTokenType} → Supply {supplyTokenType}
            </div> */}
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() => balanceHandler(balance, "supply", "value")}
                >
                  (max{" "}
                  {props.supply && props.supply.type
                    ? toFixedBalance(balance)
                    : 0}
                  )
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost">
              <div className="input-values">
                <label className="">Supply:</label>
                <input
                  name="boostAmount"
                  placeholder="0"
                  id="supplyValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "supply")}
                  value={props.supply.value}
                  className="form-item"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              value={props.supply.type}
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "supply")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.supply.type === "" ||
                props.supply.value === "" ||
                parseFloat(props.supply.value) === 0 ||
                balance < props.supply.value || !props.allowTransaction
              }
              onClick={() => props.selectGasPrice("supply")}
            >
              <span>Supply</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            {/* <div className="action-desc">Withdraw {withDrawTokenType} → Payback {withDrawTokenType}</div> */}
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(withDrawBalance, "withDraw", "value")
                  }
                >
                  (max{" "}
                  {props.withDraw && props.withDraw.type
                    ? toFixedBalance(withDrawBalance)
                    : 0}
                  )
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay">
              <div className="input-values">
                <label className="">Withdraw:</label>
                <input
                  value={props.withDraw.value}
                  className="form-item "
                  id="withDrawValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "withDraw")}
                  placeholder="0"
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.supplyList}
              label="Supply"
              objKey="symbol"
              value={props.withDraw.type}
              validateKey="supply_balance_underlying"
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "withDraw")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              disabled={
                props.supplyList.length === 0 ||
                props.withDraw.type === "" ||
                props.withDraw.value === "" ||
                parseFloat(props.withDraw.value) === 0 ||
                withDrawBalance < props.withDraw.value || !props.allowTransaction
              }
              data-after-text="Migrate"
              onClick={() => props.selectGasPrice("withDraw")}
            >
              <span>WithDraw</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function DebtTab(props) {
  let currentBorrowType =
    props.borrow && props.borrow.type ? props.borrow.type : "";
  let currentBorrowBalance =
    props.borrow && props.borrow.balance ? parseFloat(props.borrow.balance) : 0;
  let currentRepayType =
    props.repay && props.repay.type ? props.repay.type : "";
  let currentRepayBalance =
    props.repay && props.repay.balance ? parseFloat(props.repay.balance) : 0;
  const balanceHandler = (balance, objName, fieldName) => {
    props.handleChange(
      {
        target: {
          name: fieldName,
          value: balance,
        },
      },
      objName
    );
  };
  return (
    <form className="maker-advanced-form action-items-wrapper advanced-form form-wrapper false upgradable">
      <div className="item boost  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            {/* <div className="action-desc">
              Borrow {currentBorrowType} → Supply {currentBorrowType}
            </div> */}
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(currentBorrowBalance, "borrow", "value")
                  }
                >
                  (max {currentBorrowBalance.toFixedNoRounding(2)})
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper boost    ">
              <div className="input-values">
                <label className="">Borrow:</label>
                <input
                  placeholder="0"
                  className="form-item"
                  type="number"
                  value={props.borrow.value}
                  id="borrowValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "borrow")}
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.cTokensList}
              label="Supply"
              value={props.borrow.type}
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "borrow")
              }
            />
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              data-after-text="Migrate"
              disabled={
                props.borrow.type === "" ||
                props.borrow.value === "" ||
                parseFloat(props.borrow.value) === 0 ||
                currentBorrowBalance < props.borrow.value
                || !props.allowTransaction
              }
              onClick={() => props.selectGasPrice("borrow")}
            >
              <span>Borrow</span>
            </button>
          </div>
        </div>
      </div>
      <div className="item repay  select-item has-description">
        <div className="select-input-max-wrapper">
          <div className="action-items-above-wrapper">
            {/* <div className="action-desc">
              Withdraw {currentRepayType} → Payback {currentRepayType}
            </div> */}
            <div className="max-wrapper ">
              <div className="">
                <span
                  onClick={() =>
                    balanceHandler(currentRepayBalance, "repay", "value")
                  }
                >
                  (max {currentRepayBalance.toFixedNoRounding(2)})
                </span>
              </div>
            </div>
          </div>
          <div className="select-input-wrapper">
            <div className="form-item-wrapper repay    ">
              <div className="input-values">
                <label className="">Payback:</label>
                <input
                  value={props.repay.value}
                  id="repayValue"
                  name="value"
                  onChange={(e) => props.handleChange(e, "repay")}
                  className="form-item "
                  type="number"
                />
                <span className="label-replacement"></span>
              </div>
            </div>
            <CurrencyDropdown
              data={props.borrowList}
              label="Supply"
              objKey="symbol"
              validateKey="borrow_balance_underlying"
              value={props.repay.type}
              handleChange={(symbol, tokenAddress, id) =>
                props.handleTypeChange(symbol, tokenAddress, "repay")
              }
            />
            {/* <CurrencyDropdown data={props.cTokensList} label="Supply" value={props.cTokensList.length > 0 ? props.cTokensList[0]['underlying_symbol'] : ''} handleChange={(symbol, tokenAddress) => { }} /> */}
          </div>
        </div>
        <div className="item-button-wrapper">
          <div className="">
            <button
              type="button"
              className="button blue-gradient"
              disabled={
                props.repay.type === "" ||
                props.repay.value === "" ||
                parseFloat(props.repay.value) === 0 ||
                currentRepayBalance < props.repay.value
                || !props.allowTransaction
              }
              data-after-text="Migrate"
              onClick={() => props.selectGasPrice("repay", "repayApprove")}
            >
              <span>Payback</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function CurrencyDropdown(props) {
  let defualtValue =
    props.data.length > 0 ? props.data[0]["underlying_symbol"] : "";
  let value = props.value
    ? props.objKey === "symbol" && props.value[0] === "c"
      ? props.value.substring(1)
      : props.value
    : defualtValue;
  return (
    <div className="select box ">
      <div className="select__control ">
        <span className="select-label">{props.label}</span>
        <div className="select__value-container ">
          <Dropdown>
            <Dropdown.Toggle
              variant="Secondary"
              id="dropdown-basic"
              disabled={props.data.length === 0}
            >
              {value}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.data.map((item, index) => {
                let symbol =
                  props.objKey && item[props.objKey]
                    ? props.objKey === "symbol" && item[props.objKey][0] === "c"
                      ? item[props.objKey].substring(1)
                      : item[props.objKey]
                    : item.underlying_symbol;
                return (props.validateKey === undefined) || (props.validateKey && item[props.validateKey] && Number(item[props.validateKey]["value"]) > 0) ? (
                  <Dropdown.Item
                    href="#"
                    key={index}
                    name="type"
                    onSelect={(e) =>
                      props.handleChange(
                        symbol,
                        item.token_address ? item.token_address : item.address
                      )
                    }
                  >
                    {symbol}
                  </Dropdown.Item>
                ) : "";
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
