import React, { Component } from 'react';
class Footer extends Component {
   render() {
      return (

         <div className="footer-wrapper ">
            <div className="width-container">
               <div className="left-side">
                  <div className="general-info">
                     <div className="site-logo title">Elixyr</div>
                     <div className="description">Elixyr is a one-stop management solution for decentralized finance protocols.</div>
                  </div>
                  <div className="company-info-wrapper">
                     {/* <a target="_blank" rel="noopener noreferrer" href="./">
                        <div className="logo"></div>
                     </a> */}
                     <div className="company-info">
                        <div className="developed">Elixyr © 2021</div>
                        <div className="company-links"><a className="link" href="/terms-of-service">Terms of service</a> | <a className="link" href="/privacy-policy">Privacy Policy</a></div>
                     </div>
                  </div>
               </div>
               <div className="right-side">
                  <div className="right-links-wrapper"><a href="#">Stats</a><a href="#" target="_blank" rel="noopener noreferrer">Help</a><a href="#" target="_blank" rel="noopener noreferrer">Blog</a></div>
                  <div id="mc_embed_signup">
                     <form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" noValidate="">
                        <div id="mc_embed_signup_scroll">
                           <h4>Subscribe to the Elixyr newsletter:</h4>
                           <div className="mc-field-group">
                              <label htmlFor="mce-EMAIL">Email Address </label><input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="email" autoComplete="off" />
                              <div className="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" disabled="" value="Subscribe" /></div>
                           </div>
                           <div id="mce-responses" className="clear">
                              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                           </div>
                        </div>
                     </form>
                  </div>
                  <div className="social-media-links-wrapper">
                     {/* <div className="label">Follow us:</div> */}
                     <div className="icons-wrapper">
                        <a className="medium-icon" target="_blank" rel="noopener noreferrer" href="#">
                           <img src={require("../../assets/svgs/social/medium.svg").default} alt="" />
                        </a>
                        <a className="discord-icon" target="_blank" rel="noopener noreferrer" href="#">
                           <img src={require("../../assets/svgs/social/discord.svg").default} alt="" />
                        </a>
                        <a className="twitter-icon" target="_blank" rel="noopener noreferrer" href="#">
                           <img src={require("../../assets/svgs/social/twitter.svg").default} alt="" />
                        </a>
                        <a className="github-icon" target="_blank" rel="noopener noreferrer" href="#">
                           <img src={require("../../assets/svgs/social/github.svg").default} alt="" />
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      );
   }
}

export default Footer;