import {
  compoundFinanceApiUrl,
  ctokenAddress,
  backendApiUrl,
  ctokenUnderLyingAddress,
  network,
  defiexploreApiUrl,
} from "../../../constants/constant";
import Compound from "@compound-finance/compound-js";
import { FETCH } from "../../../utils/fetch";
import {
  ApiRequestedAction,
  ApiFulfilledAction,
  ApiRejectedAction,
} from "../../../components/ApiCallStatus/Actions/action";
import {
  getCompoundFinancePricesAction,
  getCompoundSupplyListAction,
  getTokenPriceListAction,
  getContractsWithSnapshotAction,
} from "../Actions/action";
import _, { forEach } from "underscore";
import {
  readContractByName,
  getContractAddressByName,
} from "../../../utils/contracts";
import { getTokenAddressQueryString } from "../../../utils/common";
import { BigNumber } from "bignumber.js";

// Apicall to get token prices
export function getTokenPrices(isUpdatePrice) {
  return (dispatch) => {
    let apiCallFor = isUpdatePrice ? "updateTokenPrice" : "getTokenPrices";
    dispatch(ApiRequestedAction({ apiCallFor }));
    dispatch(getTokenPriceListAction([]));
    let tokens = ctokenUnderLyingAddress["mainnet"];
    let url = `${backendApiUrl}prices/compound`;
    let headers = {
      accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    };
    FETCH("GET", url, "", false, headers)
      .then(function (data) {
        if (
          data &&
          data.statusCode === 200 &&
          data.response &&
          data.response.data.length > 0
        ) {
          let prices = [];
          tokens.forEach((element, index) => {
            let price = 0;
            let priceIndex = data.response.data.findIndex(
              (x) => x.token === element.name
            );
            if (priceIndex !== -1) {
              price = data.response.data[priceIndex].price;
            }
            prices.push({
              address: element.address,
              price: price,
              symbol: element.name,
            });
          });
          dispatch(getTokenPriceListAction(prices));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: data.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: data.statusCode,
              apiCallFor,
              message: data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// Apicall to get market info data
export function getCompoundMarketPrice(network) {
  return (dispatch) => {
    let apiCallFor = "getCompoundMarketPrice";
    dispatch(ApiRequestedAction({ apiCallFor }));
    dispatch(getCompoundFinancePricesAction({ list: [] }));
    let query = `network=${network}${getTokenAddressQueryString(
      "compound",
      network
    )}`;
    let url = `${compoundFinanceApiUrl}ctoken?${query}`;
    let headers = {
      accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    };
    FETCH("GET", url, "", false, headers)
      .then(function (data) {
        if (data && data.statusCode === 200 && data.response.error === null) {
          let cToken = [];
          data.response.cToken.forEach((element) => {
            let symbol = element.symbol.substring(1);
            if (symbol !== element.underlying_symbol) {
              element["underlying_symbol"] = symbol;
            }
            cToken.push(element);
          });
          dispatch(getCompoundFinancePricesAction({ list: cToken }));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: data.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: data.statusCode,
              apiCallFor,
              message: data.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// get connected account supply list
export function getSupplyListing(address, network) {
  return (dispatch) => {
    let apiCallFor = "getSupplyListing";
    dispatch(ApiRequestedAction({ apiCallFor }));
    dispatch(getCompoundSupplyListAction([]));
    Compound.api
      .account({
        addresses: address,
        network: network,
      })
      .then(function (data) {
        if (data && data.error === null) {
          let accounts = data.accounts;
          if (accounts.length === 0) {
            accounts = [
              {
                address: address,
                block_updated: null,
                health: { value: "0" },
                tokens: [],
                total_borrow_value_in_eth: { value: "0" },
                total_collateral_value_in_eth: { value: "0" },
              },
            ];
          }
          dispatch(getCompoundSupplyListAction(accounts));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: data.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: data.statusCode,
              apiCallFor,
              message: data.error,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: 400,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// get connected account supply list
export function getAccountSnapshotOfAllCTokens(list, network, accountId) {
  return (dispatch) => {
    try {
      let apiCallFor = "getAccountSnapshotOfAllCTokens";
      dispatch(ApiRequestedAction({ apiCallFor }));
      dispatch(getContractsWithSnapshotAction([]));
      console.log("getAccountSnapshotOfAllCTokens accountId", accountId);
      const promises = [];
      list &&
        list.forEach((element) => {
          promises.push(
            getSnapshotOfCToken(
              element.underlying_symbol,
              network,
              element.token_address,
              accountId
            )
          );
        });
      Promise.all(promises)
        .then((response) => {
          let data = {};
          response.forEach((element) => {
            data[element.name] = element;
          });
          dispatch(getContractsWithSnapshotAction(data));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: "data.message",
            })
          );
        })
        .catch((error) => {
          dispatch(
            ApiRejectedAction({
              statusCode: 400,
              apiCallFor,
              message: error,
            })
          );
        });
    } catch (error) {
      dispatch(
        ApiRejectedAction({
          statusCode: 400,
          apiCallFor,
          message: error,
        })
      );
    }
  };
}

function getSnapshotOfCToken(name, network, address, accountId) {
  return new Promise(async (resolve, reject) => {
    try {
      let contract = readContractByName("compound", name, network, address);
      let snapshot = await contract.instance.methods
        .getAccountSnapshot(accountId)
        .call();
      let supplyBalance = new BigNumber(snapshot[1])
        .times(snapshot[3])
        .div(new BigNumber(10).pow(contract.decimal + 18));
      supplyBalance = supplyBalance.toNumber();
      let borrowBalance = new BigNumber(snapshot[2]).div(
        new BigNumber(10).pow(contract.decimal)
      );
      borrowBalance = borrowBalance.toNumber();
      let snap = { supplyBalance: "0", borrowBalance: "0", exchangeRate: "0" };
      if (snapshot) {
        snap = {
          supplyBalance: supplyBalance, // snapshot[1]
          borrowBalance: borrowBalance, //snapshot[2],
          exchangeRate: snapshot[3],
        };
      }
      resolve({ contract, name, snapshot: snap, accountId });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}
