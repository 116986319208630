import { getCookie } from "../utils/cookies";

var StoreState = {
  apiCall: {
    apiCallFor: "",
    statusCode: "",
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
  common: {
    ethPrice: 0,
    currentGasPrice: {},
    gasPriceHistory: {
      min: {},
      max: {},
      history: [],
    },
    accountDetail: {
      isConnected: getCookie("isConnected") === "true",
      connectedWallet: getCookie("connectedWallet"),
      accountId: getCookie("accountId"),
      balance: getCookie("balance"),
      currency: getCookie("currency"),
    },
  },
  portfolio: {
    transactionHistory: {
      list: [],
      total: 0,
    },
    portFolioStats:{}
  },
  compound: {
    marketInfo: {
      list: [],
    },
    tokenPriceList: [],
    supplyList: [],
    cTokensList: [],
    contracts: {},
  },
  aave: {
    marketInfo: {
      list: [],
    },
    tokenPriceList: [],
    supplyList: [],
    cTokensList: [],
    contracts: {},
  },
};
export default StoreState;
