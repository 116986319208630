import { Fragment } from "react";
import { TableWrapper } from './tableWrapper';
import CoinIcon from "../../../../components/Common/Components/coinIcons";
import { millifyNumber } from '../../../../utils/common';

export function SupplyListing(props) {
    let data = props.supplyList;
    let isSupply =
        props.supply && props.supply.value && props.supply.value ? true : false;
    let types = [];
    if (props.afterType !== "advanceBoost" && props.afterType !== "advanceRepay") {
        if (props.selectedToken) {
            types.push(props.selectedToken.type)
        }
    } else if (props.afterType === "advanceBoost") {
        if (props.selectedToken) {
            types.push(props.selectedToken.supplytype)
        }
        isSupply = true;
    } else if (props.afterType === "advanceRepay") {
        if (props.selectedToken) {
            types.push(props.selectedToken.repaytype)
        }
        isSupply = true;
    }
    let value = props.selectedToken ? props.selectedToken.value : "";
    let afterSupplyCToken = [];
    let token = {};
    const getValues = (val1, val2, isMilify) => {
        let v1 = Number(val1);
        let v2 = Number(val2);
        let result = 0;
        if (isSupply) {
            result = v1 + v2;
        } else {
            result = v1 - v2;
        }
        if (isMilify && !isNaN(result)) {
            result = millifyNumber(result, {
                precision: 2,
                lowercase: false,
            });
        }
        return result;
    };
    if (props.isShowAfter) {
        types.forEach(type => {
            let cTokenIndex = data.findIndex(
                (x) => (x.symbol[0] === "c" ? x.symbol.substring(1) : x.symbol) === type
            );
            if (cTokenIndex !== -1) {
                token = { ...data[cTokenIndex] };
                token["symbol"] =
                    token.symbol[0] === "c"
                        ? token.symbol.substring(1)
                        : token.symbol;
                token["supply_balance_underlying"] =
                    token.supply_balance_underlying &&
                        token.supply_balance_underlying.value
                        ? token.supply_balance_underlying.value
                        : 0;
                token["lifetime_supply_interest_accrued"] =
                    token.lifetime_supply_interest_accrued &&
                        token.lifetime_supply_interest_accrued.value
                        ? token.lifetime_supply_interest_accrued.value
                        : 0;
                afterSupplyCToken.push(token)
            } else {
                cTokenIndex = props.marketInfoList.findIndex(
                    (x) => (x.symbol[0] === "c" ? x.symbol.substring(1) : x.symbol) === type
                );
                if (cTokenIndex !== -1) {
                    data = props.marketInfoList;
                    token = {
                        address: data[cTokenIndex].token_address,
                        borrow_balance_underlying: {
                            value: "0.0"
                        },
                        lifetime_borrow_interest_accrued: {
                            value: "0.0"
                        },
                        lifetime_supply_interest_accrued: 0,
                        safe_withdraw_amount_underlying: {
                            value: "0.0"
                        },
                        supply_balance_underlying: 0,
                        symbol: data[cTokenIndex].underlying_symbol,
                        tokenPrice: data[cTokenIndex].pricing.price
                    };
                    afterSupplyCToken.push(token)
                }
            }
        });
    }
    return (
        <TableWrapper
            apiCallStatus={props.apiCallStatus}
            label="Supplying"
            headers={[
                "Asset",
                "Supplied",
                "Supplied($)",
                "Earned",
                "Earned($)",
                "Collateral",
            ]}
            data={data}
            isAfter={props.isShowAfter}
            apiName="getSupplyListing"
        >
            {!props.isShowAfter &&
                data.map((item, index) => {
                    let symbol =
                        item.symbol[0] === "c" ? item.symbol.substring(1) : item.symbol;
                    let supply_balance_underlying =
                        item.supply_balance_underlying &&
                            item.supply_balance_underlying.value
                            ? item.supply_balance_underlying.value
                            : 0;
                    let lifetime_supply_interest_accrued =
                        item.lifetime_supply_interest_accrued &&
                            item.lifetime_supply_interest_accrued.value
                            ? item.lifetime_supply_interest_accrued.value.toFixedNoRounding(2)
                            : 0;
                    let isEnable = false;
                    if (props.enableSupplyTokens && props.enableSupplyTokens.length > 0) {
                        isEnable = props.enableSupplyTokens.findIndex(address => Number(address) === Number(item.address)) !== -1;
                    }
                    return supply_balance_underlying > 0 ? (
                        <tr className="first-level" key={index}>
                            <td>
                                <CoinIcon name={symbol} />
                                {symbol}
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={supply_balance_underlying}>
                                        {`${supply_balance_underlying.toFixedNoRounding(
                                            2
                                        )} ${symbol}`}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={supply_balance_underlying * item.tokenPrice}>
                                        ${" "}
                                        {millifyNumber(supply_balance_underlying * item.tokenPrice, {
                                            precision: 2,
                                            lowercase: false,
                                        })}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            item.lifetime_supply_interest_accrued &&
                                                item.lifetime_supply_interest_accrued.value
                                                ? item.lifetime_supply_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {`${item.lifetime_supply_interest_accrued &&
                                            item.lifetime_supply_interest_accrued.value
                                            ? item.lifetime_supply_interest_accrued.value.toFixedNoRounding(
                                                2
                                            )
                                            : "0"
                                            } ${symbol}`}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            lifetime_supply_interest_accrued * item.tokenPrice
                                        }
                                    >
                                        ${" "}
                                        {(
                                            lifetime_supply_interest_accrued * item.tokenPrice
                                        ).toFixedNoRounding(2)}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <button
                                    onClick={() => props.enablerHandler(item.address, symbol, isEnable)}
                                    className="button blue-gradient"
                                    disabled={!props.allowTransaction}
                                >
                                    {isEnable ? "Disable" : "Enable"}
                                </button>
                            </td>
                        </tr>
                    ) : (
                        <tr key={index}></tr>
                    );
                })}
            {props.isShowAfter ? (
                afterSupplyCToken.map((supplyCToken, index) => {
                    return <Fragment key={index}>
                        <tr className="first-level">
                            <td>
                                <CoinIcon
                                    name={supplyCToken.symbol ? supplyCToken.symbol : ''}
                                />
                                {supplyCToken.symbol ? supplyCToken.symbol : ''}
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={supplyCToken.supply_balance_underlying}>
                                        {supplyCToken.supply_balance_underlying
                                            ? `${supplyCToken.supply_balance_underlying.toFixedNoRounding(
                                                2
                                            )} ${supplyCToken.symbol}`
                                            : 0}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken.supply_balance_underlying
                                                ? supplyCToken.supply_balance_underlying *
                                                supplyCToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {supplyCToken.supply_balance_underlying
                                            ? millifyNumber(
                                                supplyCToken.supply_balance_underlying *
                                                supplyCToken.tokenPrice,
                                                {
                                                    precision: 2,
                                                    lowercase: false,
                                                }
                                            )
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken.lifetime_supply_interest_accrued &&
                                                supplyCToken.lifetime_supply_interest_accrued.value
                                                ? supplyCToken.lifetime_supply_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {supplyCToken.lifetime_supply_interest_accrued
                                            ? `${supplyCToken.lifetime_supply_interest_accrued &&
                                                supplyCToken.lifetime_supply_interest_accrued.value
                                                ? supplyCToken.lifetime_supply_interest_accrued.value.toFixedNoRounding(
                                                    2
                                                )
                                                : "0"
                                            } ${supplyCToken.symbol}`
                                            : 0}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken.lifetime_supply_interest_accrued
                                                ? supplyCToken.lifetime_supply_interest_accrued *
                                                supplyCToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {supplyCToken.lifetime_supply_interest_accrued
                                            ? (
                                                supplyCToken.lifetime_supply_interest_accrued *
                                                supplyCToken.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <button disabled={true} className="button blue-gradient">
                                    {props.isEnable ? "Disable" : "Enable"}
                                </button>
                            </td>
                        </tr>
                        <tr className="first-level">
                            <td> After:</td>
                            <td>
                                <div>
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={getValues(
                                            supplyCToken.supply_balance_underlying
                                                ? supplyCToken.supply_balance_underlying
                                                : 0,
                                            value
                                        )}
                                    >
                                        {`${getValues(
                                            supplyCToken.supply_balance_underlying
                                                ? supplyCToken.supply_balance_underlying
                                                : 0,
                                            value,
                                            "millify"
                                        )} ${supplyCToken.symbol ? supplyCToken.symbol : ''}`}{" "}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken
                                                ? getValues(
                                                    supplyCToken ? (supplyCToken.supply_balance_underlying *
                                                        supplyCToken.tokenPrice) : 0,
                                                    supplyCToken.tokenPrice * value
                                                )
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {supplyCToken
                                            ? getValues(
                                                supplyCToken ? (supplyCToken.supply_balance_underlying *
                                                    supplyCToken.tokenPrice) : 0,
                                                supplyCToken.tokenPrice * value,
                                                "millify"
                                            )
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div>
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken.lifetime_supply_interest_accrued &&
                                                supplyCToken.lifetime_supply_interest_accrued.value
                                                ? supplyCToken.lifetime_supply_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {supplyCToken.lifetime_supply_interest_accrued
                                            ? `${supplyCToken.lifetime_supply_interest_accrued &&
                                                supplyCToken.lifetime_supply_interest_accrued.value
                                                ? supplyCToken.lifetime_supply_interest_accrued.value.toFixedNoRounding(
                                                    2
                                                )
                                                : "0"
                                            } ${supplyCToken.symbol}`
                                            : 0}{" "}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            supplyCToken.lifetime_supply_interest_accrued
                                                ? supplyCToken.lifetime_supply_interest_accrued *
                                                supplyCToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {supplyCToken.lifetime_supply_interest_accrued
                                            ? (
                                                supplyCToken.lifetime_supply_interest_accrued *
                                                supplyCToken.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}{" "}
                                    </span>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </Fragment>
                })
            ) : (
                <tr></tr>
            )}
        </TableWrapper>
    );
}