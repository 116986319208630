export function SmartWallet(props) {
  return (
    <div className="user-wallet-tab-wrapper manage-user-wallet-blocker">
      <div className="title">Create Smart Wallet</div>
      <div className="description">
        In order to use our advanced Compound features you first need to create
        a Smart wallet - your personal smart contract wallet that will enable
        usage of advanced features. Please note that by using the Smart wallet
        you lose compatibility with other apps (e.g. Compound's default app).
        </div>
      <button
        type="button"
        className="button "
        disabled={!props.allowTransaction}
        onClick={props.createDSProxyWallet}
      >
        Create
        </button>
    </div>
  );
}