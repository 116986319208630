import { Fragment, useState } from "react";
import DashboardLayout from "../../../layout/DashboardLayout/dashboardLayout";
import { connect } from 'react-redux';
import { getPortFolioStats } from "../ApiCalls/transactionHistory";
import { useEffect } from "react";
import { network } from "../../../constants/constant";
import { getCookie } from "../../../utils/cookies";
import { ClearApiByNameAction } from "../../../components/ApiCallStatus/Actions/action";
import ReactTooltip from "react-tooltip";
function Portfolio(props) {
  const [state, setState] = useState({
    accountId: getCookie("accountId"),
    network,
    protocols: [{ label: "Compound", key: "compound" }, { label: "Aave v1", key: "aavev1" }, { label: "Aave v2", key: "aavev2" }]
  })
  useEffect(() => {
    props.getPortFolioStats(state.network, state.accountId)
  }, []);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props.apiCallStatus.apiCallFor])
  const links = [
    {
      name: `Portfolio`,
      url: "/"
    }, {
      name: `History</span><span className="badge">(BETA)</span>`,
      // url: "/#"
      url: "/transaction-history"
    }
  ]
  return (
    <DashboardLayout name="Portfolio" links={links} wrapperClassName="portfolio-wrapper">
      <div className="width-container">
        <div className="dashboard-header-wrapper">
          <span>
            <span className="label">Address:</span>
            <div className="" style={{ display: 'inline', wordBreak: 'break-word' }}><span>{props.accountDetail.accountId}</span></div>
          </span>
        </div>
        <div className="main-section-wrapper">
          <div className="full-width-section balances-section StyledScroll">
            <div className="data-item-wrapper standard balance-wrapper highlighted active">
              <div className="label-value-wrapper">
                <div className="label-wrapper"><span className="label">Net worth</span>:</div>
                <div className="value-wrapper">
                  <span className="amount">
                    <div className="" style={{ display: 'inline' }}><span>$0</span></div>
                  </span>
                </div>
              </div>
              <div className="after-value">
                <div className="amount-wrapper">
                  <span className="after">
                    <span className="label">Assets:</span>
                    <div className="amount" style={{ display: 'inline' }}><span>$0</span></div>
                  </span>
                </div>
              </div>
              <div className="after-value">
                <div className="amount-wrapper">
                  <span className="after">
                    <span className="label">Debt:</span>
                    <div className="amount" style={{ display: 'inline' }}><span>$0</span></div>
                  </span>
                </div>
              </div>
            </div>
            <div className="token-balances-wrapper StyledScroll"></div>
          </div>
          <div className="main-detail-wrapper">
            {state.protocols.map((protocol, index) => {
              let data = {};
              let isLoading = props.apiCallStatus.isStarted.indexOf("getPortFolioStats") !== -1;
              if (props.portFolioStats[protocol.key]) {
                data = props.portFolioStats[protocol.key]
              }
              return <div className="compound-section-wrapper" key={index}>
                <h2>{protocol.label}</h2>
                <ProtocolAccountDetail type="Smart Wallet" {...data["smartWallet"]} isLoading={isLoading} />
                <ProtocolAccountDetail type="Account" {...data["account"]} isLoading={isLoading} />
              </div>
            })}
          </div>

        </div>
      </div>
      <ReactTooltip />
    </DashboardLayout>
  );
}

const ProtocolAccountDetail = (props) => {
  return <a className="padded-section highlighted " href="/">
    <DataItemWrapper>
      <div className="label-wrapper"><span className="label">Wallet Type :</span></div>
      <div className="value-wrapper">{props.type}</div>
    </DataItemWrapper>
    <DataItemWrapper>
      <div className="label-wrapper">
        <div className="ratio-circle" style={{ backgroundColor: 'rgb(55, 176, 110)' }}></div>
        <span className="label">Ratio:</span>
      </div>
      <div className="value-wrapper">
        <div className="" style={{ display: 'inline' }}><span>{props.isLoading ? "Loading..." : <Fragment>0<span className="symbol small">%</span></Fragment>}</span></div>
      </div>
    </DataItemWrapper>
    <DataItemWrapper>
      <div className="label-wrapper"><span className="label">Collateral:</span></div>
      <div className="value-wrapper">
        <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.supplyBalance ? props.supplyBalance : 0}>
          <div className="" style={{ display: 'inline' }}><span>{props.isLoading ? "Loading..." : <Fragment><span className="symbol false">$</span>{props.supplyBalance ? props.supplyBalance.toFixedNoRounding(2) : 0}</Fragment>}</span></div>
        </span>
      </div>
    </DataItemWrapper>
    <DataItemWrapper>
      <div className="label-wrapper"><span className="label">Debt:</span></div>
      <div className="value-wrapper">
        <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={props.borrowBalance ? props.borrowBalance : 0}>
          <div className="" style={{ display: 'inline' }}><span>{props.isLoading ? "Loading..." : <Fragment><span className="symbol false">$</span>{props.borrowBalance ? props.borrowBalance.toFixedNoRounding(2) : 0}</Fragment>}</span></div>
        </span>
      </div>
    </DataItemWrapper>
    <DataItemWrapper>
      <div className="label-wrapper"><span className="label">Automation</span>:</div>
      <div className="value-wrapper">Disabled</div>
    </DataItemWrapper>
  </a>
}
const DataItemWrapper = (props) => {
  return <div className="data-item-wrapper standard-smaller  ">
    <div className="label-value-wrapper">
      {props.children}
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => ({
  // Get api handler state
  apiCallStatus: state.apicallStatusReducer,
  //Get Account Detail 
  accountDetail: state.commonReducer.accountDetail,
  portFolioStats: state.portfolioReducer.portFolioStats
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPortFolioStats: (network, accountId) => dispatch(getPortFolioStats(network, accountId)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Portfolio);