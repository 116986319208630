import DashboardLayout from "../../../layout/DashboardLayout/dashboardLayout";
import { connect } from 'react-redux';
import { getTransactionHistory } from "../ApiCalls/transactionHistory";
import { useEffect } from "react";

const links = [
    {
        name: `Portfolio`,
        url: "/"
    }, {
        name: `History</span><span className="badge">(BETA)</span>`,
        // url: "/#"
        url: "/transaction-history"
    }
]

function History(props) {
    useEffect(() => {
        props.getTransactionHistory()
    }, [])
    return (
        <DashboardLayout name="Portfolio" links={links} >
            <div className="width-container">
                <div className="styled-table-wrapper assets-table-wrapper no-after">
                    <div className="styled-table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Block Number</th>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Transaction Hash</th>
                                    <th>Claim ID</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.transactionHistory.list.length > 0 ? props.transactionHistory.list.map((item, index) => {
                                    return <tr key={index} className="first-level">
                                        <td>{item.id}</td>
                                        <td>{item.type}</td>
                                        <td>{item.status}</td>
                                        <td>{item.blockNumber}</td>
                                        <td>{item.address}</td>
                                        <td>{item.amount}</td>
                                        <td>{item.transactionHash}</td>
                                        <td>{item.claimId}</td>
                                        <td>{item.createdAt}</td>
                                    </tr>
                                }) : <tr>
                                    <td className="text-center" colSpan="12">No Data Found</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </DashboardLayout>
    );
}

const mapStateToProps = (state, ownProps) => ({
    // Get api handler state
    apiCallStatus: state.apicallStatusReducer,
    //Get transaction list
    transactionHistory: state.portfolioReducer.transactionHistory
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // Get ransaction list Apicall
    getTransactionHistory: () => dispatch(getTransactionHistory())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
