import { getTransactionHistory,getPortFolioStats} from "./../Actions/action";
import InitialState from "../../../constants/initialReducersState";

export default function PortfolioReducer(state = InitialState.portfolio, action) {
  switch (action.type) {
    case getTransactionHistory:
      let s = Object.assign(state);
      s.transactionHistory = action.payload;
      return s;
      case getPortFolioStats:
        let s1 = Object.assign(state);
        s1.portFolioStats = action.payload;
        return s1;
    default:
      return state;
  }
}
