import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Account, AccountTabs } from "./accounts";
import {
  SmartWalletModal,
  CreateDSProxyModal
} from "../../../../components/Models/models";
import { connect } from "react-redux";
import { network } from "../../../../constants/constant";
import {
  getCompoundMarketPrice,
  getSupplyListing,
  getTokenPrices,
  getAccountSnapshotOfAllCTokens
} from "../../ApiCalls/compound";
import { SmartWallet } from './createSmartWallet';
import { MarketInfoListing } from './marketInfoListing';
import { SupplyListing } from './supplyingListing';
import { BorrowListing } from './borrowListing';
import { ClearApiByNameAction, ApiRequestedAction } from "../../../../components/ApiCallStatus/Actions/action";
import { clearContractsWithSnapshotAction } from "../../Actions/action";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../../../utils/cookies";
import { readContractByName } from "../../../../utils/contracts";
import Loader from "../../../../components/Common/Components/loader";
import { toast } from "react-toastify";
import TransactionToast from "../../../../components/Common/Components/toastAlertMsg";
import ReactTooltip from "react-tooltip";
import { getAllEstimationGasPrice } from '../../../../utils/contracts';
import useInterval from "use-interval";
import _ from "underscore";
import { BigNumber } from "bignumber.js";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import $ from "jquery";

function Manage(props) {
  const [smartWalletAddress, setSmartWalletAddress] = useState("");
  const [compReward, setCompReward] = useState({
    account: {
      claimComp: 0,
      compBalance: 0
    },
    smartWallet: {
      claimComp: 0,
      compBalance: 0
    },
    isLoading: false
  });
  const [compoundType, setCompoundType] = useState(getCookie("compoundType") === "" ? "account" : getCookie("compoundType"));
  const [checkingSmaerWallet, setCheckingSmaerWallet] = useState(true);
  const [startInterval, updateInterval] = useState(false);
  const [marketInfoList, updateMarketInfoList] = useState([]);
  const [supplyList, updateSupplyList] = useState([]);
  const [borrowList, updateBorrowList] = useState([]);
  const [isFirstLoad, updateFirstLoad] = useState(true);
  const [state, setState] = useState({
    isValidNetwork: props.accountDetail.currency === "ETH",
    currency: props.accountDetail.currency,
    protocol: "compound",
    showModal: false,
    modalType: "",
    // marketInfoList: [],
    isEnable: true,
    accountId: getCookie("accountId"),
    network: network,
    supplyBalance: 0,
    borrowBalance: 0,
    borrowLimit: 0,
    netAPY: 0,
    safetyRatio: 0,
    leftToBorrow: 0,
    afterType: "",
    enableSupplyTokens: [],
    isAdvance: "",
    advanceRepay: {
      value: "",
      withDrawtype: "",
      withDrawContractAddress: "",
      repaytype: "",
      repayContractAddress: "",
      balance: 0,
    },
    advanceBoost: {
      value: "",
      borrowtype: "",
      borrowContractAddress: "",
      supplytype: "",
      supplyContractAddress: "",
      balance: 0,
    },
    supply: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    withDraw: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    borrow: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    repay: {
      value: "",
      type: "",
      contractAddress: "",
      balance: 0,
    },
    isCallAccountSnapshot: false,
    activeAccountTab: "advanced"
  });
  let transactionDetail = {
    status: "",
    toastId: "",
    progressBarToastId: "",
  };
  let createDSProxyWalletToastId;
  useEffect(() => {
    if (props.accountDetail.connectedWallet !== "") {
      setState({
        ...state,
        isValidNetwork: props.accountDetail.currency === "ETH",
        currency: props.accountDetail.currency,
        accountId: getCookie("accountId")
      })
      props.getTokenPrices();
      props.getCompoundMarketPrice(state.network);
      checkSmartWallet();
    }
  }, [props.accountDetail.connectedWallet]);
  useEffect(() => {
    if (state.isValidNetwork && state.accountId !== props.accountDetail.accountId) {
      updateFirstLoad(false);
      setSmartWalletAddress("");
      props.clearContractsWithSnapshotAction();
      checkSmartWallet(props.accountDetail.accountId);
      props.getTokenPrices();
      props.getCompoundMarketPrice(state.network);
      resetState(false);
      updateMarketInfoList([]);
      updateSupplyList([]);
      updateBorrowList([]);
      getSupplyListingData(props.accountDetail.accountId);
    }
  }, [props.accountDetail.accountId]);

  useEffect(() => {
    if (state.isValidNetwork &&
      state.accountId === props.accountDetail.accountId &&
      state.supply.type === "ETH"
    ) {
      let stateObj = { ...state };
      let balance = getCookie("balance");
      balance = balance.replace(/ .*/, "");
      stateObj["supply"]["balance"] = balance;
      stateObj["supply"]["value"] = "";
      setState(stateObj);
    }
  }, [getCookie("balance")]);

  useEffect(() => {
    if (state.isValidNetwork && !checkingSmaerWallet) {
      resetState();
      props.clearContractsWithSnapshotAction();
      updateMarketInfoList([]);
      updateSupplyList([]);
      updateBorrowList([]);
      getSupplyListingData();
      updateInterval(true);
    }
  }, [compoundType, checkingSmaerWallet]);
  useInterval(
    () => {
      if (state.isValidNetwork && !checkingSmaerWallet && props.marketInfoList.length > 0) {
        updateInterval(false)
        getAccountSnapshotOfAllCTokens();
        calculateCompReward();
      }
    },
    startInterval ? 100 : null
  );
  useEffect(() => {
    if (state.isValidNetwork && props.accountDetail.currency !== state.currency
    ) {
      let stateObj = { ...state };
      stateObj["currency"] = props.accountDetail.currency;
      stateObj["isValidNetwork"] = props.accountDetail.currency === "ETH";
      setState(stateObj);
      if (stateObj["isValidNetwork"]) {
        props.getTokenPrices();
        props.getCompoundMarketPrice(state.network);
        checkSmartWallet();
      }
    }
  }, [props.accountDetail.currency]);
  useEffect(async () => {
    ReactTooltip.rebuild();
    if (state.isValidNetwork &&
      supplyList.length === 0 &&
      props.supplyList.length > 0 &&
      props.marketInfoList.length > 0 &&
      props.tokenPriceList.length > 0 &&
      Object.size(props.contracts) > 0
    ) {
      let data = props.supplyList;
      let newSupplyList = [];
      let newBorrowList = [];
      let supplyBalance = 0;
      let borrowLimit = 0;
      let safetyRatio = 0;
      let borrowBalance = 0;
      let leftToBorrow = 0;
      let suppliedRevenue = 0;
      let borrowRevenue = 0;
      let revenue = 0;
      let netAPY = 0;
      let withDraw = { ...state.withDraw };
      let advanceRepay = { ...state.advanceRepay };
      let repay = { ...state.repay };
      let enableSupplyTokens = await checkMembership();
      if (data.length > 0) {
        data.forEach((element, index) => {
          let defaultCollateralEthValue = props.accountDetail.connectedWallet === "ropsten" ? 0.3750162265020984107223919368 : 0;
          let total_collateral_value_in_eth =
            data[index]["total_collateral_value_in_eth"] &&
              data[index]["total_collateral_value_in_eth"]["value"] !== "0"
              ? data[index]["total_collateral_value_in_eth"]["value"]
              : defaultCollateralEthValue;
          let total_borrow_value_in_eth =
            data[index]["total_borrow_value_in_eth"] &&
              data[index]["total_borrow_value_in_eth"]["value"]
              ? data[index]["total_borrow_value_in_eth"]["value"]
              : 0;
          let ethTokenPrice = 0;
          props.marketInfoList.forEach((childElem, j) => {
            let symbol =
              childElem.underlying_symbol[0] === "c"
                ? childElem.underlying_symbol.substring(1)
                : childElem.underlying_symbol;
            let i = props.tokenPriceList.findIndex((x) => x.symbol === symbol);
            let supplyTokenIndex = element.tokens.findIndex(x => x.symbol === childElem.underlying_symbol);
            let tokenPrice = i !== -1 ? props.tokenPriceList[i].price : 0;
            let obj = {}
            if (i !== -1) {
              let supply_balance_underlying = 0;
              let borrow_balance_underlying = 0;
              let lifetime_borrow_interest_accrued = 0;
              if (supplyTokenIndex !== -1) {
                supply_balance_underlying =
                  data[index]["tokens"][supplyTokenIndex]["supply_balance_underlying"] &&
                    data[index]["tokens"][supplyTokenIndex]["supply_balance_underlying"]["value"]
                    ? data[index]["tokens"][supplyTokenIndex]["supply_balance_underlying"]["value"]
                    : 0;
                borrow_balance_underlying =
                  data[index]["tokens"][supplyTokenIndex]["borrow_balance_underlying"] &&
                    data[index]["tokens"][supplyTokenIndex]["borrow_balance_underlying"]["value"]
                    ? data[index]["tokens"][supplyTokenIndex]["borrow_balance_underlying"]["value"]
                    : 0;
                lifetime_borrow_interest_accrued =
                  data[index]["tokens"][supplyTokenIndex]["lifetime_borrow_interest_accrued"] &&
                    data[index]["tokens"][supplyTokenIndex]["lifetime_borrow_interest_accrued"][
                    "value"
                    ]
                    ? data[index]["tokens"][supplyTokenIndex]["lifetime_borrow_interest_accrued"][
                    "value"
                    ]
                    : 0;
                if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["supplyBalance"] > 0)) {
                  supply_balance_underlying = props.contracts[symbol]["snapshot"]["supplyBalance"];
                  data[index]["tokens"][supplyTokenIndex]["supply_balance_underlying"]["value"] = supply_balance_underlying;
                }
                if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["borrowBalance"] > 0)) {
                  borrow_balance_underlying = props.contracts[symbol]["snapshot"]["borrowBalance"];
                  data[index]["tokens"][supplyTokenIndex]["borrow_balance_underlying"]["value"] = borrow_balance_underlying
                }
                obj = data[index]["tokens"][supplyTokenIndex];
              } else {
                if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["supplyBalance"] > 0)) {
                  supply_balance_underlying = props.contracts[symbol]["snapshot"]["supplyBalance"];
                }
                if (props.contracts[symbol] && (props.contracts[symbol]["snapshot"]["borrowBalance"] > 0)) {
                  borrow_balance_underlying = props.contracts[symbol]["snapshot"]["borrowBalance"];
                }
                obj = {
                  address: childElem.token_address,
                  borrow_balance_underlying: {
                    value: borrow_balance_underlying
                  },
                  lifetime_borrow_interest_accrued: {
                    value: lifetime_borrow_interest_accrued
                  },
                  lifetime_supply_interest_accrued: {
                    value: "0.0"
                  },
                  safe_withdraw_amount_underlying: {
                    value: "0.0"
                  },
                  supply_balance_underlying: {
                    value: supply_balance_underlying
                  },
                  symbol: childElem.underlying_symbol
                }
              }
              obj["tokenPrice"] = tokenPrice;
              let sbalance =
                props.tokenPriceList[i].price * supply_balance_underlying;
              let bbalance =
                props.tokenPriceList[i].price *
                (borrow_balance_underlying - lifetime_borrow_interest_accrued);
              supplyBalance += Number(sbalance);
              borrowBalance += Number(bbalance);
              let borrowRate = Number(childElem.borrow_rate.value) * 100;
              let supplyRate = Number(childElem.supply_rate.value) * 100;
              if (supplyRate !== 0 && sbalance !== 0) {
                suppliedRevenue = + Number(sbalance) * supplyRate;
              }
              if (borrowRate !== 0 && bbalance !== 0) {
                borrowRevenue = + Number(bbalance) * borrowRate;
              }
              if (supply_balance_underlying > 0 && enableSupplyTokens.findIndex(address => Number(address) === Number(childElem.token_address)) !== -1) {
                borrowLimit += sbalance * childElem.collateral_factor.value;
              }
              if (symbol === "ETH") {
                ethTokenPrice = tokenPrice;
                // borrowLimit = tokenPrice * total_collateral_value_in_eth;
                // safetyRatio =
                //   (borrowLimit / (total_borrow_value_in_eth * tokenPrice)) * 100;
              }
              let address = childElem.address ? childElem.address : childElem.token_address;
              if (withDraw["type"] === "" && supply_balance_underlying > 0) {
                withDraw["balance"] = supply_balance_underlying;
                withDraw["type"] = symbol;
                withDraw["contractAddress"] = address;
                advanceRepay["withDrawtype"] = symbol;
                advanceRepay["withDrawContractAddress"] = address;
              }
              if (repay["type"] === "" && borrow_balance_underlying > 0) {
                repay["balance"] =
                  borrow_balance_underlying - lifetime_borrow_interest_accrued;
                repay["type"] = symbol;
                repay["contractAddress"] = address;
                advanceRepay["repaytype"] = symbol;
                advanceRepay["repayContractAddress"] = address;
              }
              if (supply_balance_underlying > 0) {
                newSupplyList.push(obj)
              }
              if (borrow_balance_underlying > 0) {
                newBorrowList.push(obj)
              }
            }
          });
          revenue = suppliedRevenue - borrowRevenue;
          if (revenue === 0) {
            netAPY = 0;
          } else if (revenue > 0) {
            netAPY = (revenue / supplyBalance) * 100
          } else {
            netAPY = (revenue / borrowBalance) * 100
          }
          netAPY = netAPY
          total_borrow_value_in_eth = Number(total_borrow_value_in_eth);
          if (total_borrow_value_in_eth !== 0) {
            safetyRatio =
              (borrowLimit / (total_borrow_value_in_eth * ethTokenPrice)) * 100;
          }
        });
      }
      leftToBorrow = borrowLimit - borrowBalance;
      updateSupplyList(newSupplyList);
      updateBorrowList(newBorrowList);
      let stateObj = {
        ...state,
        supplyBalance,
        borrowBalance,
        borrowLimit,
        safetyRatio,
        leftToBorrow,
        enableSupplyTokens,
        withDraw,
        advanceRepay,
        repay,
        netAPY,
        isCallAccountSnapshot: true,
      };
      setState(stateObj);
      // props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
    } else if (state.isValidNetwork &&
      marketInfoList.length === 0 &&
      props.marketInfoList.length > 0 &&
      props.tokenPriceList.length > 0
    ) {
      let data = props.marketInfoList;
      data.forEach((element, index) => {
        let i = props.tokenPriceList.findIndex(
          (x) => x.symbol === element.underlying_symbol
        );
        let price = 0;
        if (i !== -1) {
          data[index]["pricing"] = props.tokenPriceList[i];
          price =
            props.tokenPriceList[i] && props.tokenPriceList[i].price
              ? props.tokenPriceList[i].price
              : 0;
        } else {
          data[index]["pricing"] = {};
        }
        element.marketSize =
          element.exchange_rate.value * element.total_supply.value * price;
      });
      let sortedData = _.sortBy(data, "marketSize");
      sortedData.reverse();
      let type = sortedData[0] ? sortedData[0].underlying_symbol : "";
      let contractAddress = sortedData[0] ? sortedData[0].token_address : "";
      let stateObj = { ...state };
      let balance = getCookie("balance");
      balance = balance.replace(/ .*/, "");
      let items = ["supply", "borrow", "advanceBoost"];
      items.forEach(async (element) => {
        if (element === "advanceBoost") {
          stateObj["advanceBoost"]["borrowtype"] = type
          stateObj["advanceBoost"]["supplytype"] = type
          stateObj["advanceBoost"]["borrowContractAddress"] = contractAddress
          stateObj["advanceBoost"]["supplyContractAddress"] = contractAddress
        } else {
          stateObj[element]["type"] = type;
          stateObj[element]["contractAddress"] = contractAddress;
        }
        if (element !== "borrow") {
          if (element === "supply" && type !== "ETH") {
            let reserve = `${state.network}Reserve`
            const { instance, decimal } = readContractByName(state.protocol,
              type,
              reserve
            );
            balance = await instance.methods.balanceOf(state.accountId).call();
            stateObj[element]["balance"] = new BigNumber(balance).div(
              new BigNumber(10).pow(decimal)).toNumber();
          } else {
            stateObj[element]["balance"] = type === "ETH" ? balance : 0;
          }
        }
      });
      // if (!state.isCallAccountSnapshot) {
      //   let accountId = ""
      //   if (compoundType === "smartWallet" && smartWalletAddress !== "") {
      //     accountId = smartWalletAddress
      //   } else {
      //     accountId = state.accountId;
      //   }
      //   stateObj.isCallAccountSnapshot = true;
      //   props.getAccountSnapshotOfAllCTokens(sortedData, state.network, accountId)
      // }
      setState(stateObj);
      updateMarketInfoList(sortedData);
      // props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
    } else if (state.isValidNetwork && !checkingSmaerWallet &&
      props.apiCallStatus.apiCallFor === "updateTokenPrice" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed && Object.size(props.contracts) > 0
    ) {
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      let marketList = props.marketInfoList;
      let data = props.supplyList;
      let supplyBalance = 0;
      let borrowLimit = 0;
      let borrowBalance = 0;
      let leftToBorrow = 0;
      // let suppliedRevenue = 0;
      // let borrowRevenue = 0;
      // let revenue = 0;
      // let netAPY = 0;
      let supplyData = supplyList;
      let borrowData = borrowList;
      let defaultCollateralEthValue = props.accountDetail.connectedWallet === "ropsten" ? 0.3750162265020984107223919368 : 0;
      let total_collateral_value_in_eth =
        props.supplyList[0] && props.supplyList[0]["total_collateral_value_in_eth"] &&
          props.supplyList[0]["total_collateral_value_in_eth"]["value"] !== "0"
          ? props.supplyList[0]["total_collateral_value_in_eth"]["value"]
          : defaultCollateralEthValue;
      marketList.forEach((element, index) => {
        let i = props.tokenPriceList.findIndex(
          (x) => x.symbol === element.underlying_symbol
        );
        let price = 0;
        if (i !== -1) {
          marketList[index]["pricing"] = props.tokenPriceList[i];
          price =
            props.tokenPriceList[i] && props.tokenPriceList[i].price
              ? props.tokenPriceList[i].price
              : 0;
          // if (element.underlying_symbol === "ETH") {
          //   borrowLimit = price * total_collateral_value_in_eth;
          // }
        } else {
          marketList[index]["pricing"] = {};
        }
        element.marketSize =
          element.exchange_rate.value * element.total_supply.value * price;
      });
      let sortedMarketList = _.sortBy(marketList, "marketSize");
      sortedMarketList.reverse();

      supplyList.forEach((childElem, j) => {
        let symbol =
          childElem.symbol[0] === "c"
            ? childElem.symbol.substring(1)
            : childElem.symbol;
        let i = props.tokenPriceList.findIndex((x) => x.symbol === symbol);
        if (i !== -1) {
          let tokenPrice = props.tokenPriceList[i].price;
          supplyData[j]["tokenPrice"] = tokenPrice;
          let supply_balance_underlying =
            supplyData[j]["supply_balance_underlying"] &&
              supplyData[j]["supply_balance_underlying"][
              "value"
              ]
              ? supplyData[j]["supply_balance_underlying"][
              "value"
              ]
              : 0;
          let sbalance =
            props.tokenPriceList[i].price * supply_balance_underlying;
          supplyBalance += sbalance;
          if (supply_balance_underlying > 0) {
            let collateralIndex = sortedMarketList.findIndex((x) => x.underlying_symbol === symbol);
            if (collateralIndex !== -1) {
              borrowLimit += sbalance * sortedMarketList[collateralIndex].collateral_factor.value;

            }
          }
        }
      });
      borrowList.forEach((childElem, j) => {
        let symbol =
          childElem.symbol[0] === "c"
            ? childElem.symbol.substring(1)
            : childElem.symbol;
        let i = props.tokenPriceList.findIndex((x) => x.symbol === symbol);
        if (i !== -1) {
          let tokenPrice = props.tokenPriceList[i].price;
          borrowList[j]["tokenPrice"] = tokenPrice;
          let borrow_balance_underlying =
            borrowList[j]["borrow_balance_underlying"] &&
              borrowList[j]["borrow_balance_underlying"][
              "value"
              ]
              ? borrowList[j]["borrow_balance_underlying"][
              "value"
              ]
              : 0;
          let lifetime_borrow_interest_accrued =
            borrowList[j][
              "lifetime_borrow_interest_accrued"
            ] &&
              borrowList[j][
              "lifetime_borrow_interest_accrued"
              ]["value"]
              ? borrowList[j][
              "lifetime_borrow_interest_accrued"
              ]["value"]
              : 0;
          let bbalance =
            props.tokenPriceList[i].price *
            (borrow_balance_underlying - lifetime_borrow_interest_accrued);
          borrowBalance += bbalance;
        }
      });
      // revenue = suppliedRevenue - borrowRevenue;
      // if (revenue === 0) {
      //   netAPY = 0;
      // } else if (revenue > 0) {
      //   netAPY = (revenue / supplyBalance) * 100
      // } else {
      //   netAPY = (revenue / borrowBalance) * 100
      // }
      leftToBorrow = borrowLimit - borrowBalance;
      updateSupplyList(supplyData);
      updateBorrowList(borrowData);
      updateMarketInfoList(sortedMarketList);
      let stateObj = {
        ...state,
        supplyBalance,
        borrowBalance,
        borrowLimit,
        leftToBorrow,
      };
      setState(stateObj);
    }
  }, [props, checkingSmaerWallet, compoundType]);

  useEffect(() => {
    if (state.isValidNetwork &&
      state.leftToBorrow !== 0 &&
      state.borrow.balance === 0 &&
      state.borrow.type !== ""
    ) {
      let borrow = { ...state.borrow };
      let i = props.tokenPriceList.findIndex(
        (x) => x.symbol === state.borrow.type
      );
      if (i !== -1) {
        let tokenPrice = props.tokenPriceList[i].price;
        borrow["balance"] = state.leftToBorrow / tokenPrice;
        setState({
          ...state,
          borrow,
        });
      }
    }
  }, [state]);

  const calculateCompReward = async () => {
    setCompReward({
      ...compReward,
      isLoading: true
    });
    let { instance } = readContractByName(state.protocol, "Comptroller", state.network);
    let compContract = readContractByName(state.protocol, "COMP", state.network);
    let promises = [calculateCompPromise(instance, compContract, state.accountId, "account")];
    if (smartWalletAddress !== "") {
      promises.push(calculateCompPromise(instance, compContract, smartWalletAddress, "smartWallet"))
    }
    Promise.all(promises)
      .then((response) => {
        let obj = { ...compReward };
        if (response[0]) {
          obj["account"] = response[0]
        }
        if (response[1]) {
          obj["smartWallet"] = response[1]
        }
        obj["isLoading"] = false;
        setCompReward(obj);
      })
      .catch((error) => {
        setCompReward({
          ...compReward,
          isLoading: false
        });
        console.log(error)
      });
  }
  const calculateCompPromise = (instance, compContract, accountId, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        let compBalance = await compContract.instance.methods.balanceOf(accountId).call();
        let compAccrued = await instance.methods.compAccrued(accountId).call();
        compBalance = new BigNumber(compBalance);
        compBalance = compBalance.dividedBy("1e" + compContract.decimal).toNumber();
        let tokenPromises = props.marketInfoList.map(async (item) => {
          return getTokenReward(item, instance, accountId)
        })
        let tokenClaimCompEarned = await Promise.all(
          tokenPromises
        );
        let totalClaimComp = new BigNumber(0);
        tokenClaimCompEarned.forEach(element => {
          totalClaimComp = totalClaimComp.plus(element)
        });
        totalClaimComp = totalClaimComp.plus(compAccrued).dividedBy(1e18).dp(8, 1).toString(10);
        resolve({ claimComp: totalClaimComp, compBalance: compBalance });
      } catch (error) {
        reject(error);
      }
    })
  }
  const getTokenReward = async (item, instance, accountId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let symbol = item.underlying_symbol[0] === "c" ? item.underlying_symbol.substring(1) : item.underlying_symbol;
        let tokenAddress = item.token_address;
        const cContract = readContractByName(state.protocol, symbol, state.network, item.token_address);
        let [
          supplyState,
          supplierIndex,
          supplierTokens,
          borrowState,
          borrowerIndex,
          borrowBalanceStored,
          borrowIndex,
        ] = await Promise.all([
          await callInstanceMethodWithPromise(instance.methods.compSupplyState(tokenAddress)),
          await callInstanceMethodWithPromise(instance.methods.compSupplierIndex(
            tokenAddress,
            accountId,
          )),
          await callInstanceMethodWithPromise(cContract.instance.methods.balanceOf(accountId)),
          await callInstanceMethodWithPromise(instance.methods.compBorrowState(tokenAddress)),
          await callInstanceMethodWithPromise(instance.methods.compBorrowerIndex(tokenAddress,
            accountId,
          )),
          await callInstanceMethodWithPromise(cContract.instance.methods.borrowBalanceStored(accountId)),
          await callInstanceMethodWithPromise(cContract.instance.methods.borrowIndex()),
        ]);
        const supplyIndex = supplyState.index;
        if (+supplierIndex === 0 && +supplyIndex > 0) {
          // supplierIndex = venusInitialIndex;
        }
        let deltaIndex = new BigNumber(supplyIndex).minus(supplierIndex);
        const supplierDelta = new BigNumber(supplierTokens)
          .multipliedBy(deltaIndex)
          .dividedBy(1e36);
        let compEarned = new BigNumber(0);
        compEarned = compEarned.plus(supplierDelta);
        if (+borrowerIndex > 0) {
          deltaIndex = new BigNumber(borrowState.index).minus(borrowerIndex);
          const borrowerAmount = new BigNumber(borrowBalanceStored)
            .multipliedBy(1e18)
            .dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
          compEarned = compEarned.plus(borrowerDelta);
        }
        resolve(compEarned)
      } catch (error) {
        reject(error);
      }
    })
  }
  const callInstanceMethodWithPromise = (instanceMethod) => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await instanceMethod.call();
        resolve(data);
      } catch (error) {
        reject(error);
      }
    })
  }
  const getAccountSnapshotOfAllCTokens = () => {
    let accountId = "";
    if (compoundType === "smartWallet" && smartWalletAddress !== "") {
      accountId = smartWalletAddress
    } else {
      accountId = state.accountId;
    }
    console.log("Account Id", accountId)
    props.getAccountSnapshotOfAllCTokens(props.marketInfoList, state.network, accountId);
  }
  const resetState = (isCallAccountSnapshot) => {
    setState({
      ...state,
      showModal: false,
      modalType: "",
      supplyBalance: 0,
      borrowBalance: 0,
      borrowLimit: 0,
      leftToBorrow: 0,
      safetyRatio: 0,
      afterType: "",
      advanceRepay: {
        value: "",
        withDrawtype: "",
        withDrawContractAddress: "",
        repaytype: "",
        repayContractAddress: "",
        balance: 0,
      },
      advanceBoost: {
        value: "",
        borrowtype: "",
        borrowContractAddress: "",
        supplytype: "",
        supplyContractAddress: "",
        balance: 0,
      },
      supply: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      withDraw: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      borrow: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      repay: {
        value: "",
        type: "",
        contractAddress: "",
        balance: 0,
      },
      isCallAccountSnapshot: !isCallAccountSnapshot ? isCallAccountSnapshot : state.isCallAccountSnapshot,
      accountId:
        state.accountId !== props.accountDetail.accountId
          ? props.accountDetail.accountId
          : state.accountId,
    });
  };
  const getSupplyListingData = (accountId) => {
    if (!checkingSmaerWallet) {
      let accountId = ""
      if (compoundType === "smartWallet" && smartWalletAddress !== "") {
        accountId = smartWalletAddress
      } else {
        accountId = accountId ? accountId : state.accountId;
      }
      props.getSupplyListing(accountId, state.network);
    }
  };
  const compundTypeHandler = (compoundType) => {
    setCookie("compoundType", compoundType);
    setCompoundType(compoundType);
  };
  const handleModalChange = (showModal, modalType, isAdvance) => {
    let obj = {
      ...state,
      showModal,
      modalType,
      isAdvance: isAdvance ? isAdvance : ""
    }
    setState(obj);
  };
  const checkMembership = async (data) => {
    try {
      const { instance } = readContractByName(state.protocol,
        "Comptroller",
        state.network
      );
      var result = await instance.methods
        .getAssetsIn(state.accountId)
        .call();
      return result;
    } catch (error) {
      console.error(error);
    }
  };
  const transHandler = (gasPrice, gasLimit, instance, isEnable, address) => {
    let toastId = transactionDetail.toastId;
    let transactionStatus = transactionDetail.status;
    try {
      let method;

      if (!isEnable) {
        method = instance.methods.enterMarkets([address, state.accountId])
      } else {
        method = instance.methods.exitMarket(address)
      }
      let isTransactionSuccessfull = false;
      method.send({
        from: state.accountId,
        gasPrice: gasPrice,
        gas: gasLimit
      }).on("transactionHash", (hash) => {
        console.log("hash", hash);
        transactionStatus = "inprogress";
        toast.update(toastId, {
          render: () => (
            <TransactionToast
              label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
              hash={hash}
              status={transactionStatus}
            />
          ),
        });

      })
        .on("confirmation", async (confirmationNumber, receipt) => {
          console.log("confirmationNumber", confirmationNumber);
          console.log("receipt", receipt);
          if (confirmationNumber === 0 || !isTransactionSuccessfull) {
            isTransactionSuccessfull = true;
            transactionStatus = "confirm";
            toast.update(toastId, {
              render: () => (
                <TransactionToast
                  label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
                  status={transactionStatus}
                />
              ),
            });
            let enableSupplyTokens = await checkMembership();
            setState({
              ...state,
              enableSupplyTokens,
            });
          }
        })
        .on("error", (error) => {
          console.log(error);
          transactionStatus = "error";
          toast.update(toastId, {
            render: () => (
              <TransactionToast
                label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
                message={error.message}
                status={transactionStatus}
              />
            ),
          });
        });
    } catch (e) {
      toast.update(toastId, {
        render: () => (
          <TransactionToast message={e.message} status={"error"} />
        ),
      });
    }

  };
  const enablerHandler = async (address, symbol, isEnable) => {
    let USD = 0;
    let tokenIndex = marketInfoList.findIndex(
      (x) => x.underlying_symbol === symbol
    );
    if (tokenIndex !== -1) {
      let cToken = marketInfoList[tokenIndex];
      USD = cToken.pricing.price ? parseFloat(cToken.pricing.price) : 0;
    }
    const { instance } = readContractByName(state.protocol,
      "Comptroller",
      state.network
    );
    let gasLimit = {};
    let transactionStatus = "creating";
    let data = await getAllEstimationGasPrice(isEnable ? instance.methods
      .exitMarket(address) : instance.methods
        .enterMarkets([address, state.accountId]), {}, props.currentGasPrice, state.accountId);
    if (!data.isError) {
      gasLimit = data.gasPrice;
    }
    let toastId = toast(<TransactionToast
      status={transactionStatus}
      USD={USD}
      gasLimit={gasLimit}
      gasPrice={props.currentGasPrice}
      handleSubmit={(gasPrice, gasLimit) => transHandler(gasPrice, gasLimit, instance, isEnable, address)}
      label={`${isEnable ? "Disable" : "Enable"} Supply Token`}
    />);
    transactionDetail = {
      status: transactionStatus,
      toastId,
    };
  };
  const handleTypeChange = async (type, address, id, name) => {
    let balance = 0;
    let obj = { ...state[id] };
    obj["value"] = "";
    obj["balance"] = balance;
    if (name) {
      obj[name + "type"] = type;
      obj[name + "ContractAddress"] = address;
    } else {
      obj["type"] = type;
      obj["contractAddress"] = address;
    }
    setState({
      ...state,
      [id]: obj,
    });
    UpdateSupplyingTable("", id);
    if (id === "borrow") {
      let index = marketInfoList.findIndex((x) => x.underlying_symbol === type);
      if (index !== -1) {
        let tokenPrice = marketInfoList[index]["pricing"]["price"];
        balance = state.leftToBorrow / tokenPrice;
      }
    } else if (id === "withDraw") {
      let data = supplyList;
      let index = data.findIndex((x) =>
        x.symbol[0] === "c" ? x.symbol.substring(1) === type : x.symbol === type
      );
      if (index !== -1) {
        balance =
          data[index]["supply_balance_underlying"] &&
            data[index]["supply_balance_underlying"]["value"]
            ? data[index]["supply_balance_underlying"]["value"]
            : 0;
      }
    } else if (id === "repay") {
      let data = borrowList;
      let index = data.findIndex((x) =>
        x.symbol[0] === "c" ? x.symbol.substring(1) === type : x.symbol === type
      );
      if (index !== -1) {
        let borrow_balance_underlying =
          data[index]["borrow_balance_underlying"] &&
            data[index]["borrow_balance_underlying"]["value"]
            ? data[index]["borrow_balance_underlying"]["value"]
            : 0;
        let lifetime_borrow_interest_accrued =
          data[index]["lifetime_borrow_interest_accrued"] &&
            data[index]["lifetime_borrow_interest_accrued"]["value"]
            ? data[index]["lifetime_borrow_interest_accrued"]["value"]
            : 0;
        balance = borrow_balance_underlying - lifetime_borrow_interest_accrued;
      }
    } else {
      if (type !== "ETH") {
        let reserve = `${state.network}Reserve`
        const { instance, decimal } = readContractByName(state.protocol,
          type,
          reserve
        );
        balance = await instance.methods.balanceOf(state.accountId).call();
        balance = new BigNumber(balance).div(
          new BigNumber(10).pow(decimal)).toNumber();
      } else {
        balance = getCookie("balance");
        balance = balance.replace(/ .*/, "");
      }
    }
    obj["balance"] = balance;
    setState({
      ...state,
      [id]: obj,
    });
  };
  const handleChange = (e, id) => {
    let stateObj = { ...state }
    let obj = state[id];
    let name = "";
    let value = "";
    if (typeof e !== "string") {
      name = e.target.name;
      value = e.target.value;
      stateObj[id][name] = value;
    } else {
      stateObj[id] = e
    }
    stateObj["afterType"] = id;
    UpdateSupplyingTable(value, id);
    if (id === "supply") {
      stateObj["withDraw"]["value"] = "";
    } else if (id === "withDraw") {
      stateObj["supply"]["value"] = "";
    } else if (id === "advanceRepay") {
      stateObj["advanceBoost"]["value"] = "";
    } else if (id === "advanceBoost") {
      stateObj["advanceRepay"]["value"] = "";
    } else if (id === "repay") {
      stateObj["borrow"]["value"] = "";
    } else if (id === "borrow") {
      stateObj["repay"]["value"] = "";
    } else if (id === "activeAccountTab") {
      stateObj["advanceRepay"]["value"] = "";
      stateObj["advanceBoost"]["value"] = "";
      stateObj["supply"]["value"] = "";
      stateObj["withDraw"]["value"] = "";
      stateObj["borrow"]["value"] = "";
      stateObj["repay"]["value"] = "";
    }
    setState(stateObj);
  };
  const UpdateSupplyingTable = (value, id) => {
    if (document.getElementById("Supplying")) {
      var tbodyRef = document
        .getElementById("Supplying")
        .getElementsByTagName("tbody")[0];
      if (value && id === "supply") {
        tbodyRef.classList.add("supply-body");
        tbodyRef.classList.remove("withDraw-body");
      } else if (!value && id === "supply") {
        tbodyRef.classList.remove("supply-body");
      } else if (value && id === "withDraw") {
        tbodyRef.classList.add("withDraw-body");
        tbodyRef.classList.remove("supply-body");
      } else if (!value && id === "withDraw") {
        tbodyRef.classList.remove("withDraw-body");
      }
    }
  }
  const refresh = () => {
    updateFirstLoad(false);
    props.clearContractsWithSnapshotAction();
    getSupplyListingData();
    props.getCompoundMarketPrice(state.network);
    updateMarketInfoList([]);
    updateSupplyList([]);
    updateBorrowList([]);
    resetState(false);
    checkSmartWallet();
  };
  const isShowSupplyBorrow = (data, key) => {
    let isShow = false;
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element[key] && element[key]["value"] > 0) {
          isShow = true;
        }
      }
    }
    return isShow;
  };
  const getIsShowAfter = () => {
    let isShowSupplyAfter = false;
    let isShowBorrowAfter = false;
    let selectedToken = state[state.afterType];
    let type = "";
    if (state.supply.type !== "" &&
      state.supply.value !== "" &&
      parseFloat(state.supply.value) !== 0) {
      isShowSupplyAfter = true;
      type = state.supply.type;
    } else if (state.withDraw.type !== "" &&
      state.withDraw.value !== "" &&
      parseFloat(state.withDraw.value) !== 0) {
      type = state.withDraw.type;
      isShowSupplyAfter = true;
    } else if (state.advanceBoost.value !== "" && parseFloat(state.advanceBoost.value) !== 0 && state.advanceBoost.supplytype !== "" && state.advanceBoost.borrowtype !== "") {
      type = state.advanceBoost.supplytype;
      isShowSupplyAfter = true;
      isShowBorrowAfter = true;
    }
    if (state.borrow.type !== "" &&
      state.borrow.value !== "" &&
      parseFloat(state.borrow.value) !== 0) {
      type = state.borrow.type;
      isShowBorrowAfter = true;
    } else if (state.repay.type !== "" &&
      state.repay.value !== "" &&
      parseFloat(state.repay.value) !== 0) {
      type = state.repay.type;
      isShowBorrowAfter = true;
    } else if (state.advanceRepay.value !== "" && parseFloat(state.advanceRepay.value) !== 0 && state.advanceRepay.repaytype !== "" && state.advanceRepay.repaytype !== "") {
      type = state.advanceRepay.repaytype;
      isShowSupplyAfter = true;
      isShowBorrowAfter = true;
    }
    let activeMarketInfoToken = {};
    if ((isShowSupplyAfter || isShowBorrowAfter) && state.afterType) {
      if (type) {
        let index = marketInfoList.findIndex(
          (x) => x.underlying_symbol === type
        );
        if (index !== -1) {
          activeMarketInfoToken = marketInfoList[index];
        }
      }

    }
    return { isShowSupplyAfter, isShowBorrowAfter, activeMarketInfoToken, selectedToken }
  }
  const checkSmartWallet = async (accountId) => {
    try {
      setCheckingSmaerWallet(true);
      const { instance } = readContractByName(state.protocol,
        "ProxyRegistry",
        state.network
      );
      const proxies = await instance.methods.proxies(accountId ? accountId : state.accountId).call();
      if (proxies !== "0x0000000000000000000000000000000000000000") {
        setSmartWalletAddress(proxies);
      }
      setCheckingSmaerWallet(false);
    } catch {
      setCheckingSmaerWallet(false);
    }
  };
  async function createDSProxyWallet() {
    const { instance } = readContractByName(state.protocol,
      "ProxyRegistry",
      state.network
    );
    let transactionStatus = "creating";
    let USD = 0;
    let tokenIndex = marketInfoList.findIndex(
      (x) => x.underlying_symbol === state.supply.type
    );
    if (tokenIndex !== -1) {
      let cToken = marketInfoList[tokenIndex];
      USD = cToken.pricing.price ? cToken.pricing.price : 0;
    }

    let gasLimit = {};
    let data = await getAllEstimationGasPrice(instance.methods
      .build(state.accountId), {}, props.currentGasPrice, state.accountId);
    if (!data.isError) {
      gasLimit = data.gasPrice;
      createDSProxyWalletToastId = toast(
        <TransactionToast
          status={transactionStatus}
          USD={USD}
          gasLimit={gasLimit}
          gasPrice={props.currentGasPrice}
          handleSubmit={(gasPrice, gasLimit) => acceptGasPrice(gasPrice, gasLimit, instance)}
          label={`Create smart wallet`}
        />
      );
    } else {
      createDSProxyWalletToastId = toast(
        <TransactionToast message={data.error} status={"error"} label={`Creating smart wallet`} />
      );
    }

  }
  const acceptGasPrice = async (gasPrice, gasLimit, instance) => {
    if (instance === undefined) {
      let contract = readContractByName(state.protocol,
        "ProxyRegistry",
        state.network
      );
      instance = contract.instance;
    }
    toast.update(createDSProxyWalletToastId, {
      render: () => <TransactionToast status={"inprogress"} label={`Creating smart wallet`} />,
    });
    try {
      const tx = await instance.methods
        .build(state.accountId)
        .send({ from: state.accountId, gasPrice: gasPrice, gas: gasLimit });
      if (tx) {
        refresh();
        toast.update(createDSProxyWalletToastId, {
          render: () => <TransactionToast status={"confirm"} label={`Smart wallet created`} />,
        });
      }
    } catch (e) {
      toast.update(createDSProxyWalletToastId, {
        render: () => <TransactionToast message={e.message} status={"error"} label={`Creating smart wallet`} />,
      });
    }
  };
  const copyToClipboard = (e) => {
    // let tooltipItem = $(e.currentTarget.children[0].children[0]);
    // tooltipItem.data("class", "custom-tooltip copy");
    // console.log(tooltipItem);
    // // copy
    // debugger
  }
  let { isShowSupplyAfter, isShowBorrowAfter, activeMarketInfoToken, selectedToken } = getIsShowAfter();
  let allowTransaction = props.accountDetail.connectedWallet !== "trackAddress"
  return (
    <React.Fragment>
      {!state.isValidNetwork ?
        <div className="width-container">
          <div className="alert alert-danger" role="alert">Connected network not supported with compound</div>
        </div>
        : ""}
      <div className={`width-container ${!state.isValidNetwork ? "disabled" : ""}`}>
        <div className="main-section-wrapper">
          <div className="dashboard-header-wrapper compound-manage-sub-header-wrapper width-container">
            <div className="wallet-type-select-wrapper">
              <div className="label">Account type:</div>
              <div className="select-container">
                <Dropdown>
                  <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                    {compoundType === "account"
                      ? "Account"
                      : "Smart Wallet"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {["account", "smartWallet"].map((item, index) => {
                      return <Dropdown.Item key={index}
                        onClick={() => compundTypeHandler(item)}
                        className={`${compoundType === item ? "active" : ""
                          }`}
                      >
                        {item === "smartWallet"
                          ? "Smart Wallet" : item.capitalizeString()}
                      </Dropdown.Item>
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="">
                  <span>
                    <button
                      type="button"
                      onClick={() => handleModalChange(true, "smartwallet")}
                      className="button mid dark with-icon wallet"
                    >
                      <i></i>
                    </button>
                  </span>
                </div>
                <div className="">
                  <span>
                    <button
                      type="button"
                      onClick={() => handleModalChange(true, "compTokens", compoundType)}
                      className="button btn-Secondary mini dark with-icon comp"
                    >
                      <i></i> COMP
                    </button>
                  </span>
                </div>
              </div>
              <div className="Flex moneymarket-sub-header-right-wrapper right ">
                <div className="data-item-wrapper arrow  ">
                  <div className="label-value-wrapper">
                    <div className="label-wrapper">
                      <div className="">
                        <span data-background-color="#5383FF" data-html="true" data-tip="Yearly interset percentage on supplied funds.Changes based on market supply and demand." data-class="custom-tooltip">
                          <i className="icon icon-Info-circle"></i>
                        </span>
                      </div>
                      <span className="label">Net APY:</span>
                    </div>
                    <div className="value-wrapper">
                      <div className="">
                        <span>
                          {state.netAPY ? state.netAPY.toFixedNoRounding(
                            2
                          ) : 0}<span className="symbol false">%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {checkingSmaerWallet ? null : (
            <>
              {compoundType === "smartWallet" &&
                smartWalletAddress === "" ? (
                <SmartWallet
                  handleModalChange={handleModalChange}
                  createDSProxyWallet={createDSProxyWallet}
                  allowTransaction={allowTransaction}
                />
              ) : (
                <Account
                  supplyBalance={state.supplyBalance}
                  supplyList={supplyList}
                  borrowList={borrowList}
                  leftToBorrow={state.leftToBorrow}
                  borrowBalance={state.borrowBalance}
                  activeMarketInfoToken={activeMarketInfoToken}
                  supply={state.supply}
                  ethPrice={state.ethPrice}
                  marketInfoList={marketInfoList}
                  borrow={state.borrow}
                  withDraw={state.withDraw}
                  borrowLimit={state.borrowLimit}
                  advanceBoost={state.advanceBoost}
                  advanceRepay={state.advanceRepay}
                  isShowAfter={isShowSupplyAfter || isShowBorrowAfter}
                  afterType={state.afterType}
                  repay={state.repay}
                  selectedToken={selectedToken}
                  safetyRatio={state.safetyRatio}
                  netAPY={state.netAPY}
                  copyToClipboard={copyToClipboard}
                />
              )}
            </>
          )}
        </div>
        {/* Account data start */}
        {compoundType === "account" ||
          (compoundType === "smartWallet" && smartWalletAddress !== "") ? (
          <AccountTabs
            network={state.network}
            borrow={state.borrow}
            supplyList={supplyList}
            cTokenContracts={props.contracts}
            borrowList={borrowList}
            cTokensList={marketInfoList}
            currentGasPrice={props.currentGasPrice}
            withDraw={state.withDraw}
            supply={state.supply}
            showModal={state.showModal}
            modalType={state.modalType}
            isAdvance={state.isAdvance}
            handleChange={handleChange}
            enableSupplyTokens={state.enableSupplyTokens}
            handleTypeChange={handleTypeChange}
            borrowLimit={state.borrowLimit}
            refresh={refresh}
            repay={state.repay}
            advanceRepay={state.advanceRepay}
            advanceBoost={state.advanceBoost}
            handleModalChange={handleModalChange}
            compundTypeHandler={compundTypeHandler}
            setSmartWalletAddress={setSmartWalletAddress}
            ApiRequestedAction={props.ApiRequestedAction}
            smartWalletAddress={smartWalletAddress}
            compoundType={compoundType}
            accountId={state.accountId}
            activeAccountTab={state.activeAccountTab}
            compReward={compReward}
            protocol={state.protocol}
            allowTransaction={allowTransaction}
          />
        ) : (
          ""
        )}
        {/* Acccount data end */}
        {(supplyList.length > 0 &&
          isShowSupplyBorrow(supplyList, "supply_balance_underlying")) ||
          isShowSupplyAfter ? (
          <SupplyListing
            supplyList={supplyList}
            supply={state.supply}
            withDraw={state.withDraw}
            selectedToken={selectedToken}
            enablerHandler={enablerHandler}
            isShowAfter={isShowSupplyAfter}
            enableSupplyTokens={state.enableSupplyTokens}
            marketInfoList={marketInfoList}
            apiCallStatus={props.apiCallStatus}
            afterType={state.afterType}
            allowTransaction={allowTransaction}
          />
        ) : (
          ""
        )}
        {(supplyList.length > 0 &&
          isShowSupplyBorrow(borrowList, "borrow_balance_underlying")) ||
          isShowBorrowAfter ? (
          <BorrowListing
            borrowList={borrowList}
            borrow={state.borrow}
            selectedToken={selectedToken}
            isShowAfter={isShowBorrowAfter}
            apiCallStatus={props.apiCallStatus}
            borrowLimit={state.borrowLimit}
            marketInfoList={marketInfoList}
            repay={state.repay}
            afterType={state.afterType}
          />
        ) : (
          ""
        )}
        <MarketInfoListing
          marketInfoList={marketInfoList}
          apiCallStatus={props.apiCallStatus}
        />
        <SmartWalletModal
          showModal={state.showModal && state.modalType === "smartwallet"}
          handleModalChange={handleModalChange}
          smartWalletAddress={smartWalletAddress}
        />
        <CreateDSProxyModal
          showModal={state.showModal && state.modalType === "createDSProxy"}
          handleModalChange={handleModalChange}
          createSmartWallet={createDSProxyWallet}
          currentGasPrice={props.currentGasPrice}
          tokenPriceList={props.tokenPriceList}
          accountId={state.accountId}
          protocol={state.protocol}
        />
      </div>
      <ReactTooltip />
      {
        props.apiCallStatus.isStarted.length !== 0 && props.apiCallStatus.apiCallFor !== "connectWallet" && props.apiCallStatus.apiCallFor !== "updateTokenPrice" && isFirstLoad ?
          <Loader isFullPage={true} message="Connecting your account, please wait..." /> : ""
      }
    </React.Fragment >
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  marketInfoList: state.compoundReducer.marketInfo.list,
  supplyList: state.compoundReducer.supplyList,
  cTokensList: state.compoundReducer.cTokensList,
  tokenPriceList: state.compoundReducer.tokenPriceList,
  currentGasPrice: state.commonReducer.currentGasPrice,
  accountDetail: state.commonReducer.accountDetail,
  contracts: state.compoundReducer.contracts
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCompoundMarketPrice: (network) =>
    dispatch(getCompoundMarketPrice(network)),
  getSupplyListing: (address, network) =>
    dispatch(getSupplyListing(address, network)),
  getCTokenList: (network) => dispatch(getCTokenList(network)),
  getTokenPrices: () => dispatch(getTokenPrices()),
  getAccountSnapshotOfAllCTokens: (list, network, accountId) => dispatch(getAccountSnapshotOfAllCTokens(list, network, accountId)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  ApiRequestedAction: (data) => dispatch(ApiRequestedAction(data)),
  clearContractsWithSnapshotAction: () => dispatch(clearContractsWithSnapshotAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(Manage);
