import {
  getVenusFinancePrices,
  getVenusSupplyList,
  getVTokenList,
  getVTokenPriceList,
  getContractsWithSnapshot,
  clearContractsWithSnapshot,
} from "../Actions/action";
import InitialState from "../../../constants/initialReducersState";

export default function CompoundReducer(state = InitialState.compound, action) {
  switch (action.type) {
    case getVenusFinancePrices:
      let s = { ...state };
      s.marketInfo = action.payload;
      return s;

    case getVenusSupplyList:
      let s1 = { ...state };
      s1.supplyList = action.payload;
      return s1;

    case getVTokenList:
      let s2 = { ...state };
      s2.cTokensList = action.payload;
      return s2;

    case getVTokenPriceList:
      let s3 = { ...state };
      s3.tokenPriceList = action.payload;
      return s3;

    case getContractsWithSnapshot:
      let s4 = { ...state };
      s4.contracts = action.payload;
      return s4;

    case clearContractsWithSnapshot:
      let s5 = { ...state };
      s5.contracts = {};
      return s5;

    default:
      return state;
  }
}
