export const network = process.env.REACT_APP_COMPOUND_NETWORK
  ? process.env.REACT_APP_COMPOUND_NETWORK
  : "mainnet";
// export const network = "kovan";
export const venusNetwork = process.env.REACT_APP_VENUS_NETWORK
  ? process.env.REACT_APP_VENUS_NETWORK
  : "mainnet";
export const compoundChainId = process.env.REACT_APP_COMPOUND_CHAINID
  ? process.env.REACT_APP_COMPOUND_CHAINID
  : 1;
// export const compoundChainId = 42;
export const etherscanApiKey = "MJYQGWI55NA9JS2Y7GVM8CX9H8SZHXPRYX";
export const etherscanAPIURL = "https://api-ropsten.etherscan.io/api";
export const defiExploreBaseUrl = "https://defiexplore.com/api/";
export const backendApiUrl = "https://apielixy2021.elixyr.net/api/";
export const compoundFinanceApiUrl = "https://api.compound.finance/api/v2/";
export const venusApiUrl = {
  mainnet: "https://api.venus.io/api/",
  testnet: "https://testnetapi.venus.io/api/",
};
export const aaveApiBaseUrl = "https://aave-api-v2.aave.com/";
export const aaveReferralCode = process.env.REACT_APP_AAVE_REFERRAL_CODE
  ? process.env.REACT_APP_AAVE_REFERRAL_CODE
  : 0;
export const infuraId = process.env.REACT_APP_INFURAID
  ? process.env.REACT_APP_INFURAID
  : "";
export const infuraUrl = `https://${network}.infura.io/v3/`;
export const etherscanUrl =
  network === "ropsten"
    ? "https://ropsten.etherscan.io"
    : network === "kovan"
    ? "https://kovan.etherscan.io"
    : "https://etherscan.io";
export const etheriumProvider = window.ethereum
  ? window.ethereum
  : `${infuraUrl}${infuraId}`;
export const bscscanUrl =
  network === "testnet" ? "https://bscscan.com" : "https://testnet.bscscan.com";
export const defiexploreApiUrl = "https://defiexplore.com/api";
export const compoundNetworks = ["mainnet", "ropsten", "kovan"];
export const venusNetworks = ["mainnet", "testnet"];
export const aavePoolId = {
  v1: {
    mainnet: "0x24a42fD28C976A61Df5D00D0599C34c4f90748c8",
    kovan: "0x24a42fD28C976A61Df5D00D0599C34c4f90748c8", //"0x506B0B2CF20FAA8f38a4E2B524EE43e1f4458Cc5",
  },
  v2: {
    mainnet: "0xb53c1a33016b2dc2ff3653530bff1848a515c8c5",
    kovan: "0xb53c1a33016b2dc2ff3653530bff1848a515c8c5", //"0x506B0B2CF20FAA8f38a4E2B524EE43e1f4458Cc5",
  },
};

export const ctokenUnderLyingAddress = {
  ropsten: [
    { address: "0x50390975d942e83d661d4bde43bf73b0ef27b426", name: "BAT" },
    { address: "0xf76d4a441e4ba86a923ce32b89aff89dbccaa075", name: "COMP" },
    { address: "0x31f42841c2db5173425b5223809cf3a38fede360", name: "DAI" },
    { address: "0xc778417E063141139Fce010982780140Aa0cD5Ab", name: "ETH" },
    { address: "0xb1cba8b721c7a241b9ad08c17f328886b014acfe", name: "REP" },
    { address: "0x63f7ab2f24322ae2ead6b971cb9a71a1cc2eee03", name: "SAI" },
    { address: "0xc8f88977e21630cf93c02d02d9e8812ff0dfc37a", name: "UNI" },
    { address: "0x07865c6e87b9f70255377e024ace6630c1eaa37f", name: "USDC" },
    { address: "0x110a13fc3efe6a245b50102d2d79b3e76125ae83", name: "USDT" },
    { address: "0x442be68395613bdcd19778e761f03261ec46c06d", name: "WBTC" },
    { address: "0xc0e2d7d9279846b80eacdea57220ab2333bc049d", name: "ZRX" },
  ],
  mainnet: [
    { address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", name: "ETH" },
    { address: "0x6b175474e89094c44da98b954eedeac495271d0f", name: "DAI" },
    { address: "0x0d8775f648430679a709e98d2b0cb6250d2887ef", name: "BAT" },
    { address: "0xe41d2489571d322189246dafa5ebde1f4699f498", name: "ZRX" },
    { address: "0x1985365e9f78359a9b6ad760e32412f4a445e862", name: "REP" },
    { address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", name: "USDC" },
    { address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", name: "WBTC" },
    { address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", name: "WBTC2" },
    { address: "0xdac17f958d2ee523a2206206994597c13d831ec7", name: "USDT" },
    { address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984", name: "UNI" },
    { address: "0xc00e94cb662c3520282e6f5717214004a7f26888", name: "COMP" },
    { address: "0x0000000000085d4780b73119b644ae5ecd22b376", name: "TUSD" },
    { address: "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359", name: "SAI" },
  ],
  kovan: [
    { address: "0xd3a691c852cdb01e281545a27064741f0b7f6825", name: "WBTC" },
    { address: "0x50dd65531676f718b018de3dc48f92b53d756996", name: "REP" },
    { address: "0x162c44e53097e7b5aae939b297fffd6bf90d1ee3", name: "ZRX" },
    { address: "0xd1308f63823221518ec88eb209cbaa1ac182105f", name: "SAI" },
    { address: "0x07de306ff27a2b630b1141956844eb1552b956b5", name: "USDT" },
    { address: "0x482dc9bb08111cb875109b075a40881e48ae02cd", name: "BAT" },
    { address: "0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa", name: "DAI" },
    { address: "0xd0A1E359811322d97991E03f863a0C30C2cF029C", name: "ETH" },
    { address: "0xb7a4f3e9097c08da09517b5ab877f7a917224ede", name: "USDC" },
  ],
};
export const ctokenAddress = {
  ropsten: [
    { address: "0xaf50a5a6af87418dac1f28f9797ceb3bfb62750a", name: "BAT" },
    { address: "0x70014768996439f71c041179ffddce973a83eef2", name: "COMP" },
    { address: "0xbc689667c13fb2a04f09272753760e38a95b998c", name: "DAI" },
    { address: "0x859e9d8a4edadfedb5a2ff311243af80f85a91b8", name: "ETH" },
    { address: "0x2862065d57749f1576f48ef4393eb81c45fc2d88", name: "REP" },
    { address: "0x7ac65e0f6dba0ecb8845f17d07bf0776842690f8", name: "SAI" },
    { address: "0x65280b21167bbd059221488b7cbe759f9fb18bb5", name: "UNI" },
    { address: "0x2973e69b20563bcc66dc63bde153072c33ef37fe", name: "USDC" },
    { address: "0xf6958cf3127e62d3eb26c79f4f45d3f3b2ccded4", name: "USDT" },
    { address: "0x541c9cb0e97b77f142684cc33e8ac9ac17b1990f", name: "WBTC" },
    { address: "0x6b8b0d7875b4182fb126877023fb93b934dd302a", name: "ZRX" },
  ],
  mainnet: [
    { address: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5", name: "ETH" },
    { address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643", name: "DAI" },
    { address: "0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e", name: "BAT" },
    { address: "0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407", name: "ZRX" },
    { address: "0x158079ee67fce2f58472a96584a73c7ab9ac95c1", name: "REP" },
    { address: "0x39aa39c021dfbae8fac545936693ac917d5e7563", name: "USDC" },
    { address: "0xc11b1268c1a384e55c48c2391d8d480264a3a7f4", name: "WBTC" },
    { address: "0xccf4429db6322d5c611ee964527d42e5d685dd6a", name: "WBTC2" },
    { address: "0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9", name: "USDT" },
    { address: "0x35a18000230da775cac24873d00ff85bccded550", name: "UNI" },
    { address: "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4", name: "COMP" },
    { address: "0x12392f67bdf24fae0af363c24ac620a2f67dad86", name: "TUSD" },
  ],
  kovan: [
    { address: "0xa1faa15655b0e7b6b6470ed3d096390e6ad93abb", name: "WBTC" },
    { address: "0xa4ec170599a1cf87240a35b9b1b8ff823f448b57", name: "REP" },
    { address: "0xaf45ae737514c8427d373d50cd979a242ec59e5a", name: "ZRX" },
    { address: "0xb3f7fb482492f4220833de6d6bfcc81157214bec", name: "SAI" },
    { address: "0x3f0a0ea2f86bae6362cf9799b523ba06647da018", name: "USDT" },
    { address: "0x4a77faee9650b09849ff459ea1476eab01606c7a", name: "BAT" },
    { address: "0xf0d0eb522cfa50b716b3b1604c4f0fa6f04376ad", name: "DAI" },
    { address: "0x41b5844f4680a8c38fbb695b7f9cfd1f64474a72", name: "ETH" },
    { address: "0x4a92e71227d294f041bd82dd8f78591b75140d63", name: "USDC" },
  ],
};
export const vtokenAddress = {
  mainnet: [
    { address: "0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8", name: "USDC" }, // - vUSDC
    { address: "0xfD5840Cd36d94D7229439859C0112a4185BC0255", name: "USDT" }, // - vUSDT
    { address: "0x95c78222B3D6e262426483D42CfA53685A67Ab9D", name: "BUSD" }, // - vBUSD
    { address: "0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0", name: "SXP" }, // - vSXP
    { address: "0xA07c5b74C9B40447a954e1466938b865b6BBea36", name: "BNB" }, // - vBNB
    { address: "0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D", name: "XVS" }, // - vXVS
    { address: "0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B", name: "BTC" }, // - vBTC
    { address: "0xf508fCD89b8bd15579dc79A6827cB4686A3592c8", name: "ETH" }, // - vETH
    { address: "0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B", name: "LTC" }, // - vLTC
    { address: "0xB248a295732e0225acd3337607cc01068e3b9c10", name: "XRP" }, // - vXRP
  ],
  testnet: [
    { address: "0xD5C4C2e2facBEB59D0216D0595d63FcDc6F9A1a7", name: "USDC" }, // - vUSDC
    { address: "0xb7526572FFE56AB9D7489838Bf2E18e3323b441A", name: "USDT" }, // - vUSDT
    { address: "0x08e0A5575De71037aE36AbfAfb516595fE68e5e4", name: "BUSD" }, // - vBUSD
    { address: "0x74469281310195A04840Daf6EdF576F559a3dE80", name: "SXP" }, // - vSXP
    { address: "0x2E7222e51c0f6e98610A1543Aa3836E092CDe62c", name: "BNB" }, // - vBNB
    { address: "0x6d6F697e34145Bb95c54E77482d97cc261Dc237E", name: "XVS" }, // - vXVS
  ],
};
export const aaveSmartWalletAddress = {
  mainnet: {
    BAT: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
    COMP: "",
    DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    REP: "0x1985365e9f78359a9B6AD760e32412f4a445E862",
    SAI: "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359",
    UNI: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    ZRX: "0xE41d2489571d322189246DaFA5ebDe1F4699F498",
  },
  ropsten: {
    BAT: "0x85B24b3517E3aC7bf72a14516160541A60cFF19d",
    COMP: "",
    DAI: "0xf80A32A835F79D7787E8a8ee5721D0fEaFd78108",
    ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    REP: "0xBeb13523503d35F9b3708ca577CdCCAdbFB236bD",
    SAI: "",
    UNI: "",
    USDC: "0x851dEf71f0e6A903375C1e536Bd9ff1684BAD802",
    USDT: "0xB404c51BBC10dcBE948077F18a4B8E553D160084",
    WBTC: "0xa0E54Ab6AA5f0bf1D62EC3526436F3c05b3348A0",
    ZRX: "0x02d7055704EfF050323A2E5ee4ba05DB2A588959",
  },
  kovan: {
    BAT: "0x2d12186Fbb9f9a8C28B3FfdD4c42920f8539D738",
    COMP: "",
    DAI: "0xFf795577d9AC8bD7D90Ee22b6C1703490b6512FD",
    ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    REP: "0x260071C8D61DAf730758f8BD0d6370353956AE0E",
    SAI: "",
    UNI: "",
    USDC: "0xe22da380ee6B445bb8273C81944ADEB6E8450422",
    USDT: "0x13512979ADE267AB5100878E2e0f485B568328a4",
    WBTC: "0x3b92f58feD223E2cB1bCe4c286BD97e42f2A12EA",
    ZRX: "0xD0d76886cF8D952ca26177EB7CfDf83bad08C00C",
  },
};
export const atokenUnderLyingAddress = {
  ropsten: [
    { address: "0x50390975d942e83d661d4bde43bf73b0ef27b426", name: "BAT" },
    { address: "0xf76d4a441e4ba86a923ce32b89aff89dbccaa075", name: "COMP" },
    { address: "0x31f42841c2db5173425b5223809cf3a38fede360", name: "DAI" },
    { address: "0xc778417E063141139Fce010982780140Aa0cD5Ab", name: "ETH" },
    { address: "0xb1cba8b721c7a241b9ad08c17f328886b014acfe", name: "REP" },
    { address: "0x63f7ab2f24322ae2ead6b971cb9a71a1cc2eee03", name: "SAI" },
    { address: "0xc8f88977e21630cf93c02d02d9e8812ff0dfc37a", name: "UNI" },
    { address: "0x07865c6e87b9f70255377e024ace6630c1eaa37f", name: "USDC" },
    { address: "0x110a13fc3efe6a245b50102d2d79b3e76125ae83", name: "USDT" },
    { address: "0x442be68395613bdcd19778e761f03261ec46c06d", name: "WBTC" },
    { address: "0xc0e2d7d9279846b80eacdea57220ab2333bc049d", name: "ZRX" },
  ],
  mainnet: [
    { address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", name: "ETH" },
    { address: "0x6b175474e89094c44da98b954eedeac495271d0f", name: "DAI" },
    { address: "0x0d8775f648430679a709e98d2b0cb6250d2887ef", name: "BAT" },
    { address: "0xe41d2489571d322189246dafa5ebde1f4699f498", name: "ZRX" },
    { address: "0x1985365e9f78359a9b6ad760e32412f4a445e862", name: "REP" },
    { address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", name: "USDC" },
    { address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", name: "WBTC" },
    { address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", name: "WBTC2" },
    { address: "0xdac17f958d2ee523a2206206994597c13d831ec7", name: "USDT" },
    { address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984", name: "UNI" },
    { address: "0xc00e94cb662c3520282e6f5717214004a7f26888", name: "COMP" },
    { address: "0x0000000000085d4780b73119b644ae5ecd22b376", name: "TUSD" },
    { address: "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359", name: "SAI" },
  ],
  kovan: [
    { address: "0xd3a691c852cdb01e281545a27064741f0b7f6825", name: "WBTC" },
    { address: "0x50dd65531676f718b018de3dc48f92b53d756996", name: "REP" },
    { address: "0x162c44e53097e7b5aae939b297fffd6bf90d1ee3", name: "ZRX" },
    { address: "0xd1308f63823221518ec88eb209cbaa1ac182105f", name: "SAI" },
    { address: "0x07de306ff27a2b630b1141956844eb1552b956b5", name: "USDT" },
    { address: "0x482dc9bb08111cb875109b075a40881e48ae02cd", name: "BAT" },
    { address: "0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa", name: "DAI" },
    { address: "0xd0A1E359811322d97991E03f863a0C30C2cF029C", name: "ETH" },
    { address: "0xb7a4f3e9097c08da09517b5ab877f7a917224ede", name: "USDC" },
  ],
};
export const atokenAddress = {
  ropsten: [
    { address: "0xaf50a5a6af87418dac1f28f9797ceb3bfb62750a", name: "BAT" },
    { address: "0x70014768996439f71c041179ffddce973a83eef2", name: "COMP" },
    { address: "0xbc689667c13fb2a04f09272753760e38a95b998c", name: "DAI" },
    { address: "0x859e9d8a4edadfedb5a2ff311243af80f85a91b8", name: "ETH" },
    { address: "0x2862065d57749f1576f48ef4393eb81c45fc2d88", name: "REP" },
    { address: "0x7ac65e0f6dba0ecb8845f17d07bf0776842690f8", name: "SAI" },
    { address: "0x65280b21167bbd059221488b7cbe759f9fb18bb5", name: "UNI" },
    { address: "0x2973e69b20563bcc66dc63bde153072c33ef37fe", name: "USDC" },
    { address: "0xf6958cf3127e62d3eb26c79f4f45d3f3b2ccded4", name: "USDT" },
    { address: "0x541c9cb0e97b77f142684cc33e8ac9ac17b1990f", name: "WBTC" },
    { address: "0x6b8b0d7875b4182fb126877023fb93b934dd302a", name: "ZRX" },
  ],
  mainnet: [
    { address: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5", name: "ETH" },
    { address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643", name: "DAI" },
    { address: "0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e", name: "BAT" },
    { address: "0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407", name: "ZRX" },
    { address: "0x158079ee67fce2f58472a96584a73c7ab9ac95c1", name: "REP" },
    { address: "0x39aa39c021dfbae8fac545936693ac917d5e7563", name: "USDC" },
    { address: "0xc11b1268c1a384e55c48c2391d8d480264a3a7f4", name: "WBTC" },
    { address: "0xccf4429db6322d5c611ee964527d42e5d685dd6a", name: "WBTC2" },
    { address: "0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9", name: "USDT" },
    { address: "0x35a18000230da775cac24873d00ff85bccded550", name: "UNI" },
    { address: "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4", name: "COMP" },
    { address: "0x12392f67bdf24fae0af363c24ac620a2f67dad86", name: "TUSD" },
  ],
  kovan: [
    { address: "0xa1faa15655b0e7b6b6470ed3d096390e6ad93abb", name: "WBTC" },
    { address: "0xa4ec170599a1cf87240a35b9b1b8ff823f448b57", name: "REP" },
    { address: "0xaf45ae737514c8427d373d50cd979a242ec59e5a", name: "ZRX" },
    { address: "0xb3f7fb482492f4220833de6d6bfcc81157214bec", name: "SAI" },
    { address: "0x3f0a0ea2f86bae6362cf9799b523ba06647da018", name: "USDT" },
    { address: "0x4a77faee9650b09849ff459ea1476eab01606c7a", name: "BAT" },
    { address: "0xf0d0eb522cfa50b716b3b1604c4f0fa6f04376ad", name: "DAI" },
    { address: "0x41b5844f4680a8c38fbb695b7f9cfd1f64474a72", name: "ETH" },
    { address: "0x4a92e71227d294f041bd82dd8f78591b75140d63", name: "USDC" },
  ],
};
