// Get Compound Finance Const
export const getCompoundFinancePrices = "GET_COMPOUND_FINANCE_PRICES";
// Get Supply List Const
export const getCompoundSupplyList = "GET_COMPOUND_SUPPLY_LIST";
// Get CToken Const
export const getCTokenList = "GET_COMPOUND_CTOKEN_LIST";
// Get CToken Price
export const getTokenPriceList = "GET_COMPOUND_TOKEN_PRICE_LIST";
//  Get all contracts with snapshot
export const getContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT";
//  Clear all contracts with snapshot
export const clearContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT";
// Actions

// Get Compound Finance Action
export function getCompoundFinancePricesAction(data) {
  return {
    type: getCompoundFinancePrices,
    payload: data,
  };
}

// Get Supply List Action
export function getCompoundSupplyListAction(data) {
  return {
    type: getCompoundSupplyList,
    payload: data,
  };
}

// Get Supply List Action
export function getCTokenListAction(data) {
  return {
    type: getCTokenList,
    payload: data,
  };
}
// Get price token list Action
export function getTokenPriceListAction(data) {
  return {
    type: getTokenPriceList,
    payload: data,
  };
}

// Get all contracts with snapshot Action
export function getContractsWithSnapshotAction(data) {
  return {
    type: getContractsWithSnapshot,
    payload: data,
  };
}

export function clearContractsWithSnapshotAction() {
  return {
    type: clearContractsWithSnapshot,
  };
}
