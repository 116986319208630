import { SvgLoader } from "react-svgmt";

let coins = {
    'DAI': 'dai.svg',
    'UNI': 'uni.svg',
    'USDC': 'usdc.svg',
    'USDT': 'usdt.svg',
    'WBTC': 'wbtc.svg',
    'WBTC2': 'wbtc.svg',
    'ETH': 'eth.svg',
    'ZRX': 'zrx.svg',
    'COMP': 'comp.svg',
    'BAT': 'bat.svg',
    'REP': 'rep.svg',
    'ADA': 'ada.svg',
    'BCH': 'bch.svg',
    // 'BETH': 'beth.png',
    'BNB': 'bnb.svg',
    'BTC': 'btc.svg',
    'BUSD': 'busd.svg',
    'DOT': 'dot.svg',
    'FIL': 'fil.svg',
    'LINK': 'link.svg',
    'LTC': 'ltc.svg',
    'SXP': 'sxp.svg',
    'XRP': 'xrp.svg',
    'XVS': 'xvs.svg',
    "ENJ": "enj.svg",
    "KNC": "knc.svg",
    "SNX": "snx.svg",
    "MKR": "mkr.svg",
    "LEND": "lend.svg",
    "AAVE": "aave.svg",
    "SUSD": "susd.svg",
    "REN": "ren.svg",
    "MANA": "mana.svg",
    "YFI": "yfi.svg",
    "TUSD": "tusd.svg",
    "BAL": "bal.svg",
    "CRV": "crv.svg",
    "GUSD": "gusd.svg",
    "XSUSHI": "xsushi.svg",
    "RENFIL": "renfil.svg",
}



export default function CoinIcon(props) {
    let isSvg = coins[props.name] ? coins[props.name].indexOf(".svg") !== -1 : false;
    // let path = require(`../../../assets/${isSvg ? "table-icons-svgs" : "table-icons"}/${coins[props.name] ? coins[props.name] : coins['DAI']}`).default;
    let path = require(`../../../assets/table-icons-svgs/${coins[props.name] ? coins[props.name] : coins['DAI']}`).default;

    // return <img className="icon" src={path} alt="" />
    return <SvgLoader width="30" height="30" path={path}>
    </SvgLoader>
}

let svgcoins = {
    'DAI': 'dai.svg',
    'UNI': 'uni.svg',
    'USDC': 'usdc.svg',
    'USDT': 'usdt.svg',
    'WBTC': 'wbtc.svg',
    'WBTC2': 'wbtc.svg',
    'ETH': 'eth.svg',
    'ZRX': 'zrx.svg',
    'COMP': 'comp.svg',
    'BAT': 'bat.svg',
    'REP': 'reb.svg',
    'ADA': 'ada.svg',
    'BCH': 'bch.svg',
    'BETH': 'beth.png',
    'BNB': 'bnb.svg',
    'BTC': 'btc.svg',
    'BUSD': 'busd.svg',
    'DOT': 'dot.svg',
    'FIL': 'fil.svg',
    'LINK': 'link.svg',
    'LTC': 'ltc.svg',
    'SXP': 'sxp.svg',
    'XRP': 'xrp.svg',
    'XVS': 'xvs.svg',
    "ENJ": "enj.svg",
    "KNC": "knc.svg",
    "SNX": "snx.svg",
    "MKR": "mkr.svg",
    "LEND": "lend.svg",
    "AAVE": "aave.svg",
    "SUSD": "susd.svg",
    "REN": "ren.svg",
    "MANA": "mana.svg",
    "YFI": "yfi.svg",
    "TUSD": "tusd.svg"
}



// function SVGCoinIcon(props) {
//     let isSvg = coins[props.name] ? coins[props.name].indexOf(".svg") !== -1 : false;
//     let path = require(`../../../assets/${isSvg ? "table-icons-svgs" : "table-icons"}/${coins[props.name] ? coins[props.name] : coins['DAI']}`).default;
//     console.log("path", path)
//     return isSvg ? <SvgLoader width="30" height="30" path={path}>
//     </SvgLoader> : <img className="icon" src={path} alt="" />
// }