import { defiExploreBaseUrl, backendApiUrl } from "../../../constants/constant";
import { FETCH } from "../../../utils/fetch";
import {
  ApiRequestedAction,
  ApiFulfilledAction,
  ApiRejectedAction,
} from "../../ApiCallStatus/Actions/action";
import {
  getEthPriceAction,
  getCurrentGasPriceAction,
  getGasPriceHistoryAction,
} from "../Actions/action";

// Apicall to get etherium price from defiexplorer
export function getEthPrice(address) {
  return (dispatch) => {
    let apiCallFor = "getEthPrice";
    dispatch(ApiRequestedAction({ apiCallFor }));
    let url = `${defiExploreBaseUrl}market/prices?addresses=${address}`;
    FETCH("GET", url)
      .then(function (myJson) {
        if (myJson && myJson.statusCode === 200) {
          dispatch(getEthPriceAction(myJson.response));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: myJson.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor,
              message: myJson.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// Apicall to get current gas price
export function getCurrentGasPrice() {
  return (dispatch) => {
    let apiCallFor = "getCurrentGasPrice";
    dispatch(ApiRequestedAction({ apiCallFor }));
    let url = `${backendApiUrl}gas-price/current`;
    FETCH("GET", url)
      .then(function (myJson) {
        if (myJson && myJson.statusCode === 200 && myJson.response.status) {
          let data = myJson.response.data;
          if (Object.size(myJson.response.data) > 0) {
            Object.keys(myJson.response.data).forEach((element) => {
              data[element] = (data[element] / 10).toFixedNoRounding(0);
            });
          }
          dispatch(getCurrentGasPriceAction(data));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: myJson.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor,
              message: myJson.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

// Apicall to get gas price history
export function getGasPriceHistory(days) {
  return (dispatch) => {
    let apiCallFor = "getGasPriceHistory";
    dispatch(ApiRequestedAction({ apiCallFor }));
    let url = `${backendApiUrl}gas-price/history?days=${days}`;
    FETCH("GET", url)
      .then(function (myJson) {
        if (myJson && myJson.statusCode === 200 && myJson.response.status) {
          var history = myJson.response.data.map(function (item, index) {
            item.average = item.average / 10;
            return item;
          });
          let { min, max } = getMinMaxPoint(history);
          dispatch(getGasPriceHistoryAction({ history, min, max }));
          dispatch(
            ApiFulfilledAction({
              apiCallFor,
              message: myJson.message,
            })
          );
        } else {
          dispatch(
            ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor,
              message: myJson.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          ApiRejectedAction({
            statusCode: error.statusCode,
            apiCallFor,
            message: error.message,
          })
        );
      });
  };
}

function getMinMaxPoint(arr) {
  arr.sort(function (a, b) {
    return a.Cost - b.Cost;
  });
  let min = arr[0] ? arr[0] : {};
  let max = arr[arr.length - 1] ? arr[arr.length - 1] : {};
  return { min, max };
}
