// Get Transaction History Const
export const getTransactionHistory = "GET_TRANSACTION_HISTORY_COMMON";
export const getPortFolioStats = "GET_PORTFOLIO_STATS";
// Actions

// Get Transaction History Action
export function getTransactionHistoryAction(data) {
  return {
    type: getTransactionHistory,
    payload: data,
  };
}
export function getPortFolioStatsAction(data) {
  return {
    type: getPortFolioStats,
    payload: data,
  };
}