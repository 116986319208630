import {
  getAaveFinancePrices,
  getCTokenList,
  getContractsWithSnapshot,
  clearContractsWithSnapshot,
} from "./../Actions/action";
import InitialState from "../../../constants/initialReducersState";

export default function AaveReducer(state = InitialState.aave, action) {
  switch (action.type) {
    case getAaveFinancePrices:
      let s = { ...state };
      s.marketInfo = action.payload;
      return s;

    case getCTokenList:
      let s2 = { ...state };
      s2.cTokensList = action.payload;
      return s2;

    case getContractsWithSnapshot:
      let s4 = { ...state };
      s4.contracts = action.payload;
      return s4;

    case clearContractsWithSnapshot:
      let s5 = { ...state };
      s5.contracts = {};
      return s5;

    default:
      return state;
  }
}
