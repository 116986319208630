import { Route, Switch } from "react-router";
import DashboardLayout from "../../../layout/DashboardLayout/dashboardLayout";
import Manage from '../Components/Manage/manage';
import Saver from '../Components/Saver/saver';

export default function Routes(props) {
    const links = [
        {
            name: `Manage`,
            url: "/compound/manage"
        },
        //  {
        //     name: `Automate</span><span className="badge">(BETA)</span>`,
        //     url: "/compound/saver"
        // }
    ]
    return <DashboardLayout name="Compound" links={links} wrapperClassName={props.location.pathname === "/compound/manage" ? "moneymarket-page-wrapper compound-page-wrapper" : "create-cdp-wrapper compound-create"}>
        <Switch>
            <Route exact path="/compound/manage" component={Manage} />
            <Route exact path="/compound/saver" component={Saver} />
        </Switch>
    </DashboardLayout>
}