import { combineReducers } from "redux";
import apicallStatusReducer from "../components/ApiCallStatus/Reducer/apicallStatusReducer";
import commonReducer from "../components/Common/Reducer/commonReducer";
import portfolioReducer from "../views/Portfolio/Reducer/portfolioReducer";
import compoundReducer from "../views/Compound/Reducer/compoundReducer";
import venusReducer from "../views/Venus/Reducer/venusReducer";
import aaveReducer from "../views/Aave/Reducer/aaveReducer";
export default combineReducers({
  apicallStatusReducer,
  commonReducer,
  portfolioReducer,
  compoundReducer,
  venusReducer,
  aaveReducer
});
