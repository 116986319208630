import { Fragment } from "react";
import { TableWrapper } from './tableWrapper';
import CoinIcon from "../../../../components/Common/Components/coinIcons";
import { millifyNumber } from '../../../../utils/common';

export function BorrowListing(props) {
    let data = props.borrowList;
    let type = "";
    if (props.afterType !== "advanceBoost" && props.afterType !== "advanceRepay") {
        type = props.selectedToken ? props.selectedToken.type : "";
    } else if (props.afterType === "advanceBoost") {
        type = props.selectedToken ? props.selectedToken.borrowtype : "";
    } else if (props.afterType === "advanceRepay") {
        type = props.selectedToken ? props.selectedToken.withDrawtype : "";
    }
    let value = props.selectedToken ? props.selectedToken.value : "";
    let token = {};
    let afterBorrowList = [];
    const getValues = (val1, val2) => {
        let v1 = Number(val1);
        let v2 = Number(val2);
        let result = 0;
        if (props.afterType === "borrow" || props.afterType === "advanceBoost") {
            result = v1 + v2;
        } else if (props.afterType === "repay" || props.afterType === "advanceRepay") {
            result = v1 - v2;
        }
        if (result > 1) {
            result = millifyNumber(result, {
                precision: 2,
                lowercase: false,
            });
        } else {
            result.toFixedNoRounding(2)
        }
        return result;
    };
    if (props.isShowAfter) {
        let cTokenIndex = data.findIndex(
            (x) => (x.symbol[0] === "c" ? x.symbol.substring(1) : x.symbol) === type
        );
        if (cTokenIndex !== -1) {
            token = { ...data[cTokenIndex] };
            token["symbol"] =
                token.symbol[0] === "c"
                    ? token.symbol.substring(1)
                    : token.symbol;
            token["supply_balance_underlying"] =
                token.supply_balance_underlying &&
                    token.supply_balance_underlying.value
                    ? token.supply_balance_underlying.value.toFixedNoRounding(2)
                    : 0;
            token["lifetime_supply_interest_accrued"] =
                token.lifetime_supply_interest_accrued &&
                    token.lifetime_supply_interest_accrued.value
                    ? token.lifetime_supply_interest_accrued.value.toFixedNoRounding(
                        2
                    )
                    : 0;
            afterBorrowList.push(token)
        } else {
            cTokenIndex = props.marketInfoList.findIndex(
                (x) => (x.symbol[0] === "c" ? x.symbol.substring(1) : x.symbol) === type
            );
            if (cTokenIndex !== -1) {
                data = props.marketInfoList;
                token = {
                    address: data[cTokenIndex].token_address,
                    borrow_balance_underlying: {
                        value: "0.0"
                    },
                    lifetime_borrow_interest_accrued: {
                        value: "0.0"
                    },
                    lifetime_supply_interest_accrued: 0,
                    safe_withdraw_amount_underlying: {
                        value: "0.0"
                    },
                    supply_balance_underlying: 0,
                    symbol: data[cTokenIndex].underlying_symbol,
                    tokenPrice: data[cTokenIndex].pricing.price
                };
                afterBorrowList.push(token)
            }
        }
    }
    return (
        <TableWrapper
            apiCallStatus={props.apiCallStatus}
            label="Borrowing"
            headers={[
                "Asset",
                "Borrowed",
                "Borrowed ($)",
                "Accured Debt",
                "Accured Debt ($)",
                "Limit",
            ]}
            isAfter={props.isShowAfter}
            data={data}
            apiName="getCompoundMarketPrice"
        >
            {!props.isShowAfter &&
                data.map((item, index) => {
                    let symbol =
                        item.symbol[0] === "c" ? item.symbol.substring(1) : item.symbol;
                    let borrow_balance_underlying =
                        item.borrow_balance_underlying &&
                            item.borrow_balance_underlying.value
                            ? item.borrow_balance_underlying.value
                            : 0;
                    return borrow_balance_underlying > 0 ? (
                        <tr className="first-level" key={index}>
                            <td>
                                <CoinIcon name={symbol} />
                                {symbol}
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            item.borrow_balance_underlying &&
                                                item.lifetime_borrow_interest_accrued
                                                ? item.borrow_balance_underlying.value -
                                                item.lifetime_borrow_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {item.borrow_balance_underlying &&
                                            item.lifetime_borrow_interest_accrued
                                            ? (
                                                item.borrow_balance_underlying.value -
                                                item.lifetime_borrow_interest_accrued.value
                                            ).toFixedNoRounding(2)
                                            : 0}{" "}
                                        {symbol}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            item.borrow_balance_underlying &&
                                                item.lifetime_borrow_interest_accrued
                                                ? (item.borrow_balance_underlying.value -
                                                    item.lifetime_borrow_interest_accrued.value) *
                                                item.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {item.borrow_balance_underlying &&
                                            item.lifetime_borrow_interest_accrued &&
                                            item.tokenPrice
                                            ? (
                                                (item.borrow_balance_underlying.value -
                                                    item.lifetime_borrow_interest_accrued.value) *
                                                item.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            item.lifetime_borrow_interest_accrued
                                                ? item.lifetime_borrow_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {item.lifetime_borrow_interest_accrued
                                            ? item.lifetime_borrow_interest_accrued.value.toFixedNoRounding(
                                                2
                                            )
                                            : 0}{" "}
                                        {symbol}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            item.lifetime_borrow_interest_accrued.value *
                                            item.tokenPrice
                                        }
                                    >
                                        ${" "}
                                        {item.lifetime_borrow_interest_accrued && item.tokenPrice
                                            ? (
                                                item.lifetime_borrow_interest_accrued.value *
                                                item.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            (item.borrow_balance_underlying.value /
                                                props.borrowLimit) *
                                            100
                                        }
                                    >
                                        {item.borrow_balance_underlying && props.borrowLimit
                                            ? (
                                                (item.borrow_balance_underlying.value /
                                                    props.borrowLimit) *
                                                100
                                            ).toFixedNoRounding(2)
                                            : 0}
                      %
                    </span>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <tr key={index}></tr>
                    );
                })}
            {props.isShowAfter ? (
                afterBorrowList.map((borrowToken, index) => {
                    return <Fragment key={index}>
                        <tr className="first-level">
                            <td>
                                <CoinIcon name={borrowToken.symbol ? borrowToken.symbol : type} />
                                {borrowToken.symbol ? borrowToken.symbol : type}
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.borrow_balance_underlying &&
                                                borrowToken.lifetime_borrow_interest_accrued
                                                ? borrowToken.borrow_balance_underlying.value -
                                                borrowToken.lifetime_borrow_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {borrowToken.borrow_balance_underlying &&
                                            borrowToken.lifetime_borrow_interest_accrued
                                            ? (
                                                borrowToken.borrow_balance_underlying.value -
                                                borrowToken.lifetime_borrow_interest_accrued.value
                                            ).toFixedNoRounding(2)
                                            : 0}{" "}
                                        {borrowToken.symbol ? borrowToken.symbol : type}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.borrow_balance_underlying &&
                                                borrowToken.lifetime_borrow_interest_accrued &&
                                                borrowToken.tokenPrice
                                                ? (borrowToken.borrow_balance_underlying.value -
                                                    borrowToken.lifetime_borrow_interest_accrued.value) *
                                                borrowToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {borrowToken.borrow_balance_underlying &&
                                            borrowToken.tokenPrice &&
                                            borrowToken.lifetime_borrow_interest_accrued
                                            ? (
                                                (borrowToken.borrow_balance_underlying.value -
                                                    borrowToken.lifetime_borrow_interest_accrued.value) *
                                                borrowToken.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.lifetime_borrow_interest_accrued
                                                ? borrowToken.lifetime_borrow_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {borrowToken.lifetime_borrow_interest_accrued
                                            ? borrowToken.lifetime_borrow_interest_accrued.value.toFixedNoRounding(
                                                2
                                            )
                                            : 0}{" "}
                                        {borrowToken.symbol ? borrowToken.symbol : type}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.lifetime_borrow_interest_accrued &&
                                                borrowToken.pricing &&
                                                borrowToken.tokenPrice
                                                ? borrowToken.lifetime_borrow_interest_accrued.value *
                                                borrowToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {borrowToken.lifetime_borrow_interest_accrued &&
                                            borrowToken.pricing &&
                                            borrowToken.tokenPrice
                                            ? (
                                                borrowToken.lifetime_borrow_interest_accrued.value *
                                                borrowToken.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.borrow_balance_underlying && props.borrowLimit
                                                ? (borrowToken.borrow_balance_underlying.value /
                                                    props.borrowLimit) *
                                                100
                                                : 0
                                        }
                                    >
                                        {borrowToken.borrow_balance_underlying && props.borrowLimit
                                            ? (
                                                (borrowToken.borrow_balance_underlying.value /
                                                    props.borrowLimit) *
                                                100
                                            ).toFixedNoRounding(2)
                                            : 0}
                    %
                  </span>
                                </div>
                            </td>
                        </tr>

                        <tr className="first-level">
                            <td> After:</td>
                            <td>
                                <div>
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={getValues(
                                            borrowToken.borrow_balance_underlying &&
                                                borrowToken.lifetime_borrow_interest_accrued
                                                ? borrowToken.borrow_balance_underlying.value -
                                                borrowToken.lifetime_borrow_interest_accrued.value
                                                : 0,
                                            value
                                        )}
                                    >
                                        {`${getValues(
                                            borrowToken.borrow_balance_underlying &&
                                                borrowToken.lifetime_borrow_interest_accrued
                                                ? borrowToken.borrow_balance_underlying.value -
                                                borrowToken.lifetime_borrow_interest_accrued.value
                                                : 0,
                                            value
                                        )} ${type}`}{" "}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken &&
                                                borrowToken.tokenPrice
                                                ? getValues(
                                                    borrowToken &&
                                                        borrowToken.tokenPrice
                                                        ? (borrowToken.borrow_balance_underlying.value -
                                                            borrowToken.lifetime_borrow_interest_accrued
                                                                .value) *
                                                        borrowToken.tokenPrice
                                                        : 0,
                                                    borrowToken.tokenPrice * value
                                                )
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {borrowToken &&
                                            borrowToken.tokenPrice
                                            ? getValues(
                                                borrowToken &&
                                                    borrowToken.tokenPrice
                                                    ? (borrowToken.borrow_balance_underlying.value -
                                                        borrowToken.lifetime_borrow_interest_accrued
                                                            .value) *
                                                    borrowToken.tokenPrice
                                                    : 0,
                                                borrowToken.tokenPrice * value,
                                                "millify"
                                            )
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.lifetime_borrow_interest_accrued
                                                ? borrowToken.lifetime_borrow_interest_accrued.value
                                                : 0
                                        }
                                    >
                                        {borrowToken.lifetime_borrow_interest_accrued
                                            ? borrowToken.lifetime_borrow_interest_accrued.value.toFixedNoRounding(
                                                2
                                            )
                                            : 0}{" "}
                                        {borrowToken.symbol ? borrowToken.symbol : type}
                                    </span>
                                </div>
                            </td>
                            <td className="">
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.lifetime_borrow_interest_accrued &&
                                                borrowToken.pricing &&
                                                borrowToken.tokenPrice
                                                ? borrowToken.lifetime_borrow_interest_accrued.value *
                                                borrowToken.tokenPrice
                                                : 0
                                        }
                                    >
                                        ${" "}
                                        {borrowToken.lifetime_borrow_interest_accrued &&
                                            borrowToken.pricing &&
                                            borrowToken.tokenPrice
                                            ? (
                                                borrowToken.lifetime_borrow_interest_accrued.value *
                                                borrowToken.tokenPrice
                                            ).toFixedNoRounding(2)
                                            : 0}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="">
                                    <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                        data-tip={
                                            borrowToken.borrow_balance_underlying && props.borrowLimit
                                                ? (borrowToken.borrow_balance_underlying.value /
                                                    props.borrowLimit) *
                                                100
                                                : 0
                                        }
                                    >
                                        {borrowToken.borrow_balance_underlying && props.borrowLimit
                                            ? (
                                                (borrowToken.borrow_balance_underlying.value /
                                                    props.borrowLimit) *
                                                100
                                            ).toFixedNoRounding(2)
                                            : 0}
                    %
                  </span>
                                </div>
                            </td>
                        </tr>
                    </Fragment>
                })

            ) : (
                <tr></tr>
            )}
        </TableWrapper>
    );
}