import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import PortfolioContainer from "./views/Portfolio/Components/portfolioContainer";
import History from "./views/Portfolio/Components/transactionHistory";
// import Venus from "./views/Venus/Routes/venusRoutes";
import Aave from "./views/Aave/Routes/aaveRoutes";
import Compound from "./views/Compound/Routes/compoundRoutes";
import SmartSaving from "./views/SmartSaving/Components/smartSaving";
import PrivacyPolicy from "./views/LandingPages/privacyPolicy";
import Terms from "./views/LandingPages/terms";
import Footer from "./components/Footer/footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Sidebar from "./components/sidebar/sidebar";
const createBrowserHistory = require("history").createBrowserHistory;
const history = createBrowserHistory();
export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="app">
          {/* <Sidebar /> */}
          <div className="main-content">
            <Switch>
              <Route exact path="/" component={PortfolioContainer} />
              <Route exact path="/transaction-history" component={History} />
              {/* <Route path="/venus" component={Venus} /> */}
              <Route path="/aave" component={Aave} />
              <Route path="/compound" component={Compound} />
              <Route path="/smart-savings" component={SmartSaving} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-of-service" component={Terms} />
            </Switch>
            <Footer />
          </div>
          <ToastContainer autoClose={false} closeOnClick={false} />
        </div>
      </Router>
    );
  }
}
