import { TableWrapper } from './tableWrapper';
import CoinIcon from "../../../../components/Common/Components/coinIcons";
import { millifyNumber } from '../../../../utils/common';

export function MarketInfoListing(props) {
    let data = props.marketInfoList;
    return (
        <TableWrapper
            apiCallStatus={props.apiCallStatus}
            label="Market info"
            headers={[
                "Asset",
                "Price",
                "Sup. APY",
                "Coll. fac.",
                "Bor. APY",
                <>
                    <img
                        className="icon"
                        src={require("../../../../assets/svgs/table/apy.svg").default}
                        alt=""
                    />
                    <div className="">
                        <span>Sup. APY</span>
                    </div>
                </>,
                <>
                    <img
                        className="icon"
                        src={require("../../../../assets/svgs/table/apy.svg").default}
                        alt=""
                    />
                    <div className="">
                        <span>Bor. APY</span>
                    </div>
                </>,
                "Mar. size",
                "Util.",
            ]}
            data={data}
            apiName="getCompoundMarketPrice"
        >
            {data.map((item, index) => {
                return (
                    <tr className="first-level" key={index}>
                        <td>
                            <CoinIcon name={item.underlying_symbol} />
                            {item.underlying_symbol}
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.pricing && item.pricing.price ? item.pricing.price : 0
                                    }
                                >
                                    $
                    {item.pricing && item.pricing.price
                                        ? item.pricing.price.toFixedNoRounding(2)
                                        : 0}
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span  data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.supply_rate.value * 100}>
                                    {(item.supply_rate.value * 100).toFixedNoRounding(2)}%
                  </span>
                            </div>
                        </td>
                        <td className="">
                            {(item.collateral_factor.value * 100).toFixedNoRounding(2)}%
              </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.borrow_rate.value * 100}>
                                    {(item.borrow_rate.value * 100).toFixedNoRounding(2)}%
                  </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.comp_supply_apy ? item.comp_supply_apy.value : 0
                                    }
                                >
                                    {item.comp_supply_apy
                                        ? (item.comp_supply_apy.value).toFixedNoRounding(2)
                                        : 0}
                    %
                  </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.comp_borrow_apy ? item.comp_borrow_apy.value : 0
                                    }
                                >
                                    {item.comp_borrow_apy
                                        ? (item.comp_borrow_apy.value).toFixedNoRounding(2)
                                        : 0}
                    %
                  </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip" data-tip={item.marketSize}>
                                    $
                    {item.marketSize ? item.marketSize > Number.MAX_SAFE_INTEGER ? item.marketSize : millifyNumber(item.marketSize, {
                                    precision: 1,
                                    lowercase: false,
                                }) : 0}
                                </span>
                            </div>
                        </td>
                        <td className="">
                            <div className="">
                                <span data-background-color="#5383FF" data-html="true" data-arrow-color="#5383FF" data-class="custom-tooltip"
                                    data-tip={
                                        item.comp_supply_apy && item.comp_borrow_apy
                                            ? Number(item.comp_supply_apy.value) + Number(item.comp_borrow_apy.value)
                                            : 0
                                    }
                                >
                                    {item.comp_supply_apy && item.comp_borrow_apy
                                        ? (
                                            Number(item.comp_supply_apy.value) + Number(item.comp_borrow_apy.value)
                                        ).toFixedNoRounding(2)
                                        : 0}
                    %
                  </span>
                            </div>
                        </td>
                    </tr>
                );
            })}
        </TableWrapper>
    );
}