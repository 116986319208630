// Get Aave Finance Const
export const getAaveFinancePrices = "GET_AAVE_FINANCE_PRICES";
// Get CToken Const
export const getCTokenList = "GET_AAVE_CTOKEN_LIST";
//  Get all contracts with snapshot
export const getContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT_AAVE";
//  Clear all contracts with snapshot
export const clearContractsWithSnapshot = "GET_CONTRACTS_WITH_SNAPSHOT_AAVE";
// Actions

// Get Compound Finance Action
export function getAaveFinancePricesAction(data) {
  return {
    type: getAaveFinancePrices,
    payload: data,
  };
}



// Get Supply List Action
export function getCTokenListAction(data) {
  return {
    type: getCTokenList,
    payload: data,
  };
}

// Get all contracts with snapshot Action
export function getContractsWithSnapshotAction(data) {
  return {
    type: getContractsWithSnapshot,
    payload: data,
  };
}

export function clearContractsWithSnapshotAction() {
  return {
    type: clearContractsWithSnapshot,
  };
}
